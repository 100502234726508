import type { ReactElement } from "react";
import { useContext, useEffect, useState } from "react";
import { Button, Modal, Typography } from "antd";
import { useIdleTimer } from "react-idle-timer";
import AuthContext from "../../../store/auth-context";
import { setAuthSessionTerminationState } from "../../../store/localStoreHelper";
import { AUTH_SESSION_TERMINATION_STATE } from "../../../helpers/auth";

enum SessionState {
  Idle = "Idle",
  Active = "Active",
  Prompted = "Prompted",
}

const USER_SESSION_DURATION = 1000 * 60 * 60; // 1 hour
const PROMPT_BEFORE_TIMEOUT = 1000 * 60; // 1 min
const THROTTLE = 1000; // 1 sec

const IdleSession = (): ReactElement => {
  const authCtx = useContext(AuthContext);
  const { isLoggedIn, logout } = authCtx;

  const [state, setState] = useState<SessionState>(SessionState.Active);
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const onIdle = () => {
    setState(SessionState.Idle);
    setAuthSessionTerminationState(AUTH_SESSION_TERMINATION_STATE.IDLE_TIMEOUT);
    setModalOpen(false);
    logout();
  };

  const onActive = () => {
    if (state === SessionState.Idle) {
      // Forbid continue current session if it timed out
      return;
    }

    setState(SessionState.Active);
    setModalOpen(false);
  };

  const onPrompt = () => {
    setState(SessionState.Prompted);
    setModalOpen(true);
  };

  const { activate, start, pause } = useIdleTimer({
    onIdle,
    onActive,
    onPrompt,
    timeout: USER_SESSION_DURATION,
    promptBeforeIdle: PROMPT_BEFORE_TIMEOUT,
    throttle: THROTTLE,
    crossTab: true,
    leaderElection: true,
    syncTimers: Math.round(THROTTLE / 2),
  });

  useEffect(() => {
    if (isLoggedIn) {
      start();
    } else {
      pause();
    }
  }, [isLoggedIn, start, pause]);

  return (
    <Modal
      data-testid="idle-session-modal"
      className="modal-idle-session"
      open={modalOpen}
      centered={true}
      closable={false}
      keyboard={false}
      footer={[
        <Button key="sign_out" onClick={logout}>
          Sign out
        </Button>,
        <Button key="stay_alive" type="primary" onClick={activate}>
          Stay signed in
        </Button>,
      ]}
    >
      <Typography.Title level={3}>Are you still here?</Typography.Title>
      <Typography.Paragraph strong>Please confirm you're still here, otherwise your session will over soon.</Typography.Paragraph>
    </Modal>
  );
};

export default IdleSession;
