import * as THREE from "three";
import { appModel } from "../../models/AppModel";
import RoomManager from "../managers/RoomManager/RoomManager";
import { Direction } from "../models/Direction";
import { SnapData } from "../models/SnapData";
import GeometryUtils from "../utils/GeometryUtils";
import SceneUtils from "../utils/SceneUtils";
import UnitsUtils from "../utils/UnitsUtils";
import { WallAnalysisUtils } from "../utils/WallAnalysisUtils";
import { MessageKindsEnum, showToastMessage } from "../../helpers/messages";
import MathUtils from "../utils/MathUtils";
import { Side } from "../../models/Side";
import { Segment } from "../models/segments/Segment";
import { RoomEntityType } from "../../models/RoomEntityType";
import {
  GRID_MAJOR_CELL_RATIO,
  GRID_MINOR_CELL_RATIO,
  GRID_MIN_CELL_SIZE_CUTOFF_2D,
  MESSAGE_DURATION,
  MODEL_LINE_COLOR,
  MODEL_LINE_RENDER_ORDER,
  SNAP_ERROR_MESSAGE,
  SNAP_WARNING_MESSAGE,
} from "../consts";

enum FloorMode {
  Same = "Same",
  Other = "Other",
  All = "All",
}

const H = Direction.Horizontal;
const V = Direction.Vertical;

export default class RoomSnapTool {
  private precisionFactor: number = 150.0;
  private snapData: { [key in Direction]: SnapData } = {
    [H]: null,
    [V]: null,
  };
  private soRoot = new THREE.Group();

  constructor(private roomManager: RoomManager) {
    this.soRoot.name = "Room Snap Tool Root";
    this.roomManager.getSoRoot().add(this.soRoot);
  }

  public performSnapping(primaryObject: THREE.Object3D, secondaryObjects: THREE.Object3D[] = []): void {
    if (!primaryObject) {
      return;
    }

    this.snapData[H] = new SnapData(H);
    this.snapData[V] = new SnapData(V);
    this.deleteColinearSnapLines();

    const tolerance = this.roomManager.getRaycasterRecommendedPrecision() * this.precisionFactor * UnitsUtils.getConversionFactor();

    const primaryBb = SceneUtils.getRoomBoundingBoxByModelLines(primaryObject);

    let alignOffsetH = 0;
    let alignOffsetV = 0;
    let colinearSnapH = false;
    let colinearSnapV = false;

    // 1) check snapping on the same floor
    let res = this.getSnapData(FloorMode.Same, primaryObject, primaryBb, tolerance);
    if (res.H.absDistance <= tolerance) {
      alignOffsetH = res.H.absAlignDistance > tolerance ? (res.H.cornerDistance > tolerance ? 0 : res.H.cornerDistance) : res.H.alignDistance;
      RoomSnapTool.updateSnapDataSharedObjects(res.H, primaryObject, this.roomManager.getCorePlanSoRoom(res.H.otherRoomId), res[H].distance, alignOffsetH);
      this.snapData[H] = res.H;
    }
    if (res.V.absDistance <= tolerance) {
      alignOffsetV = res.V.absAlignDistance > tolerance ? (res.V.cornerDistance > tolerance ? 0 : res.V.cornerDistance) : res.V.alignDistance;
      RoomSnapTool.updateSnapDataSharedObjects(res.V, primaryObject, this.roomManager.getCorePlanSoRoom(res.V.otherRoomId), alignOffsetV, res[V].distance);
      this.snapData[V] = res.V;
    }

    // 2) check snapping to colinear walls on the same floor
    if (this.snapData[H].absDistance > tolerance || this.snapData[V].absDistance > tolerance) {
      const res = this.getSnapDataCollinear(primaryObject, primaryBb);
      if (res[H].absDistance <= tolerance) {
        this.snapData[H] = res.H;
        colinearSnapH = true;
      }
      if (res[V].absDistance <= tolerance) {
        this.snapData[V] = res.V;
        colinearSnapV = true;
      }
    }

    // 3) check snapping on the other floors
    if (this.snapData[H].absDistance > tolerance || this.snapData[V].absDistance > tolerance) {
      res = this.getSnapData(FloorMode.Other, primaryObject, primaryBb, tolerance);
      if (res.H.absDistance <= tolerance) {
        alignOffsetH = res.H.absAlignDistance > tolerance ? (res.H.cornerDistance > tolerance ? 0 : res.H.cornerDistance) : res.H.alignDistance;
        this.snapData[H] = res.H;
        colinearSnapH = false;
      }
      if (res.V.absDistance <= tolerance) {
        alignOffsetV = res.V.absAlignDistance > tolerance ? (res.V.cornerDistance > tolerance ? 0 : res.V.cornerDistance) : res.V.alignDistance;
        this.snapData[V] = res.V;
        colinearSnapV = false;
      }
    }

    // 4) check snapping to grid
    if (appModel.showGrid && appModel.snapToGrid && this.snapData[H].absDistance > tolerance && this.snapData[V].absDistance > tolerance) {
      const res = RoomSnapTool.getSnapDataByBoundingBoxInGrid(
        primaryBb,
        tolerance,
        GeometryUtils.getSceneUnitPixels(this.roomManager.camera, this.roomManager.baseManager.renderer)
      );

      if (res[H].absDistance <= tolerance) {
        alignOffsetH = res[H].absAlignDistance > tolerance ? (res[H].cornerDistance > tolerance ? 0 : res[H].cornerDistance) : res[H].alignDistance;
        this.snapData[H] = res[H];
        colinearSnapH = false;
      }
      if (res[V].absDistance <= tolerance) {
        alignOffsetV = res[V].absAlignDistance > tolerance ? (res[V].cornerDistance > tolerance ? 0 : res[V].cornerDistance) : res[V].alignDistance;
        this.snapData[V] = res[V];
        colinearSnapV = false;
      }
    }

    // snap room
    if (this.snapData[H].absDistance <= tolerance || this.snapData[V].absDistance <= tolerance) {
      let offsetX = 0;
      let offsetY = 0;

      if (this.snapData[H].absDistance <= tolerance) {
        offsetX = this.snapData[H].distance;
        offsetY = this.snapData[V].absDistance <= tolerance ? this.snapData[V].distance : alignOffsetH;
      }
      if (this.snapData[V].absDistance <= tolerance) {
        offsetX = this.snapData[H].absDistance <= tolerance ? this.snapData[H].distance : alignOffsetV;
        offsetY = this.snapData[V].distance;
      }

      if (offsetX != 0 || offsetY != 0) {
        secondaryObjects.push(primaryObject);
        secondaryObjects.forEach(so => {
          const pos = so.position.clone();
          so.position.copy(pos.setX(pos.x + offsetX).setY(pos.y + offsetY));
          so.updateMatrixWorld();
        });

        const primaryBB = SceneUtils.getRoomBoundingBoxByModelLines(primaryObject);
        if (colinearSnapH || colinearSnapV) {
          this.addColinearSnapLines(primaryObject, primaryBB);
        }
      }
    }
  }
  public checkSnappingWhileMovingStretchTriangles(
    primaryObject: THREE.Object3D,
    direction: Direction,
    sign: number,
    tolerance?: number,
    soRooms?: THREE.Object3D[]
  ): SnapData {
    this.snapData[H] = new SnapData(H);
    this.snapData[V] = new SnapData(V);

    this.deleteColinearSnapLines();

    if (tolerance === undefined) {
      tolerance = this.roomManager.getRaycasterRecommendedPrecision() * this.precisionFactor * UnitsUtils.getConversionFactor();
    }

    const bb = SceneUtils.getRoomBoundingBoxByModelLines(primaryObject);
    const primaryBb = new THREE.Box3();
    if (direction === H) {
      if (sign === 1) {
        // right side
        primaryBb.expandByPoint(bb.max);
        primaryBb.expandByPoint(new THREE.Vector3(bb.max.x, bb.min.y, 0));
      } else {
        // left side
        primaryBb.expandByPoint(bb.min);
        primaryBb.expandByPoint(new THREE.Vector3(bb.min.x, bb.max.y, 0));
      }
    } else {
      if (sign === 1) {
        // top side
        primaryBb.expandByPoint(bb.max);
        primaryBb.expandByPoint(new THREE.Vector3(bb.min.x, bb.max.y, 0));
      } else {
        // bottom side
        primaryBb.expandByPoint(bb.min);
        primaryBb.expandByPoint(new THREE.Vector3(bb.max.x, bb.min.y, 0));
      }
    }

    // 1) check snapping to other rooms on all floors
    const { [direction]: result } = this.getSnapData(FloorMode.All, primaryObject, primaryBb, tolerance, soRooms);
    if (result.absDistance <= tolerance) {
      const offsetX = direction === H ? result.distance : 0;
      const offsetY = direction === H ? 0 : result.distance;
      RoomSnapTool.updateSnapDataSharedObjects(result, primaryObject, this.roomManager.getCorePlanSoRoom(result.otherRoomId), offsetX, offsetY);
      this.snapData[direction] = result;
    }

    // 2) check snapping to colinear walls on the same floor
    if (this.snapData[direction].absDistance > tolerance) {
      const { [direction]: result } = this.getSnapDataCollinear(primaryObject, primaryBb);
      if (result.absDistance <= tolerance) {
        this.snapData[direction] = result;
      }
    }

    // 3) check snapping to grid
    if (appModel.showGrid && appModel.snapToGrid && this.snapData[direction].absDistance > tolerance) {
      const { [direction]: result } = RoomSnapTool.getSnapDataByBoundingBoxInGrid(
        primaryBb,
        tolerance,
        GeometryUtils.getSceneUnitPixels(this.roomManager.camera, this.roomManager.baseManager.renderer)
      );
      if (result.absDistance <= tolerance) {
        this.snapData[direction] = result;
      }
    }

    return this.snapData[direction];
  }
  public showSnappingMessages(): void {
    if (this.snapData[H]?.hasIntersectedSharedObjects) {
      const bbox = SceneUtils.getRoomBoundingBox(this.roomManager.getActiveFloorSoRoom(this.snapData[H].roomId));
      const bbox2 = SceneUtils.getRoomBoundingBox(this.roomManager.getCorePlanSoRoom(this.snapData[H].otherRoomId));
      if (bbox.intersectsBox(bbox2)) {
        showToastMessage(MessageKindsEnum.Error, SNAP_ERROR_MESSAGE, { autoClose: MESSAGE_DURATION });
      }
    } else if (this.snapData[V]?.hasIntersectedSharedObjects) {
      const bbox = SceneUtils.getRoomBoundingBox(this.roomManager.getActiveFloorSoRoom(this.snapData[V].roomId));
      const bbox2 = SceneUtils.getRoomBoundingBox(this.roomManager.getCorePlanSoRoom(this.snapData[V].otherRoomId));
      if (bbox.intersectsBox(bbox2)) {
        showToastMessage(MessageKindsEnum.Error, SNAP_ERROR_MESSAGE, { autoClose: MESSAGE_DURATION });
      }
    } else if (this.snapData[H]?.hasSharedObjects || this.snapData[V]?.hasSharedObjects) {
      showToastMessage(MessageKindsEnum.Warning, SNAP_WARNING_MESSAGE, { autoClose: MESSAGE_DURATION });
    }
  }
  public addColinearSnapLines(/*colinearSnapH: boolean, colinearSnapV: boolean, */ primaryObject: THREE.Object3D, primaryBB: THREE.Box3): void {
    // if (colinearSnapH || colinearSnapV) {
    const soRooms = this.roomManager
      .getVisibleSoFloors()
      .flatMap(soFloor => soFloor.children)
      .filter(soRoom => soRoom.userData.id !== primaryObject.userData.id && !appModel.selectedRoomsIds.includes(soRoom.userData.id));
    const currentFloorSoRooms = soRooms.filter(soRoom => appModel.activeFloor.rooms.some(r => r.id === soRoom.userData.id));

    const leftYY: number[] = [primaryBB.min.y, primaryBB.max.y];
    const rightYY: number[] = [primaryBB.min.y, primaryBB.max.y];
    const bottomXX: number[] = [primaryBB.min.x, primaryBB.max.x];
    const topXX: number[] = [primaryBB.min.x, primaryBB.max.x];

    currentFloorSoRooms.forEach(soRoom => {
      const bb = SceneUtils.getRoomBoundingBoxByModelLines(soRoom);

      // if (colinearSnapH) {
      if (MathUtils.areNumbersEqual(primaryBB.min.x, bb.min.x) || MathUtils.areNumbersEqual(primaryBB.min.x, bb.max.x)) {
        leftYY.push(bb.min.y, bb.max.y);
      }
      if (MathUtils.areNumbersEqual(primaryBB.max.x, bb.min.x) || MathUtils.areNumbersEqual(primaryBB.max.x, bb.max.x)) {
        rightYY.push(bb.min.y, bb.max.y);
      }
      // }
      // if (colinearSnapV) {
      if (MathUtils.areNumbersEqual(primaryBB.min.y, bb.min.y) || MathUtils.areNumbersEqual(primaryBB.min.y, bb.max.y)) {
        bottomXX.push(bb.min.x, bb.max.x);
      }
      if (MathUtils.areNumbersEqual(primaryBB.max.y, bb.min.y) || MathUtils.areNumbersEqual(primaryBB.max.y, bb.max.y)) {
        topXX.push(bb.min.x, bb.max.x);
      }
      // }
    });

    const fnSort = (n1: number, n2: number) => {
      if (n1 > n2) {
        return 1;
      }
      if (n1 < n2) {
        return -1;
      }
      return 0;
    };

    if (leftYY.length >= 4) {
      const ar = leftYY.sort(fnSort);
      const p1 = new THREE.Vector3(primaryBB.min.x, ar[1], 0);
      const p2 = new THREE.Vector3(primaryBB.min.x, ar[ar.length - 2], 0);
      this.soRoot.add(RoomSnapTool.createColinearSnapLine(p1, p2));
    }
    if (rightYY.length >= 4) {
      const ar = rightYY.sort(fnSort);
      const p1 = new THREE.Vector3(primaryBB.max.x, ar[1], 0);
      const p2 = new THREE.Vector3(primaryBB.max.x, ar[ar.length - 2], 0);
      this.soRoot.add(RoomSnapTool.createColinearSnapLine(p1, p2));
    }

    if (bottomXX.length >= 4) {
      const ar = bottomXX.sort(fnSort);
      const p1 = new THREE.Vector3(ar[1], primaryBB.min.y, 0);
      const p2 = new THREE.Vector3(ar[ar.length - 2], primaryBB.min.y, 0);
      this.soRoot.add(RoomSnapTool.createColinearSnapLine(p1, p2));
    }
    if (topXX.length >= 4) {
      const ar = topXX.sort(fnSort);
      const p1 = new THREE.Vector3(ar[1], primaryBB.max.y, 0);
      const p2 = new THREE.Vector3(ar[ar.length - 2], primaryBB.max.y, 0);
      this.soRoot.add(RoomSnapTool.createColinearSnapLine(p1, p2));
    }
    // }
  }
  public end() {
    this.deleteColinearSnapLines();
  }

  // --------------------------------------------

  private getSnapData(
    floorMode: FloorMode,
    primaryObject: THREE.Object3D,
    primaryBb: THREE.Box3,
    tolerance: number,
    soRooms?: THREE.Object3D[]
  ): { [key in Direction]: SnapData } {
    const result: { [key in Direction]: SnapData } = {
      [H]: new SnapData(H),
      [V]: new SnapData(V),
    };

    if (!soRooms) {
      soRooms = this.roomManager
        .getVisibleSoFloors()
        .flatMap(soFloor => soFloor.children)
        .filter(soRoom => soRoom.userData.id !== primaryObject.userData.id && !appModel.selectedRoomsIds.includes(soRoom.userData.id));
    }

    const currentFloorSoRooms = soRooms.filter(soRoom => appModel.activeFloor.rooms.some(r => r.id === soRoom.userData.id));
    const { internalSegments } = WallAnalysisUtils.collectSegments(currentFloorSoRooms);

    if (floorMode == FloorMode.Same || floorMode == FloorMode.All) {
      // current floor
      for (let i = 0; i < currentFloorSoRooms.length; i++) {
        const snapData = RoomSnapTool.getSnapDataByBoundingBoxes(
          primaryBb,
          SceneUtils.getRoomBoundingBoxByModelLines(currentFloorSoRooms[i]),
          tolerance,
          true,
          internalSegments
        );

        if (snapData[H] && (result[H].absDistance > snapData[H].absDistance || result[H].alignDistance > snapData[H].alignDistance)) {
          result[H] = snapData[H];
          result[H].isSameFloor = true;
          result[H].roomId = primaryObject.userData.id;
          result[H].otherRoomId = currentFloorSoRooms[i].userData.id;
        }
        if (snapData[V] && (result[V].absDistance > snapData[V].absDistance || result[V].alignDistance > snapData[V].alignDistance)) {
          result[V] = snapData[V];
          result[V].isSameFloor = true;
          result[V].roomId = primaryObject.userData.id;
          result[V].otherRoomId = currentFloorSoRooms[i].userData.id;
        }
      }
    }

    if (floorMode == FloorMode.Other || (floorMode == FloorMode.All && (result[H].absDistance > tolerance || result[V].absDistance > tolerance))) {
      // other floors
      const otherFloorSoRooms = soRooms.filter(soRoom => !appModel.activeFloor.rooms.some(r => r.id === soRoom.userData.id));
      for (let i = 0; i < otherFloorSoRooms.length; i++) {
        const snapData = RoomSnapTool.getSnapDataByBoundingBoxes(
          primaryBb,
          SceneUtils.getRoomBoundingBoxByModelLines(otherFloorSoRooms[i]),
          tolerance,
          false,
          internalSegments
        );

        if (snapData[H] && (result[H].absDistance > snapData[H].absDistance || result[H].alignDistance > snapData[H].alignDistance)) {
          result[H] = snapData[H];
          result[H].isSameFloor = false;
          result[H].roomId = primaryObject.userData.id;
          result[H].otherRoomId = otherFloorSoRooms[i].userData.id;
        }
        if (snapData[V] && (result[V].absDistance > snapData[V].absDistance || result[V].alignDistance > snapData[V].alignDistance)) {
          result[V] = snapData[V];
          result[V].isSameFloor = false;
          result[V].roomId = primaryObject.userData.id;
          result[V].otherRoomId = otherFloorSoRooms[i].userData.id;
        }
      }
    }

    return result;
  }
  private getSnapDataCollinear(primaryObject: THREE.Object3D, primaryBb: THREE.Box3): { [key in Direction]: SnapData } {
    const result: { [key in Direction]: SnapData } = {
      [H]: new SnapData(H),
      [V]: new SnapData(V),
    };

    const soRooms = this.roomManager
      .getVisibleSoFloors()
      .flatMap(soFloor => soFloor.children)
      .filter(soRoom => primaryObject !== soRoom && !appModel.selectedRoomsIds.includes(soRoom.userData.id));
    const currentFloorSoRooms = soRooms.filter(soRoom => appModel.activeFloor.rooms.some(r => r.id === soRoom.userData.id));

    for (let i = 0; i < currentFloorSoRooms.length; i++) {
      const bb = SceneUtils.getRoomBoundingBoxByModelLines(currentFloorSoRooms[i]);

      // Avoid collinear snapping for intersected rooms (from inside).
      if (primaryBb.intersectsBox(bb)) {
        continue;
      }

      let offsetX = bb.min.x - primaryBb.min.x;
      if (Math.abs(offsetX) < Math.abs(result[H].distance)) {
        result[H].distance = offsetX;
      }
      offsetX = bb.max.x - primaryBb.min.x;
      if (Math.abs(offsetX) < Math.abs(result[H].distance)) {
        result[H].distance = offsetX;
      }
      offsetX = bb.min.x - primaryBb.max.x;
      if (Math.abs(offsetX) < Math.abs(result[H].distance)) {
        result[H].distance = offsetX;
      }
      offsetX = bb.max.x - primaryBb.max.x;
      if (Math.abs(offsetX) < Math.abs(result[H].distance)) {
        result[H].distance = offsetX;
      }

      let offsetY = bb.min.y - primaryBb.min.y;
      if (Math.abs(offsetY) < Math.abs(result[V].distance)) {
        result[V].distance = offsetY;
      }
      offsetY = bb.max.y - primaryBb.min.y;
      if (Math.abs(offsetY) < Math.abs(result[V].distance)) {
        result[V].distance = offsetY;
      }
      offsetY = bb.min.y - primaryBb.max.y;
      if (Math.abs(offsetY) < Math.abs(result[V].distance)) {
        result[V].distance = offsetY;
      }
      offsetY = bb.max.y - primaryBb.max.y;
      if (Math.abs(offsetY) < Math.abs(result[V].distance)) {
        result[V].distance = offsetY;
      }
    }

    return result;
  }
  private deleteColinearSnapLines(): void {
    while (this.soRoot.children.length > 0) {
      GeometryUtils.disposeObject(this.soRoot.children[0]);
      this.soRoot.remove(this.soRoot.children[0]);
    }
  }

  private static getSnapDataByBoundingBoxes(
    bb: THREE.Box3,
    bbOther: THREE.Box3,
    tolerance: number = UnitsUtils.getSnapTolerance(),
    isSameFloors = false,
    internalSegments?: Segment[] // shared wall parts
  ): { [key in Direction]: SnapData | null } {
    const result = { [H]: new SnapData(H), [V]: new SnapData(V) };
    const internalWallSnap = { [H]: null, [V]: null };

    const center = bb.getCenter(new THREE.Vector3());
    const size = bb.getSize(new THREE.Vector3());
    const center2 = bbOther.getCenter(new THREE.Vector3());
    const size2 = bbOther.getSize(new THREE.Vector3());
    let distX = Math.abs(center.x - center2.x) - size.x / 2.0 - size2.x / 2.0;
    let distY = Math.abs(center.y - center2.y) - size.y / 2.0 - size2.y / 2.0;
    if (MathUtils.areNumbersEqual(0, distX)) {
      distX = 0;
    }
    if (MathUtils.areNumbersEqual(0, distY)) {
      distY = 0;
    }

    let tempSnap = new SnapData();

    const setSnapDataBySides = (direction: Direction, side: Side, sideOther: Side): void => {
      let distance = Number.MAX_VALUE;
      let distanceOther = Number.MAX_VALUE;

      if (direction === H) {
        distanceOther = distY;
        if (side === Side.Left) {
          if (sideOther === Side.Left) {
            distance = bb.min.x - bbOther.min.x;
          }
          if (sideOther === Side.Right) {
            distance = bb.min.x - bbOther.max.x;
          }
        }
        if (side === Side.Right) {
          if (sideOther === Side.Left) {
            distance = bb.max.x - bbOther.min.x;
          }
          if (sideOther === Side.Right) {
            distance = bb.max.x - bbOther.max.x;
          }
        }
        tempSnap = this.updateSnapDataDistances(result[direction], false, tolerance, distance, distanceOther, bb, bbOther);
      } else {
        distanceOther = distX;
        if (side === Side.Top) {
          if (sideOther === Side.Top) {
            distance = bb.max.y - bbOther.max.y;
          }
          if (sideOther === Side.Bottom) {
            distance = bb.max.y - bbOther.min.y;
          }
        }
        if (side === Side.Bottom) {
          if (sideOther === Side.Top) {
            distance = bb.min.y - bbOther.max.y;
          }
          if (sideOther === Side.Bottom) {
            distance = bb.min.y - bbOther.min.y;
          }
        }
        tempSnap = this.updateSnapDataDistances(result[direction], true, tolerance, distance, distanceOther, bb, bbOther);
      }

      if (tempSnap.absDistance <= tolerance) {
        if (this.checkSnapToInternalWall(bb, direction, side, tempSnap.absDistance, internalSegments || [])) {
          internalWallSnap[direction] = tempSnap;
        } else {
          result[direction] = tempSnap;
        }
      }
    };

    if (distX <= tolerance || distY <= tolerance) {
      if (distX <= tolerance && distY <= tolerance && distX > 0 && distY > 0 && size.x > 0 && size.y > 0) {
        //snap to corner
        const cornerDistX = bb.max.x <= bbOther.min.x ? distX : -distX;
        const cornerDistY = bb.min.y <= bbOther.min.y ? distY : -distY;
        result[H] = new SnapData(H, cornerDistX);
        const topDelta = bb.max.y - bbOther.max.y;
        if (Math.abs(topDelta) <= tolerance && Math.abs(topDelta) < Math.abs(result[H].alignDistance)) {
          result[H].alignDistance = -topDelta;
        }
        const bottomDelta = bb.min.y - bbOther.min.y;
        if (Math.abs(bottomDelta) <= tolerance && Math.abs(bottomDelta) < Math.abs(result[H].alignDistance)) {
          result[H].alignDistance = -bottomDelta;
        }

        result[V] = new SnapData(V, cornerDistY);
        const rightDelta = bb.max.x - bbOther.max.x;
        if (Math.abs(rightDelta) <= tolerance && Math.abs(rightDelta) < Math.abs(result[V].alignDistance)) {
          result[V].alignDistance = -rightDelta;
        }
        const leftDelta = bb.min.x - bbOther.min.x;
        if (Math.abs(leftDelta) <= tolerance && Math.abs(leftDelta) < Math.abs(result[V].alignDistance)) {
          result[V].alignDistance = -leftDelta;
        }
        return result;
      }

      setSnapDataBySides(H, Side.Left, Side.Right);
      setSnapDataBySides(H, Side.Right, Side.Left);
      setSnapDataBySides(V, Side.Top, Side.Bottom);
      setSnapDataBySides(V, Side.Bottom, Side.Top);
      if (!isSameFloors) {
        // Snap to internal wall sides for rooms on other floors.
        setSnapDataBySides(V, Side.Top, Side.Top);
        setSnapDataBySides(V, Side.Bottom, Side.Bottom);
        setSnapDataBySides(H, Side.Left, Side.Left);
        setSnapDataBySides(H, Side.Right, Side.Right);
      }
    }

    if (result[H].absDistance > tolerance) {
      result[H] = null;
    }
    if (result[V].absDistance > tolerance) {
      result[V] = null;
    }

    // internal wall with lower priority
    return !result[H] && !result[V] ? internalWallSnap : result;
  }
  private static getSnapDataByBoundingBoxInGrid(bb: THREE.Box3, tolerance: number, sceneUnitSize: number): { [key in Direction]: SnapData } {
    const calculateDistance = (value: number, cellSize): number => {
      const dev = MathUtils.floor(value / cellSize, 1);
      let diff = value - dev * cellSize;
      if (Math.abs(diff) > tolerance) {
        diff = value - (dev + 1) * cellSize;
      }
      if (MathUtils.areNumbersEqual(0, diff)) {
        diff = 0;
      }
      return diff;
    };

    const result = {
      [H]: new SnapData(H),
      [V]: new SnapData(V),
    };

    const cellSize = UnitsUtils.getGridCellSize();
    let minorCellSize = cellSize / GRID_MINOR_CELL_RATIO;
    const minorCellSizePixels = minorCellSize * sceneUnitSize;
    if (minorCellSizePixels < GRID_MIN_CELL_SIZE_CUTOFF_2D) {
      minorCellSize = minorCellSize * 2;
      if (minorCellSizePixels * GRID_MINOR_CELL_RATIO < GRID_MIN_CELL_SIZE_CUTOFF_2D) {
        minorCellSize = cellSize * GRID_MAJOR_CELL_RATIO;
      }
    }
    const diffMinX = calculateDistance(bb.min.x, minorCellSize);
    const diffMinY = calculateDistance(bb.min.y, minorCellSize);
    const diffMaxX = calculateDistance(bb.max.x, minorCellSize);
    const diffMaxY = calculateDistance(bb.max.y, minorCellSize);

    const diffX = Math.abs(diffMinX) > Math.abs(diffMaxX) ? diffMaxX : diffMinX;
    const diffY = Math.abs(diffMinY) > Math.abs(diffMaxY) ? diffMaxY : diffMinY;

    if (Math.abs(diffX) <= tolerance) {
      result[H].distance = -diffX;
    }
    if (Math.abs(diffY) <= tolerance) {
      result[V].distance = -diffY;
    }

    return result;
  }

  private static checkSnapToInternalWall(bb: THREE.Box3, direction: Direction, side: Side, distance: number, internalSegments: Segment[]): boolean {
    return internalSegments.some(segment => {
      if (direction === Direction.Horizontal) {
        if (
          (side === Side.Left && MathUtils.areNumbersEqual(segment.start.x, bb.min.x - distance)) ||
          (side === Side.Right && MathUtils.areNumbersEqual(segment.start.x, bb.max.x + distance))
        ) {
          return MathUtils.isNumberInRange(bb.min.y, segment.start.y, segment.end.y) && MathUtils.isNumberInRange(bb.max.y, segment.start.y, segment.end.y);
        }
      } else {
        if (
          (side === Side.Top && MathUtils.areNumbersEqual(segment.start.y, bb.max.y + distance)) ||
          (side === Side.Bottom && MathUtils.areNumbersEqual(segment.start.y, bb.min.y - distance))
        ) {
          return MathUtils.isNumberInRange(bb.min.x, segment.start.x, segment.end.x) && MathUtils.isNumberInRange(bb.max.x, segment.start.x, segment.end.x);
        }
      }

      return false;
    });
  }
  private static updateSnapDataSharedObjects(
    snapData: SnapData,
    soRoom: THREE.Object3D,
    soRoomOther: THREE.Object3D,
    shiftX: number,
    shiftY: number
  ): SnapData {
    if (snapData.isSameFloor) {
      const wallDirection = snapData.direction === Direction.Horizontal ? Direction.Vertical : Direction.Horizontal;

      const shift = new THREE.Vector3(shiftX, shiftY, 0);
      const soWalls = SceneUtils.getRoomWallLines(soRoom, wallDirection, shift);
      const openingsBbs = soRoom.children
        .filter(item => item.userData.type === RoomEntityType.Window || item.userData.type === RoomEntityType.Door)
        .map(item => {
          const bbox = GeometryUtils.getGeometryBoundingBox2D(item);
          bbox.min.add(shift);
          bbox.max.add(shift);
          return bbox;
        });

      const soOtherWalls = SceneUtils.getRoomWallLines(soRoomOther, wallDirection);
      const openingsBbsOther = soRoomOther?.children
        .filter(item => item.userData.type === RoomEntityType.Window || item.userData.type === RoomEntityType.Door)
        .map(item => GeometryUtils.getGeometryBoundingBox2D(item));

      const sharedOpenings = openingsBbs.filter(item => soOtherWalls.some(w => GeometryUtils.lineIntersectsBoundingBox(w, item)));
      const sharedOpenings2 = openingsBbsOther.filter(item => soWalls.some(w => GeometryUtils.lineIntersectsBoundingBox(w, item)));

      snapData.hasSharedObjects = sharedOpenings.length > 0 || sharedOpenings2.length > 0;
      snapData.hasIntersectedSharedObjects = GeometryUtils.doBoundingBoxesIntersect(sharedOpenings, sharedOpenings2);
    }

    return snapData;
  }
  private static updateSnapDataDistances(
    snapData: SnapData,
    isVertical: boolean,
    maxRange: number,
    distance: number,
    distanceOther: number,
    bb: THREE.Box3,
    bb2: THREE.Box3
  ): SnapData {
    const absD = Math.abs(distance);

    if (absD <= maxRange && absD < snapData.absDistance && distanceOther <= 0) {
      snapData = new SnapData(isVertical ? V : H, -distance);

      const deltaMax = isVertical ? bb.max.x - bb2.max.x : bb.max.y - bb2.max.y;
      const deltaMin = isVertical ? bb.min.x - bb2.min.x : bb.min.y - bb2.min.y;
      const cornerDelta1 = isVertical ? bb.max.x - bb2.min.x : bb.max.y - bb2.min.y;
      const cornerDelta2 = isVertical ? bb.min.x - bb2.max.x : bb.min.y - bb2.max.y;

      if (Math.abs(deltaMax) <= maxRange && Math.abs(deltaMax) < Math.abs(snapData.alignDistance)) {
        snapData.alignDistance = -deltaMax;
      }
      if (Math.abs(deltaMin) <= maxRange && Math.abs(deltaMin) < Math.abs(snapData.alignDistance)) {
        snapData.alignDistance = -deltaMin;
      }
      if (Math.abs(cornerDelta1) <= maxRange && Math.abs(cornerDelta1) < Math.abs(snapData.cornerDistance)) {
        snapData.cornerDistance = -cornerDelta1;
      }
      if (Math.abs(cornerDelta2) <= maxRange && Math.abs(cornerDelta2) < Math.abs(snapData.cornerDistance)) {
        snapData.cornerDistance = -cornerDelta2;
      }
    }

    return snapData;
  }

  private static createColinearSnapLine(p1: THREE.Vector3, p2: THREE.Vector3): THREE.Object3D {
    const result = new THREE.Line(
      new THREE.BufferGeometry().setFromPoints([p1, p2]),
      new THREE.LineDashedMaterial({
        color: MODEL_LINE_COLOR,
        dashSize: 0.3 * UnitsUtils.getConversionFactor(),
        gapSize: 0.3 * UnitsUtils.getConversionFactor(),
        transparent: true,
        opacity: 1.0,
      })
    );
    result.computeLineDistances();
    result.renderOrder = MODEL_LINE_RENDER_ORDER;

    return result;
  }
}
