import IntervalTree from "node-interval-tree";
import * as THREE from "three";
import { EPSILON } from "../consts";
import { Space } from "../models/FloorSpaceTree";
import { Edge, GraphManager } from "../models/GraphManager";
import { Segment } from "../models/segments/Segment";
import GeometryUtils from "./GeometryUtils";
import MathUtils from "./MathUtils";
import UnitsUtils from "./UnitsUtils";
import { WallAnalysisUtils } from "./WallAnalysisUtils";
import { MergeSegmentsMode } from "../models/SegmentsMergeMode";

export default class SegmentsUtils {
  public static segmentsToPoints(segments: Segment[]): THREE.Vector3[] {
    return segments.map(segment => GeometryUtils.Vector2ToVector3(segment.start));
  }
  public static pointsToSegments(contour: THREE.Vector3[]): Segment[] {
    const contourSegments = [];
    const n = contour.length;

    for (let i = 0; i < n; i++) {
      const start = new THREE.Vector2(contour[i].x, contour[i].y);
      const end = new THREE.Vector2(contour[(i + 1) % n].x, contour[(i + 1) % n].y);
      contourSegments.push(new Segment(start, end));
    }

    return contourSegments;
  }

  public static alignSegments(segments: Segment[]): void {
    segments.forEach(s => !s.isAxisAligned() && s.revert());
  }

  public static splitSegments(segments: Segment[], splitters: Segment[]): Segment[] {
    const result: Segment[] = [];

    for (const segment of segments) {
      const splitParameters: number[] = [];

      for (const splitter of splitters) {
        const params = GeometryUtils.getLineSegmentsIntersectionPointParameters(segment.start, segment.end, splitter.start, splitter.end);
        if (!params || MathUtils.areNumbersEqual(params[0], 0) || MathUtils.areNumbersEqual(params[0], 1)) {
          continue;
        }

        splitParameters.push(params[0]);
      }

      if (splitParameters.length) {
        splitParameters.sort((a, b) => a - b);
        const splitPoints = splitParameters.map(p => segment.evaluate(p));

        for (let i = 0; i <= splitPoints.length; i++) {
          const start = i === 0 ? segment.start.clone() : splitPoints[i - 1].clone();
          const end = i === splitPoints.length ? segment.end.clone() : splitPoints[i].clone();

          if (!GeometryUtils.areVectors2Equal(start, end)) {
            const splitted = segment.clone();
            splitted.start = start;
            splitted.end = end;
            result.push(splitted);
          }
        }
      } else {
        result.push(segment.clone());
      }
    }

    return result;
  }

  // TODO: Use splitSegments inside.
  public static splitIntersectedSegments(segments: Segment[]): Segment[] {
    // The algorithm assumes that all segments are oriented from bottom to top or from left to right.
    const isReverted: boolean[] = [];
    segments.forEach((segment, idx) => {
      if (!segment.isAxisAligned()) {
        segment.revert();
        isReverted[idx] = true;
      } else {
        isReverted[idx] = false;
      }
    });

    const horizontalSegments = new IntervalTree<Segment, number>();
    const verticalSegments = new IntervalTree<Segment, number>();
    segments.forEach(segment => {
      if (segment.isHorizontal()) {
        horizontalSegments.insert(segment.start.x, segment.end.x, segment);
      } else {
        verticalSegments.insert(segment.start.y, segment.end.y, segment);
      }
    });

    const splittedSegments: Segment[] = [];
    segments.forEach(segment => {
      if (segment.isHorizontal()) {
        const intersectedSegments = verticalSegments
          .search(segment.start.y - EPSILON, segment.start.y + EPSILON)
          .filter(
            vertical => !MathUtils.isNumberLessOrEqual(vertical.start.x, segment.start.x) && !MathUtils.isNumberLessOrEqual(segment.end.x, vertical.start.x)
          )
          .sort((a, b) => a.start.x - b.start.x);

        if (intersectedSegments.length) {
          for (let i = 0; i <= intersectedSegments.length; i++) {
            const startX = i === 0 ? segment.start.x : intersectedSegments[i - 1].start.x;
            const endX = i === intersectedSegments.length ? segment.end.x : intersectedSegments[i].start.x;
            if (!MathUtils.areNumbersEqual(startX, endX)) {
              const splitted = segment.clone();
              splitted.start = new THREE.Vector2(startX, segment.start.y);
              splitted.end = new THREE.Vector2(endX, segment.start.y);
              splittedSegments.push(splitted);
            }
          }
        } else {
          splittedSegments.push(segment);
        }
      } else {
        const intersectedSegments = horizontalSegments
          .search(segment.start.x - EPSILON, segment.start.x + EPSILON)
          .filter(
            horizontal =>
              !MathUtils.isNumberLessOrEqual(horizontal.start.y, segment.start.y) && !MathUtils.isNumberLessOrEqual(segment.end.y, horizontal.start.y)
          )
          .sort((a, b) => a.start.y - b.start.y);

        if (intersectedSegments.length) {
          for (let i = 0; i <= intersectedSegments.length; i++) {
            const startY = i === 0 ? segment.start.y : intersectedSegments[i - 1].start.y;
            const endY = i === intersectedSegments.length ? segment.end.y : intersectedSegments[i].start.y;
            if (!MathUtils.areNumbersEqual(startY, endY)) {
              const splitted = segment.clone();
              splitted.start = new THREE.Vector2(segment.start.x, startY);
              splitted.end = new THREE.Vector2(segment.start.x, endY);
              splittedSegments.push(splitted);
            }
          }
        } else {
          splittedSegments.push(segment);
        }
      }
    });

    // restore original direction
    segments.forEach((segment, idx) => isReverted[idx] && segment.revert());

    return splittedSegments;
  }

  public static clipSegmentsBySphere(segments: Segment[], sphere: THREE.Sphere): Segment[] {
    const result: Segment[] = [];

    const addSegment = (p1: THREE.Vector3, p2: THREE.Vector3) => {
      if (!GeometryUtils.areVectorsEqual(p1, p2)) {
        result.push(new Segment(new THREE.Vector2(p1.x, p1.y), new THREE.Vector2(p2.x, p2.y)));
      }
    };

    segments.forEach(segment => {
      const line = segment.toLine3();
      const intersection = GeometryUtils.lineSegmentIntersectSphere(line, sphere);

      if (intersection === null) {
        // Segment lies outside of the circle.
        if (!sphere.containsPoint(segment.getCenter3())) {
          result.push(segment.clone());
        }
        return;
      }

      if (intersection.length === 1) {
        const middle = intersection[0];
        const liesLeft = sphere.containsPoint(GeometryUtils.getLineMidPoint2(line.start, middle));
        const liesRight = sphere.containsPoint(GeometryUtils.getLineMidPoint2(middle, line.end));

        // Segment is tangent to the circle.
        if (!liesLeft && !liesRight) {
          result.push(segment.clone());
        }

        if (liesLeft) {
          addSegment(middle, line.end);
        }

        if (liesRight) {
          addSegment(line.start, middle);
        }
      }

      if (intersection.length === 2) {
        addSegment(line.start, intersection[0]);
        addSegment(intersection[1], line.end);
      }
    });

    return result;
  }

  /**
   * Performs a graph traversal starting from a specified edge and returns the contour and inner edges.
   * The inner edges are connected to the contour but do not form a closed path.
   */
  public static graphTraverse(
    startEdge: Edge<Segment>,
    graph: GraphManager<Segment>,
    isClockwise: boolean = false,
    visitedEdges?: Set<string>
  ): { contour: Edge<Segment>[]; innerEdges: Edge<Segment>[] } {
    const sign = isClockwise ? -1 : 1;
    const innerEdges: Edge<Segment>[] = [];
    const contour: Edge<Segment>[] = [startEdge];
    const localVisitedEdges = new Set<string>([startEdge.keyStr]);

    while (contour.length !== 0 && !GeometryUtils.areVectors2Equal(contour[0].data.start, contour[contour.length - 1].data.end)) {
      const currentEdge = contour[contour.length - 1];

      const linkedEdges = graph.getLinkedEdges(graph.getVertexByPoint(currentEdge.data.end)).filter(e => {
        const key = e.keyStr;
        return !(localVisitedEdges.has(key) || visitedEdges?.has(key));
      });

      if (linkedEdges.length === 0) {
        innerEdges.push(contour.pop());
        continue;
      }

      // Find next edge in the contour
      let nextEdge: Edge<Segment> = null;
      let nextEdgeCost: number = Number.NEGATIVE_INFINITY;
      const currentEdgeDelta = currentEdge.data.delta();

      for (const edge of linkedEdges) {
        const segment = edge.data.clone();
        if (GeometryUtils.areVectors2Equal(currentEdge.data.end, segment.end)) {
          segment.revert();
        }

        const segmentDelta = segment.delta();
        const edgeCost = sign * GeometryUtils.calculateSignedAngle(currentEdgeDelta.x, currentEdgeDelta.y, segmentDelta.x, segmentDelta.y);

        if (edgeCost > nextEdgeCost) {
          nextEdge = edge;
          nextEdgeCost = edgeCost;
        }
      }

      if (GeometryUtils.areVectors2Equal(currentEdge.data.end, nextEdge.data.end)) {
        nextEdge.data.revert();
      }

      localVisitedEdges.add(nextEdge.keyStr);
      contour.push(nextEdge);
    }

    return {
      contour,
      innerEdges,
    };
  }

  /**
   * Simplifies the graph by merging colinear edges that are linked to a vertex with only two edges.
   */
  public static simplifyGraph(graph: GraphManager<Segment>): void {
    graph.getVertices().forEach(vertex => {
      const linkedEdges = graph.getLinkedEdges(vertex);

      if (linkedEdges.length === 2) {
        const s1 = linkedEdges[0].data;
        const s2 = linkedEdges[1].data;

        // Check collinearity.
        if (MathUtils.areNumbersEqual(s1.delta().cross(s2.delta()), 0)) {
          graph.removeEdge(linkedEdges[0]);
          graph.removeEdge(linkedEdges[1]);
          s1.end.copy(GeometryUtils.areVectors2Equal(s1.end, s2.start) ? s2.end : s2.start);
          graph.createEdgeFromPoints(s1.start, s1.end, s1);
        }
      }
    });
  }

  /**
   * Extracts an array of spaces in graph that have no common edges. Spaces have CCW orientation.
   * @param {Edge<Segment>[]} edges - Sorted edges
   * @param {GraphManager<Segment>} graph - Graph
   * @param {boolean} isClockwise - Traverse direction
   * @returns {Space[]}
   */
  public static extractUnlinkedSpacesFromGraph(graph: GraphManager<Segment>): Space[] {
    const spaces: Space[] = [];
    const visitedEdges = new Set<string>();
    const edges = graph.getEdges().slice().sort(SegmentsUtils.edgeComparedFn);

    edges.forEach(edge => {
      if (visitedEdges.has(edge.keyStr)) {
        return;
      }

      const path = SegmentsUtils.graphTraverse(edge, graph, true, visitedEdges);
      path.contour.forEach(edge => visitedEdges.add(edge.keyStr));
      path.innerEdges.forEach(edge => visitedEdges.add(edge.keyStr));

      if (path.contour.length === 0) {
        return;
      }

      spaces.push(
        new Space(
          path.contour.map(edge => edge.data),
          []
        )
      );
    });

    return spaces;
  }

  /**
   * Finds an array of spaces with the smallest area within which all the segments lie in graph. Spaces are not connected to each other.
   * @param {Edge<Segment>[]} edges - Sorted edges
   * @param {GraphManager<Segment>} graph - Graph
   * @param {boolean} isClockwise - Traverse direction
   * @returns {Space[]}
   */
  public static extractBoundingSpaces(edges: Edge<Segment>[], graph: GraphManager<Segment>, isClockwise: boolean): Space[] {
    const spaces: Space[] = [];
    const visitedEdges = new Set<string>();

    edges.forEach(edge => {
      if (visitedEdges.has(edge.keyStr)) {
        return;
      }

      const path = SegmentsUtils.graphTraverse(edge, graph, isClockwise, visitedEdges);
      path.contour.forEach(edge => visitedEdges.add(edge.keyStr));
      path.innerEdges.forEach(edge => visitedEdges.add(edge.keyStr));

      if (path.contour.length === 0) {
        return;
      }

      const space = new Space(
        path.contour.map(edge => edge.data),
        []
      );
      spaces.push(space);

      edges.forEach(edge => {
        if (space.containsPoint(edge.data.getCenter3())) {
          visitedEdges.add(edge.keyStr);
        }
      });
    });

    return spaces;
  }

  /**
   * Finds an array of spaces with the smallest area within which all the segments lie. Spaces are not connected to each other.
   * @param {Segment[]} segments - Axis aligned segments
   * @returns {Space[]} - Array of spaces
   */
  public static findBoundingSpaces(segments: Segment[]): Space[] {
    segments = SegmentsUtils.splitIntersectedSegments(segments);

    const graph = new GraphManager<Segment>();
    segments.forEach(segment => graph.createEdgeFromPoints(segment.start, segment.end, segment));
    const edges = graph.getEdges().slice().sort(SegmentsUtils.edgeComparedFn);

    return SegmentsUtils.extractBoundingSpaces(edges, graph, true);
  }
  public static findRoofSpaces(boxes: THREE.Box3[], holeBoxes: THREE.Box3[]): { boundingSpaces: Space[]; holeSpaces: Space[] } {
    const offset = UnitsUtils.getSweepOffset();

    let boundingSpaces = SegmentsUtils.findSweepSpaces(boxes);
    let holeSpaces = SegmentsUtils.findSweepSpaces(holeBoxes);

    // Generate axis aligned expanded segments for bounding spaces.
    const boundingSegments = boundingSpaces.flatMap(space => {
      const contourPoints = GeometryUtils.addOffsetToContour(SegmentsUtils.segmentsToPoints(space.contour), offset);
      const contourSegments = SegmentsUtils.pointsToSegments(contourPoints);
      contourSegments.forEach(s => !s.isAxisAligned() && s.revert());
      return contourSegments;
    });

    boundingSpaces = SegmentsUtils.findBoundingSpaces(boundingSegments);

    holeSpaces = holeSpaces.filter(holeSpace => {
      const holeContour = SegmentsUtils.segmentsToPoints(holeSpace.contour);
      return boundingSpaces.some(bs => GeometryUtils.isPolygonInsidePolygon(holeContour, SegmentsUtils.segmentsToPoints(bs.contour)));
    });

    return { boundingSpaces, holeSpaces };
  }
  public static findSweepSpaces(boxes: THREE.Box3[]): Space[] {
    const segments = boxes.flatMap(bb => [
      new Segment(new THREE.Vector2(bb.min.x, bb.max.y), new THREE.Vector2(bb.max.x, bb.max.y)), // top
      new Segment(new THREE.Vector2(bb.min.x, bb.min.y), new THREE.Vector2(bb.max.x, bb.min.y)), // bottom
      new Segment(new THREE.Vector2(bb.min.x, bb.min.y), new THREE.Vector2(bb.min.x, bb.max.y)), // left
      new Segment(new THREE.Vector2(bb.max.x, bb.min.y), new THREE.Vector2(bb.max.x, bb.max.y)), // right
    ]);

    return this.findBoundingSpaces(segments);
  }

  /**
   * Detects loops and contours segments in an array of segments.
   */
  public static detectLoops(segments: Segment[]): { contourSegments: Segment[]; loopsSegments: Segment[] } {
    const loopsSegments: Segment[] = [];
    const contourSegments: Segment[] = [];
    const visitedEdges = new Set<string>();
    const graph = new GraphManager<Segment>();

    segments = SegmentsUtils.splitIntersectedSegments(segments.map(s => s.clone()));
    SegmentsUtils.alignSegments(segments);

    segments.forEach(segment => graph.createEdgeFromPoints(segment.start, segment.end, segment));
    const edges = graph.getEdges().slice().sort(SegmentsUtils.edgeComparedFn);

    edges.forEach(edge => {
      if (visitedEdges.has(edge.keyStr)) {
        return;
      }

      const boundingPath = SegmentsUtils.graphTraverse(edge, graph, true, visitedEdges);
      if (boundingPath.contour.length === 0) {
        return;
      }

      const result = SegmentsUtils.detectInnerLoopsInClosedContour(boundingPath.contour, graph, visitedEdges);
      contourSegments.push(...result.contourSegments);
      loopsSegments.push(...result.loopsSegments);
    });

    return { contourSegments, loopsSegments };
  }

  /**
   * Get the closest edge from the graph based on a given point. Closest edge will be oriented from left to right.
   * The starting edge is the non vertical edge with the highest y-coordinate that intersects with the point's x-coordinate range and is below the point.
   * @param {THREE.Vector3} point - The point used to determine the edge.
   * @param {GraphManager<Segment>} graph - The graph manager containing the edges.
   * @returns {Edge<Segment>} - The closest edge.
   */
  public static getClosestEdgeUnderPoint(point: THREE.Vector3, graph: GraphManager<Segment>): Edge<Segment> | null {
    let startEdge: Edge<Segment>;
    let closestY = Number.NEGATIVE_INFINITY;

    graph.getEdges().forEach(edge => {
      const segment = edge.data;

      if (MathUtils.areNumbersEqual(segment.start.x, segment.end.x)) {
        return;
      }

      // Get y on the segment with point x.
      const t = (point.x - segment.start.x) / (segment.end.x - segment.start.x);
      const segmentY = segment.evaluate(t).y;

      if (!MathUtils.isNumberInRange(t, 0, 1) || segmentY > point.y) {
        return;
      }

      if (!startEdge || segmentY > closestY) {
        startEdge = edge;
        closestY = segmentY;
      }
    });

    if (startEdge && startEdge.data.start.x > startEdge.data.end.x) {
      startEdge.data.revert();
    }

    return startEdge || null;
  }

  /**
   * Calculates the area of the smallest closed contour surrounding a given point on a graph.
   */
  public static calculateAreaOfSmallestSurroundingContour(point: THREE.Vector3, graph: GraphManager<Segment>): number {
    const startEdge = SegmentsUtils.getClosestEdgeUnderPoint(point, graph);
    if (!startEdge) {
      return 0;
    }

    let area = 0;
    const traversalResult = SegmentsUtils.graphTraverse(startEdge, graph, false);

    traversalResult.contour.forEach(edge => {
      const segment = edge.data;
      area += segment.start.x * segment.end.y - segment.start.y * segment.end.x;
    });

    return area / 2;
  }

  public static collectSegmentsFromBoundingBox(box: THREE.Box3): Segment[] {
    return [
      new Segment(new THREE.Vector2(box.min.x, box.max.y), new THREE.Vector2(box.max.x, box.max.y)), // top
      new Segment(new THREE.Vector2(box.min.x, box.min.y), new THREE.Vector2(box.max.x, box.min.y)), // bottom
      new Segment(new THREE.Vector2(box.min.x, box.min.y), new THREE.Vector2(box.min.x, box.max.y)), // left
      new Segment(new THREE.Vector2(box.max.x, box.min.y), new THREE.Vector2(box.max.x, box.max.y)), // right
    ];
  }

  /**
   * Function to compare two edges of segments.
   * The comparison is based on the following criteria:
   * 1. Orientation: Horizontal edges are considered before vertical edges.
   * 2. Position: For horizontal edges, they are sorted from bottom to top.
   *              For vertical edges, they are sorted from left to right.
   * @param {Edge<Segment>} a: The first edge to compare.
   * @param {Edge<Segment>} b: The second edge to compare.
   * @returns {number}
   */
  public static edgeComparedFn(a: Edge<Segment>, b: Edge<Segment>): number {
    const aIsHorizontal = a.data.isHorizontal();
    const bIsHorizontal = b.data.isHorizontal();

    if (aIsHorizontal !== bIsHorizontal) {
      return aIsHorizontal ? -1 : 1;
    }

    if (aIsHorizontal) {
      return a.data.start.y - b.data.start.y;
    }

    return b.data.start.x - a.data.start.x;
  }

  public static getNotOverlappedSegmentParts(s: Segment, otherSegments: (THREE.Line3 | Segment)[]): Segment[] {
    // Checks segment if it's reverted.
    const segment = s.isAxisAligned() ? s : s.clone().revert();
    const isHorizontal = segment.isHorizontal();
    const comp = isHorizontal ? "x" : "y";
    const otherComp = isHorizontal ? "y" : "x";
    const overlappedSegments = otherSegments
      .filter(
        otherSegment =>
          isHorizontal === GeometryUtils.isLineHorizontal(otherSegment) &&
          MathUtils.areNumbersEqual(segment.start[otherComp], otherSegment.end[otherComp], UnitsUtils.getMaxWallDeviation()) &&
          segment.end[comp] >= otherSegment.start[comp] &&
          otherSegment.end[comp] >= segment.start[comp]
      )
      .sort((a, b) => a.start[comp] - b.start[comp]);

    if (!overlappedSegments.length) {
      return [segment.clone()];
    }

    const newSegments: Segment[] = [];

    let lastStartValue = segment.start[comp];
    for (const otherSegment of overlappedSegments) {
      if (otherSegment.start[comp] > lastStartValue) {
        const newSegmentStart = isHorizontal ? new THREE.Vector2(lastStartValue, segment.start.y) : new THREE.Vector2(segment.start.x, lastStartValue);
        const newSegmentEnd = isHorizontal
          ? new THREE.Vector2(otherSegment.start[comp], segment.start.y)
          : new THREE.Vector2(segment.start.x, otherSegment.start[comp]);
        const newSegment = new Segment(newSegmentStart, newSegmentEnd, segment.roomId, segment.hasWall, segment.extras);
        newSegments.push(newSegment);
      }

      lastStartValue = otherSegment.end[comp];
    }

    if (lastStartValue < segment.end[comp]) {
      const newSegmentStart = isHorizontal ? new THREE.Vector2(lastStartValue, segment.start.y) : new THREE.Vector2(segment.start.x, lastStartValue);
      const newSegmentEnd = isHorizontal ? new THREE.Vector2(segment.end[comp], segment.start.y) : new THREE.Vector2(segment.start.x, segment.end[comp]);
      const newSegment = new Segment(newSegmentStart, newSegmentEnd, segment.roomId, segment.hasWall, segment.extras);
      newSegments.push(newSegment);
    }

    return newSegments.filter(s => s.length() > EPSILON);
  }
  public static getOverlappedSegmentParts(segment: Segment, otherSegments: (THREE.Line3 | Segment)[]): Segment[] {
    const isHorizontal = segment.isHorizontal();
    const comp = isHorizontal ? "x" : "y";
    const otherComp = isHorizontal ? "y" : "x";
    const overlappedSegments = otherSegments
      .filter(
        otherSegment =>
          isHorizontal === GeometryUtils.isLineHorizontal(otherSegment) &&
          MathUtils.areNumbersEqual(segment.start[otherComp], otherSegment.end[otherComp], UnitsUtils.getMaxWallDeviation()) &&
          segment.end[comp] >= otherSegment.start[comp] &&
          otherSegment.end[comp] >= segment.start[comp]
      )
      .sort((a, b) => a.start[comp] - b.start[comp]);

    if (!overlappedSegments.length) {
      return [];
    }

    const newSegments: Segment[] = [];

    for (const otherSegment of overlappedSegments) {
      const overlap = [Math.max(segment.start[comp], otherSegment.start[comp]), Math.min(segment.end[comp], otherSegment.end[comp])];

      const newSegmentStart = isHorizontal ? new THREE.Vector2(overlap[0], segment.start.y) : new THREE.Vector2(segment.start.x, overlap[0]);
      const newSegmentEnd = isHorizontal ? new THREE.Vector2(overlap[1], segment.start.y) : new THREE.Vector2(segment.start.x, overlap[1]);
      const newSegment = new Segment(newSegmentStart, newSegmentEnd, segment.roomId, segment.hasWall, Object.assign({}, segment.extras));
      newSegments.push(newSegment);
    }

    return newSegments.filter(s => s.length() > EPSILON);
  }

  public static addOffsetToContour(contour: Segment[], offset: number): void {
    const expandedContourPoints = [];

    for (let i = 0; i < contour.length; i++) {
      const p1 = contour[i === 0 ? contour.length - 1 : i - 1].start;
      const p2 = contour[i].start;
      const p3 = contour[i].end;

      const delta1 = p3.clone().sub(p2);
      const delta2 = p2.clone().sub(p1);
      // 90 degrees clockwise
      const a = new THREE.Vector2(delta1.y, -delta1.x).normalize().multiplyScalar(offset);
      const b = new THREE.Vector2(delta2.y, -delta2.x).normalize().multiplyScalar(offset);

      if (MathUtils.areNumbersEqual(a.cross(b), 0)) {
        b.multiplyScalar(0);
      }

      const point = a.add(b).add(p2);
      expandedContourPoints.push(point);
    }

    // Expand contour
    for (let i = 0; i < contour.length; i++) {
      contour[i].start.copy(expandedContourPoints[i]);
      contour[i === 0 ? contour.length - 1 : i - 1].end.copy(expandedContourPoints[i]);
    }
  }

  /**
   * Detects loops and contour segments in closed contour.
   */
  private static detectInnerLoopsInClosedContour(
    contour: Edge<Segment>[],
    graph: GraphManager<Segment>,
    visitedEdges: Set<string>
  ): { contourSegments: Segment[]; loopsSegments: Segment[] } {
    const contourSegments: Segment[] = [];
    const loopsSegments: Segment[] = [];

    while (contour.length !== 0) {
      const traversalResult = SegmentsUtils.graphTraverse(getStartEdge(), graph, false, visitedEdges);

      traversalResult.contour.forEach(edge => {
        const idx = contour.indexOf(edge);
        if (idx !== -1) {
          if (!loopsSegments.includes(edge.data)) {
            contourSegments.push(edge.data);
          }

          contour.splice(idx, 1);
          visitedEdges.add(edge.keyStr);
        } else {
          loopsSegments.push(edge.data);
          edge.data.revert();
          contour.push(edge);
        }
      });

      traversalResult.innerEdges.forEach(edge => visitedEdges.add(edge.keyStr));
    }

    return { contourSegments, loopsSegments };

    function getStartEdge(): Edge<Segment> {
      let horizontal: Edge<Segment>, vertical: Edge<Segment>;
      contour.forEach(edge => {
        if (edge.data.isHorizontal()) {
          if (!horizontal || horizontal.data.start.y > edge.data.start.y) {
            horizontal = edge;
          }
        } else {
          if (!vertical || vertical.data.start.x < edge.data.start.x) {
            vertical = edge;
          }
        }
      });

      return horizontal || vertical;
    }
  }

  public static mergeUnsortedSegments<T extends Segment>(segments: T[]): T[] {
    const clones = segments.map(s => s.clone() as T);
    const horizontal = clones.filter(w => w.isHorizontal()).sort((a, b) => b.start.x - a.start.x);
    const vertical = clones.filter(w => !w.isHorizontal()).sort((a, b) => b.start.y - a.start.y);
    return WallAnalysisUtils.mergeSegments([...vertical, ...horizontal], MergeSegmentsMode.All);
  }
}
