export enum WallType {
  EXT = "EXT",
  INT = "INT",
  GRG = "GRG",
  DDL = "DDL",
  PLM = "PLM",
}

export enum LayerType {
  EXTERIOR = "EXTERIOR",
  INTERIOR = "INTERIOR",
  CORE = "CORE",
}

export interface Layer {
  thickness: number;
  type: LayerType;
}

export interface RevitWall {
  layers: Layer[];
  type: WallType;
}

export interface Wall {
  interiorThickness: number;
  exteriorThickness: number;
  coreThickness: number;
}
