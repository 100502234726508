export enum SceneEntityType {
  BasePoint = "BasePoint",
  FloorPlan = "FloorPlan",
  Room = "Room",
  FloorBackground = "FloorBackground",
  FloorBackgroundScalingLine = "FloorBackgroundScalingLine",
  StretchTriangle = "StretchTriangle",
  SyntheticWall = "SyntheticWall",
  FloorContour = "FloorContour",
  RoofContour = "RoofContour",
  LotLineVertex = "LotLineVertex",
  LotLineEdge = "LotLineEdge",
  LotLineOffset = "LotLineOffset",
  LotLineLabel = "LotLineLabel",
  ValidationResultContainer = "ValidationResultContainer",
  ObsoleteRoomIndicator = "ObsoleteRoomIndicator",
  RoomLabel = "RoomLabel",
  Cladding = "Cladding",
}
