import "./Editor.sass";
import { useEffect, useRef } from "react";
import { SceneEditorMode } from "../../../editor/models/SceneEditorMode";
import { appModel } from "../../../models/AppModel";
import ContextMenu from "../common/ContextMenu";
import Tooltip from "../common/Tooltip";
import EditorFooter from "./EditorFooter";
import EditorLeftBar from "./EditorLeftBar";
import EditorSpinner from "./EditorSpinner";
import EditorToolbar from "./EditorToolbar";
import { SceneMode } from "../../../models/SceneMode";
import { observer } from "mobx-react";

const Editor = observer(() => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const isEditorMode = appModel.sceneMode == SceneMode.Editor;
  const isRenderMode = appModel.sceneMode == SceneMode.Render;

  useEffect(() => {
    appModel.baseManager.init(canvasRef.current!);

    return () => {
      appModel.baseManager.uninit();
      appModel.setSceneMode(SceneMode.Editor);
      appModel.setSceneEditorMode(SceneEditorMode.Room);
    };
  }, []);

  useEffect(() => {
    appModel.baseManager.resize();
  }, [appModel.sceneMode]);

  const get3dModelFileUrl = () => {
    return `${process.env.REACT_APP_DE_VIEWER_URL}/?url=${encodeURIComponent(appModel.active3dModelPresignedUrl)}`;
  };

  return (
    <div className="editor-content">
      <div className="editor-left-panel">
        <EditorLeftBar baseManager={appModel.baseManager} />
      </div>
      <div className="editor-right-panel">
        <div className="editor-scene-holder" onContextMenu={e => e.preventDefault()}>
          <canvas className={`editor-canvas${isRenderMode ? " hidden" : ""}`} ref={canvasRef} />
          {isRenderMode && !!appModel.active3dModelPresignedUrl && <iframe className="de-viewer-iframe" src={get3dModelFileUrl()} title="DEViewer" />}
          {isEditorMode && <EditorToolbar />}
        </div>

        {isEditorMode && <EditorFooter />}
        {isEditorMode && <ContextMenu />}
        {isEditorMode && <Tooltip />}
        <EditorSpinner />
      </div>
    </div>
  );
});

export default Editor;
