import { action, makeObservable, observable } from "mobx";
import { v4 as uuid } from "uuid";
import { DTOConvertOptions, EntityDataType, PersistentObject } from "../entities/entityManager";
import { deepCopy2DTO } from "../helpers/pojo";
import { arrayIdGet, arrayIdSet } from "../helpers/utilities";
import { kindFloorRaw, markPublic } from "../services/allKinds";
import { RawFloor } from "../services/rawTypes";
import { appModel } from "./AppModel";
import { Background } from "./Background";
import { FileEntity } from "./FileEntity";
import { LotLine } from "./LotLine";
import { Room } from "./Room";
import { CorePlan } from "./CorePlan";
import { Roof } from "./Roof";

export class Floor implements PersistentObject<Floor, RawFloor> {
  corePlanId: string = "";
  id: string = uuid();
  name: string = "";
  index: number = 0;
  background?: Background = null;
  lotLine: LotLine = null;
  rooms: Room[] = [];
  roofs: Roof[] = [];

  constructor(index: number = 0, name: string = "") {
    makeObservable(this, {
      name: observable,
      index: observable,
      background: observable,
      rooms: observable,
      roofs: observable,

      setBackground: action,
      addRoom: action,
      removeRoom: action,
      fromDTO: action,
    });

    this.index = index;
    this.name = name;
  }

  setBackground(background?: Background): void {
    if (background) {
      background.floorId = this.id;
    }
    this.background = background;
  }

  checkUsedFile(file: FileEntity, corePlan: CorePlan): { message: string; owner: any }[] {
    const result = [];

    if (this.background?.imageFileId === file.id) {
      result.push({
        message: `The file is used in the background "${this.name}"`,
        owner: this,
      });
    } else {
      const fileEntity = corePlan.files.find(f => f.id === this.background?.imageFileId);
      if (fileEntity?.ext_info?.pdf_file_id === file.id) {
        result.push({
          message: `The file is used in the background "${this.name}"`,
          owner: this,
        });
      }
    }

    return result;
  }

  addRoom(room: Room): void {
    if (room) {
      room.floorId = this.id;
      arrayIdSet(this.rooms, room);
    }
  }
  removeRoom(id: string): void {
    const idx = this.rooms.findIndex(room => room.id === id);
    if (idx > -1) {
      this.rooms.splice(idx, 1);
    }
  }

  getRoomsByType(roomTypeId: string): Room[] {
    return this.rooms.filter(room => room.roomTypeId === roomTypeId);
  }

  /**
   * Adds a roof to the floor.
   * @param roof The roof to add.
   */
  addRoof(roof: Roof): void {
    if (roof) {
      this.roofs.push(roof);
    }
  }

  /**
   * Resets or clears all roofs from the floor.
   */
  resetRoofs(): void {
    this.roofs = []; // Clear the array of roofs
  }

  clone(): Floor {
    const result = new Floor();
    result.name = this.name;
    result.index = this.index;
    result.drawing = deepCopy2DTO(this.drawing);
    result.lotLine = this.lotLine?.clone() || null;

    // Background.floorId and Background.imageFileId will be replaced on server side, see server CorePlan.clone
    if (this.background) {
      result.background = new Background().fromDTO(deepCopy2DTO(this.background));
    }

    result.rooms.push(
      ...this.rooms.map(room => {
        const newRoom = room.clone();
        newRoom.floorId = result.id;
        return newRoom;
      })
    );

    return result;
  }

  getIndoorArea(): number {
    return this.rooms.reduce((sum, room) => (appModel.getRoomType(room.roomTypeId).attributes.indoor ? sum + room.area : sum), 0);
  }

  // * Persistence part *

  mark: number = markPublic.id;
  static readonly kind = kindFloorRaw;
  drawing?: { [key: string]: any };

  toDTO(format?: EntityDataType, options?: DTOConvertOptions): RawFloor {
    this.drawing = this.drawing || {};
    this.drawing.lotLine = this.lotLine?.toDTO();

    return deepCopy2DTO(
      {
        kind: Floor.kind.id,
        corePlanId: this.corePlanId,
        id: this.id,
        name: this.name,
        index: this.index,
        background: this.background,
        attached: options?.scope === "full" ? this.rooms : undefined,
        mark: this.mark,
        drawing: this.drawing,
        //commands: ,
      },
      { scope: options?.scope, only: options?.onlyProps }
    ) as unknown as RawFloor;
  }

  fromDTO(dto: RawFloor, format?: EntityDataType): Floor {
    if (Floor.kind.id != dto?.kind) throw "dto:RawFloor: incorrect kind";
    this.id = dto.id;
    this.name = dto.name;
    this.mark = dto.mark;
    this.drawing = dto.drawing;
    if (format === "APIdata" || format === "APIdata.saved") {
      if (dto.corePlanId) {
        this.corePlanId = dto.corePlanId;
      }
      if (dto.index !== undefined) {
        this.index = dto.index;
      }
      if (dto.background) {
        this.setBackground(new Background().fromDTO(dto.background));
      }

      if (dto.drawing?.lotLine) {
        this.lotLine = new LotLine().fromDTO(dto.drawing.lotLine);
      }
    }

    for (const rawRoom of dto.attached || []) {
      if (rawRoom.kind == Room.kind.id) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.addRoom((arrayIdGet(this.rooms, rawRoom.id) || new Room()).fromDTO(rawRoom, format));
      } else throw "Floor.attached item kind is unknown";
    }

    return this;
  }
}
