import { action, computed, makeObservable, observable } from "mobx";
import { v4 as uuid } from "uuid";
import { EntityDataType, PersistentObject } from "../entities/entityManager";
import { deepCopy2DTO } from "../helpers/pojo";
import { kindRoomRaw } from "../services/allKinds";
import { RawRoom } from "../services/rawTypes";
import { appModel } from "./AppModel";
import { ExteriorFinish } from "./DesignStyle";
import RoomOpening from "./RoomOpening";

export class Room implements PersistentObject<Room, RawRoom> {
  roomTypeId: string = "";
  floorId: string = "";
  id: string = uuid();
  name: string = "";
  drawing?: { [key: string]: any };
  colorIndex: number;
  customColorIndex: number;

  // helper properties:
  x: number = 0;
  y: number = 0;
  width: number = 0;
  height: number = 0;
  netWidth: number = 0;
  netHeight: number = 0;
  rotation: number = 0;
  mirroring: boolean = false;
  openings: RoomOpening[] = [];
  roofSlopes: number[] = null;
  dutchGableDepths: number[] = null;
  exteriorFinishes: number[] = null;
  gableExteriorFinishes: number[] = null;

  constructor(roomTypeId: string = "", name: string = "") {
    this.roomTypeId = roomTypeId;
    this.name = name;

    makeObservable(this, {
      name: observable,
      // colorIndex: observable,

      x: observable,
      y: observable,
      width: observable,
      height: observable,
      netWidth: observable,
      netHeight: observable,
      rotation: observable,
      mirroring: observable,
      openings: observable,

      setX: action,
      setY: action,
      setWidth: action,
      setHeight: action,
      setNetWidth: action,
      setNetHeight: action,
      setRotation: action,
      setMirroring: action,
      setDrawing: action,
      // setColorIndex: action,
      addOpening: action,
      removeOpening: action,

      area: computed,

      fromDTO: action,
    });
  }

  get area(): number {
    return this.width * this.height;
  }

  get finish(): ExteriorFinish {
    const idx = this.customColorIndex ?? this.colorIndex;
    return appModel.activeDesignStyle?.finishes?.find(f => f.index === idx)?.clone() || ExteriorFinish.createFallback();
  }

  get isObsolete(): boolean {
    return appModel.getRoomType(this.roomTypeId).isMarkHidden;
  }

  setX(x: number): void {
    this.x = x;
  }
  setY(y: number): void {
    this.y = y;
  }
  setWidth(width: number): void {
    this.width = width;
  }
  setHeight(height: number): void {
    this.height = height;
  }
  setNetWidth(netWidth: number): void {
    this.netWidth = netWidth;
  }
  setNetHeight(netHeight: number): void {
    this.netHeight = netHeight;
  }
  setRotation(rotation: number): void {
    this.rotation = rotation;
  }
  setMirroring(mirroring: boolean): void {
    this.mirroring = mirroring;
  }
  setDrawing(drawing: { [key: string]: any }): void {
    this.drawing = drawing;
  }
  setRoofSlopes(roofSlopes: number[]): void {
    this.roofSlopes = roofSlopes;
  }
  setRoofSlope(index: number, slope: number): void {
    if (this.roofSlopes.length > index) this.roofSlopes[index] = slope;
  }
  setDutchGableDepth(index: number, depth: number): void {
    if (this.dutchGableDepths.length > index) this.dutchGableDepths[index] = depth;
  }
  setWallExteriorFinish(index: number, exteriorFinish: number): void {
    if (this.exteriorFinishes.length > index) this.exteriorFinishes[index] = exteriorFinish;
  }
  setGableExteriorFinish(index: number, exteriorFinish: number): void {
    if (this.gableExteriorFinishes.length > index) this.gableExteriorFinishes[index] = exteriorFinish;
  }

  addOpening(opening: RoomOpening): void {
    opening.roomId = this.id;
    this.openings.push(opening);
  }
  removeOpening(openingId: string): void {
    const idx = this.openings.findIndex(it => it.id == openingId);
    if (idx !== -1) {
      this.openings.splice(idx, 1);
    }
  }

  clone(): Room {
    const result = new Room();
    result.roomTypeId = this.roomTypeId;
    result.name = this.name;
    result.drawing = deepCopy2DTO(this.drawing);
    result.colorIndex = this.colorIndex;
    result.customColorIndex = this.customColorIndex;
    result.roofSlopes = this.roofSlopes;
    result.dutchGableDepths = this.dutchGableDepths;
    result.exteriorFinishes = this.exteriorFinishes;
    result.gableExteriorFinishes = this.gableExteriorFinishes;

    return result;
  }

  // * Persistence part *

  static readonly kind = kindRoomRaw;

  toDTO(): RawRoom {
    this.drawing = this.drawing || {};
    this.drawing.colorIndex = this.colorIndex;
    this.drawing.customColorIndex = this.customColorIndex;
    this.drawing.roofSlopes = this.roofSlopes;
    this.drawing.dutchGableDepths = this.dutchGableDepths;
    this.drawing.exteriorFinishes = this.exteriorFinishes;
    this.drawing.gableExteriorFinishes = this.gableExteriorFinishes;

    return deepCopy2DTO({
      kind: Room.kind.id,
      roomTypeId: this.roomTypeId,
      id: this.id,
      floorId: this.floorId,
      name: this.name,
      drawing: this.drawing,
    }) as unknown as RawRoom;
  }

  fromDTO(dto: RawRoom, format?: EntityDataType): Room {
    if (Room.kind.id != dto?.kind) throw "dto:RawRoom: incorrect kind";
    this.id = dto.id;
    this.name = dto.name;
    if (format === "APIdata.saved") return this;

    this.roomTypeId = dto.roomTypeId;
    this.floorId = dto.floorId;
    this.drawing = dto.drawing;
    this.colorIndex = dto.drawing?.colorIndex || null;
    this.customColorIndex = dto.drawing?.customColorIndex || null;
    this.roofSlopes = dto.drawing?.roofSlopes || null;
    this.dutchGableDepths = dto.drawing?.dutchGableDepths || null;
    this.exteriorFinishes = dto.drawing?.exteriorFinishes || null;
    this.gableExteriorFinishes = dto.drawing?.gableExteriorFinishes || null;

    return this;
  }

  public getFinishByIndex(exteriorFinishIndex: number): ExteriorFinish {
    const idx = exteriorFinishIndex;
    return appModel.activeDesignStyle?.finishes?.find(f => f.index === idx)?.clone() || ExteriorFinish.createFallback();
  }
}
