import { action, computed, makeObservable, observable, runInAction } from "mobx";
import axios from "../../services/api/utilities/axios-wrapper";
import apiProvider from "../../services/api/utilities/Provider";
import { defaultSettings, defaultDescriptions } from "./defaults";
import { TSettings, TSettingsDescriptions, TSettingsValues, TWebAppUISettings } from "./types";
import { NIL as nilUuid } from "uuid";
import { getAxiosRequestConfig } from "../../services/api/utilities";
import { showToastMessage } from "../../helpers/messages";

export class Settings {
  private _settings: TSettings = observable({
    values: defaultSettings,
    descriptions: defaultDescriptions,
  });
  public settingsUpdated = false;

  constructor() {
    makeObservable(this, {
      values: computed,
      settingsUpdated: observable,
      descriptions: computed,
    });
  }

  public get values(): TSettingsValues {
    return this._settings.values;
  }

  public get descriptions(): TSettingsDescriptions {
    return this._settings.descriptions;
  }

  public set isSettingsUpdated(settingsUpdated: boolean) {
    this.settingsUpdated = settingsUpdated;
  }

  public async loadSettings() {
    try {
      const result = await axios.get(`${apiProvider.host}system-settings/${nilUuid}`, getAxiosRequestConfig());

      if (result.status !== 200 || !result.data) {
        showToastMessage("Error", "Error occurred on settings loading. Using default settings.");
        return;
      }
      const settingsResult: TSettingsValues = result.data;
      runInAction(() => {
        this._settings.values = settingsResult;
        this._settings.descriptions = defaultDescriptions;
      });
    } catch (error) {
      showToastMessage("Error", "Error occurred on settings loading. Using default settings.");
    }
  }

  public async updateSettings(newSettings: TSettingsValues) {
    try {
      const result = await axios.put(`${apiProvider.host}system-settings/${nilUuid}`, { settings: newSettings }, getAxiosRequestConfig());
      const updateResult = result.data.settings;

      runInAction(() => {
        this.isSettingsUpdated = true;
        this._settings.values = updateResult;
      });
    } catch (error) {
      showToastMessage("Error", "Error occurred on settings update.");
    }
  }

  public getColorNumber(setting: keyof TWebAppUISettings): number {
    return parseInt(this.values.webAppUISettings[setting].slice(1), 16);
  }
}

const settings = new Settings();

export default settings;
