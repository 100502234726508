import { Button, Modal } from "antd";
import { makeAutoObservable } from "mobx";
import { observer } from "mobx-react";
import { useHistory } from "react-router-dom";
import { CorePlan } from "../../../models/CorePlan";
import { ContentMode, openModalCorePlanWindow } from "./ModalCorePlanWindow";
import "./CorePlanLockModal.sass";

class CorePlanLockModalState {
  public corePlan: CorePlan;

  constructor() {
    makeAutoObservable(this);
  }

  public setCorePlan(corePlan: CorePlan): void {
    this.corePlan = corePlan;
  }
}

export const corePlanLockModalState = new CorePlanLockModalState();

const CorePlanLockModal = observer(() => {
  const history = useHistory();
  const corePlan = corePlanLockModalState.corePlan;

  const handleClose = () => {
    corePlanLockModalState.setCorePlan(null);
  };

  const handleOpenViewOnly = async () => {
    history.push(`/editor/${corePlan.id}`);
    handleClose();
  };

  const handleOpenDuplicate = async () => {
    const onCorePlanCreated = (corePlan: CorePlan) => {
      history.push(`/editor/${corePlan.id}`);
    };
    openModalCorePlanWindow(corePlan, ContentMode.Clone, onCorePlanCreated);
    handleClose();
  };

  let buttonsKey = 1;
  const buttons = [
    <Button key={buttonsKey++} onClick={handleOpenViewOnly}>
      Open for View Only
    </Button>,
    <Button key={buttonsKey++} type="primary" onClick={handleOpenDuplicate}>
      Duplicate
    </Button>,
  ];

  return (
    <Modal className="corePlan-lock-modal" open={!!corePlan} onCancel={handleClose} destroyOnClose={true} footer={buttons}>
      CorePlan is locked for editing by {corePlan?.lockedBy?.email}
    </Modal>
  );
});

export default CorePlanLockModal;
