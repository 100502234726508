import * as THREE from "three";
import { Vector3V } from "../../models/Vector3V";
import { EPSILON, ZOOM_TO_FIT_SIZE_FACTOR_2D, ZOOM_TO_FIT_SIZE_FACTOR_3D } from "../consts";
import OrbitControls from "../libs/OrbitControls";
import TrackballControls from "../libs/TrackballControls";
import { Direction } from "../models/Direction";
import { Segment } from "../models/segments/Segment";
import { Side } from "../../models/Side";
import MathUtils from "./MathUtils";
import UnitsUtils from "./UnitsUtils";

export default class GeometryUtils {
  static Vector3VToVector3(v: Vector3V): THREE.Vector3 {
    return new THREE.Vector3(v.x, v.y, v.z);
  }
  static Vector3ToVector3V(v: THREE.Vector3): Vector3V {
    return new Vector3V(v.x, v.y, v.z);
  }
  static Vector2ToVector3(v: THREE.Vector2): THREE.Vector3 {
    return new THREE.Vector3(v.x, v.y, 0);
  }

  static areVectorsEqual(left: THREE.Vector3, right: THREE.Vector3, epsilon = EPSILON): boolean {
    return MathUtils.areNumberArraysEqual(left.toArray(), right.toArray(), epsilon);
  }
  static areVectors2Equal(left: THREE.Vector2, right: THREE.Vector2, epsilon = EPSILON): boolean {
    return MathUtils.areNumberArraysEqual(left.toArray(), right.toArray(), epsilon);
  }

  static ceilVector(target: THREE.Vector3, e: number = UnitsUtils.getRoundPrecision()): THREE.Vector3 {
    target.x = MathUtils.ceil(target.x, e);
    target.y = MathUtils.ceil(target.y, e);
    target.z = MathUtils.ceil(target.z, e);

    return target;
  }

  static disposeObject(obj: any): void {
    if (obj) {
      GeometryUtils._disposeObject(obj);

      while (obj.children.length > 0) {
        GeometryUtils.disposeObject(obj.children[0]);
        obj.remove(obj.children[0]);
      }
    }
  }
  private static _disposeObject(obj: any): void {
    if (obj) {
      obj.geometry?.dispose();

      if (obj.material) {
        if (obj.material.length) {
          for (let i = 0; i < obj.material.length; ++i) {
            obj.material[i].dispose();
          }
        } else {
          obj.material.dispose();
        }
      }
    }
  }

  /**
   * Calculates signed CCW angle
   */
  static calculateSignedAngle(x1: number, y1: number, x2: number, y2: number): number {
    const dot = x1 * x2 + y1 * y2;
    const dot90 = x1 * -y2 + y1 * x2;
    return -Math.atan2(dot90, dot);
  }

  static setRenderOrder(object: THREE.Object3D, renderOrder: number): void {
    if (!(object instanceof THREE.Group)) {
      object.renderOrder = renderOrder;
    }

    for (let i = 0, l = object.children.length; i < l; i++) {
      GeometryUtils.setRenderOrder(object.children[i], renderOrder);
    }
  }

  static isLineHorizontal(line: THREE.Line3 | Segment): boolean {
    return MathUtils.areNumbersEqual(line.start.y, line.end.y);
  }
  static isLineVertical(line: THREE.Line3): boolean {
    return MathUtils.areNumbersEqual(line.start.x, line.end.x);
  }
  static getLineDirection(line: THREE.Line3 | Segment): Direction {
    return GeometryUtils.isLineHorizontal(line) ? Direction.Horizontal : Direction.Vertical;
  }

  /**
   * Check if lines(horizontal or vertical) overlap fully or partially
   * @param {THREE.Line3 | Segment} line1:Horizontal or Vertical line
   * @param {THREE.Line3 | Segment} line2:Horizontal or Vertical line
   * @returns {boolean}
   */
  static doLinesOverlap(line1: THREE.Line3 | Segment, line2: THREE.Line3 | Segment): boolean {
    const line1Dir = GeometryUtils.getLineDirection(line1);
    const line2Dir = GeometryUtils.getLineDirection(line2);
    if (line1Dir !== line2Dir) {
      return false;
    }
    const [axis, axis2] = line1Dir === Direction.Vertical ? ["x", "y"] : ["y", "x"];
    if (!MathUtils.areNumbersEqual(line1.start[axis], line2.start[axis])) {
      return false;
    }
    if (
      MathUtils.isNumberInRange(line1.start[axis2], line2.start[axis2], line2.end[axis2]) ||
      MathUtils.isNumberInRange(line1.end[axis2], line2.start[axis2], line2.end[axis2]) ||
      MathUtils.isNumberInRange(line2.start[axis2], line1.start[axis2], line1.end[axis2]) ||
      MathUtils.isNumberInRange(line2.end[axis2], line1.start[axis2], line1.end[axis2])
    ) {
      return true;
    }
    return false;
  }
  static doLinesIntersect(
    line1A: THREE.Vector3 | THREE.Vector2,
    line1B: THREE.Vector3 | THREE.Vector2,
    line2C: THREE.Vector3 | THREE.Vector2,
    line2D: THREE.Vector3 | THREE.Vector2
  ): boolean {
    const det = (line1B.x - line1A.x) * (line2D.y - line2C.y) - (line2D.x - line2C.x) * (line1B.y - line1A.y);
    if (MathUtils.areNumbersEqual(det, 0)) {
      return false;
    }

    // Check that intersection lies between both sets of points
    const lambda = ((line2D.y - line2C.y) * (line2D.x - line1A.x) + (line2C.x - line2D.x) * (line2D.y - line1A.y)) / det;
    const gamma = ((line1A.y - line1B.y) * (line2D.x - line1A.x) + (line1B.x - line1A.x) * (line2D.y - line1A.y)) / det;
    return lambda > 0 && lambda < 1 && gamma > 0 && gamma < 1;
  }
  static lineIntersectsBoundingBox(line: THREE.Line3, bbox: THREE.Box3 | THREE.Box2): boolean {
    const cLine = line.clone();
    const [axis, axis2] = GeometryUtils.isLineHorizontal(cLine) ? ["x", "y"] : ["y", "x"];
    if (cLine.start[axis] > cLine.end[axis]) {
      const temp = cLine.start.clone();
      cLine.start = cLine.end;
      cLine.end = temp;
    }
    if (cLine.start[axis2] >= bbox.min[axis2] && cLine.start[axis2] <= bbox.max[axis2]) {
      if (cLine.start[axis] <= bbox.min[axis]) {
        if (cLine.end[axis] >= bbox.min[axis]) {
          return true;
        }
      } else if (cLine.start[axis] <= bbox.max[axis]) {
        return true;
      }
    }
    return false;
  }
  static isLineInsideLine(line: THREE.Line3, mainLine: THREE.Line3): boolean {
    const lineDir = GeometryUtils.isLineHorizontal(line) ? Direction.Horizontal : Direction.Vertical;
    const mainLineDir = GeometryUtils.isLineHorizontal(mainLine) ? Direction.Horizontal : Direction.Vertical;
    if (lineDir !== mainLineDir) {
      return false;
    }
    const [axis, axis2] = lineDir === Direction.Vertical ? ["x", "y"] : ["y", "x"];
    if (!MathUtils.areNumbersEqual(line.start[axis], mainLine.start[axis])) {
      return false;
    }
    if (
      MathUtils.isNumberInRange(line.start[axis2], mainLine.start[axis2], mainLine.end[axis2]) &&
      MathUtils.isNumberInRange(line.end[axis2], mainLine.start[axis2], mainLine.end[axis2])
    ) {
      return true;
    }
    return false;
  }

  //treating the lines as infinite
  static getLineIntersectionPoint(
    line1start: THREE.Vector3,
    line1end: THREE.Vector3,
    line2start: THREE.Vector3,
    line2end: THREE.Vector3
  ): THREE.Vector3 | null {
    const denominator = (line2end.y - line2start.y) * (line1end.x - line1start.x) - (line2end.x - line2start.x) * (line1end.y - line1start.y);
    if (MathUtils.areNumbersEqual(denominator, 0)) {
      return null;
    }
    let a = line1start.y - line2start.y;
    let b = line1start.x - line2start.x;
    const numerator1 = (line2end.x - line2start.x) * a - (line2end.y - line2start.y) * b;
    const numerator2 = (line1end.x - line1start.x) * a - (line1end.y - line1start.y) * b;
    a = numerator1 / denominator;
    b = numerator2 / denominator;
    return new THREE.Vector3(line1start.x + a * (line1end.x - line1start.x), line1start.y + a * (line1end.y - line1start.y));
  }

  static getLineSegmentsIntersectionPointParameters(
    start1: THREE.Vector3 | THREE.Vector2,
    end1: THREE.Vector3 | THREE.Vector2,
    start2: THREE.Vector3 | THREE.Vector2,
    end2: THREE.Vector3 | THREE.Vector2,
    epsilon = EPSILON
  ): number[] | null {
    const denominator = (end1.x - start1.x) * (end2.y - start2.y) - (end2.x - start2.x) * (end1.y - start1.y);
    if (MathUtils.areNumbersEqual(denominator, 0)) {
      return null;
    }

    const u = ((end2.y - start2.y) * (end2.x - start1.x) + (start2.x - end2.x) * (end2.y - start1.y)) / denominator;
    const v = ((start1.y - end1.y) * (end2.x - start1.x) + (end1.x - start1.x) * (end2.y - start1.y)) / denominator;

    // Check that intersection lies between both sets of points.
    if (MathUtils.isNumberInRange(u, 0, 1, epsilon) && MathUtils.isNumberInRange(v, 0, 1, epsilon)) {
      return [u, v];
    }

    return null;
  }
  static getLineSegmentsIntersectionPoint(
    start1: THREE.Vector3 | THREE.Vector2,
    end1: THREE.Vector3 | THREE.Vector2,
    start2: THREE.Vector3 | THREE.Vector2,
    end2: THREE.Vector3 | THREE.Vector2,
    epsilon = EPSILON
  ): THREE.Vector3 | null {
    const params = GeometryUtils.getLineSegmentsIntersectionPointParameters(start1, end1, start2, end2, epsilon);
    if (!params) {
      return null;
    }

    const u = params[0];
    return new THREE.Vector3(start1.x + u * (end1.x - start1.x), start1.y + u * (end1.y - start1.y));
  }

  static evaluateLineParameter(parameter: number, start: THREE.Vector3, end: THREE.Vector3): THREE.Vector3 {
    return end.clone().sub(start).multiplyScalar(parameter).add(start);
  }

  static getLineMidPoint(line: THREE.Line3): THREE.Vector3 {
    return GeometryUtils.getLineMidPoint2(line.start, line.end);
  }
  static getLineMidPoint2(startPoint: THREE.Vector3, endPoint: THREE.Vector3): THREE.Vector3 {
    return startPoint.clone().add(endPoint).multiplyScalar(0.5);
  }
  static getLocalLine3(line: THREE.Object3D): THREE.Line3 {
    const bb = GeometryUtils.getGeometryBoundingBox2D(line.clone());
    return new THREE.Line3(bb.min, bb.max);
  }

  static isPointInsidePolygon(polygonPoints: THREE.Vector3[], point: THREE.Vector3): boolean {
    const t = (p1: THREE.Vector3, p2: THREE.Vector3) => ((p2.x - p1.x) * (point.y - p1.y)) / (p2.y - p1.y) + p1.x;

    let inside = false;

    for (let i = -1, l = polygonPoints.length, j = l - 1; ++i < l; j = i) {
      const p1 = polygonPoints[i];
      const p2 = polygonPoints[j];

      ((p1.y <= point.y && point.y < p2.y) || (p2.y <= point.y && point.y < p1.y)) && point.x < t(p1, p2) && (inside = !inside);
    }

    return inside;
  }

  static isPolygonInsidePolygon(polygon: THREE.Vector3[], container: THREE.Vector3[]): boolean {
    for (let i = 0; i < polygon.length; i++) {
      const p1 = polygon[i];
      const p2 = polygon[(i + 1) % polygon.length];

      for (let j = 0; j < container.length; j++) {
        const q1 = container[j];
        const q2 = container[(j + 1) % container.length];

        if (GeometryUtils.doLinesIntersect(p1, p2, q1, q2)) {
          return false; // Lines intersect, polygons are not completely inside each other
        }
      }
    }

    // Check if one of the points of the polygon is inside container polygon
    return polygon.some(point => GeometryUtils.isPointInsidePolygon(container, point));
  }

  static getPointOnObject(object: THREE.Object3D): THREE.Vector3 {
    const geometry = (object as any).geometry;
    if (geometry) {
      if (geometry instanceof THREE.BufferGeometry && geometry.attributes?.position) {
        return new THREE.Vector3().fromBufferAttribute(geometry.attributes.position, 0).applyMatrix4(object.matrixWorld);
      }

      if (THREE.Geometry !== undefined && geometry instanceof THREE.Geometry) {
        return geometry.vertices[0].clone().applyMatrix4(object.matrixWorld);
      }
    }

    for (const child of object.children) {
      const point = this.getPointOnObject(child);
      if (point) {
        return point;
      }
    }
    return null;
  }
  static getPointsPositions(geometry: THREE.BufferGeometry): THREE.Vector3[] {
    const start = new THREE.Vector3().fromBufferAttribute(geometry.attributes.position, 0);
    const end = new THREE.Vector3().fromBufferAttribute(geometry.attributes.position, 1);
    return [start, end];
  }
  static doObjectsIntersect3D(obj1: THREE.Object3D, obj2: THREE.Object3D): boolean {
    const bb1 = GeometryUtils.getGeometryBoundingBox3D(obj1);
    const bb2 = GeometryUtils.getGeometryBoundingBox3D(obj2);

    return bb1.intersectsBox(bb2);
  }

  static getGeometryBoundingBox3D(obj: THREE.Object3D): THREE.Box3 {
    return new THREE.Box3().setFromObject(obj);
  }
  static getGeometryBoundingBox2D(obj: THREE.Object3D): THREE.Box3 {
    const bb = this.getGeometryBoundingBox3D(obj);
    bb.min.z = bb.max.z = 0;
    return bb;
  }
  static getGeometryBoundingSphere(obj: THREE.Object3D): THREE.Sphere {
    const bb = this.getGeometryBoundingBox3D(obj);

    if (!bb.isEmpty()) {
      const result = new THREE.Sphere();
      bb.getBoundingSphere(result);
      return result.clone();
    } else {
      return new THREE.Sphere(new THREE.Vector3(), 1);
    }
  }

  static getSceneUnitPixels(camera: THREE.PerspectiveCamera, renderer?: THREE.WebGLRenderer): number {
    const vFOV = (camera.fov * Math.PI) / 180;
    const height = 2 * Math.tan(vFOV / 2) * Math.abs(camera.position.z);
    //const width = height * camera.aspect;

    const rect = renderer?.domElement.getBoundingClientRect();
    //return rect ? rect.width / width : 0.0; // pixels per scene unit
    return rect ? rect.height / height : 0.0; // pixels per scene unit
  }

  static zoomToFit2D(sphere: THREE.Sphere, camera: THREE.PerspectiveCamera, controls: TrackballControls): void {
    const distance = sphere.radius * ZOOM_TO_FIT_SIZE_FACTOR_2D;
    const c = sphere.center.clone();

    camera.position.copy(new THREE.Vector3(c.x, c.y, c.z + distance));
    camera.lookAt(c);
    camera.updateProjectionMatrix();

    controls.target.copy(c);
    controls.update();
  }
  static zoomToFit3D(sphere: THREE.Sphere, camera: THREE.PerspectiveCamera, controls: TrackballControls | OrbitControls): void {
    const lookAt = new THREE.Vector3(-1, 1, -1);
    const up = new THREE.Vector3(0, 0, 1);
    this.zoomToFit3DByDirection(sphere, lookAt, up, camera, controls);
  }
  static zoomToFit3DByQuaternion(
    sphere: THREE.Sphere,
    q: THREE.Quaternion,
    camera: THREE.PerspectiveCamera,
    controls: TrackballControls | OrbitControls
  ): void {
    camera.setRotationFromQuaternion(q);

    const lookAt = new THREE.Vector3(0, 0, -1).applyQuaternion(q).normalize();
    const up = new THREE.Vector3(0, 1, 0).applyQuaternion(q).normalize();
    this.zoomToFit3DByDirection(sphere, lookAt, up, camera, controls);
  }
  static zoomToFit3DByDirection(
    sphere: THREE.Sphere,
    lookAt: THREE.Vector3,
    up: THREE.Vector3,
    camera: THREE.PerspectiveCamera,
    controls: TrackballControls | OrbitControls
  ): void {
    camera.position.copy(lookAt.clone().negate().add(sphere.center));
    camera.up.copy(up);
    camera.lookAt(sphere.center);

    controls.target.copy(sphere.center);
    this.fitCameraDistance(sphere, camera, controls);
    controls.update();
  }
  static fitCameraDistance(sphere: THREE.Sphere, camera: THREE.PerspectiveCamera, controls: TrackballControls | OrbitControls): void {
    const p = camera.position.clone();
    const cameraTarget = controls.target.clone();

    const distance = sphere.radius * ZOOM_TO_FIT_SIZE_FACTOR_3D;

    const c = sphere.center.clone();
    const diff = cameraTarget.sub(p).normalize().multiplyScalar(distance);

    const newPosition = c.sub(diff);
    camera.position.copy(newPosition);

    // if (!isPerspectiveCamera) {
    // 	_originalAspect = renderer.domElement.width / renderer.domElement.height;
    // 	_aspect = _originalAspect;

    // 	if (_aspect > 1) {
    // 		_frustumSize = sphere.radius * 2;
    // 	} else {
    // 		_frustumSize = (sphere.radius * 2) / _aspect;
    // 	}

    // 	_camera.left = (-_frustumSize * _aspect) / 2.0;
    // 	_camera.right = (_frustumSize * _aspect) / 2.0;
    // 	_camera.top = _frustumSize / 2.0;
    // 	_camera.bottom = -_frustumSize / 2.0;
    // }

    camera.updateProjectionMatrix();
  }

  static getBoundingBoxCenterLine(bb: THREE.Box3): THREE.Line3 {
    const lineLeft = new THREE.Line3(new THREE.Vector3(bb.min.x, bb.min.y, bb.min.z), new THREE.Vector3(bb.min.x, bb.max.y, bb.min.z));
    const lineRight = new THREE.Line3(new THREE.Vector3(bb.max.x, bb.min.y, bb.min.z), new THREE.Vector3(bb.max.x, bb.max.y, bb.min.z));
    const lineBottom = new THREE.Line3(new THREE.Vector3(bb.min.x, bb.min.y, bb.min.z), new THREE.Vector3(bb.max.x, bb.min.y, bb.min.z));
    const lineTop = new THREE.Line3(new THREE.Vector3(bb.min.x, bb.max.y, bb.min.z), new THREE.Vector3(bb.max.x, bb.max.y, bb.min.z));

    const dA = lineLeft.distance();
    const dB = lineBottom.distance();

    if (dA > dB) {
      // vertical line
      return new THREE.Line3(GeometryUtils.getLineMidPoint(lineBottom), GeometryUtils.getLineMidPoint(lineTop));
    } else {
      // horizontal line
      return new THREE.Line3(GeometryUtils.getLineMidPoint(lineLeft), GeometryUtils.getLineMidPoint(lineRight));
    }
  }
  static doBoundingBoxesTouch(box1: THREE.Box3, box2: THREE.Box3): Direction {
    if (MathUtils.areNumbersEqual(box1.max.x, box2.min.x) || MathUtils.areNumbersEqual(box1.min.x, box2.max.x)) {
      return Direction.Horizontal;
    }

    if (MathUtils.areNumbersEqual(box1.max.y, box2.min.y) || MathUtils.areNumbersEqual(box1.min.y, box2.max.y)) {
      return Direction.Vertical;
    }

    return null;
  }
  static getBoundingBoxesTouch(bb1: THREE.Box3, bb2: THREE.Box3): { [key in Side]: boolean } {
    const result = {
      [Side.Top]: false,
      [Side.Right]: false,
      [Side.Bottom]: false,
      [Side.Left]: false,
    };

    if (Math.max(bb1.min.y, bb2.min.y) < Math.min(bb1.max.y, bb2.max.y) - EPSILON) {
      if (MathUtils.areNumbersEqual(bb1.max.x, bb2.min.x) || MathUtils.areNumbersEqual(bb1.max.x, bb2.max.x)) {
        result[Side.Right] = true;
      }

      if (MathUtils.areNumbersEqual(bb1.min.x, bb2.max.x) || MathUtils.areNumbersEqual(bb1.min.x, bb2.min.x)) {
        result[Side.Left] = true;
      }
    }

    if (Math.max(bb1.min.x, bb2.min.x) < Math.min(bb1.max.x, bb2.max.x) - EPSILON) {
      if (MathUtils.areNumbersEqual(bb1.max.y, bb2.min.y) || MathUtils.areNumbersEqual(bb1.max.y, bb2.max.y)) {
        result[Side.Top] = true;
      }

      if (MathUtils.areNumbersEqual(bb1.min.y, bb2.max.y) || MathUtils.areNumbersEqual(bb1.min.y, bb2.min.y)) {
        result[Side.Bottom] = true;
      }
    }

    return result;
  }
  static doBoundingBoxesIntersect(bbs1: THREE.Box3[], bbs2: THREE.Box3[]): boolean {
    return bbs1.some(bb1 => bbs2.some(bb2 => bb2.intersectsBox(bb1)));
  }

  static positionToScreenPosition(position: THREE.Vector3, camera: THREE.Camera, container: DOMRect): { x: number; y: number } {
    if (!position || !container) return undefined;

    const screenPosition = new THREE.Vector3();

    screenPosition.copy(position);
    screenPosition.project(camera);

    const widthHalf = 0.5 * container.width;
    const heightHalf = 0.5 * container.height;

    screenPosition.x = screenPosition.x * widthHalf + widthHalf + container.x;
    screenPosition.y = -(screenPosition.y * heightHalf) + heightHalf + container.y;

    return {
      x: screenPosition.x,
      y: screenPosition.y,
    };
  }

  static deepClone(
    obj: THREE.Object3D,
    options: {
      cloneGeometry?: boolean | ((o: THREE.Object3D) => boolean);
      cloneMaterial?: boolean | ((o: THREE.Object3D) => boolean);
    } = { cloneGeometry: true, cloneMaterial: true }
  ): THREE.Object3D {
    const clone = obj.clone();

    if (options) {
      clone.traverse((child: any) => {
        if (
          options.cloneGeometry &&
          child.geometry &&
          (options.cloneGeometry.toString() === "true" || (typeof options.cloneGeometry === "function" && options.cloneGeometry(child)))
        ) {
          child.geometry = child.geometry.clone();
        }

        if (
          options.cloneMaterial &&
          child.material &&
          (options.cloneMaterial.toString() === "true" || (typeof options.cloneMaterial === "function" && options.cloneMaterial(child)))
        ) {
          child.material = child.material.clone();
        }
      });
    }

    return clone;
  }

  static createFilledPlane(bb: THREE.Box3, parameters?: THREE.MeshBasicMaterialParameters): THREE.Mesh {
    const size = bb.getSize(new THREE.Vector3());
    const center = bb.getCenter(new THREE.Vector3());

    const result = new THREE.Mesh(
      new THREE.PlaneBufferGeometry(size.x, size.y),
      new THREE.MeshBasicMaterial(parameters) //transparent: true, opacity: 1.0
    );
    result.position.copy(center);

    return result;
  }

  static getParallelPointsWithOffset(lineStart: THREE.Vector3, lineEnd: THREE.Vector3, offset: number): [THREE.Vector3, THREE.Vector3] {
    const normal = new THREE.Vector3()
      .subVectors(lineStart, lineEnd)
      .applyAxisAngle(new THREE.Vector3(0, 0, 1), -Math.PI * 0.5)
      .normalize()
      .setLength(offset);

    return [normal.clone().add(lineStart), normal.clone().add(lineEnd)];
  }

  // Eliminates elements that differs less than epsilon
  static deduplicateSites(sites: number[], epsilon: number = EPSILON): number[] {
    const orderedSites = sites.sort((a, b) => a - b);
    const uniqueSites: number[] = [];

    let similarSites: number[] = [];
    for (const site of orderedSites) {
      if (similarSites.length == 0 || MathUtils.areNumbersEqual(site, similarSites[0], epsilon)) {
        similarSites.push(site);
      } else {
        const uniqueSite = similarSites.reduce((sum, current) => sum + current, 0) / similarSites.length;
        uniqueSites.push(uniqueSite);
        similarSites = [site];
      }
    }

    // Ensure that we add the last sites as well
    if (similarSites.length > 0) {
      const uniqueSite = similarSites.reduce((sum, current) => sum + current, 0) / similarSites.length;
      uniqueSites.push(uniqueSite);
    }

    return uniqueSites;
  }

  static calculateSignedArea(points: THREE.Vector3[]): number {
    let area = 0;
    for (let i = 0; i < points.length; i++) {
      const start = points[i === 0 ? points.length - 1 : i - 1];
      const end = points[i];
      area += start.x * end.y - start.y * end.x;
    }
    return area * 0.5;
  }

  // assumes CCW polygon
  static assignSidesToPolygon(polygon: THREE.Vector3[]): Side[] {
    const n = polygon.length;
    const nextCyclicIndex = (i: number): number => {
      return (i + 1) % n;
    };
    const prevCyclicIndex = (i: number): number => {
      return i === 0 ? n - 1 : i - 1;
    };
    const areCollinear = (v1: THREE.Vector3, v2: THREE.Vector3, tol: number): boolean => {
      const dot = v1.dot(v2);
      return dot > 0 && dot * dot > tol * tol * v1.lengthSq() * v2.lengthSq();
    };

    // find extreme points (East, West, South, North)
    const extremeIndices = [-1, -1, -1, -1];
    let eastSouthPoint = new THREE.Vector3(Number.NEGATIVE_INFINITY, Number.POSITIVE_INFINITY);
    let westNorthPoint = new THREE.Vector3(Number.POSITIVE_INFINITY, Number.NEGATIVE_INFINITY);
    let southWestPoint = new THREE.Vector3(Number.POSITIVE_INFINITY, Number.POSITIVE_INFINITY);
    let northEastPoint = new THREE.Vector3(Number.NEGATIVE_INFINITY, Number.NEGATIVE_INFINITY);

    for (let i = 0; i < polygon.length; ++i) {
      const point = polygon[i];
      if (point.x > eastSouthPoint.x + EPSILON || (point.x > eastSouthPoint.x - EPSILON && point.y <= eastSouthPoint.y)) {
        eastSouthPoint = point;
        extremeIndices[0] = i;
      }
      if (point.x < westNorthPoint.x - EPSILON || (point.x < westNorthPoint.x + EPSILON && point.y >= westNorthPoint.y)) {
        westNorthPoint = point;
        extremeIndices[1] = i;
      }
      if (point.y < southWestPoint.y - EPSILON || (point.y < southWestPoint.y + EPSILON && point.x <= southWestPoint.x)) {
        southWestPoint = point;
        extremeIndices[2] = i;
      }
      if (point.y > northEastPoint.y + EPSILON || (point.y > northEastPoint.y - EPSILON && point.x >= northEastPoint.x)) {
        northEastPoint = point;
        extremeIndices[3] = i;
      }
    }

    const polygonSides: Side[] = [];
    const tol = 0.70710678; //cos(45deg) rounded down to 8 digit, so we had weakly less than 45 deg for collinearity checks
    // Directions in assignment priority
    const baseDirection = [
      new THREE.Vector3(1, 0), // South
      new THREE.Vector3(-1, 0), // North
      new THREE.Vector3(0, 1), // East
      new THREE.Vector3(0, -1), // West
    ];

    for (let i = 0; i < n; i++) {
      polygonSides[i] = null;
      const edge = polygon[nextCyclicIndex(i)].clone().sub(polygon[i]);
      for (let dirIdx = 0; dirIdx < 4; ++dirIdx) {
        if (areCollinear(edge, baseDirection[dirIdx], tol)) {
          switch (dirIdx) {
            case 0: {
              polygonSides[i] = Side.Bottom;
              break;
            }
            case 1: {
              polygonSides[i] = Side.Top;
              break;
            }
            case 2: {
              polygonSides[i] = Side.Right;
              break;
            }
            case 3: {
              polygonSides[i] = Side.Left;
              break;
            }
          }
          break;
        }
      }
    }

    // Fill up the results
    const segmentSides: Side[] = [];

    //Iterate from West through South to the East and find first and last correctly oriented segments
    let startSouthIdx = -1;
    let endSouthIdx = -1;
    for (let i = extremeIndices[1]; i != extremeIndices[0]; i = nextCyclicIndex(i)) {
      if (polygonSides[i] === Side.Bottom) {
        endSouthIdx = i;
        if (startSouthIdx === -1) {
          startSouthIdx = i;
        }
      }
    }

    if (startSouthIdx !== -1) {
      for (let i = startSouthIdx, end = nextCyclicIndex(endSouthIdx); i != end; i = nextCyclicIndex(i)) {
        polygonSides[i] = segmentSides[i] = Side.Bottom;
      }
    }

    //Iterate from East through North to the West and find first and last correctly oriented segments
    let startNorthIdx = -1;
    let endNorthIdx = -1;
    for (let i = extremeIndices[0]; i != extremeIndices[1]; i = nextCyclicIndex(i)) {
      if (polygonSides[i] === Side.Top) {
        endNorthIdx = i;
        if (startNorthIdx === -1) {
          startNorthIdx = i;
        }
      }
    }

    if (startNorthIdx !== -1) {
      for (let i = startNorthIdx, end = nextCyclicIndex(endNorthIdx); i != end; i = nextCyclicIndex(i)) {
        polygonSides[i] = segmentSides[i] = Side.Top;
      }
    }

    const westStart = endNorthIdx !== -1 ? nextCyclicIndex(endNorthIdx) : extremeIndices[3];
    const westEnd = prevCyclicIndex(startSouthIdx !== -1 ? startSouthIdx : extremeIndices[2]);

    const eastStart = endSouthIdx !== -1 ? nextCyclicIndex(endSouthIdx) : extremeIndices[2];
    const eastEnd = prevCyclicIndex(startNorthIdx !== -1 ? startNorthIdx : extremeIndices[3]);

    for (let i = westStart, end = nextCyclicIndex(westEnd); i != end; i = nextCyclicIndex(i)) {
      if (segmentSides[i] === undefined) {
        segmentSides[i] = Side.Left;
      }
    }

    for (let i = eastStart, end = nextCyclicIndex(eastEnd); i != end; i = nextCyclicIndex(i)) {
      if (segmentSides[i] === undefined) {
        segmentSides[i] = Side.Right;
      }
    }

    return segmentSides;
  }

  // assumes CCW polygon
  static assignSidesToLotLinePolygon(polygon: THREE.Vector3[]): Side[] {
    const nextCyclicIndex = (i: number, n: number = polygon.length): number => {
      return (i + 1) % n;
    };

    const hull = GeometryUtils.findConvexHull(polygon);
    const hullAssignments = GeometryUtils.assignSidesToPolygon(hull);

    const segmentSides: Side[] = [];

    for (let i = 0, l = hull.length; i < l; i++) {
      const startIdx = polygon.indexOf(hull[i]);
      const endIdx = polygon.indexOf(hull[nextCyclicIndex(i, l)]);

      for (let j = startIdx; j !== endIdx; j = nextCyclicIndex(j)) {
        segmentSides[j] = hullAssignments[i];
      }
    }

    return segmentSides;
  }

  // returns CCW polygon
  static findConvexHull(points: THREE.Vector3[]): THREE.Vector3[] {
    // Find the leftmost point
    let pointOnHull: THREE.Vector3 = points[0];
    for (const point of points) {
      if (point.x < pointOnHull.x || (point.x === pointOnHull.x && point.y < pointOnHull.y)) {
        pointOnHull = point;
      }
    }

    // Build the convex hull
    const result: THREE.Vector3[] = [];

    while (result[0] !== pointOnHull) {
      let nextPoint = points[0];
      for (let j = 1, l = points.length; j < l; j++) {
        if (nextPoint === pointOnHull || GeometryUtils.isRightOf(points[j], pointOnHull, nextPoint)) {
          nextPoint = points[j];
        }
      }

      result.push(pointOnHull);
      pointOnHull = nextPoint;
    }

    return result;
  }
  static isRightOf(point: THREE.Vector3, start: THREE.Vector3, end: THREE.Vector3): boolean {
    // Check if point is on the right side of the line (start, end)
    const cross = (end.x - start.x) * (point.y - start.y) - (point.x - start.x) * (end.y - start.y);

    // If the point lies on the same line, check if it lies further away from 'start'.
    if (cross === 0) {
      const p = new THREE.Line3(start, end).closestPointToPointParameter(point, false);
      return p > 1;
    }

    return cross < 0;
  }

  static addOffsetToContour(contour: THREE.Vector3[], offset: number): THREE.Vector3[] {
    const result: THREE.Vector3[] = [];

    for (let i = 0; i < contour.length; i++) {
      const p1 = contour[i === 0 ? contour.length - 1 : i - 1];
      const p2 = contour[i];
      const p3 = contour[i === contour.length - 1 ? 0 : i + 1];

      const delta1 = p3.clone().sub(p2);
      const delta2 = p2.clone().sub(p1);
      // 90 degrees clockwise
      const a = new THREE.Vector3(delta1.y, -delta1.x).normalize().multiplyScalar(offset);
      const b = new THREE.Vector3(delta2.y, -delta2.x).normalize().multiplyScalar(offset);

      if (MathUtils.areNumbersEqual(a.clone().cross(b).lengthSq(), 0)) {
        b.multiplyScalar(0);
      }

      const point = a.add(b).add(p2);
      result.push(point);
    }

    return result;
  }

  static addOffsetToContour2D(contour: THREE.Vector2[], offset: number): THREE.Vector2[] {
    const result: THREE.Vector2[] = [];
    // Function to calculate the angle between two vectors
    function angleBetween(v1, v2): number {
      const dot = v1.dot(v2);
      const det = v1.x * v2.y - v1.y * v2.x; // determinant
      const angle = Math.atan2(det, dot);
      return angle;
    }
    for (let i = 0; i < contour.length; i++) {
      const p1 = contour[i === 0 ? contour.length - 1 : i - 1];
      const p2 = contour[i];
      const p3 = contour[i === contour.length - 1 ? 0 : i + 1];
      const delta1 = p3.clone().sub(p2).normalize();
      const delta2 = p2.clone().sub(p1).normalize();
      // Calculating offset vectors
      const a = new THREE.Vector2(delta1.y, -delta1.x).multiplyScalar(offset);
      const b = new THREE.Vector2(delta2.y, -delta2.x).multiplyScalar(offset);
      // Calculate the angle to check for collinearity
      const angle = angleBetween(delta1, delta2);
      // For near-collinear or actual collinear points, just average the offsets
      if (Math.abs(angle) < 0.01) {
        // Arbitrary small angle threshold
        const avgOffset = a.add(b).multiplyScalar(0.5);
        result.push(new THREE.Vector2(p2.x + avgOffset.x, p2.y + avgOffset.y));
      } else {
        // Not collinear, proceed with the usual offset logic
        const offsetPoint = p2.clone().add(a).add(b);
        result.push(offsetPoint);
      }
    }
    return result;
  }

  static addContourToPath(path: THREE.Path, contour: THREE.Vector3[]): void {
    if (contour.length === 0) {
      return;
    }

    path.moveTo(contour[0].x, contour[0].y);
    for (let i = 1; i < contour.length; i++) {
      path.lineTo(contour[i].x, contour[i].y);
    }
    path.closePath();
  }

  static getSpaceIntersectionPoint(mousePosition: THREE.Vector2, camera: THREE.Camera, zoomTarget: THREE.Vector3): THREE.Vector3 {
    const result = new THREE.Vector3();

    const hoveredPoint = new THREE.Vector3(mousePosition.x, mousePosition.y, 1 - EPSILON);
    hoveredPoint.unproject(camera);

    // set plane
    const point = zoomTarget.clone();
    const planeNormal = new THREE.Vector3();
    planeNormal.subVectors(camera.position, point).normalize();
    const plane = new THREE.Plane();
    plane.setFromNormalAndCoplanarPoint(planeNormal, point);

    if (camera instanceof THREE.PerspectiveCamera) {
      // find intersection point of the line and the plane:
      const planeIntersection = new THREE.Vector3();
      plane.intersectLine(new THREE.Line3(camera.position, hoveredPoint), planeIntersection);
      result.copy(planeIntersection);
    } else {
      plane.projectPoint(hoveredPoint, result);
    }

    return result;
  }

  // Assumes start point is to the left/bottom of the end point.
  static getLineSegmentInsideSphere(line: THREE.Line3, sphere: THREE.Sphere): THREE.Line3 | null {
    const isStartInCircle = sphere.containsPoint(line.start);
    const isEndInCircle = sphere.containsPoint(line.end);
    if (isStartInCircle && isEndInCircle) {
      return line;
    }
    let closestLinePointToCenter = line.closestPointToPoint(sphere.center, true, new THREE.Vector3());
    let distance = closestLinePointToCenter.distanceTo(sphere.center);

    if (distance + EPSILON > sphere.radius) {
      return null;
    }

    closestLinePointToCenter = line.closestPointToPoint(sphere.center, false, closestLinePointToCenter);
    distance = closestLinePointToCenter.distanceTo(sphere.center);
    const newLineDistance = Math.sqrt(sphere.radius * sphere.radius - distance * distance);
    const vector = GeometryUtils.isLineHorizontal(line) ? new THREE.Vector3(1, 0, 0) : new THREE.Vector3(0, 1, 0);

    const start = isStartInCircle ? line.start.clone() : closestLinePointToCenter.clone().addScaledVector(vector, -newLineDistance);
    const end = isEndInCircle ? line.end.clone() : closestLinePointToCenter.clone().addScaledVector(vector, +newLineDistance);
    return new THREE.Line3(start, end);
  }

  static lineSegmentIntersectSphere(line: THREE.Line3, sphere: THREE.Sphere): THREE.Vector3[] | null {
    const lineDelta = line.delta(new THREE.Vector3());
    const lineDelta2 = lineDelta.lengthSq();
    if (MathUtils.areNumbersEqual(lineDelta2, 0)) {
      return null;
    }

    const delta = line.start.clone().sub(sphere.center);
    const discriminant = lineDelta.dot(delta) ** 2 - lineDelta2 * (delta.lengthSq() - sphere.radius ** 2);

    if (discriminant < 0) {
      return null;
    }

    if (MathUtils.areNumbersEqual(discriminant, 0)) {
      const t = -lineDelta.dot(delta) / lineDelta2;
      if (!MathUtils.isNumberInRange(t, 0, 1)) {
        return null;
      }

      return [GeometryUtils.evaluateLineParameter(t, line.start, line.end)];
    }

    const b = lineDelta.dot(delta);
    const root = Math.sqrt(discriminant);
    const t1 = (-b - root) / lineDelta2;
    const t2 = (-b + root) / lineDelta2;

    const results: THREE.Vector3[] = [];

    if (MathUtils.isNumberInRange(t1, 0, 1)) {
      results.push(GeometryUtils.evaluateLineParameter(t1, line.start, line.end));
    }

    if (MathUtils.isNumberInRange(t2, 0, 1)) {
      results.push(GeometryUtils.evaluateLineParameter(t2, line.start, line.end));
    }

    return results.length ? results : null;
  }

  static setChildrenLinesColor(obj: THREE.Object3D, color: number, setOriginalColor = false) {
    obj?.traverse(child => {
      if (child instanceof THREE.Line) {
        child.material.color.setHex(color);
        if (setOriginalColor && child.userData.originalColor) {
          child.userData.originalColor = color;
        }
      }
    });
  }
  static setChildrenMeshColor(obj: THREE.Object3D, color: number) {
    obj?.traverse(child => {
      if (child instanceof THREE.Mesh) {
        child.material.color.setHex(color);
      }
    });
  }
  static setStencilMask(obj: THREE.Object3D, stencilRef: number) {
    obj?.traverse(child => {
      if (child instanceof THREE.Mesh || child instanceof THREE.Line) {
        child.material.stencilWrite = true;
        child.material.stencilRef = stencilRef;
        child.material.stencilFunc = THREE.NotEqualStencilFunc;
        child.material.stencilFail = THREE.KeepStencilOp;
        child.material.stencilZFail = THREE.KeepStencilOp;
        child.material.stencilZPass = THREE.KeepStencilOp;
      }
    });
  }
  static setChildrenRenderOrder(obj: THREE.Object3D, renderOrder: number) {
    obj?.traverse(child => {
      if (child instanceof THREE.Mesh || child instanceof THREE.Line || child instanceof THREE.Points) {
        child.renderOrder = renderOrder;
      }
    });
  }
  static setChildrenMeshRenderOrder(obj: THREE.Object3D, renderOrder: number) {
    obj?.traverse(child => {
      if (child instanceof THREE.Mesh) {
        child.renderOrder = renderOrder;
      }
    });
  }

  static swapLineVectors(line: THREE.Line3): THREE.Line3 {
    const tmp = line.start;
    line.start = line.end;
    line.end = tmp;

    return line;
  }

  static alignLine(line: THREE.Line3): THREE.Line3 {
    const axis = GeometryUtils.isLineHorizontal(line) ? "x" : "y";
    if (line.start[axis] > line.end[axis]) {
      GeometryUtils.swapLineVectors(line);
    }

    return line;
  }

  static getFitToSizeFactor2D(dstSize: THREE.Vector2, srcSize: THREE.Vector2): number {
    const kx = dstSize.x / srcSize.x;
    const ky = dstSize.y / srcSize.y;
    return Math.min(kx, ky);
  }

  // quality = 0...1
  static getMaxRenderSize(aspect: number, quality: number = 1.0): THREE.Vector2 {
    const maxSize = 4096 * Math.min(1.0, Math.abs(quality)); // WebGL
    const w = aspect >= 1.0 ? maxSize : maxSize * aspect;
    const h = w / aspect;
    return new THREE.Vector2(w, h);
  }

  static reintroduceCollinearPoints(path: THREE.Vector2[], originalPoints: THREE.Vector2[]): THREE.Vector2[] {
    // Function to check if a point is collinear with a segment
    function isPointCollinear(point: THREE.Vector2, segmentStart: THREE.Vector2, segmentEnd: THREE.Vector2): boolean {
      const crossProduct = (segmentEnd.y - segmentStart.y) * (point.x - segmentStart.x) - (segmentEnd.x - segmentStart.x) * (point.y - segmentStart.y);
      return Math.abs(crossProduct) < Number.EPSILON;
    }
    // Function to check if point is between two points on a line segment
    function isPointBetween(point: THREE.Vector2, segmentStart: THREE.Vector2, segmentEnd: THREE.Vector2): boolean {
      const dotProduct = (point.x - segmentStart.x) * (segmentEnd.x - segmentStart.x) + (point.y - segmentStart.y) * (segmentEnd.y - segmentStart.y);
      const squaredLength = (segmentEnd.x - segmentStart.x) ** 2 + (segmentEnd.y - segmentStart.y) ** 2;
      return dotProduct > 0 && dotProduct < squaredLength;
    }
    // Iterate over each segment in the path
    const newPath = [...path]; // Clone the path to avoid mutating the original
    newPath.push(newPath[0]); // Close the path
    for (let i = 0; i < newPath.length - 1; i++) {
      const segmentStart = newPath[i];
      const segmentEnd = newPath[i + 1];
      // Find original points that are collinear with the current segment
      const collinearPoints = originalPoints.filter(
        point => isPointCollinear(point, segmentStart, segmentEnd) && isPointBetween(point, segmentStart, segmentEnd)
      );
      // Sort collinear points by their distance from the segment start
      collinearPoints.sort((a, b) => {
        const distA = (a.x - segmentStart.x) ** 2 + (a.y - segmentStart.y) ** 2;
        const distB = (b.x - segmentStart.x) ** 2 + (b.y - segmentStart.y) ** 2;
        return distA - distB;
      });
      // Insert collinear points into newPath at the correct position
      newPath.splice(i + 1, 0, ...collinearPoints);
      i += collinearPoints.length; // Adjust index to account for newly inserted points
    }
    newPath.pop(); // Remove the closing point
    return newPath;
  }
}
