import { Col, Button, Modal, Tooltip } from "antd";
import { RenderJobStatus, TRenderJob } from "../../../../entities/renderJob/types";
import LoadingSpinner from "../../common/LoadingSpinner";
import { CloseCircleOutlined, HistoryOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { getFormattedDate } from "../../../../helpers/utilities";
import RenderVariationJob from "../../../../entities/renderJob/renderVariationJob";

interface JobItemProps {
  renderJob: TRenderJob;
  variationId: string;
  removeJob: (jobId: string) => void;
}

const jobStatusText = {
  [RenderJobStatus.Failed]: "File creation failed",
  [RenderJobStatus.Pending]: "File in progress",
  [RenderJobStatus.TimedOut]: "File timed out",
};

const RenderJobItem = ({ renderJob, variationId, removeJob }: JobItemProps) => {
  if (!renderJob || renderJob.status === RenderJobStatus.Completed) {
    return;
  }

  const deleteRenderJob = async () => {
    const result = await RenderVariationJob.deleteCorePlanJob(variationId, renderJob.id);
    if (result) {
      removeJob(renderJob.id);
    }
  };
  const showDeleteConfirm = () => {
    const { confirm } = Modal;
    confirm({
      title: "Are you sure you want to delete this job?",
      okText: "Proceed",
      okType: "default",
      cancelText: "Go Back",
      onOk: deleteRenderJob,
    });
  };

  const jobIcon = () => {
    switch (renderJob.status) {
      case RenderJobStatus.Pending:
        return <LoadingSpinner size="small"></LoadingSpinner>;
      case RenderJobStatus.TimedOut:
        return <HistoryOutlined className="job-status-icon" />;
      case RenderJobStatus.Failed:
        return <CloseCircleOutlined className="job-status-icon" />;
      default:
        break;
    }
  };

  return (
    <Col span={6}>
      {
        <div className="job-item">
          {renderJob.errors?.length && (
            <Tooltip title={decodeURIComponent(renderJob.errors.join())} overlayClassName="render-job-error">
              <InfoCircleOutlined className="job-info-icon" />
            </Tooltip>
          )}
          <div className="title">
            {jobIcon()}
            <p>{jobStatusText[renderJob.status]}</p>
          </div>
          <div className="details">
            <p>Last updated {getFormattedDate(renderJob.updatedAt.getTime().toString())}</p>
            <div className="btn-group">
              {renderJob.status !== RenderJobStatus.Pending && (
                <Button className="btn-file btn-delete" onClick={showDeleteConfirm}>
                  &nbsp;
                </Button>
              )}
            </div>
          </div>
        </div>
      }
    </Col>
  );
};

export default RenderJobItem;
