import * as THREE from "three";
import RoomManager from "../../managers/RoomManager/RoomManager";
import SceneUtils from "../../utils/SceneUtils";
import { CommandTypes } from "./CommandTypes";
import { RoomCommand } from "./RoomCommand";

export class MirrorRoomCommand extends RoomCommand {
  constructor(
    entityId: string,
    private vector: THREE.Vector3,
    private position?: THREE.Vector3
  ) {
    super(entityId);

    this.type = CommandTypes.MirrorRoomCommand;
  }

  apply(roomManager: RoomManager): void {
    const soRoom = roomManager.getActiveFloorSoRoom(this.entityId);
    SceneUtils.scaleRoomInPosition(soRoom, this.vector, this.position);

    roomManager.updateRoomsProperties([this.entityId]);
    roomManager.checkRoomsSharedObjects([this.entityId]);
  }
  undo(roomManager: RoomManager): void {
    const soRoom = roomManager.getActiveFloorSoRoom(this.entityId);
    SceneUtils.scaleRoomInPosition(soRoom, this.vector, this.position);

    roomManager.updateRoomsProperties([this.entityId]);
    roomManager.checkRoomsSharedObjects([this.entityId]);
  }
}
