import { ReloadOutlined } from "@ant-design/icons";
import { Col, Collapse, Row } from "antd";
import { observer } from "mobx-react";
import { useCallback, useEffect } from "react";
import { Scrollbar } from "react-scrollbars-custom";
import { SceneEditorMode } from "../../../../../editor/models/SceneEditorMode";
import { Size, useWindowSize } from "../../../../../helpers/utilities";
import { appModel } from "../../../../../models/AppModel";
import { markPublic } from "../../../../../services/allKinds";
import "./RoomCatalog.sass";
import { RoomTypeListItem } from "./RoomTypeListItem";

const obsoleteRoomContainerHeight = 43;
const { Panel } = Collapse;

const RoomCatalogView = observer(() => {
  const size: Size = useWindowSize();
  const showObsoleteRooms = appModel.sceneEditorMode === SceneEditorMode.Room && appModel.activeFloor?.rooms.some(room => room.isObsolete);

  useEffect(() => {
    appModel.setShowObsoleteRooms(true);

    return () => {
      appModel.setShowObsoleteRooms(false);
    };
  }, []);

  let scrollbarHeight: number | string = "auto";
  if (size.height) {
    scrollbarHeight = size.height - 227;

    if (showObsoleteRooms) {
      scrollbarHeight -= obsoleteRoomContainerHeight;
    }
  }

  const onReplaceObsoleteRoomsClick = useCallback(() => {
    appModel.baseManager.roomManager.replaceObsoleteRooms();
  }, []);

  const sortedCategories = appModel.roomCategories
    .filter(rc => rc.roomTypes.some(roomType => roomType.mark >= markPublic.id))
    .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));

  return (
    <div className="room-catalog">
      {!sortedCategories.length ? (
        <p className="empty-message">Catalog is empty.</p>
      ) : (
        <>
          <Scrollbar style={{ height: scrollbarHeight }}>
            <Collapse accordion>
              {sortedCategories.map(roomCategory => (
                <Panel header={roomCategory.name} key={roomCategory.id} className="category-collapse">
                  <Collapse accordion destroyInactivePanel>
                    {[...roomCategory.visibleRoomTypesByFunction].map(typesByFunction => (
                      <Panel header={typesByFunction[0]} key={typesByFunction[0]} className="function-collapse">
                        <Row>
                          {typesByFunction[1].map(roomType => (
                            <Col span={12} key={roomType.id}>
                              <RoomTypeListItem roomType={roomType} imgURL={roomType.thumbnailUrl} title={roomType.name} />
                            </Col>
                          ))}
                        </Row>
                      </Panel>
                    ))}
                  </Collapse>
                </Panel>
              ))}
            </Collapse>
          </Scrollbar>
          {showObsoleteRooms && (
            <div className="obsolete-room-container">
              <hr />
              <Row className="obsolete-row" onClick={onReplaceObsoleteRoomsClick}>
                <span className="text">Floor contains obsolete rooms</span>
                <ReloadOutlined className="reload-icon" />
              </Row>
            </div>
          )}
        </>
      )}
    </div>
  );
});

export default RoomCatalogView;
