function isObject(value) {
  return value && typeof value === "object";
}

// Quick and dirty check if an object is a plain object and not
// for example an instance of some class.
function isPlainObject(value) {
  return isObject(value) && (!value.constructor || value.constructor === Object) && (!value.toString || value.toString === Object.prototype.toString);
}

function isFunction(value) {
  return typeof value === "function";
}

function isString(value) {
  return typeof value === "string";
}

function isNumber(value) {
  return typeof value === "number";
}

function asArray(value) {
  if (value === undefined) return undefined;
  return Array.isArray(value) ? value : [value];
}

function asSingle(value) {
  return Array.isArray(value) ? value[0] : value;
}

function popPojoProp(obj, prop, d) {
  let result = d;

  if (obj[prop] !== undefined) {
    result = obj[prop];
    delete obj[prop];
  }

  return result;
}

function isEmpty(a) {
  if (a === null || a === undefined) return true;
  if (typeof a === "boolean") return !a;
  if (a === "") return true;

  if (a instanceof Map || a instanceof Set) return a.size === 0;

  if (isObject(a)) return Object.keys(a).length === 0;

  const isNotNumber = !isNumber(a);
  if (isNotNumber && a?.length === 0) return true;

  return false;
}

function _call2DTO(object, rules = {}) {
  if (isObject(object) && object.toDTO) {
    return object.toDTO(rules.format, rules);
  }
  return deepCopy2DTO(object, rules);
}

function deepCopy2DTO(object, rules = {}) {
  if (!rules.format) rules.format = "APIdata";

  if (object === null || object === undefined) return object;

  const objType = typeof object;

  if (objType === "string" || objType === "number" || objType === "boolean") {
    return object;
  }

  if (isObject(object)) {
    if (object[Symbol.iterator]) {
      const tmpVal = [];

      for (const vv of object) {
        tmpVal.push(_call2DTO(vv, rules));
      }

      return tmpVal;
    }
  }

  let result = Array.isArray(object) ? [] : {};
  let allKeys = Object.keys(object);

  if (rules.only) {
    rules.only.push("id", "kind", "name");
    allKeys = allKeys.filter(key => rules.only.some(rule => rule === key));
    rules = Object.assign({}, rules, { only: undefined });
  } else if (rules.ignore) {
    allKeys = allKeys.filter(key => !rules.ignore.some(rule => rule === key));
  }

  for (let key of allKeys) {
    let val = object[key];

    if (val === undefined) continue;

    const firstSymbol = key.charAt(0);

    if (firstSymbol === "_" || firstSymbol === "$") continue;

    const typeVal = typeof val;

    if (typeVal === "function") {
      continue;
    }

    if (typeVal === "string" || typeVal === "number" || typeVal === "boolean") {
      result[key] = val;
      continue;
    }

    if (val instanceof Set) {
      val = Array.from(val.values());
    }

    if (val instanceof Map) {
      const mapObj = {};

      val.forEach((mapValue, mapKey) => {
        if (typeof mapKey !== "string") {
          mapKey = JSON.stringify(mapKey);
        }

        mapObj[mapKey] = mapValue;
      });

      val = mapObj;
    }

    if (Array.isArray(val)) {
      result[key] = val.map(elem => _call2DTO(elem, rules));
      continue;
    } else if (typeVal === "object") val = _call2DTO(val, rules);

    result[key] = val;
  }

  return result;
}

//export { updateJson }
export { popPojoProp, isEmpty, asArray, asSingle, isObject, isPlainObject, isString, isNumber, isFunction, deepCopy2DTO };
