import { AxiosRequestConfig, AxiosRequestHeaders } from "axios";
import { getStoredAccessToken } from "../../../store/localStoreHelper";
import { UserDepot } from "../../../store/UserDepot";

/**
 * Gets Authorization header object
 * @returns AxiosRequestHeaders with Authorization header value if user is signed in,
 * otherwise - null
 */
export const getAuthorizationHeader = (): AxiosRequestHeaders | null => (UserDepot.current ? { Authorization: `Bearer ${getStoredAccessToken()}` } : null);

/**
 * Gets AxiosRequestConfig with Authorization header for the AxiosRequest
 * @returns AxiosRequestConfig with Authorization header only
 * OR an empty object if current user is not defined
 */
export const getAxiosRequestConfig = (): AxiosRequestConfig => {
  const authHeader = getAuthorizationHeader();
  return authHeader ? { headers: authHeader } : {};
};

export const addAuthParam = (link: string) => `${link}?authorization=Bearer ${getStoredAccessToken()}`;
