import { useEffect, useRef, useState } from "react";
import { Button, Modal, Input, Collapse } from "antd";
import { observer } from "mobx-react";
import { runInAction } from "mobx";

import { finishesManager } from "../../../../../entities/finishesManager";
import { appModel } from "../../../../../models/AppModel";
import { ExteriorFinish, Roof } from "../../../../../models/DesignStyle";
import { SceneMode } from "../../../../../models/SceneMode";
import { variationsManager } from "../../../../../entities/variationsManager";
import { handlePendingChanges } from "../../../../../helpers/appUtils";
import { entityManager } from "../../../../../entities/entityManager";
import { useHistory } from "react-router-dom";
import { SceneEditorMode } from "../../../../../editor/models/SceneEditorMode";
import { Variation } from "../../../../../models/Variation";
import { showToastMessage } from "../../../../../helpers/messages";
import { RawVariation } from "../../../../../services/rawTypes";
import { SnapshotPosition } from "../../../../../editor/managers/PreviewManager/PreviewManager";
import { fileManager } from "../../../../../entities/fileManager";
import { FolderOptions } from "../LeftBar";

import { strHasSpaces } from "../../../../../helpers/utilities";
import CustomInput from "./CustomInput";

import "./Configure.sass";

const CladdingHeader = () => (
  <div className="header-container">
    <img src="/assets/icons/cladding.svg" alt="Cladding" />
    Cladding
  </div>
);

const RoofHeader = () => (
  <div className="header-container">
    <img src="/assets/icons/roof.svg" alt="Cladding" />
    Roof
  </div>
);

const Configure = observer(() => {
  const history = useHistory();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [variationName, setVariationName] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  // const [dutchGableDepth, setDutchGableDepth] = useState<string>(`01" 00'`);

  const cardsContainerRef = useRef(null);
  const cardRef = useRef(null);
  const { Panel } = Collapse;

  useEffect(() => {
    appModel.setSceneMode(SceneMode.Preview);
  }, []);

  const handleFinishClick = (finish: ExteriorFinish) => {
    if (appModel.isViewOnlyMode) {
      return;
    }
    appModel.setActiveFinish(appModel.activeFinish !== finish ? finish : null);
  };

  const onAddVariation = async () => {
    if (!(await handlePendingChanges())) {
      return;
    }

    appModel.baseManager.roomManager.updateRoomDrawings(true);
    const { notFound } = await entityManager.saveCorePlan(appModel.activeCorePlan, false);
    if (notFound) {
      await appModel.setActiveCorePlan(null, false);
      history.push("/");
      return;
    }
    appModel.setSceneEditorMode(SceneEditorMode.Room);
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    if (variationName.trim() === "") {
      setErrorMessage("Variation name cannot be empty");
      return;
    }

    if (strHasSpaces(variationName)) {
      setErrorMessage("Variation name cannot contain spaces");
      return;
    }

    if (variationName.length > 15) {
      setErrorMessage("Variation name cannot exceed 15 characters");
      return;
    }

    const existingVariation = appModel.activeCorePlan.variations.find(variation => variation.name === variationName);
    if (existingVariation) {
      setErrorMessage("Name already exists");
      return;
    }

    setIsModalVisible(false);
    setErrorMessage("");

    const variationCreationResponse = await variationsManager.createVariation(variationName);
    let newVariation;
    runInAction(async () => {
      appModel.setIsBusy(true);
      try {
        newVariation = new Variation().fromDTO(variationCreationResponse.data as RawVariation, "APIdata");
        appModel.activeCorePlan.addVariation(newVariation);

        const frontThumbnailSnapshot = appModel.baseManager.previewManager.makeThumbnailSnapshot(SnapshotPosition.FRONT);
        const frontSnapshotFile = await newVariation.generateSnapshotThumbnail(frontThumbnailSnapshot, SnapshotPosition.FRONT);
        await fileManager.updateVariationFiles(newVariation.guid, [frontSnapshotFile], FolderOptions.ThumbnailsFolder);

        const leftThumbnailSnapshot = appModel.baseManager.previewManager.makeThumbnailSnapshot(SnapshotPosition.LEFT);
        const leftSnapshotFile = await newVariation.generateSnapshotThumbnail(leftThumbnailSnapshot, SnapshotPosition.LEFT);
        await fileManager.updateVariationFiles(newVariation.guid, [leftSnapshotFile], FolderOptions.ThumbnailsFolder);

        await saveExteriorFinishThumbnails(newVariation);
      } finally {
        appModel.setIsBusy(false);
        showToastMessage("Success", `'${newVariation.name}' was Added`);
      }
    });
  };

  const saveExteriorFinishThumbnails = async newVariation => {
    try {
      const exteriorFinishes = await Promise.all(
        appModel.activeCorePlan.floors.flatMap(floor =>
          floor.rooms.flatMap(room => room.exteriorFinishes.map(exteriorFinishIndex => room.getFinishByIndex(exteriorFinishIndex)))
        )
      );

      const uniqueFinishes = Array.from(
        exteriorFinishes
          .filter(finish => finish.index !== -1) // Filter out finishes with index -1
          .reduce((map, finish) => map.set(finish.index, finish), new Map()) // Create a map to track unique finishes by their index
          .values() // Convert the map back to an array
      );

      // Fetch file content for each unique finish's image
      const finishesWithContent = await Promise.all(
        uniqueFinishes.map(async finish => {
          if (finish.image) {
            const content = await fetchFileContent(finish.image);
            return { ...finish, imageContent: content };
          }
          return finish;
        })
      );

      const materialThumbnails = await Promise.all(
        finishesWithContent.map(async finish => {
          if (finish.imageContent) {
            return await newVariation.generateMaterialThumbnail(finish.imageContent);
          }
          return null;
        })
      );

      // Filter out null thumbnails
      const validThumbnails = materialThumbnails.filter(thumbnail => thumbnail !== null);

      // Update variation files with each material thumbnail
      await Promise.all(
        validThumbnails.map(async thumbnail => {
          await fileManager.updateVariationFiles(newVariation.guid, [thumbnail], FolderOptions.ThumbnailsFolder);
        })
      );

      console.log(uniqueFinishes); // Do something with the unique finishes
    } catch (error) {
      console.error("Error fetching exterior finishes:", error);
    }
  };

  const fetchFileContent = async uri => {
    const content = await fileManager.getCorePlanFile(uri);
    return content;
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setErrorMessage("");
  };

  const onSaveScreenshot = () => {
    appModel.baseManager.previewManager.makeSnapshot();
  };

  if (!appModel.activeDesignStyle) {
    return null;
  }

  const handleRoofClick = (roof: Roof): void => {
    if (appModel.isViewOnlyMode || appModel.activeRoofId === roof.id) {
      return;
    }
    appModel.setActiveRoofId(appModel.activeRoofId !== roof.id ? roof.id : null);
  };

  const suggestVariationName = () => {
    return `Elevation-${appModel.activeCorePlan.variations.length + 1}`;
  };

  const handleSetVariationName = e => {
    const { value } = e.target;

    setVariationName(value);
  };

  useEffect(() => {
    setVariationName(suggestVariationName());
  }, [isModalVisible]);

  const FinishList = ({ activeFinish }) => (
    <div className="finishes-list">
      {appModel.activeDesignStyle.finishes.map(finish => (
        <div key={finish.id}>
          {activeFinish === finish ? (
            <div className="material-image-selected" style={{ backgroundColor: finish.colorCSS }} onClick={() => handleFinishClick(finish)}>
              <i className="icon icon-paint-brush-blue"></i>
            </div>
          ) : (
            <img
              src={finishesManager.getFinishTextureUrl(finish.image)}
              alt={finish.name}
              className="material-image"
              onClick={() => handleFinishClick(finish)}
            />
          )}
          <div className="material-name">{finish.name}</div>
        </div>
      ))}
    </div>
  );

  const RoofList = ({ activeRoofId }) => (
    <div className="roof-list" ref={cardsContainerRef}>
      {appModel.activeDesignStyle.roofs.map(roof => {
        const activeRoofClass = activeRoofId === roof.id ? "roof-image-selected" : "roof-image";

        return (
          <div key={roof.id}>
            <div ref={cardRef} className={`${activeRoofClass} ${roof.imageClassName}`} onClick={e => handleRoofClick(roof)} />
            <div className="roof-label">{roof.displayName}</div>
            {roof.id === "ducthGable" && <CustomInput cardsContainerRef={cardsContainerRef} cardRef={cardRef} setRoofId={e => handleRoofClick(roof)} />}
          </div>
        );
      })}
    </div>
  );

  return (
    <div className="configure-tab">
      <div className="padding">
        <Collapse accordion className="custom-collapse">
          <Panel header={<CladdingHeader />} key="1">
            <FinishList activeFinish={appModel.activeFinish} />
          </Panel>
          <Panel header={<RoofHeader />} key="2">
            <RoofList activeRoofId={appModel.activeRoofId} />
          </Panel>
        </Collapse>
      </div>
      <div className="btn-container">
        <Button type="primary" className="add-variation-btn" disabled={appModel.isViewOnlyMode} onClick={onAddVariation}>
          Add Variation
        </Button>
      </div>

      <Modal title="Enter the variation name:" open={isModalVisible} onOk={handleOk} onCancel={handleCancel} okText="Save">
        <Input value={variationName} onChange={handleSetVariationName} />
        {errorMessage && <div style={{ color: "red" }}>{errorMessage}</div>}
      </Modal>
    </div>
  );
});

export default Configure;
