interface Props {
  title: string;
  onClick: () => void;
}

const HomeButton = ({ title = "", onClick }: Props) => {
  return (
    <div className="home-button">
      <div className="logo-container">
        <div data-testid="imgContainer" className="img-container" onClick={onClick}>
          <img src="/digops_icon.svg" alt="Home page button" />
        </div>
        <div className="ellipsis-title">{title}</div>
      </div>
    </div>
  );
};

export default HomeButton;
