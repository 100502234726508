import { observer } from "mobx-react";
import { Modal, Image, Row, Empty } from "antd";
import { appModel } from "../../../../models/AppModel";
import { FileEntity } from "../../../../models/FileEntity";
import { Background } from "../../../../models/Background";
import { MessageKindsEnum, showToastMessage } from "../../../../helpers/messages";
import { MESSAGE_DURATION } from "../../../../editor/consts";
import { addAuthParam } from "../../../../services/api/utilities";
import { getFileExtension } from "../../../../helpers/utilities";
import { pdfjs } from "react-pdf";
import "./EditorBackgroundSelector.sass";
import PdfItem from "./PdfItem";
import { useState } from "react";
import { LeftOutlined } from "@ant-design/icons";
import PdfPageSelector from "./PdfPageSelector";
import { fileManager } from "../../../../entities/fileManager";

type Props = {
  isOpen: boolean;
  onSelect: any;
  onClose: any;
};

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const imageFileExtensions = ["pdf", "png", "jpeg", "jpg", "gpe", "gfif", "gfi", "gif"];

const EditorBackgroundSelector = observer(({ isOpen, onSelect, onClose }: Props) => {
  const [selectedPdf, setSelectedPdf] = useState<FileEntity>(null);

  const selectFile = async (file?: FileEntity, convertPdf = false, pageNumber?: number) => {
    const floor = appModel.activeFloor;

    if (!floor) return;

    const { confirm } = Modal;

    if (!floor.background) {
      if (!file) {
        showToastMessage(MessageKindsEnum.Info, "This background is already blank.", { autoClose: MESSAGE_DURATION });
      } else {
        await setBackground();
      }
      onClose();
    } else {
      let replaceAction: boolean;
      if (convertPdf) {
        const prevFile = appModel.activeCorePlan.files.find(f => f.id === floor.background.imageFileId);
        replaceAction = prevFile?.ext_info?.page_number !== pageNumber || prevFile?.ext_info?.pdf_file_id !== file.id;
      } else {
        replaceAction = file ? file.id !== floor.background.imageFileId : !!floor.background;
      }

      if (replaceAction) {
        const confirmQuestion = file ? (
          <>
            Background already exist in the current floor.
            <br />
            Do you want to replace it?
          </>
        ) : (
          <>
            Background will be reset.
            <br />
            Do you want to do it?
          </>
        );
        confirm({
          title: confirmQuestion,
          okText: "Proceed",
          okType: "default",
          cancelText: "Go Back",
          async onOk() {
            await setBackground();
            onClose();
          },
        });
      } else {
        showToastMessage(MessageKindsEnum.Info, "This background is already assigned to this floor.", { autoClose: MESSAGE_DURATION });
      }
    }

    async function setBackground() {
      let backgroundFile = file;
      if (convertPdf && file) {
        backgroundFile = await fileManager.getPdfPageImage(appModel.activeCorePlan.id, file.URI.replace("/files/", ""), pageNumber);
      }

      floor.setBackground(backgroundFile ? new Background(backgroundFile.id) : null);
    }
  };

  const onSelectPdf = (file: FileEntity) => {
    setSelectedPdf(file);
  };

  const unsetPdf = () => {
    setSelectedPdf(null);
  };

  const imageFiles = appModel.activeCorePlan?.publicFiles?.filter(f => imageFileExtensions.includes(getFileExtension(f.id))) || [];

  const pdfPagesView = () => {
    return (
      <div className="pdf-pages-view">
        <Row className="title-row" onClick={unsetPdf}>
          <LeftOutlined />
          {selectedPdf.name}
        </Row>
        <PdfPageSelector file={selectedPdf} selectFileAsBackground={selectFile} />
      </div>
    );
  };

  const filesView = () => {
    return (
      <Row justify="space-evenly" align="middle">
        {imageFiles.length > 0 && (
          <div onClick={() => selectFile()}>
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Blank" className="col-6 image-item" />
          </div>
        )}

        {imageFiles.length > 0 ? (
          imageFiles.map(file => {
            if (getFileExtension(file.id) === "pdf") {
              return <PdfItem file={file} key={file.id} onSelectPdf={onSelectPdf} selectFileAsBackground={selectFile} />;
            }

            return (
              <Image key={file.id} onClick={() => selectFile(file)} className="col-6 image-item" width={100} preview={false} src={addAuthParam(file.URL)} />
            );
          })
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="The corePlan has no image files" />
        )}
      </Row>
    );
  };

  return (
    <Modal title="Select Background" className="editor-background-selector" open={isOpen} onOk={onSelect} onCancel={onClose} footer={null}>
      {!!selectedPdf ? pdfPagesView() : filesView()}
    </Modal>
  );
});

export default EditorBackgroundSelector;
