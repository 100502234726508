import * as THREE from "three";
import { Command } from "../../models/commands/Command";

export enum CommandManagerEvents {
  ScopesChanged = "ScopesChanged",
}

export interface Scope<C> {
  undos: C[];
  redos: C[];
}

export class CommandManager<M, C extends Command<M>> extends THREE.EventDispatcher {
  protected scopes: Map<string, Scope<C>> = new Map();
  protected activeScope: Scope<C>;

  constructor(protected manager: M) {
    super();
  }

  public add(command: C): void {
    this.activeScope.undos.push(command);
    this.activeScope.redos = [];

    this.dispatchEvent({ type: CommandManagerEvents.ScopesChanged, scopes: Array.from(this.scopes.entries()) });
  }

  public apply(command: C): void {
    command.apply(this.manager);
    this.add(command);
  }

  public undo(): C {
    const command = this.activeScope.undos.pop();
    if (command) {
      command.undo(this.manager);
      this.activeScope.redos.push(command);

      this.dispatchEvent({ type: CommandManagerEvents.ScopesChanged, scopes: Array.from(this.scopes.entries()) });
    }

    return command;
  }

  public redo(): C {
    const command = this.activeScope.redos.pop();
    if (command) {
      command.apply(this.manager);
      this.activeScope.undos.push(command);

      this.dispatchEvent({ type: CommandManagerEvents.ScopesChanged, scopes: Array.from(this.scopes.entries()) });
    }

    return command;
  }

  public setScope(scopeId?: string): void {
    if (!scopeId) {
      return;
    }

    let scope = this.scopes.get(scopeId);
    if (!scope) {
      scope = {
        undos: [],
        redos: [],
      };
      this.scopes.set(scopeId, scope);
    }

    this.activeScope = scope;
  }

  public clearScopes(scopeIds?: string[]): void {
    if (scopeIds) {
      scopeIds.forEach(id => this.scopes.delete(id));
      return;
    }

    this.scopes.clear();

    this.dispatchEvent({ type: CommandManagerEvents.ScopesChanged, scopes: Array.from(this.scopes.entries()) });
  }
}
