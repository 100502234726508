import { PersistentObject } from "../entities/entityManager";
import { DTORecord } from "../helpers/pojo";
import { isWooMode } from "../helpers/utilities";
import { kindFileRaw, markPublic } from "../services/allKinds";
import apiProvider from "../services/api/utilities/Provider";
import { RawFile } from "../services/rawTypes";

export class FileEntity implements PersistentObject<FileEntity, RawFile> {
  id: string;
  name: string;
  fileSize: number;
  fileType: number;
  relType?: number;
  lastModified: string;
  URI: string;
  ext_info: DTORecord;
  presignedDownloadUrl?: string;
  presignedOpenUrl?: string;
  mark: number = markPublic.id;

  get URL(): string {
    // TODO remove it
    if (isWooMode()) {
      return this.URI;
    }

    return apiProvider.host + this.URI.substring(1);
  }

  // * Persistence part *

  static readonly kind = kindFileRaw;

  toDTO(): RawFile {
    throw "FileEntity cannot convert to DTO";
  }

  fromDTO(dto: RawFile) {
    if (FileEntity.kind.id != dto?.kind) throw "dto: FileEntity: incorrect kind";
    this.id = dto.id;
    this.fileSize = dto.fileSize;
    this.fileType = dto.fileType;
    this.lastModified = dto.lastModified;
    this.URI = dto.URI;
    this.ext_info = dto.ext_info;
    this.relType = dto.relType;
    this.name = dto.name;
    this.mark = dto.mark;

    return this;
  }
}
