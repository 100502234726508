import { feet2inches, feet2inchesSq, inches2feet, inches2feetSq } from "../helpers/measures";

export enum LotLineSide {
  Front = "front",
  Side = "side",
  Rear = "rear",
  Front2 = "front2",
}

export type CorePlanAttributes = {
  lotSize?: number;
  lotLength?: number;
  lotWidth?: number;
  isNotRect?: boolean;
  floors?: number;
  bedRooms?: number;
  garage?: number;
  bathRooms?: number;
  halfBathRooms?: number;
  floorHeight?: number;
  ceilingHeight?: number;
  gridUnitSize?: number;
  includeCladdingThickness?: boolean;
  minNetArea?: number;
  maxGrossArea?: number;
  lotLineSetback?: {
    [LotLineSide.Front]: number;
    [LotLineSide.Side]: number;
    [LotLineSide.Rear]: number;
    [LotLineSide.Front2]: number;
  };
  lotLineSideAssociation?: {
    left: LotLineSide;
    top: LotLineSide;
    right: LotLineSide;
  };
  floorCoverages?: number[];
};

const convertAttributes = ["minNetArea", "maxGrossArea", "lotSize", "lotLength", "lotWidth", "lotLineSetback"];

function convertAttr(attributes: CorePlanAttributes, attrName: string, unit: string): void {
  const value: number = attributes[attrName];
  if (!value) return;

  if (attrName === "lotSize" || attrName === "minNetArea" || attrName === "maxGrossArea") {
    attributes[attrName] = unit === "ft" ? feet2inchesSq(value) : inches2feetSq(value);
    return;
  }
  if (attrName === "lotLineSetback") {
    attributes[attrName] = {
      front: unit === "ft" ? feet2inches(attributes[attrName].front) : inches2feet(attributes[attrName].front),
      side: unit === "ft" ? feet2inches(attributes[attrName].side) : inches2feet(attributes[attrName].side),
      rear: unit === "ft" ? feet2inches(attributes[attrName].rear) : inches2feet(attributes[attrName].rear),
      front2: unit === "ft" ? feet2inches(attributes[attrName].front2) : inches2feet(attributes[attrName].front2),
    };
    return;
  }

  attributes[attrName] = unit === "ft" ? feet2inches(value) : inches2feet(value);
}

export function convert2inches(attributes: CorePlanAttributes, copyMode: boolean = true) {
  if (copyMode) attributes = { ...attributes };
  convertAttributes.forEach(attr => convertAttr(attributes, attr, "ft"));
  return attributes;
}
export function convert2feet(attributes: CorePlanAttributes, copyMode: boolean = true) {
  if (copyMode) attributes = { ...attributes };
  convertAttributes.forEach(attr => convertAttr(attributes, attr, "inch"));
  return attributes;
}
