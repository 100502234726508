// GenerateSpinner.tsx
import React from "react";
import "./GenerateSpinner.sass";

type Props = {
  size?: "large" | "medium" | "small";
};

const GenerateSpinner = ({ size = "large" }: Props) => {
  const cssClasses = `generate-spinner ${size}`;
  return <div className={cssClasses}></div>;
};

export default GenerateSpinner;
