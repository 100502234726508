import * as ExcelJS from "exceljs";
import { markHidden } from "../../services/allKinds";

export type CorePlanStatistics = {
  corePlans: {
    totalCount: number;
    range: string;
    activeCount: number;
    obsoleteCount: number;
    obsoleteCorePlans: { corePlanName: string; clientName: string }[];
  };
  roomCatalogs: { roomName: string; usageCount: number; mark: number }[];
  jobRequests: {
    totalCount: number;
    succeeded: { corePlanName: string; jobCount: number }[];
  };
};

export class StatisticsTools {
  headerFillColor: string = "ffd9d9d9";
  public async generateExcelReport(data: CorePlanStatistics) {
    const applyHeaderStyle = (row: ExcelJS.Row, columns: number[]) => {
      columns.forEach(col => {
        const cell = row.getCell(col);
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: this.headerFillColor },
        };
        cell.border = {
          top: { style: "thin" },
          bottom: { style: "thin" },
        };
      });
    };

    const columnWidth = 35;

    // Generate Excel file
    const workbook = new ExcelJS.Workbook();
    let sheet = workbook.addWorksheet("Statistics");
    applyHeaderStyle(sheet.addRow(["CorePlans"]), [1, 2]);
    sheet.mergeCells(sheet.rowCount, 1, sheet.rowCount, 2);
    applyHeaderStyle(sheet.addRow(["Selection period:", data.corePlans.range]), [1]);
    applyHeaderStyle(sheet.addRow(["Active corePlans:", data.corePlans.activeCount]), [1]);
    applyHeaderStyle(sheet.addRow(["Total corePlans number:", data.corePlans.totalCount]), [1]);
    sheet.addRow("");
    applyHeaderStyle(sheet.addRow(["Active corePlans using obsolete rooms:", data.corePlans.obsoleteCount]), [1]);
    applyHeaderStyle(sheet.addRow(["CorePlan name"]), [1, 1]);
    data.corePlans.obsoleteCorePlans.forEach(corePlan => {
      sheet.addRow([corePlan.corePlanName]);
    });
    sheet.columns[0].width = columnWidth;
    sheet.columns[1].width = columnWidth;

    sheet = workbook.addWorksheet("Room catalog usage");
    applyHeaderStyle(sheet.addRow(["Room catalogs"]), [1, 2]);
    sheet.mergeCells(sheet.rowCount, 1, sheet.rowCount, 2);
    applyHeaderStyle(sheet.addRow(["Room name", "Usage count in corePlans"]), [1, 2]);
    data.roomCatalogs.forEach(roomCat => {
      sheet.addRow([`${roomCat.roomName}${roomCat.mark === markHidden.id ? "(Obsolete)" : ""}`, roomCat.usageCount]);
    });
    sheet.columns[0].width = columnWidth;
    sheet.columns[1].width = columnWidth;

    sheet = workbook.addWorksheet("3D generation jobs");
    applyHeaderStyle(sheet.addRow(["3D generation job requests"]), [1, 2]);
    sheet.mergeCells(sheet.rowCount, 1, sheet.rowCount, 2);
    applyHeaderStyle(sheet.addRow(["Total requests:", data.jobRequests.totalCount]), [1]);
    applyHeaderStyle(sheet.addRow(["Succeeded job request"]), [1, 2]);
    sheet.mergeCells(sheet.rowCount, 1, sheet.rowCount, 2);
    applyHeaderStyle(sheet.addRow(["CorePlan", "Usage count"]), [1, 2]);
    let totalSucceeded = 0;
    data.jobRequests.succeeded.forEach(job => {
      sheet.addRow([job.corePlanName, job.jobCount]);
      totalSucceeded += job.jobCount;
    });
    applyHeaderStyle(sheet.addRow(["Total:", totalSucceeded]), [1]);

    sheet.columns[0].width = columnWidth;
    sheet.columns[1].width = columnWidth;

    const buffer = await workbook.xlsx.writeBuffer();
    return new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
  }
}
