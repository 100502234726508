import { useMemo, useState, ReactElement, useEffect } from "react";
import type { UploadRequestOption as RcCustomRequestOptions } from "rc-upload/lib/interface";
import { Upload, UploadProps } from "antd";
import { UploadChangeParam, UploadFile } from "antd/lib/upload";
import { shrinkUnique } from "../../../helpers/utilities";
import "./VeUpload.sass";

type OnChangeUploadEvent = UploadChangeParam<UploadFile<any>>;

interface VeUploadProps extends UploadProps {
  onChangeFileList?: (evFileList: UploadFile[], origFileList?: UploadFile[]) => UploadFile[];
  onFileUploaded?: (file: UploadFile) => void;
}

const dummyRequest = ({ file, onSuccess }: RcCustomRequestOptions) => {
  setTimeout(() => {
    onSuccess("success"); // type UploadFileStatus = 'error' | 'success' | 'done' | 'uploading' | 'removed';
  }, 0);
};

export const VeUpload = ({ onChange, fileList, onChangeFileList, onRemove, showUploadList, onFileUploaded, ...rest }: VeUploadProps): ReactElement => {
  const [upFileList, setUpFileList] = useState<UploadFile[]>(fileList || []);

  const changeFileList = (flArray: UploadFile[], flNew: UploadFile, allDone: boolean = true): void => {
    flArray = shrinkUnique(flArray, el => el.name);

    if (allDone) flArray.forEach(fl => (fl.status = "done"));

    if (onChangeFileList) {
      setUpFileList(onChangeFileList(flArray, fileList));
    } else {
      if (Array.isArray(fileList)) {
        fileList.splice(0);
        fileList.push(...flArray);
      }
      setUpFileList(flArray);
    }
    if (flNew.status === "done" && onFileUploaded) {
      onFileUploaded(flNew);
    }
  };

  const onChangeUpload = (event: OnChangeUploadEvent) => changeFileList(event.fileList, event.file, false);

  const onRemoveUpload = (fl: UploadFile) => {
    changeFileList(
      upFileList.filter(el => el.uid !== fl.uid),
      fl,
      false
    );

    return true;
  };

  useEffect(() => {
    if ((fileList || [])?.length != upFileList.length) {
      setUpFileList(fileList || []);
    }
  }, [upFileList, fileList]);

  const cssClassNames = useMemo(() => {
    const MAIN_CSS_CLASS = "veev-upload";
    if (rest.disabled) {
      return `${MAIN_CSS_CLASS} disabled`;
    }
    return MAIN_CSS_CLASS;
  }, [rest]);

  return (
    <Upload
      {...rest}
      className={cssClassNames}
      fileList={upFileList}
      onChange={onChangeUpload}
      onRemove={onRemove ?? onRemoveUpload}
      customRequest={dummyRequest}
      showUploadList={
        showUploadList ?? {
          showRemoveIcon: true,
          //removeIcon: <i className="icon icon-trash icon-trash-upload-list red"></i>
        }
      }
    ></Upload>
  );
};

/*
customRequest
Allows for advanced customization by overriding default behavior in AjaxUploader. Provide your own XMLHttpRequest calls to interface with custom backend processes or interact with AWS S3 service through the aws-sdk-js package.

customRequest callback is passed an object with:

onProgress: (event: { percent: number }): void
onError: (event: Error, body?: Object): void
onSuccess: (body: Object): void
data: Object
filename: String
file: File
withCredentials: Boolean
action: String
headers: Object

https://github.com/react-component/upload#customrequest
https://stackoverflow.com/questions/51514757/action-function-is-required-with-antd-upload-control-but-i-dont-need-it

*/
