import { observer } from "mobx-react";
import { FileEntity } from "../../../../models/FileEntity";
import { Document, Page } from "react-pdf";
import { useEffect, useState } from "react";
import { Col, Row } from "antd";
import apiProvider from "../../../../services/api/utilities/Provider";

export interface IPdfPageSelector {
  file: FileEntity;
  selectFileAsBackground: (file?: FileEntity, convertPdf?: boolean, pageNumber?: number) => void;
}

const PdfPageSelector = observer(({ file, selectFileAsBackground }: IPdfPageSelector) => {
  const [numPages, setNumPages] = useState(null);
  const [fileContent, setFileContent] = useState<string>();

  useEffect(() => {
    const strippedUri = file.URI.replace("/files/", "");
    apiProvider.getFile(`files/${strippedUri}?encode=base64`).then(content => {
      setFileContent("data:/plain;base64," + content);
    });
  }, [file]);

  const onPdfPageClick = async (_, page) => {
    selectFileAsBackground(file, true, page.pageNumber);
  };

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  if (!fileContent) {
    return null;
  }

  return (
    <Document file={fileContent} onLoadSuccess={onDocumentLoadSuccess} key={file.id}>
      <Row justify="space-evenly">
        {/* eslint-disable-next-line prefer-spread */}
        {Array.apply(null, Array(numPages)).map((_, index) => (
          <Col key={index}>
            <Page className="pdf-page" width={100} pageNumber={index + 1} renderTextLayer={false} renderAnnotationLayer={false} onClick={onPdfPageClick} />
          </Col>
        ))}
      </Row>
    </Document>
  );
});
export default PdfPageSelector;
