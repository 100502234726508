import { entityManager } from "../entities/entityManager";
import { appModel } from "../models/AppModel";

const HEARTBEAT_INTERVAL = 1000 * 60 * 5; // 5 min
const MAX_RETRIES_COUNT = 3;

export class CorePlanLockHeartbeat {
  private timerSubscription: NodeJS.Timer;
  private retriesCount = 0;

  public start(): void {
    this.stop();
    this.timerSubscription = setInterval(this.updateCorePlanLock.bind(this), HEARTBEAT_INTERVAL);
  }

  public stop(): void {
    if (this.timerSubscription) {
      //clearInterval(this.timerSubscription);
      this.timerSubscription = null;
      this.retriesCount = 0;
    }
  }

  private async updateCorePlanLock(): Promise<void> {
    if (this.retriesCount > MAX_RETRIES_COUNT) {
      this.stop();
      return;
    }

    const success = await entityManager.lockCorePlan(appModel.activeCorePlan);
    if (success) {
      this.retriesCount = 0;
    } else {
      this.retriesCount++;
    }
  }
}

export const corePlanLockHeartbeat = new CorePlanLockHeartbeat();
