import axios from "axios";
import type { AxiosError, AxiosInstance } from "axios";
import { AUTH_SESSION_TERMINATION_STATE, finishCurrentAuthSession, refreshAccessJwtToken } from "../../../helpers/auth";

/**
 * Axios instance, that should be used across the App
 * instead of direct import of Axios from 'axios' package
 */
const axiosClient = axios.create();

/**
 * Interceptor factory for HTTP 401 response error
 * Will try to refresh access token
 * and repeat last request if token refreshed,
 * otherwise will wipe current token from storage and logout user
 */
const makeResponseHTTP401Interceptor =
  (axiosInstance: AxiosInstance) =>
  async (error: AxiosError): Promise<any> => {
    const originalRequest = error.config;
    if (error.response.status === 401) {
      const accessToken = await refreshAccessJwtToken();

      if (accessToken != null) {
        originalRequest.headers["Authorization"] = `Bearer ${accessToken}`;
        return axiosInstance(originalRequest);
      } else {
        // Refreshing access token failed
        finishCurrentAuthSession(AUTH_SESSION_TERMINATION_STATE.REFRESH_TOKEN_EXPIRED);
      }
    } else {
      throw error;
    }
  };

/**
 * Attach interceptor to Axios instance
 */
axiosClient.interceptors.response.use(response => response, makeResponseHTTP401Interceptor(axiosClient));

export { makeResponseHTTP401Interceptor };
export default axiosClient;
