import { useContext, useState } from "react";
import { Dropdown, Menu, Tooltip } from "antd";
import { LogoutOutlined } from "@ant-design/icons";

import { ProfileIcon } from "../common/ProfileIcon";
import AuthContext from "../../../store/auth-context";
import { APIlogs } from "../../../services/api/loggerAPI";

import "./Profile.sass";

export const Profile = () => {
  const [visible, setVisible] = useState(false);

  const authCtx = useContext(AuthContext);

  const logoutHandler = () => {
    APIlogs.post({
      action: "logOut",
      date: new Date(),
    });
    authCtx.logout();
  };

  const menuItems = [
    {
      label: "Sign out",
      key: "logout",
      icon: <LogoutOutlined />,
      onClick: logoutHandler,
    },
  ];

  return (
    <div className="container">
      <Dropdown
        onOpenChange={() => setVisible(false)}
        placement="bottomRight"
        trigger={["click"]}
        overlay={<Menu items={menuItems} selectable className="menu" />}
      >
        <Tooltip open={visible} onOpenChange={setVisible} title="User Profile">
          <>
            <ProfileIcon data-testid="buttonProfile" />
          </>
        </Tooltip>
      </Dropdown>
    </div>
  );
};
