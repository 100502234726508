import React, { useState, useEffect } from "react";
import { APIlogs } from "../services/api/loggerAPI";
import { showToastMessage } from "../helpers/messages";
import { UserDepot } from "./UserDepot";
import { removeAccessToken, storeAccessToken, getStoredAccessToken, getStoredIdToken, storeIdToken } from "./localStoreHelper";
import { isEnabledInnerUser, userByLoginType } from "./fake-user-support";
import { parseJwt, updateTokenIfAlmostExpired } from "../helpers/auth";
import { redirectBrowser } from "../helpers/utilities";
import { AUTH_URLS } from "../helpers/auth";
import { getCookie, deleteCookie } from "../helpers/cookies";
import { COOKIES, WEB_APP_STATE } from "../helpers/auth";
import { appModel } from "../models/AppModel";

const CHECK_ACCESS_TOKEN_EXPIRATION_PERIOD = 1000 * 15; // 15 sec

export type User = {
  id?: string;
  username?: string;
  isInner?: boolean;
  name?: string;
  getToken?: () => Promise<string>;
};

const getToken = async () => getStoredAccessToken();

const AuthContext = React.createContext({
  getToken: () => {},
  isLoggedIn: false,
  isUserDataLoading: true,
  login: loginType => {},
  logout: () => {},
  user: {} as User,
  depot: null,
});

export const AuthContextProvider = props => {
  const [signedIn, setSignedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState<User>({});

  const clearUserData = () => {
    removeAccessToken();
    setSignedIn(false);
    setUser({});
  };

  useEffect(() => {
    // Try to use fake login
    const { loginType } = isEnabledInnerUser();
    if (loginType) {
      loginHandler(loginType);
      setIsLoading(false);
      return;
    }

    // Regular auth flow - try to use stored token
    let jwtAccessToken = getStoredAccessToken();
    if (!jwtAccessToken) {
      // ... or extract it from cookie
      jwtAccessToken = getCookie(COOKIES.ACCESS_TOKEN);
      deleteCookie(COOKIES.ACCESS_TOKEN);
    }

    // Regular auth flow - try to use stored token
    let jwtIdToken = getStoredIdToken();
    if (!jwtIdToken) {
      // ... or extract it from cookie
      jwtIdToken = getCookie(COOKIES.ID_TOKEN);
      deleteCookie(COOKIES.ID_TOKEN);
    }

    const parsedAccessJwt = parseJwt(jwtAccessToken);
    const parsedIdJwt = parseJwt(jwtIdToken);
    const isLoggedIn = !!parsedAccessJwt;

    // Extract app auth state from cookie
    const webAppState = getCookie(COOKIES.WEB_APP_STATE);
    deleteCookie(COOKIES.WEB_APP_STATE);

    if (isLoggedIn && webAppState === WEB_APP_STATE.LoggedIn) {
      //Message removed
    } else if (webAppState === WEB_APP_STATE.LoggedOut) {
      //Message removed
    } else if (webAppState === WEB_APP_STATE.Unauthorized) {
      showToastMessage("Error", "Log in failed");
    }

    if (isLoggedIn) {
      APIlogs.post({
        action: "logIn",
        user: parsedAccessJwt.username,
        date: new Date(),
      });
      storeAccessToken(jwtAccessToken);
      storeIdToken(jwtIdToken);
      setSignedIn(true);
      setUser({
        id: parsedAccessJwt.sub,
        username: parsedAccessJwt.username,
        isInner: false,
        name: parsedIdJwt?.name,
        getToken,
      });
    } else {
      clearUserData();
    }

    setIsLoading(false);
  }, []);

  useEffect(() => {
    // Auto-update access token
    let timer;
    const periodCheck = () => {
      timer = setTimeout(async () => {
        await updateTokenIfAlmostExpired();

        // Repeat
        periodCheck();
      }, CHECK_ACCESS_TOKEN_EXPIRATION_PERIOD);
    };

    clearTimeout(timer);
    if (user && user?.isInner === false) {
      periodCheck();
    }

    return () => {
      clearTimeout(timer);
    };
  }, [user]);

  const logoutHandler = async () => {
    // Unload and unlock corePlan.
    if (appModel.activeCorePlan) {
      await appModel.setActiveCorePlan(null);
    }

    removeAccessToken();
    redirectBrowser(AUTH_URLS.LOGOUT);
  };

  const loginHandler = async loginType => {
    const innerUser = userByLoginType(loginType);
    if (innerUser) {
      setSignedIn(true);
      setUser(innerUser);
      storeAccessToken((await innerUser.getToken())?.jwt);
    } else {
      redirectBrowser(AUTH_URLS.LOGIN);
    }
  };

  const tokenFunc = user?.isInner ? user.getToken : getToken;

  const contextValue = {
    getToken: tokenFunc,
    isLoggedIn: signedIn,
    isUserDataLoading: isLoading,
    user,
    login: loginHandler,
    logout: logoutHandler,
    depot: signedIn ? new UserDepot(user, tokenFunc) : UserDepot.close(),
  };

  return <AuthContext.Provider value={contextValue}>{props.children}</AuthContext.Provider>;
};

export default AuthContext;
