import { EntityDataType, PersistentObject } from "../entities/entityManager";
import { deepCopy2DTO } from "../helpers/pojo";
import { kindRoomEntityRaw } from "../services/allKinds";
import { RawRoomEntity } from "../services/rawTypes";
import { RoomEntityType } from "./RoomEntityType";

export class RoomEntity implements PersistentObject<RoomEntity, RawRoomEntity> {
  id: string;
  type: RoomEntityType;
  fileId?: string;
  bindingId?: string; // Revit owner id
  revit?: { id: string; name: string };
  properties?: { [index: string]: any }[];

  // * Persistence part *

  static readonly kind = kindRoomEntityRaw;

  toDTO(): RawRoomEntity {
    const result = deepCopy2DTO(this) as unknown as RawRoomEntity;
    result.kind = RoomEntity.kind.id;
    return result;
  }

  fromDTO(dto: RawRoomEntity, format: EntityDataType = "APIdata"): RoomEntity {
    if (RoomEntity.kind.id != dto?.kind) throw "dto: RawRoomEntity: incorrect kind";
    this.id = dto.id;
    this.fileId = dto.fileId;

    if (format === "APIdata.saved") return this;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.type = dto.type;

    this.bindingId = dto.bindingId;
    this.revit = dto.revit;
    this.properties = dto.properties;

    return this;
  }
}
