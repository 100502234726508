import { Col, Button, Modal } from "antd";
import { MESSAGE_DURATION } from "../../../../editor/consts";
import { fileManager } from "../../../../entities/fileManager";
import { showToastMessage } from "../../../../helpers/messages";
import { getFileExtension, getFormattedDate } from "../../../../helpers/utilities";
import { FileEntity } from "../../../../models/FileEntity";
import { CorePlan } from "../../../../models/CorePlan";
import apiProvider from "../../../../services/api/utilities/Provider";
import { ExportOutlined } from "@ant-design/icons";
import { runInAction } from "mobx";

const extensionBackgroundMap = {
  fxd: "#D9D9D9",
  pdf: "#FF3B47",
  exe: "#8CFFEA",
  tlx: "#F0FF97",
  rvt: "#1A3E8C",
  doc: "#2F6CF6",
  docx: "#2F6CF6",
  json: "#FF9D97",
  xls: "#3A6F4A",
  xlsx: "#3A6F4A",
};
const extensionColorMap = {
  fxd: "#012827",
  pdf: "#012827",
  exe: "#012827",
  tlx: "#012827",
  rvt: "#FFFFFF",
  doc: "#FFFFFF",
  docx: "#FFFFFF",
  json: "#012827",
  xls: "#FFFFFF",
  xlsx: "#FFFFFF",
};

const defaultExtensionBackground = "#F0FFF4";
const defaultExtensionColor = "#012827";
const glbExtension = "glb";
const openableExtensions = ["pdf", "txt", "png", "jpeg", "jpg", "gpe", "gfif", "gfi", "gif", "json"];

interface FileProps {
  file: FileEntity;
  corePlan: CorePlan;
  isAttachedFile: boolean;
  removeFile: (fileId: string, folderName: string) => void;
  folderName: string;
}

const FileItem = ({ file, corePlan, isAttachedFile, removeFile, folderName }: FileProps) => {
  const deleteFile = async (file: FileEntity) => {
    let result: boolean;
    if (isAttachedFile) {
      const delResult = await fileManager.deleteCorePlanFiles(corePlan.id, [file.URI.replace("/files/", "")]);
      result = delResult?.length && delResult[0] === file.id;
    } else {
      result = await fileManager.deleteCorePlanFile(file.URI.replace("/files/", ""));
    }

    if (result) {
      showToastMessage("Success", `Successfully deleted file ${file.name}`);
      const fileIndex = corePlan.files.findIndex(f => f.id === file.id);
      if (fileIndex >= 0) {
        runInAction(() => {
          corePlan.files.splice(fileIndex, 1);
        });
      }
      removeFile(file.id, folderName);
    }
  };

  const showDeleteConfirm = () => {
    const used = corePlan.checkUsedFile(file);
    if (used.length) {
      for (const usedInfo of used) {
        showToastMessage("Warning", usedInfo.message, { autoClose: MESSAGE_DURATION * 2 });
      }
      showToastMessage("Info", "You have to clean a floor background before delete its file");
      return;
    }

    const { confirm } = Modal;

    confirm({
      title: "Are you sure you want to delete this file?",
      okText: "Proceed",
      okType: "default",
      cancelText: "Go Back",
      onOk() {
        deleteFile(file);
      },
    });
  };

  const downloadFile = async () => {
    try {
      let content: string;
      if (isAttachedFile) {
        const strippedUri = file.URI.replace("/files/", "");
        content = await apiProvider.getFile(`files/${strippedUri}?encode=base64`);
      } else {
        content = await fileManager.getCorePlanFile(file.URI);
      }
      if (!content) {
        showToastMessage("Error", `Error on file download ${file.name}`);
        return;
      }

      const link = document.createElement("a");
      link.href = "data:/plain;base64," + content;
      link.download = file.name;
      link.click();
    } catch (err) {
      showToastMessage("Error", `Error on file download ${file.name}`);
    }
  };

  const downloadButton = () => {
    if (file.presignedDownloadUrl) {
      return (
        <a href={file.presignedDownloadUrl}>
          <Button className="btn-file btn-download">&nbsp;</Button>
        </a>
      );
    }

    return (
      <Button className="btn-file btn-download" onClick={downloadFile}>
        &nbsp;
      </Button>
    );
  };

  const getViewerLink = () => {
    if (fileExt === glbExtension && process.env.REACT_APP_DE_VIEWER_URL) {
      return (
        <a
          href={`${process.env.REACT_APP_DE_VIEWER_URL}?url=${encodeURIComponent(file.presignedOpenUrl)}`}
          target="_blank"
          rel="noreferrer"
          media="application/pdf"
        >
          <ExportOutlined className="file-viewer-icon" />
        </a>
      );
    } else if ((file as any).presignedOpenUrl && openableExtensions.includes(fileExt)) {
      return (
        <a href={(file as any).presignedOpenUrl} target="_blank" rel="noreferrer">
          <ExportOutlined className="file-viewer-icon" />
        </a>
      );
    }
  };
  const fileExt = getFileExtension(file.id);
  const dateFormatted = getFormattedDate(file.lastModified);

  return (
    <Col span={6}>
      <div className="item">
        {getViewerLink()}
        <div className="title">
          <div className="icon">
            <span
              style={{
                background: extensionBackgroundMap[fileExt] || defaultExtensionBackground,
                color: extensionColorMap[fileExt] || defaultExtensionColor,
              }}
            >
              {fileExt}
            </span>
          </div>
          <p>{file.name}</p>
        </div>
        <div className="details">
          <p>Published {dateFormatted.toString()}</p>
          <div className="btn-group">
            {downloadButton()}
            <Button className="btn-file btn-delete" onClick={showDeleteConfirm}>
              &nbsp;
            </Button>
          </div>
        </div>
      </div>
    </Col>
  );
};

export default FileItem;
