import { Col, Row } from "antd";
import { inchesToFeetInchesFraction, inches2feetSq } from "../../../../helpers/measures";
import { CorePlan } from "../../../../models/CorePlan";
import "./TabCorePlanOverview.sass";
import MathUtils from "../../../../editor/utils/MathUtils";

const TabCorePlanOverview = ({ corePlan }: { corePlan: CorePlan }) => {
  const showGarage = (garage: number) => {
    if (!garage) return "N/A";
    const plural = garage > 1 ? "s" : "";
    return `${garage} Car${plural}`;
  };

  if (!corePlan) {
    return;
  }

  const { attributes, state, width, series, name, notes, cost, costPerSqft, co2Emission, isCostOutdated, floorToFloorHeight } = corePlan;

  const toUsLocale = (value: number, precision = 1): string => {
    return typeof value === "number" ? MathUtils.round(value, precision).toLocaleString("en-US") : "-";
  };

  return (
    <Row className="tab-details" align="stretch">
      <Col xs={24} lg={12} className="corePlan-overview-column">
        <div className="corePlan-details">
          <div className="corePlan-name">
            <h5>{name}</h5>
          </div>
          <div className="Core Plan-Series">
            <h5>{series}</h5>
          </div>
          <div className="State">
            <h5>{state}</h5>
          </div>
          <hr />
          <div className="Core Plan Width">
            <h5>{width}</h5>
          </div>
          <hr />
          <div className="corePlan-notes">
            <h5>Core Plan Notes</h5>
            <p>{notes}</p>
          </div>
          <h5>Attached Files</h5>
          <p>See documents tab</p>
        </div>
      </Col>
      <Col xs={24} lg={12} className="corePlan-overview-column">
        <div className="corePlan-attributes">
          <Row className="area-attributes">
            <Col>
              <span className="title">Min Net area</span>
              <span>
                {toUsLocale(inches2feetSq(attributes?.minNetArea))} <span className="units">SqFt</span>
              </span>
            </Col>
            <Col>
              <span className="title">Max “Gross” area</span>
              <span>
                {toUsLocale(inches2feetSq(attributes?.maxGrossArea))} <span className="units">SqFt</span>
              </span>
            </Col>
            <Col>
              <span className="title">Lot Area</span>
              <span>
                {toUsLocale(inches2feetSq(attributes?.lotSize))} <span className="units">SqFt</span>
              </span>
            </Col>
          </Row>
          <ul className="params">
            <li>
              <i className="icon icon-floors"></i>
              <span>Floors</span>
              <span className="value">{attributes?.floors}</span>
            </li>
            <li>
              <i className="icon icon-garage"></i>
              <span>Garage</span>
              <span className="value">{showGarage(attributes?.garage)} </span>
            </li>
            <li>
              <i className="icon icon-bedrooms"></i>
              <span>Bedrooms</span>
              <span className="value">{attributes?.bedRooms}</span>
            </li>
            <li>
              <i className="icon icon-floorHeight"></i>
              <span>Floor to floor</span>
              <span className="value">{inchesToFeetInchesFraction(floorToFloorHeight)}</span>
            </li>
            <li>
              <i className="icon icon-bathrooms"></i>
              <span>Bathrooms</span>
              <span className="value">{attributes?.bedRooms}</span>
            </li>
          </ul>
          <Row className="cost-attributes">
            {isCostOutdated && (
              <Col className="outdated-warning" span={24}>
                *Cost estimations are outdated
              </Col>
            )}
            <Col>
              <span className="title">Overall Cost</span>
              <span>
                {toUsLocale(cost)} <span className="units">USD</span>
              </span>
            </Col>
            <Col>
              <span className="title">Price per SqFt</span>
              <span>
                {toUsLocale(costPerSqft)} <span className="units">USD</span>
              </span>
            </Col>
            <Col>
              <span className="title">Co2 Emission</span>
              <span>{toUsLocale(co2Emission, 100)} kg per sqft</span>
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  );
};

export default TabCorePlanOverview;
