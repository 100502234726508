import { readUploadedFile } from "../helpers/files";
import { isEmpty } from "../helpers/pojo";
import { appModel } from "../models/AppModel";
import { RoomType } from "../models/RoomType";
import { markDelete, markHidden } from "../services/allKinds";
import { RawEntity } from "../services/rawTypes";

export type BeforeRefinement = "create:RoomEntities";
export type AfterRefinement = "updateRoomTypes" | "addCategory";

export async function beforeSave(object: any, before?: BeforeRefinement[]) {
  if (isEmpty(before)) return object;
  for (const refinement of before) {
    switch (refinement) {
      case "create:RoomEntities":
        await createRoomTypeEntities(object as RoomType);
        break;
      default:
        throw `Unknown refinement ${refinement}`;
    }
  }

  return object;
}

export async function afterSave(object: any, result: RawEntity, after?: AfterRefinement[]) {
  if (isEmpty(after)) return object.fromDTO(result, "APIdata.saved");

  if (!after.includes("updateRoomTypes")) {
    object.fromDTO(result, "APIdata.saved");
  }

  for (const refinement of after) {
    switch (refinement) {
      case "updateRoomTypes":
        const affectedRoomType = result.affectedRoomType as any;
        object.fromDTO(result, "APIdata.saved");
        const category = appModel.roomCategories.find(el => object.roomCategoryId === el.id);
        if (affectedRoomType) {
          if (affectedRoomType.mark === markDelete.id) {
            appModel.removeFromRoomCategory(affectedRoomType.id, category.id);
          } else if (affectedRoomType.mark === markHidden.id) {
            appModel.markRoomTypeHidden(affectedRoomType.id);
          }
        }
        category?.addRoomType(object);

        break;
      case "addCategory":
        appModel.addRoomCategory(object);
        break;
      default:
        throw `Unknown refinement ${refinement}`;
    }
  }

  return object;
}

async function createRoomTypeEntities(roomType: RoomType) {
  let foundJson = false;
  for (const file of roomType?._uploadFiles || []) {
    if (file.name.indexOf(".json") > 0) {
      RoomType.fromRevitJson(JSON.parse(await readUploadedFile(file.originFileObj)), roomType);
      foundJson = true;
      break;
    }
  }
  if (!foundJson) throw "At least 1 .json file should be uploaded";

  for (const roomEntity of roomType.roomEntities) {
    if (isEmpty(roomEntity.fileId)) continue;

    const requiredFile = roomEntity.fileId.replace(/-[a-zA-Z0-9]+\.dxf/, ".dxf");

    if (
      !roomType._uploadFiles.find(file => {
        const origFileName = file.name.toLowerCase();
        if (origFileName === requiredFile) {
          file.uid = "!" + roomEntity.fileId;

          return true;
        }
        return false;
      })
    )
      throw `Missing required file ${requiredFile}`;
  }
}
