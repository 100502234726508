import { useState } from "react";
import { Col, Row } from "antd";
import * as _ from "lodash";
import { observer } from "mobx-react-lite";
import { filter, flow, orderBy } from "lodash/fp";
import { Scrollbar } from "react-scrollbars-custom";

import { Size, useWindowSize } from "../../../helpers/utilities";
import { appModel } from "../../../models/AppModel";
import { CorePlan } from "../../../models/CorePlan";
import { markPublic } from "../../../services/allKinds";
import LoadingSpinner from "../common/LoadingSpinner";
import ModalCorePlanWindow from "./ModalCorePlanWindow";
import CorePlanFilter from "./CorePlanFilter";
import CorePlanListItem from "./CorePlanListItem";
import CorePlanLockModal from "./CorePlanLockModal";
import { CORE_PLAN_STATUS, CorePlansFilterInfo, initFilters } from "./CorePlansPage.types";
import ModalCorePlanPackageWindow from "./ModalCorePlanPackageWindow";
import ModalVariationPackageWindow from "./ModalVariationPackageWindow";
import { inches2feet } from "../../../helpers/measures";

import "./CorePlans.sass";
import { Floor } from "../../../models/Floor";
import EditorSpinner from "../Editor/EditorSpinner";

let CorePlansListView = () => {
  const [corePlans, setCorePlans] = useState<CorePlan[]>(appModel.corePlans);
  const [isFiltered, setIsFiltered] = useState<boolean>(false);
  const size: Size = useWindowSize();

  const isLoaded = !appModel.isBusy || corePlans.length > 0;

  const introBlock = document.getElementsByClassName("header")[0];
  const filterBlock = document.getElementsByClassName("filter-corePlans")[0];
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const introHeight = introBlock?.offsetHeight ? introBlock.offsetHeight : 0;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const filterHeight = filterBlock?.offsetHeight ? filterBlock.offsetHeight : 0;

  const staticContentHeight = introHeight + filterHeight + 1;
  const contentHeight = size.height ? size.height - staticContentHeight : "auto";

  const onApplyFilter = (filterInfo: CorePlansFilterInfo) => {
    const filteredCorePlans = flow([
      // search
      ...(filterInfo.searchBy ? [filter((prj: CorePlan) => prj.name.toLowerCase().includes(filterInfo.searchBy?.toLowerCase()))] : []),
      // filter by status mocked
      filter((prj: CorePlan) => [CORE_PLAN_STATUS.ALL, CORE_PLAN_STATUS.TWO_D].includes(filterInfo.status)),
      // filter by floors Number
      ...(filterInfo.floorsNumber ? [filter((prj: CorePlan) => prj.attributes?.floors == filterInfo.floorsNumber)] : []),
      // filter by bedrooms Number
      ...(filterInfo.bedroomsNumber ? [filter((prj: CorePlan) => prj.attributes?.bedRooms == filterInfo.bedroomsNumber)] : []),
      // filter by bath Number (sum of bathRooms and halfBathRooms)
      ...(filterInfo.bathroomsNumber || filterInfo.bathroomsNumber === 0
        ? [
            filter((prj: CorePlan) => {
              const totalBaths = (prj.attributes?.bathRooms || 0) + (prj.attributes?.halfBathRooms / 2 || 0);
              return totalBaths == filterInfo.bathroomsNumber;
            }),
          ]
        : []),
      // filter by car spaces Number
      ...(filterInfo.carSpacesNumber || filterInfo.carSpacesNumber === 0
        ? [filter((prj: CorePlan) => prj.attributes?.garage == filterInfo.carSpacesNumber)]
        : []),
      // filter by lot width in feet
      ...(filterInfo.lotWidthFeet || filterInfo.lotWidthFeet === 0
        ? [filter((prj: CorePlan) => inches2feet(prj.attributes?.lotWidth) == filterInfo.lotWidthFeet)]
        : []),
      // sorting
      orderBy([filterInfo.sortBy], [filterInfo.order]),
    ])(appModel.corePlans);
    setCorePlans(filteredCorePlans);

    setIsFiltered(!_.isEqual(filterInfo, initFilters));
  };

  const NoCorePlansToShow = () => {
    if (isFiltered) {
      return (
        <div className="no-core-plans-message">
          <p>No Matching Core Plans</p>
          <span>Try adjusting your filters</span>
        </div>
      );
    } else if (isLoaded) {
      return (
        <div className="no-core-plans-message">
          <p>There aren’t any Core Plans in the Library</p>
          <span>Add a core plan to the Studio library </span>
        </div>
      );
    }
    return <div></div>;
  };

  return (
    <section className="corePlans">
      <CorePlanFilter filterHandler={onApplyFilter} corePlans={appModel.corePlans} />
      {appModel.isBusy && (
        <div className="loading-spinner-container">
          <EditorSpinner />
        </div>
      )}
      {!corePlans.length ? (
        <NoCorePlansToShow />
      ) : (
        <Scrollbar style={{ height: contentHeight }} noScrollX>
          <div className="scroll-container">
            {isLoaded &&
              corePlans
                .filter(corePlan => corePlan.mark >= markPublic.id)
                .map(corePlan => (
                  <div className="card-container" key={corePlan.id}>
                    <CorePlanListItem corePlan={corePlan} />
                  </div>
                ))}
          </div>
        </Scrollbar>
      )}
      <ModalCorePlanWindow />
      <ModalCorePlanPackageWindow />
      <ModalVariationPackageWindow />
      <CorePlanLockModal />
    </section>
  );
};

CorePlansListView = observer(CorePlansListView);

export default CorePlansListView;
