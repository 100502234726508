import log from "loglevel";

export const readUploadedFile = async (file: File): Promise<string> => {
  return new Promise((good, bad) => {
    try {
      const reader = new FileReader();

      reader.addEventListener(
        "load",
        () => {
          good(reader.result as string);
        },
        false
      );

      if (file) {
        reader.readAsText(file);
      }
    } catch (error) {
      log.error("readUploadedFile", error);
      bad(error);
    }
  });
};
