import { Col, Row } from "antd";
import { observer } from "mobx-react-lite";
import { finishesManager } from "../../../../../../entities/finishesManager";
import { ExteriorFinish } from "../../../../../../models/DesignStyle";
import "./FinishesList.sass";

type FinishesListProps = {
  finishes: ExteriorFinish[];
  selectedFinishesIds: string[];
  onFinishSelected: (finish: ExteriorFinish) => void;
};

const FinishesList = observer(({ finishes, selectedFinishesIds, onFinishSelected }: FinishesListProps) => {
  if (finishes.length === 0) {
    return <div>The list of finishes is empty.</div>;
  }

  return (
    <Row className="finishes-list">
      {finishes.map(finish => (
        <Col className={`finish-item ${selectedFinishesIds.includes(finish.id) ? "selected" : ""}`} span={6} key={finish.id}>
          <div className="image-container">
            <img src={finishesManager.getFinishTextureUrl(finish.image)} alt={finish.name} onClick={() => onFinishSelected(finish)} />
          </div>
          <div className="finish-name">{finish.name}</div>
        </Col>
      ))}
    </Row>
  );
});

export default FinishesList;
