import axios from "axios";
import log from "loglevel";
import { getStoredAccessToken, removeAccessToken, setAuthSessionTerminationState, storeAccessToken } from "../store/localStoreHelper";
import { redirectBrowser } from "./utilities";

// Supposed that backend URL haven't trailing slash
const BACKEND_URL = process.env.REACT_APP_API_HOST;

const AUTH_URLS = {
  LOGIN: `${BACKEND_URL}/auth/login`,
  LOGOUT: `${BACKEND_URL}/auth/logout`,
  REFRESH: `${BACKEND_URL}/auth/refresh`,
};

const COOKIES = {
  ID_TOKEN: "id_token",
  ACCESS_TOKEN: "access_token",
  WEB_APP_STATE: "web-app-state",
};

const WEB_APP_STATE = {
  Unauthorized: "Unauthorized",
  LoggedIn: "LoggedIn",
  LoggedOut: "LoggedOut",
};

const AUTH_SESSION_TERMINATION_STATE = {
  IDLE_TIMEOUT: "IDLE_TIMEOUT",
  REFRESH_TOKEN_EXPIRED: "REFRESH_TOKEN_EXPIRED",
};

type AuthTerminationStateKeys = keyof typeof AUTH_SESSION_TERMINATION_STATE;
type AuthTerminationStateValues = (typeof AUTH_SESSION_TERMINATION_STATE)[AuthTerminationStateKeys];

/**
 * Refresh access token and store new token if refreshing succeed
 * Returns either new access token, or null if refreshing failed
 */
const refreshAccessJwtToken = async (): Promise<string | null> => {
  try {
    // (!) Using not wrapped axios
    const response = await axios.post(AUTH_URLS.REFRESH, {}, { withCredentials: true });

    const token = response?.data?.access_token;

    if (!!token) {
      storeAccessToken(token);
      return token;
    }

    return null;
  } catch (err) {
    log.error("Can not refresh access token");
    return null;
  }
};

/**
 * Force current Auth session to be terminated:
 * - remove stored access token
 * - set mark about reason of session termination
 * - redirect browser to logout endpoint
 */
const finishCurrentAuthSession = (reason: AuthTerminationStateValues) => {
  removeAccessToken();
  setAuthSessionTerminationState(reason);
  redirectBrowser(AUTH_URLS.LOGOUT);
};

/**
 * Parse JWT token, returns either object with parsed result, or null if parsing failed
 */
const parseJwt = (token: string): { [key: string]: any } | null => {
  try {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    const parsedJwt = JSON.parse(jsonPayload);
    return parsedJwt;
  } catch (_) {
    return null;
  }
};

/**
 * Predicate, returns true if a token (i.e. expiration time in Unix timestamp format) almost expired
 */
const checkIfTokenExpiredSoon = (exp?: number): boolean => {
  if (typeof exp !== "number") {
    return false;
  }

  const now = Date.now();
  const expired = new Date(exp * 1000).getTime();
  const diff = expired - now; // in ms

  // Token will be considered as 'expired soon' if it should expire in less or eq to threshold
  const THRESHOLD = 1000 * 60; // 1 min

  if (diff <= THRESHOLD) {
    return true;
  }

  return false;
};

/**
 * Refresh and store new access token, if current one almost expired
 */
const updateTokenIfAlmostExpired = async (): Promise<void> => {
  try {
    const currentToken = getStoredAccessToken();
    const parsedJwt = parseJwt(currentToken);

    const isTokenExpiredSoon = checkIfTokenExpiredSoon(parsedJwt.exp);

    if (!isTokenExpiredSoon) {
      return;
    }

    const newToken = await refreshAccessJwtToken();
    if (!newToken) {
      finishCurrentAuthSession(AUTH_SESSION_TERMINATION_STATE.REFRESH_TOKEN_EXPIRED);
    }
  } catch (err) {
    log.error(err);
  }
};

export type { AuthTerminationStateValues };
export {
  AUTH_URLS,
  COOKIES,
  WEB_APP_STATE,
  AUTH_SESSION_TERMINATION_STATE,
  refreshAccessJwtToken,
  finishCurrentAuthSession,
  checkIfTokenExpiredSoon,
  updateTokenIfAlmostExpired,
  parseJwt,
};
