import { runInAction } from "mobx";
import { CommandTypes } from "./CommandTypes";
import { Command } from "./Command";

export class MultiCommand<T> extends Command<T> {
  constructor(public commands: Command<T>[]) {
    super(null);

    this.type = CommandTypes.MultiCommand;
  }

  apply(manager: T): void {
    runInAction(() => {
      this.commands.forEach(cmd => cmd.apply(manager));
    });
  }

  undo(manager: T): void {
    runInAction(() => {
      for (let i = this.commands.length - 1; i >= 0; i--) {
        this.commands[i].undo(manager);
      }
    });
  }
}
