import { Button, Modal } from "antd";

import "./style.sass";

type ConfirmModalProps = {
  isOpen: boolean;
  confirmLoading?: boolean;
  onOk: () => void;
  onCancel: () => void;
  deleteItemName?: string;
  type?: string;
};

const Message = ({ deleteItemName, deleteType }) => (
  <div>
    <p>Are you sure you want to delete {deleteItemName}?</p>
    <p>Note that all data related to the {deleteType} will be deleted.</p>
  </div>
);

const ConfirmModal = ({ isOpen, onOk, onCancel, type = "", deleteItemName = "", confirmLoading = false }: ConfirmModalProps) => {
  return (
    <Modal
      className="confirm-modal"
      open={isOpen}
      confirmLoading={confirmLoading}
      onCancel={onCancel}
      footer={[
        <Button className="primary" key="ok" type="primary" loading={confirmLoading} onClick={onOk}>
          Yes
        </Button>,
        <Button className="default" key="cancel" onClick={onCancel}>
          No
        </Button>,
      ]}
    >
      <Message deleteItemName={deleteItemName} deleteType={type} />
    </Modal>
  );
};

export default ConfirmModal;
