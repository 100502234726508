const prepListCategory = [
  {
    id: "e7742928-3dbc-11ed-a46e-ebc04954e698",
    kind: 1290,
    name: "Outdoor Areas",
    mark: 32,
    forKind: 1200,
    sortNum: 100,
  },
  {
    id: "e774a056-3dbc-11ed-a46f-63b453131d5d",
    kind: 1290,
    name: "Kitchens",
    mark: 32,
    forKind: 1200,
    sortNum: 120,
  },
  {
    id: "e774c7c0-3dbc-11ed-a470-43267686610b",
    kind: 1290,
    name: "Bathrooms",
    mark: 32,
    forKind: 1200,
    sortNum: 140,
  },
  {
    id: "e774ee12-3dbc-11ed-a471-173626f61cd9",
    kind: 1290,
    name: "Bedrooms",
    mark: 32,
    forKind: 1200,
    sortNum: 160,
  },
  {
    id: "e774ee13-3dbc-11ed-a472-13b709a59a9a",
    kind: 1290,
    name: "Dining Areas",
    mark: 32,
    forKind: 1200,
    sortNum: 180,
  },
  {
    id: "e77514aa-3dbc-11ed-a473-670952a883b7",
    kind: 1290,
    name: "Utility Rooms",
    mark: 32,
    forKind: 1200,
    sortNum: 200,
  },
  {
    id: "e77514ab-3dbc-11ed-a474-ab972d993ab6",
    kind: 1290,
    name: "Garages",
    mark: 32,
    forKind: 1200,
    sortNum: 220,
  },
  {
    id: "e77514ac-3dbc-11ed-a475-5fae65af7348",
    kind: 1290,
    name: "Passageways",
    mark: 32,
    forKind: 1200,
    sortNum: 240,
  },
  {
    id: "e77514ad-3dbc-11ed-a476-9fb65f6cc399",
    kind: 1290,
    name: "Living Areas",
    mark: 32,
    forKind: 1200,
    sortNum: 260,
  },
  {
    id: "e7753c14-3dbc-11ed-a477-67dc4d0ad9a3",
    kind: 1290,
    name: "Stairs",
    mark: 32,
    forKind: 1200,
    sortNum: 280,
  },
  {
    id: "e7753c15-3dbc-11ed-a478-7b8497e1d3d4",
    kind: 1290,
    name: "Storage",
    mark: 32,
    forKind: 1200,
    sortNum: 300,
  },
];

const listCategory = prepListCategory.map(elem => Object.assign(elem, { attributes: {} }));
// const listCategory = prepListCategory

export default listCategory;
