import { action, makeObservable, observable } from "mobx";
import { v4 as uuid } from "uuid";
import { Side } from "./Side";
import { Vector3V } from "./Vector3V";

export class LotItem {
  id: string;

  // helper properties:
  x: number = 0;
  y: number = 0;

  constructor(id: string = uuid()) {
    this.id = id;

    makeObservable(this, {
      x: observable,
      y: observable,

      setX: action,
      setY: action,
    });
  }

  public setX(x: number): void {
    this.x = x;
  }

  public setY(y: number): void {
    this.y = y;
  }

  public toDTO(): any {
    return {
      id: this.id,
    };
  }

  public fromDTO(dto: any): LotItem {
    this.id = dto.id;

    return this;
  }
}

export class LotLineVertex extends LotItem {
  point: Vector3V;

  constructor(point: Vector3V = new Vector3V(), id?: string) {
    super(id);
    this.point = point;
  }

  public setX(x: number): void {
    super.setX(x);
    this.point.x = x;
  }

  public setY(y: number): void {
    super.setY(y);
    this.point.y = y;
  }

  public clone(): LotLineVertex {
    return new LotLineVertex(this.point.clone(), this.id);
  }

  public toDTO(): any {
    const data = super.toDTO();
    data.point = this.point.clone();

    return data;
  }

  public fromDTO(dto: any): LotLineVertex {
    super.fromDTO(dto);
    this.point = new Vector3V().fromDTO(dto.point);

    return this;
  }
}
export class LotLineOffsetVertex extends LotLineVertex {
  edgeId: string;
  constructor(point: Vector3V = new Vector3V(), edgeId: string, id?: string) {
    super(point, id);
    this.edgeId = edgeId;
  }

  public clone(): LotLineOffsetVertex {
    return new LotLineOffsetVertex(this.point.clone(), this.edgeId, this.id);
  }
}

export class LotLineEdge extends LotItem {
  public side?: Side;

  constructor(
    public startId: string = "",
    public endId: string = "",
    public offset: number = 0,

    id?: string
  ) {
    super(id);
    makeObservable(this, {
      offset: observable,
      setOffset: action,
    });
  }

  public setOffset(offset: number) {
    this.offset = offset;
  }

  public clone(): LotLineEdge {
    return new LotLineEdge(this.startId, this.endId, this.offset, this.id);
  }

  public toDTO(): any {
    const data = super.toDTO();
    data.startId = this.startId;
    data.endId = this.endId;
    data.offset = this.offset;

    return data;
  }

  public fromDTO(dto: any): LotLineEdge {
    super.fromDTO(dto);
    this.startId = dto.startId;
    this.endId = dto.endId;
    this.offset = dto.offset;
    return this;
  }
}

export class LotLine {
  vertices: LotLineVertex[] = [];
  edges: LotLineEdge[] = [];
  offsetVertices: LotLineOffsetVertex[] = [];

  public build(points: Vector3V[]): LotLine {
    this.vertices.length = 0;
    this.edges.length = 0;
    this.offsetVertices.length = 0;
    points.forEach(point => this.vertices.push(new LotLineVertex(point.clone())));
    for (let i = 0; i < this.vertices.length; i++) {
      this.edges.push(new LotLineEdge(this.vertices[i].id, this.vertices[i !== this.vertices.length - 1 ? i + 1 : 0].id));
    }

    return this;
  }

  public clone(): LotLine {
    const result = new LotLine();
    result.vertices = this.vertices.map(vertex => vertex.clone());
    result.offsetVertices = this.offsetVertices.map(vertex => vertex.clone());
    result.edges = this.edges.map(edge => edge.clone());

    return result;
  }

  public getLotItem(id?: string): LotItem {
    if (!id) {
      return null;
    }

    return this.edges.find(it => it.id === id) || this.vertices.find(it => it.id === id);
  }
  public getLinkedEdges(vertexId: string): LotLineEdge[] {
    let edge1: LotLineEdge, edge2: LotLineEdge;

    this.edges.forEach(edge => {
      if (edge.endId === vertexId) {
        edge1 = edge;
      }
      if (edge.startId === vertexId) {
        edge2 = edge;
      }
    });

    return [edge1, edge2];
  }

  public getArea() {
    let area = 0;
    for (let i = 0; i < this.vertices.length; i++) {
      const start = this.vertices[i === 0 ? this.vertices.length - 1 : i - 1].point;
      const end = this.vertices[i].point;
      area += start.x * end.y - start.y * end.x;
    }
    return Math.abs(area) / 2;
  }

  public sync(other: LotLine): void {
    const vertices: LotLineVertex[] = [];
    const edges: LotLineEdge[] = [];

    for (const otherVertex of other.vertices) {
      const thisVertex = this.vertices.find(v => v.id === otherVertex.id);
      if (thisVertex) {
        thisVertex.setX(otherVertex.point.x);
        thisVertex.setY(otherVertex.point.y);
        vertices.push(thisVertex);
      } else {
        vertices.push(otherVertex.clone());
      }
    }

    for (const otherEdge of other.edges) {
      const thisEdge = this.edges.find(v => v.id === otherEdge.id);
      if (thisEdge) {
        thisEdge.setX(otherEdge.x);
        thisEdge.setY(otherEdge.y);
        edges.push(thisEdge);
      } else {
        edges.push(otherEdge.clone());
      }
    }

    this.vertices = vertices;
    this.edges = edges;
  }

  public addVertex(vertex: LotLineVertex, idx?: number): void {
    if (idx !== undefined) {
      this.vertices.splice(idx, 0, vertex);
      return;
    }

    this.vertices.push(vertex);
  }
  public removeVertex(vertex: LotLineVertex): number {
    const index = this.vertices.indexOf(vertex);
    if (index !== -1) {
      this.vertices.splice(index, 1);
    }

    return index;
  }

  public addEdge(edge: LotLineEdge, idx?: number): void {
    if (idx !== undefined) {
      this.edges.splice(idx, 0, edge);
      return;
    }

    this.edges.push(edge);
  }
  public removeEdge(edge: LotLineEdge): number {
    const index = this.edges.indexOf(edge);
    if (index !== -1) {
      this.edges.splice(index, 1);
    }

    return index;
  }

  public toDTO(): any {
    return {
      vertices: this.vertices.map(v => v.toDTO()),
      edges: this.edges.map(e => e.toDTO()),
    };
  }

  public fromDTO(dto: any): LotLine {
    this.vertices = dto.vertices.map(vertex => new LotLineVertex().fromDTO(vertex));
    this.edges = dto.edges.map(edge => new LotLineEdge().fromDTO(edge));

    return this;
  }
}
