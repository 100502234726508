import { Tabs } from "antd";
import log from "loglevel";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { fileManager } from "../../../../entities/fileManager";
import { RenderJob } from "../../../../entities/renderJob";
import { TRenderJob } from "../../../../entities/renderJob/types";
import { FileEntity } from "../../../../models/FileEntity";
import { CorePlan } from "../../../../models/CorePlan";
import { FolderOptions } from "../../Editor/LeftBar/LeftBar";
import "./VariationDetails.sass";
import TabVariationFiles from "./TabVariationFiles";
import { Variation } from "../../../../models/Variation";
import RenderVariationJob from "../../../../entities/renderJob/renderVariationJob";

interface VariationDetailsProps {
  corePlan: CorePlan;
  variation: Variation;
}

let VariationDetails = ({ corePlan: corePlan, variation }: VariationDetailsProps) => {
  const [fileList, setFileList] = useState<Map<string, FileEntity[]>>(new Map<string, FileEntity[]>());
  const [jobs, setJobs] = useState<TRenderJob[]>([]);

  useEffect(() => {
    getVariationFilesAndJobs(corePlan);
  }, [corePlan]);

  const getVariationFilesAndJobs = async (corePlan: CorePlan) => {
    try {
      const files = await fileManager.getVariationFileList(variation.guid);
      if (!files) {
        setFileList(new Map<string, FileEntity[]>());
        return;
      }
      const folderFilesMap = files.reduce((map, file) => {
        const splitName = file.URI.split("/");
        // unattached FilePath structure - [empty space]/files/core_plans/<corePlanId>/<variationId>/<folderName>[/<folderName>...]/<fileName>
        // registered in db FilePath structure - [empty space]/files/core_plans/<corePlanId>/<variationId>/<fileName>
        const folderName = splitName.length < 6 ? FolderOptions.CorePlanFolder : splitName[5]; // File is in core plan root folder -> so is registered in db -< show in Documents tab
        if (map.has(folderName)) {
          map.get(folderName).push(file);
        } else {
          map.set(folderName, [file]);
        }

        return map;
      }, new Map<string, FileEntity[]>());

      setFileList(folderFilesMap);
      const jobs = await RenderVariationJob.getVariationJobs(variation.guid);
      setJobs(jobs);
    } catch (error) {
      setFileList(new Map<string, FileEntity[]>());
      log.error(error);
    }
  };

  const removeJob = (jobId: string) => {
    const jobIndex = jobs.findIndex(j => j.id === jobId);
    if (jobIndex >= 0) {
      jobs.splice(jobIndex, 1);
      setJobs([...jobs]);
    }
  };

  const removeFile = async (fileId: string, folder: string) => {
    const folderFiles = fileList.get(folder);
    const fileIndex = folderFiles.findIndex(f => f.id === fileId);
    if (fileIndex >= 0) {
      folderFiles.splice(fileIndex, 1);
      setFileList(new Map(fileList));
    }
  };

  const corePlanTabs = [
    {
      label: "2D Drawings",
      key: "2DDrawings",
      children: (
        <TabVariationFiles
          corePlan={corePlan}
          variation={variation}
          files={fileList.get(FolderOptions.FloorPlanFolder)}
          folderName={FolderOptions.FloorPlanFolder}
          removeFile={removeFile}
        />
      ),
    },
    {
      label: "3D Model",
      key: "3DModel",
      children: (
        <TabVariationFiles
          corePlan={corePlan}
          variation={variation}
          files={fileList.get(FolderOptions.ThreeDModelsFolder)}
          folderName={FolderOptions.ThreeDModelsFolder}
          removeFile={removeFile}
          jobs={jobs}
          removeJob={removeJob}
        />
      ),
    },
    {
      label: "Quantities",
      key: "quantities",
      children: (
        <TabVariationFiles
          corePlan={corePlan}
          variation={variation}
          files={fileList.get(FolderOptions.TmlFolder)}
          folderName={FolderOptions.TmlFolder}
          removeFile={removeFile}
        />
      ),
    },
    {
      label: "Data Model",
      key: "dataModel",
      children: (
        <TabVariationFiles
          corePlan={corePlan}
          variation={variation}
          files={fileList.get(FolderOptions.DataModelFolder)}
          folderName={FolderOptions.DataModelFolder}
          removeFile={removeFile}
        />
      ),
    },
    {
      label: "Documents",
      key: "documents",
      children: (
        <TabVariationFiles
          corePlan={corePlan}
          variation={variation}
          files={fileList.get(FolderOptions.CorePlanFolder)}
          folderName={FolderOptions.CorePlanFolder}
          removeFile={removeFile}
        />
      ),
    },
  ];

  return <Tabs defaultActiveKey="2DDrawings" className="tabs-corePlan" items={corePlanTabs} />;
};

VariationDetails = observer(VariationDetails);
export default VariationDetails;
