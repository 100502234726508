import { observer } from "mobx-react";
import { appModel } from "../../../../../models/AppModel";
import { integerToHexColor, Size, useWindowSize } from "../../../../../helpers/utilities";
import { useEffect } from "react";
import { ValidationMode } from "../../../../../editor/models/ValidationType";
import {
  DDL_WALL_SEGMENT_COLOR,
  EXT_WALL_SEGMENT_COLOR,
  GRG_WALL_SEGMENT_COLOR,
  INT_WALL_SEGMENT_COLOR,
  PLM_WALL_SEGMENT_COLOR,
} from "../../../../../editor/consts";
import { IWallTypesValidationResult } from "../../../../../editor/models/ValidationResult";
import ValidationTab from "./ValidationTab";
import { ValidationItemProps, ValidationItemType } from "./ValidationItem";

export const wallTypesLegendItems = {
  Ddl: "DDL wall (DDL)",
  Grg: "Garage walls (GRG)",
  Int: "Interior walls (INT)",
  Plm: "Plumbing walls (PLM)",
  Ext: "Exterior walls (EXT)",
};

const WallTypesTab = observer(() => {
  const colorsMap = {
    Ddl: integerToHexColor(DDL_WALL_SEGMENT_COLOR),
    Grg: integerToHexColor(GRG_WALL_SEGMENT_COLOR),
    Int: integerToHexColor(INT_WALL_SEGMENT_COLOR),
    Plm: integerToHexColor(PLM_WALL_SEGMENT_COLOR),
    Ext: integerToHexColor(EXT_WALL_SEGMENT_COLOR),
  };
  const size: Size = useWindowSize();
  const height = size.height ? size.height - 503 : "auto";

  const result = appModel.validationResult as IWallTypesValidationResult;

  useEffect(() => {
    appModel.setActiveValidationMode(ValidationMode.ArcWallTypes);

    return () => {
      appModel.setActiveValidationMode(ValidationMode.None);
    };
  }, []);

  if (!result?.presentSegmentTypes) {
    return;
  }

  const items: ValidationItemProps[] = result?.presentSegmentTypes.map(st => ({
    color: colorsMap[st],
    text: wallTypesLegendItems[st],
    type: ValidationItemType.Segment,
  }));

  return <ValidationTab items={items} height={height} />;
});

export default WallTypesTab;
