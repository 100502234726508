import { observer } from "mobx-react-lite";
import { useCallback, useMemo, useState, useEffect } from "react";
import { Typography, Modal, Table, Tooltip, Button } from "antd";
import { CheckCircleTwoTone, CloseCircleTwoTone, InfoCircleOutlined } from "@ant-design/icons";
import { RcFile, UploadChangeParam, UploadFile } from "antd/lib/upload";
import { DTOConvertOptions, entityManager } from "../../../entities/entityManager";
import { appModel } from "../../../models/AppModel";
import { RoomType } from "../../../models/RoomType";
import { RoomCategory } from "../../../models/RoomCategory";
import { kindRoomTypeRaw, markPublic } from "../../../services/allKinds";
import { showToastMessage } from "../../../helpers/messages";
import { revitFiles as revitFilesModel } from "../../../entities/revitFiles";
import { UploadingRevitFileListState, isEmptyUploadFilesList, isUploadFilesListContainsValidValues, isRevitFileName } from "../../../entities/revitFiles/utils";
import ProgressIndicator from "../common/ProgressIndicator";
import RevitFilesUploadProgressHOC from "./ModalSystemSettings/RevitFilesUploadProgressHOC";
import "./ModalBulkRoomUpload.sass";

interface FolderState {
  roomTypeName: string;
  files: RcFile[];
  category: string;
  uploadStatus: boolean | null;
  error: string;
  isUploadProcessing?: boolean;
  revitFilesItem?: {
    files: UploadFile[];
  };
}

interface IModalBulkRoomUploadProps {
  isOpen: boolean;
  handleClose: () => void;
  folders: Map<string, UploadFile[]>;
  revitFiles: UploadFile[];
}

const { confirm } = Modal;

async function getStateData(uploadedFolder: Map<string, UploadFile[]>): Promise<FolderState[]> {
  if (!uploadedFolder) {
    return [];
  }

  const data = await Promise.all(
    [...uploadedFolder.entries()].map(async uf => {
      const json = uf[1].find(f => f.name.endsWith(".json"));
      let category: string;
      let roomTypeName: string;
      let error: string;
      if (json) {
        const parsedJson = JSON.parse(await json.originFileObj.text());
        category = parsedJson.category;
        roomTypeName = parsedJson.name;
        if (!category) {
          error = "Missing category property in .json file";
        }
      } else {
        error = "Missing .json file";
      }
      return {
        roomTypeName: roomTypeName || uf[0],
        files: uf[1],
        category,
        uploadStatus: error ? false : null,
        error,
        isUploadProcessing: false,
      } as FolderState;
    })
  );
  return data;
}

async function uploadRoomType(folder: FolderState): Promise<void> {
  const saveOptions: DTOConvertOptions = {
    scope: "full",
    mode: "new",
  };

  const newRoomType = new RoomType(folder.roomTypeName);
  const roomTypeCategory = appModel.roomCategories.find(rc => rc.name === folder.category);
  newRoomType._uploadFiles = folder.files;

  folder.isUploadProcessing = true;

  try {
    if (roomTypeCategory) {
      newRoomType.roomCategoryId = roomTypeCategory.id;
      await entityManager.save(newRoomType, "APIdata", saveOptions, true);
    } else {
      const newCategory = new RoomCategory(folder.category);
      newCategory.forKind = kindRoomTypeRaw.id;
      const { result: resultCategory } = await entityManager.save(newCategory, "APIdata", saveOptions, true);

      newRoomType.roomCategoryId = resultCategory.id;
      await entityManager.save(newRoomType, "APIdata", saveOptions, true);
    }
    folder.uploadStatus = true;
  } catch (err) {
    folder.uploadStatus = false;
    folder.error = err.message || err;
  } finally {
    folder.isUploadProcessing = false;
  }
}

async function uploadRevitFiles(folder: FolderState): Promise<void> {
  try {
    if (!folder.revitFilesItem) {
      throw new Error("Lack of Revit files");
    }

    if (folder.uploadStatus === false) {
      return;
    }

    folder.isUploadProcessing = true;

    const res = await revitFilesModel.uploadFiles(folder.revitFilesItem.files);
    folder.uploadStatus = res;
  } catch (err) {
    folder.uploadStatus = false;
    folder.error = err.message || err;
  } finally {
    folder.isUploadProcessing = false;
  }
}

let ModalBulkRoomUpload = ({ isOpen, handleClose, folders, revitFiles }: IModalBulkRoomUploadProps) => {
  const [open, setOpen] = useState(isOpen);
  const [isProcessing, setIsProcessing] = useState<boolean | null>(null);
  const [folderState, setFolderState] = useState<FolderState[]>([]);
  const [isRevitFilesExists, setIsRevitFilesExists] = useState<boolean>(() => revitFilesModel.isFilesExists);

  useEffect(() => {
    setIsRevitFilesExists(revitFilesModel.isFilesExists);
  }, [revitFiles]);

  const roomCategories = appModel.roomCategories;

  const revitFilesListState = useMemo(() => {
    if (isEmptyUploadFilesList(revitFiles)) {
      return UploadingRevitFileListState.Empty;
    } else if (isUploadFilesListContainsValidValues(revitFiles)) {
      return UploadingRevitFileListState.Valid;
    } else {
      return UploadingRevitFileListState.Invalid;
    }
  }, [revitFiles]);

  const RevitFilesSection = useMemo(() => {
    if (revitFilesListState === UploadingRevitFileListState.Valid) {
      return (
        <Typography.Paragraph strong>DEngine catalog files detected. {isRevitFilesExists && "Already used files will be overwritten."}</Typography.Paragraph>
      );
    } else if (revitFilesListState === UploadingRevitFileListState.Invalid) {
      return (
        <Typography.Paragraph className="warning-message">
          {revitFiles.length} .rvt DEngine catalog file(s) detected. You should upload 3 .rvt files simultaneously. Their names should match the schema:
          <br />- <strong>&lt;filename&gt;.rvt</strong> - for catalog file
          <br />- <strong>&lt;filename&gt;.Settings.rvt</strong> - for settings file
          <br />- <strong>&lt;filename&gt;.Template.rvt</strong> - for template file
        </Typography.Paragraph>
      );
    } else {
      return null;
    }
  }, [isRevitFilesExists, revitFilesListState, revitFiles]);

  const roomTypesAmount = useMemo(() => {
    return folderState.filter(f => !f.revitFilesItem).length;
  }, [folderState]);

  useEffect(() => {
    setOpen(isOpen);
    updateFoldersState();

    async function updateFoldersState() {
      let finalFolderState: FolderState[];
      const folderState = await getStateData(folders);

      if (revitFilesListState !== UploadingRevitFileListState.Empty) {
        // Inject Revit files entry
        finalFolderState = [
          {
            roomTypeName: "DEngine catalog files",
            files: [],
            category: "Catalog and Settings",
            uploadStatus: revitFilesListState === UploadingRevitFileListState.Invalid ? false : null,
            error: "",
            isUploadProcessing: false,
            revitFilesItem: {
              files: revitFiles,
            },
          },
          ...folderState,
        ];
      } else {
        finalFolderState = folderState;
      }

      setFolderState(finalFolderState);
    }
  }, [isOpen, folders, isRevitFilesExists, revitFilesListState, revitFiles]);

  const uploadData = useCallback(async () => {
    setIsProcessing(true);

    for (const folder of folderState) {
      if (folder.error) {
        continue;
      }

      if (folder.revitFilesItem) {
        await uploadRevitFiles(folder);
      } else {
        await uploadRoomType(folder);
      }

      setFolderState([...folderState]);
    }

    const { revitFiles, roomTypesWORevitFiles } = folderState.reduce(
      (acc, f) => {
        if (f.revitFilesItem) {
          acc.revitFiles.push(f);
        } else {
          acc.roomTypesWORevitFiles.push(f);
        }
        return acc;
      },
      { revitFiles: [], roomTypesWORevitFiles: [] }
    );

    const uploadedSuccessfully = roomTypesWORevitFiles.filter(f => f.uploadStatus === true).length;
    const uploadFailed = roomTypesWORevitFiles.filter(f => f.uploadStatus === false).length;

    if (uploadedSuccessfully) {
      showToastMessage("Success", `Successfully uploaded ${uploadedSuccessfully} room types.`);
    }

    if (uploadFailed) {
      showToastMessage("Error", `Upload failed for ${uploadFailed} room types.`);
    }

    if (revitFiles[0] && revitFiles[0].uploadStatus) {
      showToastMessage("Success", `DEngine catalog files have been uploaded.`);
    }

    setIsProcessing(false);
  }, [folderState]);

  const onUploadClick = useCallback(async () => {
    const shouldReplaceRevitFiles = isRevitFilesExists && revitFilesListState === UploadingRevitFileListState.Valid;
    const shouldReplaceRooms = folderState.some(fs => {
      roomCategories.find(rc => rc.name === fs.category)?.roomTypes.some(rt => rt.name === fs.roomTypeName && rt.mark === markPublic.id);
    });

    const revitFilesPrompt = shouldReplaceRevitFiles ? "DEngine catalog files will be replaced." : "";
    const roomsPrompt = shouldReplaceRooms ? "Some rooms will be replaced." : "";
    const Prompt = (
      <>
        <strong>
          {roomsPrompt} {revitFilesPrompt}
        </strong>
        <br />
        Are you sure you want to continue
      </>
    );

    if (shouldReplaceRooms || shouldReplaceRevitFiles) {
      confirm({
        title: Prompt,
        okText: "OK",
        okType: "primary",
        cancelText: "Cancel",
        onOk: () => {
          // Upload files after dialog will be closed
          setTimeout(uploadData, 0);
        },
      });
    } else {
      await uploadData();
    }
  }, [folderState, roomCategories, isRevitFilesExists, revitFilesListState, uploadData]);

  // Do not display any layout if modal hidden
  if (!isOpen) {
    return <></>;
  }

  const onCancelClick = () => {
    setIsProcessing(null);
    handleClose();
  };

  const columns = [
    {
      title: "Index",
      key: "index",
      width: "70px",
      render: (value, item, index: number) => index + 1,
    },
    {
      title: "Room Type Name",
      dataIndex: "roomTypeName",
      key: "roomTypeName",
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
    },
    {
      title: "Upload Result",
      dataIndex: "uploadStatus",
      key: "uploadStatus",
      align: "center" as const,

      render: (isSuccessful: boolean, item: FolderState) => {
        if (item.isUploadProcessing) {
          return item.revitFilesItem ? <RevitFilesUploadProgressHOC /> : <ProgressIndicator />;
        } else if (isSuccessful === true) {
          return <CheckCircleTwoTone twoToneColor="#03CB6A" className="upload-state-icon" />;
        } else if (isSuccessful === false) {
          return (
            <>
              <CloseCircleTwoTone twoToneColor="#E04242" className="upload-state-icon" />
              {item.error && (
                <Tooltip title={item.error}>
                  <InfoCircleOutlined className="upload-state-icon info" />
                </Tooltip>
              )}
            </>
          );
        } else {
          return;
        }
      },
    },
  ];

  return (
    <>
      <Modal
        data-testid="bulk-room-type-upload-modal"
        className="modal-add-rooms"
        open={open}
        confirmLoading={isProcessing}
        maskClosable={!isProcessing}
        footer={[
          <Button key="close" onClick={onCancelClick}>
            {isProcessing === null || isProcessing === true ? "Cancel" : "Close"}
          </Button>,
          ...(isProcessing === null
            ? [
                <Button key="upload" type="primary" onClick={onUploadClick}>
                  Upload
                </Button>,
              ]
            : []),
        ]}
      >
        <Typography.Title level={3}>Room Type Creation</Typography.Title>
        {roomTypesAmount > 0 && (
          <Typography.Paragraph key="description" strong>
            {roomTypesAmount} room types detected.
          </Typography.Paragraph>
        )}
        {RevitFilesSection}

        <Table dataSource={folderState} columns={columns} pagination={false} scroll={{ y: 400 }} rowKey={r => `${r.category}/${r.roomTypeName}`} />
      </Modal>
    </>
  );
};

ModalBulkRoomUpload = observer(ModalBulkRoomUpload);

export default ModalBulkRoomUpload;

export async function processUploadedFolders(
  info: UploadChangeParam<UploadFile<any>>,
  setIsReadingFolder: (state: boolean) => void,
  handleBulkUploadModalOpen: (uploadedCategoriesMap: Map<string, UploadFile<any>[]>, uploadedRevitFilesList: UploadFile<any>[]) => void
) {
  const PATH_SEPARATOR = "/";

  if (!info.fileList.length) {
    showToastMessage("Error", "Folder is empty.");
    return;
  }

  setIsReadingFolder(true);
  setTimeout(() => {
    // Separate Revit and Categories files
    const { revitFileList, categoriesFileList } = info.fileList.reduce(
      (acc, f) => {
        if (isRevitFileName(f.name)) {
          acc.revitFileList.push(f);
        } else {
          acc.categoriesFileList.push(f);
        }
        return acc;
      },
      { revitFileList: [], categoriesFileList: [] }
    );

    const isOnlyRevitFilesUploaded = revitFileList.length > 0 && categoriesFileList.length == 0;

    const categoriesFolderFileMap = new Map<string, UploadFile[]>();
    const isBulkUpload = categoriesFileList.some(file => file.originFileObj.webkitRelativePath.split(PATH_SEPARATOR).length > 2);

    if (isOnlyRevitFilesUploaded) {
      handleBulkUploadModalOpen(categoriesFolderFileMap, revitFileList);
    } else if (!isBulkUpload) {
      // Single Room upload case (possibly w/ Revit files)
      const folderName = categoriesFileList[0].originFileObj.webkitRelativePath.split(PATH_SEPARATOR)[0];
      categoriesFolderFileMap.set(folderName, categoriesFileList);
      handleBulkUploadModalOpen(categoriesFolderFileMap, revitFileList);
    } else {
      // Balk upload case (possibly w/ Revit files)
      for (let i = 0; i < categoriesFileList.length; i++) {
        const file = categoriesFileList[i];
        const filePath = file.originFileObj.webkitRelativePath.split(PATH_SEPARATOR);
        if (filePath.length !== 3) {
          continue;
        }
        const folderName = filePath[1];

        if (categoriesFolderFileMap.has(folderName)) {
          categoriesFolderFileMap.get(folderName).push(file);
        } else {
          categoriesFolderFileMap.set(folderName, [file]);
        }
      }

      handleBulkUploadModalOpen(categoriesFolderFileMap, revitFileList);
    }

    setIsReadingFolder(false);
  }, 5);
}
