import axios from "axios";
import log from "loglevel";
import { runInAction } from "mobx";
import { showToastMessage } from "../helpers/messages";
import { isEmpty } from "../helpers/pojo";
import { showAPIErrors } from "../helpers/showAPIErrors";
import { appModel } from "../models/AppModel";
import { Floor } from "../models/Floor";
import { CorePlan } from "../models/CorePlan";
import { RoomCategory } from "../models/RoomCategory";
import { RoomType, updateRoomTypeMark } from "../models/RoomType";
import { APIoptions, deleteSingle, handlerMessagesFromInfo, receiveList, saveSingle } from "../services";
import { kindCategoryRaw, kindCorePlanRaw, kindRoomTypeRaw, markDelete, markHidden } from "../services/allKinds";
import { getAxiosRequestConfig } from "../services/api/utilities";
import apiProvider from "../services/api/utilities/Provider";
import { UserDepot } from "../store/UserDepot";
import { openErrorFloorSave } from "../ui/components/Editor/ModalErrorFloorSave";
import { AfterRefinement, BeforeRefinement, afterSave, beforeSave } from "./em-refinements";
import { CorePlanStatistics } from "../editor/tools/StatisticsTools";

const LOADING_CORE_PLAN_ERROR_MESSAGE = "Error occurred when loading corePlan";
const LOCK_CORE_PLAN_ERROR_MESSAGE = "Error occurred when locking corePlan";
const UNLOCK_CORE_PLAN_ERROR_MESSAGE = "Error occurred when unlocking corePlan";
const CORE_PLAN_STATISTICS_ERROR_MESSAGE = "Error occurred when generating statistics";

export type EntityDataType = "FormData" | "EditorData" | "APIdata" | "APIdata.saved";

export type OptsCreate = {
  type: EntityDataType;
};

export type DTOConvertOptions = {
  scope: "full" | "single";
  mode?: "new" | "noUpdate";
  onlyProps?: string[];
  before?: BeforeRefinement[];
  after?: AfterRefinement[];
};

export interface PersistentObject<T, R> {
  toDTO: (format?: EntityDataType, options?: DTOConvertOptions) => R;
  fromDTO: (arg: any, format?: EntityDataType) => T;
}

function updateOptions(object: any, options?: DTOConvertOptions) {
  if (isEmpty(options?.mode)) return options;

  options = { ...options };

  if (options?.mode === "new") {
    options.before = options.before || [];
    options.after = options.after || [];
    if (object instanceof RoomType) {
      options.before.push("create:RoomEntities");
      options.after.push("updateRoomTypes");
    } else if (object instanceof RoomCategory) {
      options.after.push("addCategory");
    }
  }

  return options;
}

class EntityManager {
  public async save<T extends PersistentObject<any, any>>(
    object: T,
    format?: EntityDataType,
    options?: DTOConvertOptions,
    throwError: boolean = false
  ): Promise<{ result: T; notFound: boolean }> {
    try {
      options = updateOptions(object, options);

      await beforeSave(object, options?.before);

      const extraReturn: APIoptions = {};

      // TODO: put options.scope to VAPI
      const { result, notFound } = await saveSingle(object.toDTO(format, options), extraReturn, !throwError);

      if (isEmpty(result?.kind)) {
        log.error("entityManager.save", object, format, options, result);
        if (notFound) {
          return { result: null, notFound };
        }
        if (throwError) {
          throw "Unexpected error on entity creation.";
        } else {
          showAPIErrors({ source: "entityManager.save", message: "Object wasn't saved." });
        }
        return { result: null, notFound };
      }

      if (extraReturn.data?.length) {
        updateRoomTypeMark(extraReturn.data);
      }

      if (options?.mode !== "noUpdate") {
        await afterSave(object, result, options?.after);
      }

      return { result: object, notFound };
    } catch (error) {
      log.error(error);
      if (throwError) {
        throw error;
      } else {
        showToastMessage("Error", error);
      }

      return { result: null, notFound: false };
    }
  }

  public async pullList<T extends PersistentObject<any, any>>(f: new () => T, opts?: any): Promise<T[]> {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const kind: any = f.kind;

    if (!kind) throw "Constructor must have field: kind";

    const result = await receiveList({ opts, kind } as any);
    return result.map(el => {
      const object = new f();
      return object.fromDTO(el, "APIdata");
    });
  }

  public savePartially<T extends PersistentObject<any, any>>(entity: T, properties: string[]): void {
    entityManager.save(entity, "APIdata.saved", { mode: "noUpdate", scope: "single", onlyProps: properties });
  }

  public async getCorePlan(id: string): Promise<CorePlan> {
    try {
      const envelop = await apiProvider.getSingle("core_plans", id);
      if (!envelop?.data?.[0]) {
        throw new Error("CorePlan not loaded: " + JSON.stringify(envelop));
      }

      return new CorePlan().fromDTO(envelop.data[0], "APIdata");
    } catch (e) {
      showToastMessage("Error", LOADING_CORE_PLAN_ERROR_MESSAGE);
      log.error(e);
      return null;
    }
  }

  public saveCorePlan(corePlan: CorePlan, showMessage: boolean): Promise<{ result: CorePlan; notFound: boolean }> {
    const saveMode: DTOConvertOptions = { scope: "full", mode: "noUpdate" };
    return entityManager.save(corePlan, "APIdata", saveMode, true).then(
      savedCorePlan => {
        if (savedCorePlan.result && showMessage) {
          showToastMessage("Success", `CorePlan '${corePlan.name}' was successfully saved`);
        }
        if (savedCorePlan.notFound) {
          appModel.removeCorePlan(corePlan.id);
          showToastMessage("Error", `CorePlan '${corePlan.name}' could not be saved. May have been deleted previously.`);
          return { result: null, notFound: true };
        }
        return { result: savedCorePlan.result, notFound: savedCorePlan.notFound };
      },
      error => {
        openErrorFloorSave(corePlan, error);
        return { result: null, notFound: false };
      }
    );
  }

  public saveFloor(floor: Floor, showMessage: boolean): Promise<{ result: Floor; notFound: boolean }> {
    const saveMode: DTOConvertOptions = { scope: "full", mode: "noUpdate" };
    return entityManager.save(floor, "APIdata", saveMode, true).then(
      savedFloor => {
        if (savedFloor.result && showMessage) {
          showToastMessage("Success", `'${floor.name}' was successfully saved`);
        }
        if (savedFloor.notFound) {
          const corePlan = appModel.corePlans.find(p => p.id === floor.corePlanId);
          appModel.removeCorePlan(floor.corePlanId);
          showToastMessage("Error", `Floor could not be saved. CorePlan '${corePlan?.name}' may have been deleted previously.`);
          return { result: null, notFound: true };
        }
        return { result: savedFloor.result, notFound: savedFloor.notFound };
      },
      error => {
        openErrorFloorSave(floor, error);
        return null;
      }
    );
  }

  public async delete(object: any, format?: EntityDataType, options?: DTOConvertOptions): Promise<PersistentObject<any, any>> {
    const rawData = object.toDTO?.(format, options) || object;
    rawData.mark = markDelete.id;
    const result = await deleteSingle(rawData);
    if (isEmpty(result) || !result.kind) {
      showAPIErrors({ source: "entityManager.delete", message: "Object wasn't deleted." });
      log.error("entityManager.delete", result);
      return object;
    }
    runInAction(() => {
      object.mark = result.mark;
    });
    if (result.kind === kindRoomTypeRaw.id) {
      if (object.mark === markDelete.id) {
        appModel.removeFromRoomCategory(object.id, object.roomCategoryId);
      } else if (object.mark === markHidden.id) {
        appModel.markRoomTypeHidden(object.id);
      }
    } else if (result.kind === kindCorePlanRaw.id) {
      appModel.removeCorePlan(object.id);
      (result as any).deletedRoomTypeIds?.forEach((rtId: string) => {
        appModel.removeFromRoomCategory(rtId);
      });
    }
    return object;
  }

  public async clearCatalog(): Promise<void> {
    const envelope = await apiProvider.clearCatalog();
    handlerMessagesFromInfo(envelope);
    const data = envelope.data;
    data.forEach(item => {
      if (item.kind === kindRoomTypeRaw.id) {
        if (item.mark === markDelete.id) {
          appModel.removeFromRoomCategory(item.id, item.categoryId);
        } else if (item.mark === markHidden.id) {
          appModel.markRoomTypeHidden(item.id);
        }
      } else if (item.kind === kindCategoryRaw.id) {
        appModel.removeRoomCategory(item.id);
      }
    });

    return envelope;
  }

  public async lockCorePlan(corePlan: CorePlan): Promise<boolean> {
    try {
      await axios.put(`${apiProvider.host}corePlans/${corePlan.id}/lock`, {}, getAxiosRequestConfig());
      corePlan.setLockedBy(UserDepot.userIdentity);
      return true;
    } catch (e) {
      showToastMessage("Error", LOCK_CORE_PLAN_ERROR_MESSAGE);
      log.error(e);
    }

    return false;
  }

  public async unlockCorePlan(corePlan: CorePlan, showMessage: boolean = true): Promise<void> {
    try {
      await axios.put(`${apiProvider.host}corePlans/${corePlan.id}/unlock`, {}, getAxiosRequestConfig());

      if (showMessage) {
        showToastMessage("Success", `CorePlan '${corePlan.name}' was successfully unlocked`);
      }

      corePlan.setLockedBy(null);
    } catch (e) {
      showToastMessage("Error", UNLOCK_CORE_PLAN_ERROR_MESSAGE);
      log.error(e);
    }
  }

  public async getCorePlansStatistics(months: number): Promise<CorePlanStatistics | null> {
    try {
      const config = getAxiosRequestConfig();
      config.params = { months };
      const envelop = await axios.get(`${apiProvider.host}corePlans/statistics`, config);
      if (!envelop.data) {
        throw new Error("CorePlans statistics not provided: " + JSON.stringify(envelop));
      }

      return envelop.data as unknown as CorePlanStatistics;
    } catch (e) {
      showToastMessage("Error", CORE_PLAN_STATISTICS_ERROR_MESSAGE);
      log.error(e);
      return null;
    }
  }
}

export const entityManager = new EntityManager();
