export class UserIdentity {
  public id: string;
  public email: string;

  constructor(id: string = "", email: string = "") {
    this.id = id;
    this.email = email;
  }

  public static fromJs(data: any): UserIdentity {
    const user = new UserIdentity();
    user.id = data.id;
    user.email = data.email;

    return user;
  }
}
