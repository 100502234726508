import { Menu } from "antd";
import { runInAction } from "mobx";
import { appModel } from "../../../../models/AppModel";

export const DIMENSION_CORE_FACE = "Core Face based";
export const DIMENSION_FINISH_FACE = "Finish Face based";

const DimensionTypeSetting = () => {
  let keyIndex = 1;

  return (
    <Menu
      items={[
        {
          label: DIMENSION_CORE_FACE,
          key: keyIndex++,
          onClick: () => {
            runInAction(() => {
              appModel.setDimensionType(false);
            });
          },
        },
        {
          label: DIMENSION_FINISH_FACE,
          key: keyIndex++,
          onClick: () => {
            appModel.setDimensionType(true);
          },
        },
      ]}
    />
  );
};

export default DimensionTypeSetting;
