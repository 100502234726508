export enum CORE_PLAN_STATUS {
  ALL = "all",
  REQUESTS = "requests",
  TWO_D = "2d",
  THREE_D = "3d",
}

export enum SORT_ORDER {
  ASC = "asc",
  DESC = "desc",
}

export interface CorePlansFilterBase {
  searchBy?: string;
  sortBy?: string;
  order?: SORT_ORDER;
}

export interface CorePlansFilterExtension {
  status?: CORE_PLAN_STATUS;
  floorsNumber?: number;
  bedroomsNumber?: number;
  bathroomsNumber?: number;
  carSpacesNumber?: number;
  lotWidthFeet?: number;
}

export type CorePlansFilterInfo = CorePlansFilterBase & CorePlansFilterExtension;

export const initFilters = {
  status: CORE_PLAN_STATUS.ALL,
  searchBy: "",
  sortBy: "updatedAt",
  order: SORT_ORDER.DESC,
  floorsNumber: 0,
  bedroomsNumber: 0,
  bathroomsNumber: null,
  carSpacesNumber: null,
  lotWidthFeet: null,
};
