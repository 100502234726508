import * as THREE from "three";
import { SoPreviewFace } from "./SoPreviewFace";
import { SoPreviewRoom } from "./SoPreviewRoom";

export class SoPreviewWall extends SoPreviewFace {
  constructor(
    index: number,
    direction: string,
    soRoom: SoPreviewRoom,
    width: number,
    height: number,
    position: THREE.Vector3,
    rotation: THREE.Euler,
    material: THREE.MeshStandardMaterial,
    isIndoor: boolean
  ) {
    const geometry = new THREE.PlaneGeometry(width, height);
    super(geometry, material, index, direction, soRoom, width, height, position, rotation, isIndoor);
  }

  public updateExteriorFinish(exteriorFinishIndex: number): void {
    if (this.direction == "bottom" || this.direction == "top") return;
    this.exteriorFinishIndex = exteriorFinishIndex;
    const color = this.finish.colorRGB;
    this.updateMaterial(new THREE.Color(color.r / 255, color.g / 255, color.b / 255));
  }
}
