import "./ModalVariationPackageWindow.sass";
import { makeAutoObservable } from "mobx";
import { observer } from "mobx-react-lite";
import { CorePlan } from "../../../models/CorePlan";
import { Button, Modal } from "antd";
import VariationDetails from "./VariationDetails/VariationDetails";
import { LeftOutlined } from "@ant-design/icons";
import { openModalCorePlanPackageWindow } from "./ModalCorePlanPackageWindow";
import { CorePlanPackageDetailTabs } from "./CorePlanDetails/CorePlanPackageDetails";
import { Variation } from "../../../models/Variation";

export const openModalVariationPackageWindow = (corePlan: CorePlan | null, variation: Variation | null, onSuccess?: (corePlan: CorePlan) => void) => {
  if (modalVariationPackageWindowState.isOpen) {
    return;
  }
  modalVariationPackageWindowState.openVariationPackageWindow(corePlan, variation, onSuccess);
};

export const closeModalVariationPackageWindow = () => {
  modalVariationPackageWindowState.closeVariationPackageWindow();
};

export const getIsModalVariationPackageWindowOpen = () => {
  return modalVariationPackageWindowState.isOpen;
};

export type ContentModalVariationPackageWindowProps = {
  corePlan: CorePlan;
  variation: Variation;
  onModalOk: () => void;
  onModalCancel: () => void;
};

interface ModalVariationPackageWindowProps {
  isOpen?: boolean;
  corePlan?: CorePlan;
  variation?: Variation;
}

class ModalVariationPackageWindowState implements ModalVariationPackageWindowProps {
  isOpen = false;
  corePlan?: CorePlan;
  variation?: Variation;
  onSuccess?: (corePlan: CorePlan) => void;

  constructor() {
    makeAutoObservable(this);
  }

  openVariationPackageWindow(corePlan: CorePlan, variation: Variation, onSuccess?: (corePlan: CorePlan) => void) {
    this.isOpen = true;
    this.corePlan = corePlan;
    this.variation = variation;
    this.onSuccess = onSuccess;
  }

  closeVariationPackageWindow() {
    this.isOpen = false;
    this.corePlan = undefined;
    this.variation = undefined;
    this.onSuccess = undefined;
  }
}

const modalVariationPackageWindowState = new ModalVariationPackageWindowState();

let ModalVariationPackageWindow = ({ isOpen, corePlan: corePlan, variation }: ModalVariationPackageWindowProps) => {
  if (isOpen) {
    modalVariationPackageWindowState.openVariationPackageWindow(corePlan, variation);
  }
  const isModalOpen = isOpen || modalVariationPackageWindowState.isOpen;

  if (!isModalOpen) return <></>;

  const handleBackButtonClick = (corePlan: CorePlan) => {
    closeModalVariationPackageWindow();
    openModalCorePlanPackageWindow(corePlan, CorePlanPackageDetailTabs.Variations);
  };

  const modalTitle = (
    <div style={{ display: "flex", alignItems: "center" }}>
      <button onClick={() => handleBackButtonClick(modalVariationPackageWindowState.corePlan)} className="back-button" style={{ marginRight: "16px" }}>
        <LeftOutlined />
      </button>
      {modalVariationPackageWindowState.corePlan?.name + " - " + modalVariationPackageWindowState.variation?.name}
    </div>
  );

  const onSuccess = (corePlan: CorePlan) => {
    modalVariationPackageWindowState.onSuccess?.(corePlan);
    closeModalVariationPackageWindow();
  };

  return (
    <Modal
      className={`modal-corePlan${" corePlan-details"}`}
      open={isModalOpen}
      onCancel={closeModalVariationPackageWindow}
      onOk={closeModalVariationPackageWindow}
      title={modalTitle}
      footer={[]}
      destroyOnClose
      transitionName={null} // Disable modal transition animation
      maskTransitionName={null} // Disable mask transition animation
    >
      {<VariationDetails corePlan={modalVariationPackageWindowState.corePlan} variation={modalVariationPackageWindowState.variation} />}
    </Modal>
  );
};

ModalVariationPackageWindow = observer(ModalVariationPackageWindow);
export default ModalVariationPackageWindow;
