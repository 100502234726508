import { Col, Row } from "antd";
import { observer } from "mobx-react-lite";
import { FileEntity } from "../../../../models/FileEntity";
import { CorePlan } from "../../../../models/CorePlan";
import FileItem from "./FileItem";
import { RenderJobStatus, TRenderJob } from "../../../../entities/renderJob/types";
import RenderJobItem from "./JobItem";
import { FolderOptions } from "../../Editor/LeftBar/LeftBar";
import "./TabVariationFiles.sass";
import { markPublic } from "../../../../services/allKinds";
import { Variation } from "../../../../models/Variation";

interface ITabVariationFilesProps {
  corePlan: CorePlan;
  variation: Variation;
  folderName: string;
  files: FileEntity[];
  removeFile: (fileId: string, folderName: string) => void;
  jobs?: TRenderJob[];
  removeJob?: (jobId: string) => void;
}

const TabVariationFiles = observer(({ corePlan: corePlan, variation, folderName, files = [], jobs = [], removeJob, removeFile }: ITabVariationFilesProps) => {
  const isJob = (item: FileEntity | TRenderJob): item is TRenderJob => "status" in item;
  const getTime = (item: FileEntity | TRenderJob): number => (isJob(item) ? item.updatedAt.getTime() : Number.parseInt(item.lastModified));

  const allItems = [...files.filter(f => f.mark === markPublic.id), ...jobs].sort((a, b) => getTime(b) - getTime(a));

  return (
    <Row gutter={24} className="tab-corePlan-files">
      {!allItems.length && (
        <Col span={6}>
          <p>no files</p>
        </Col>
      )}
      {allItems.map((item, index) =>
        isJob(item) ? (
          <RenderJobItem variationId={variation.id} renderJob={item} removeJob={removeJob} key={index} />
        ) : (
          <FileItem
            file={item}
            corePlan={corePlan}
            variation={variation}
            isAttachedFile={folderName === FolderOptions.CorePlanFolder}
            removeFile={removeFile}
            key={index}
            folderName={folderName}
          />
        )
      )}
    </Row>
  );
});
export default TabVariationFiles;
