import { APIoptions } from "..";
import { asArray, DTORecord, isEmpty } from "../../helpers/pojo";
import { Envelope } from "../Envelope";
import apiProvider from "./utilities/Provider";
import { sendWithFiles } from "./utilities/sendWithFiles";

export async function takeCollection(resource: string, opts?: APIoptions): Promise<Envelope> {
  return await apiProvider.getAll(resource);
}

export async function sendSingle(dto: DTORecord, opts: APIoptions): Promise<Envelope> {
  if (!isEmpty(dto._uploadFiles)) return sendWithFiles(dto, opts);

  const envelope: Envelope = {
    meta: { v: 1 },
    data: asArray(dto),
  };
  // TODO: put options.scope to VAPI
  const answer = apiProvider.post(opts.url, envelope);

  return answer;
}
