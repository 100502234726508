import { observer } from "mobx-react";
import "./ModellingIssues.sass";
import { makeAutoObservable } from "mobx";

export type TModellingIssuesState = {
  hasPrimaryErrors?: boolean;
  hasSecondaryErrors?: boolean;
};

class ModellingIssuesState {
  public hasPrimaryErrors = false;
  public hasSecondaryErrors = false;

  constructor() {
    makeAutoObservable(this);
  }

  public setValue(state: TModellingIssuesState): void {
    this.hasPrimaryErrors = state.hasPrimaryErrors ?? false;
    this.hasSecondaryErrors = state.hasSecondaryErrors ?? false;
  }
}

export const modellingIssuesState = new ModellingIssuesState();

const ModellingIssues = observer(() => {
  const primaryErrors = modellingIssuesState.hasPrimaryErrors;
  const secondaryErrors = modellingIssuesState.hasSecondaryErrors;

  if (!primaryErrors && !secondaryErrors) {
    return null;
  }

  return (
    <div className="modelling-issues-error-container">
      <span className={`error-message ${primaryErrors ? "" : "secondary"}`}>Modeling issues found</span>
      <div className={`error-ring ${!primaryErrors && secondaryErrors ? "secondary" : ""}`}>
        {primaryErrors && secondaryErrors && <div className="error-inner-ring" />}
      </div>
    </div>
  );
});

export default ModellingIssues;
