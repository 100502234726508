import React from "react";
import { Col, Radio, RadioChangeEvent } from "antd";
import "./AttributeSelector.sass";
interface Props {
  label: string;
  name: string;
  iconClass: string;
  formData: number | string;
  handleRadioAttributeChange: (e: RadioChangeEvent) => void;
  options: (number | string)[];
}

const AttributeSelector: React.FC<Props> = ({ label, name, iconClass, formData, handleRadioAttributeChange, options }) => {
  const Icon = ({ name }) => {
    return <img className="icon" src={`/assets/icons/attributes/${name}.svg`} alt={`${name} icon`} />;
  };
  return (
    <div className="attribute-selector">
      <div className="form-item-label label-custom">
        <Icon name={iconClass} />
        <label>{label}</label>
      </div>
      <Radio.Group name={name} className="radio-group" value={formData} onChange={handleRadioAttributeChange}>
        {options.map(option => (
          <Radio.Button key={option} value={option} data-testid={`${label}${option}`}>
            {option}
          </Radio.Button>
        ))}
      </Radio.Group>
    </div>
  );
};

export default AttributeSelector;
