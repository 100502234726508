import log from "loglevel";
import { toast, Id, ToastOptions } from "react-toastify";

export const MessageKindsEnum = {
  Message: 10,
  Success: 11,
  Info: 12,
  Warning: 14,
  Error: 16,
  Server: 18,
  FATAL: 19,
};

export const ErrorKindsEnum = {
  Error: 16,
  Server: 18,
  FATAL: 19,
};

type MessageType = keyof typeof MessageKindsEnum | number;

export function showToastMessage(msgType: MessageType, message: string, options?: ToastOptions): Id {
  if (typeof msgType === "number") {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    msgType = Object.entries(MessageKindsEnum).find(pair => pair[1] == msgType)?.[0] || "Message";
  }

  if (msgType === "Server") {
    log.error("Server", message);
    return 0;
  }

  options = Object.assign({ theme: "dark" }, options || {});

  // TODO : Reinstate the warning and error messages once they have been refined.
  switch (msgType) {
    case "Info":
      return toast.info(message, options);
    case "Success":
      return toast.success(message, options);
    case "Warning":
      return 0;
    //return toast.warning(message, options);
    case "Error":
      return 0;
    //return toast.error(message, options);
    case "FATAL":
      return toast.error(message, options);
  }

  return toast.info(message, { autoClose: false, ...options });
}

export function hideToastMessage(id?: Id) {
  toast.dismiss(id);
}
