import { Modal } from "antd";
import { appModel } from "../models/AppModel";
import React from "react";
import { SceneEditorMode } from "../editor/models/SceneEditorMode";

/**
 * A function that handles pending changes in the app by displaying a confirmation modal to the user.
 * @returns A Promise that resolves to a boolean value indicating whether the user confirmed to proceed or not.
 */
export const handlePendingChanges = (): Promise<boolean> => {
  return new Promise(resolve => {
    if (!appModel.pendingChanges) {
      resolve(true);
      return;
    }

    let editorMode = "";
    switch (appModel.sceneEditorMode) {
      case SceneEditorMode.LotLine: {
        editorMode = "Lot line";
        break;
      }
      case SceneEditorMode.Background: {
        editorMode = "Background";
        break;
      }
    }

    Modal.confirm({
      title: React.createElement(React.Fragment, null, `Pending ${editorMode} changes will be applied.`, React.createElement("br"), "Do you want to proceed?"),
      okText: "Proceed",
      cancelText: "Cancel",
      onOk: () => {
        appModel.setPendingChanges(false);
        resolve(true);
      },
      onCancel: () => resolve(false),
    });
  });
};
