import { LayerType, RevitWall, Wall, WallType } from "./types";

export const parseRevitWalls = (revitWalls: RevitWall[]): Record<WallType, Wall> => {
  const result = {} as Record<WallType, Wall>;

  revitWalls.forEach(revitWall => {
    const layersThicknesses = {
      [LayerType.EXTERIOR]: 0,
      [LayerType.CORE]: 0,
      [LayerType.INTERIOR]: 0,
    };

    revitWall.layers.forEach(layer => {
      layersThicknesses[layer.type] += layer.thickness;
    });

    const halfCoreWidth = layersThicknesses[LayerType.CORE] / 2;

    result[revitWall.type] = {
      interiorThickness: layersThicknesses[LayerType.INTERIOR] + halfCoreWidth,
      exteriorThickness: layersThicknesses[LayerType.EXTERIOR] + halfCoreWidth,
      coreThickness: halfCoreWidth,
    };
  });

  return result;
};
