import log from "loglevel";
import { showToastMessage } from "../helpers/messages";
import { ExteriorFinish } from "../models/DesignStyle";
import { addAuthParam, getAxiosRequestConfig } from "../services/api/utilities";
import apiProvider from "../services/api/utilities/Provider";
import axiosClient from "../services/api/utilities/axios-wrapper";
import { UploadFile } from "antd";

const LIST_FINISHES_ERROR_MESSAGE = "Error occurred when fetching finishes";

class FinishesManager {
  public async listFinishes(): Promise<ExteriorFinish[]> {
    try {
      const response = await axiosClient.get(`${apiProvider.host}finishes`, getAxiosRequestConfig());

      return response.data.map(it => ExteriorFinish.fromJs(it));
    } catch (e) {
      log.error(e);
      showToastMessage("Error", LIST_FINISHES_ERROR_MESSAGE);
    }

    return [];
  }

  public async uploadFinishes(finishes: ExteriorFinish[], files: UploadFile[]): Promise<ExteriorFinish[]> {
    try {
      const payload = this.prepareFinishesForUpload(finishes, files);
      const response = await axiosClient.post(`${apiProvider.host}finishes`, payload, getAxiosRequestConfig());

      return response.data.filter(it => it.success).map(it => ExteriorFinish.fromJs(it.data));
    } catch (e) {
      log.error(e);
    }

    return [];
  }

  public async deleteFinish(id: string): Promise<boolean> {
    try {
      await axiosClient.delete(`${apiProvider.host}finishes/${id}`, getAxiosRequestConfig());

      return true;
    } catch (e) {
      log.error(e);
    }

    return false;
  }

  public getFinishTextureUrl(uri: string): string {
    const url = `${apiProvider.host}files/${encodeURIComponent(uri)}`;
    return addAuthParam(url);
  }

  private prepareFinishesForUpload(finishes: ExteriorFinish[], files: UploadFile[]) {
    const formData = new FormData();

    const envelope = {
      meta: { v: 1 },
      data: finishes,
    };

    formData.append("envelope", JSON.stringify(envelope));
    files.forEach((file, idx) => {
      formData.append(idx.toString(), file.originFileObj);
    });

    return formData;
  }
}

export const finishesManager = new FinishesManager();
