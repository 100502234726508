import { Button, Col, Collapse, Modal, Row, UploadFile } from "antd";
import log from "loglevel";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { finishesManager } from "../../../../../entities/finishesManager";
import { showToastMessage } from "../../../../../helpers/messages";
import { appModel } from "../../../../../models/AppModel";
import { ExteriorFinish } from "../../../../../models/DesignStyle";
import { VeUpload } from "../../../common/VeUpload";
import FinishesList from "./FinishesList/FinishesList";
import "./MaterialsSection.sass";

const FINISHES_FILES_ACCEPT = "application/json,image/*";
const FINISHES_LIST_FILE_NAME = "materials.json";

interface MaterialsUploadState {
  finishes?: ExteriorFinish[];
  textureFiles?: UploadFile[];
  error?: string;
  hasError?: boolean;
  isEmpty?: boolean;
}

const MaterialsSection = observer(() => {
  const [selectedFinishesIds, setSelectedFinishesIds] = useState<string[]>([]);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [materialsUploadState, setMaterialsUploadState] = useState<MaterialsUploadState>({ isEmpty: true });
  const [isUploadProcessing, setIsUploadProcessing] = useState<boolean>(false);

  const disableUploading = materialsUploadState.isEmpty || materialsUploadState.hasError;

  useEffect(() => {
    const getMaterialsUploadState = async (): Promise<MaterialsUploadState> => {
      if (fileList.length === 0) {
        return { isEmpty: true };
      }

      const finishesFile = fileList.find(file => file.name.toLocaleLowerCase() === FINISHES_LIST_FILE_NAME);
      if (!finishesFile) {
        return { hasError: true, error: "File with exterior finishes should be presented!" };
      }

      let finishes: ExteriorFinish[];

      try {
        const finishesJsonContent = JSON.parse(await finishesFile.originFileObj.text());
        finishes = finishesJsonContent.map(data => ExteriorFinish.fromJs(data));
      } catch (e) {
        log.error(e);
        return { hasError: true, error: "File with exterior finishes has invalid content!" };
      }

      const textureFiles = fileList.filter(file => finishes.some(finish => finish.image === file.name));

      if (textureFiles.length !== finishes.length) {
        return { hasError: true, error: "Some texture files for exterior finishes have not been selected!" };
      }

      return { finishes, textureFiles };
    };

    getMaterialsUploadState().then(setMaterialsUploadState);
  }, [fileList.length]);

  const onFinishSelected = (finish: ExteriorFinish): void => {
    const selectedIds = selectedFinishesIds.slice();
    const idx = selectedIds.indexOf(finish.id);

    if (idx !== -1) {
      selectedIds.splice(idx, 1);
    } else {
      selectedIds.push(finish.id);
    }

    setSelectedFinishesIds(selectedIds);
  };

  const onChangeUploadFileList = (fileList: UploadFile[], oldFileList?: UploadFile[]): UploadFile[] => {
    setFileList(fileList);
    return fileList;
  };

  const onUploadFinishesClick = async () => {
    if (materialsUploadState.isEmpty || materialsUploadState.hasError) {
      return;
    }

    setIsUploadProcessing(true);

    const uploadedFinishes = await finishesManager.uploadFinishes(materialsUploadState.finishes, materialsUploadState.textureFiles);
    uploadedFinishes.forEach(it => appModel.addFinish(it));

    if (uploadedFinishes.length === 0) {
      showToastMessage("Error", "An error occurred when uploading finishes!");
    } else {
      setFileList([]);

      if (uploadedFinishes.length < materialsUploadState.finishes.length) {
        showToastMessage("Warning", "Some finishes were not uploaded.");
      } else {
        showToastMessage("Success", "Finishes have been successfully uploaded.");
      }
    }

    setIsUploadProcessing(false);
  };

  const onDeleteFinishesClick = (e: React.MouseEvent) => {
    e.stopPropagation();

    if (selectedFinishesIds.length === 0) {
      showToastMessage("Error", "Select at least one finish.");
      return;
    }

    const deleteSelectedFinishes = async () => {
      let successCount = 0;
      for (const selectedFinishId of selectedFinishesIds) {
        const result = await finishesManager.deleteFinish(selectedFinishId);
        if (result) {
          successCount++;
          appModel.deleteFinish(selectedFinishId);
        }
      }

      if (successCount === 0) {
        showToastMessage("Error", "An error occurred when deleting finishes!");
      } else {
        setSelectedFinishesIds([]);

        if (successCount < selectedFinishesIds.length) {
          showToastMessage("Warning", "Some finishes were not deleted.");
        } else {
          showToastMessage("Success", "Finishes have been removed.");
        }
      }
    };

    Modal.confirm({
      title: `Are you sure you want to remove selected finishes?`,
      okText: "Yes",
      okType: "primary",
      cancelText: "No",
      onOk: deleteSelectedFinishes,
    });
  };

  const ActionButtons = (
    <div className="actions">
      <Button type="primary" onClick={onDeleteFinishesClick}>
        <i className="icon icon-trash" />
      </Button>
    </div>
  );

  return (
    <Collapse destroyInactivePanel>
      <Collapse.Panel header="Materials" key="MATERIALS_SECTION" className="materials-collapse-item" extra={ActionButtons}>
        <FinishesList finishes={appModel.finishes} selectedFinishesIds={selectedFinishesIds} onFinishSelected={onFinishSelected} />

        <Collapse>
          <Collapse.Panel header={<span className="subtitle">Upload finishes</span>} key="UPLOAD_MATERIALS_SECTION" className="upload-collapse-item">
            <Row>
              <Col span={12}>
                <VeUpload
                  accept={FINISHES_FILES_ACCEPT}
                  multiple={true}
                  fileList={fileList}
                  onChangeFileList={onChangeUploadFileList}
                  disabled={isUploadProcessing}
                  beforeUpload={() => false}
                >
                  <Button className="upload">Upload or Drag &amp; Drop Files</Button>
                </VeUpload>
              </Col>
              <Col span={1}></Col>
              <Col span={11}>
                <Button className="upload-button" onClick={onUploadFinishesClick} loading={isUploadProcessing} disabled={disableUploading}>
                  Upload
                </Button>
              </Col>
            </Row>
            {materialsUploadState.hasError && (
              <Row>
                <Col span={24} className="warning-message-wrapper">
                  <p className="warning-message">{materialsUploadState.error}</p>
                </Col>
              </Row>
            )}
          </Collapse.Panel>
        </Collapse>
      </Collapse.Panel>
    </Collapse>
  );
});

export default MaterialsSection;
