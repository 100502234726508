import { LotItemCommand } from "../../models/commands/LotItemCommand";
import LotLineManager from "../RoomManager/LotLineManager";
import { CommandManager } from "./CommandManager";

export class LotLineCommandManager extends CommandManager<LotLineManager, LotItemCommand> {
  public apply(command: LotItemCommand): void {
    super.apply(command);
    this.validate();
  }

  public undo(): LotItemCommand {
    const command = super.undo();
    if (command) {
      this.validate();
    }

    return command;
  }

  public redo(): LotItemCommand {
    const command = super.redo();
    if (command) {
      this.validate();
    }

    return command;
  }

  public validate(): void {
    // Recalculate Offset Lines to keep them in sync with Lot Lines
    this.manager.updateOffsetLines();
    this.manager.updateLotLineLabels();
  }
}
