import { observer } from "mobx-react";
import { appModel } from "../../../../../models/AppModel";
import { integerToHexColor, Size, useWindowSize } from "../../../../../helpers/utilities";
import { useEffect } from "react";
import { ValidationMode } from "../../../../../editor/models/ValidationType";
import { INTERSECTED_COLOR, MESSAGE_DURATION } from "../../../../../editor/consts";
import { IGravityLoadsValidationResult } from "../../../../../editor/models/ValidationResult";
import { MessageKindsEnum, showToastMessage } from "../../../../../helpers/messages";
import { settings } from "../../../../../entities/settings";
import ValidationTab from "./ValidationTab";
import { ValidationItemProps, ValidationItemType } from "./ValidationItem";
import TooltipCell from "./TooltipCell";
import { inches2feet } from "../../../../../helpers/measures";

export const gravityLoadsLegendItems = {
  Exterior: "Exterior Bearing walls (LB)",
  Stacked: "Stacked bearing walls (LB)",
  Non: "Non load bearing walls (NLB)",
  Beam: "Beams (B)",
  ImpossibleBeam: "Non possible Beam (NB)",
};

export const gravityLoadsErrors = {
  maxSpanCheckResult: "Max span validation check failed",
  maxCantileverCheckResult: "Max cantilever validation check failed",
  maxBeamLengthResult: "Some beams exceed max length",
};

export function getGravityLoadsLegendColors() {
  return {
    Exterior: settings.values.webAppUISettings.gravityExteriorLBColor,
    Stacked: settings.values.webAppUISettings.gravityStackedLBColor,
    Non: settings.values.webAppUISettings.gravityNonLBColor,
    Beam: settings.values.webAppUISettings.gravityBeamColor,
    ImpossibleBeam: settings.values.webAppUISettings.gravityBeamColor,
  };
}

const GravityLoadsTab = observer(() => {
  const colorsMap = getGravityLoadsLegendColors();
  const size: Size = useWindowSize();
  const height = size.height ? size.height - 503 : "auto";

  const result = appModel.validationResult as IGravityLoadsValidationResult;

  useEffect(() => {
    appModel.setActiveValidationMode(ValidationMode.StrGravityLoads);

    return () => {
      appModel.setActiveValidationMode(ValidationMode.None);
    };
  }, []);

  useEffect(() => {
    if (!result) {
      return;
    }

    if (result.maxSpanCheckResult === false) {
      showToastMessage(MessageKindsEnum.Error, `${appModel.activeFloor.name}: max span validation check failed`, {
        autoClose: MESSAGE_DURATION,
      });
    }

    if (result.maxCantileverCheckResult === false) {
      showToastMessage(MessageKindsEnum.Error, `${appModel.activeFloor.name}: max cantilever validation check failed`, {
        autoClose: MESSAGE_DURATION,
      });
    }
  }, [result]);

  if (!result?.legend?.percentages?.length) {
    return;
  }

  const items: ValidationItemProps[] = result?.presentSegmentTypes.map(st => ({
    color: colorsMap[st],
    text: gravityLoadsLegendItems[st],
    type: st === "ImpossibleBeam" ? ValidationItemType.DashedSegment : ValidationItemType.Segment,
  }));

  if (!result.maxSpanCheckResult) {
    items.push({
      color: integerToHexColor(INTERSECTED_COLOR),
      text: gravityLoadsErrors["maxSpanCheckResult"],
      type: ValidationItemType.Dot,
    });
  }

  if (!result.maxCantileverCheckResult) {
    items.push({
      color: integerToHexColor(INTERSECTED_COLOR),
      text: gravityLoadsErrors["maxCantileverCheckResult"],
      type: ValidationItemType.Dot,
    });
  }

  if (!result.maxBeamLengthResult) {
    items.push({
      color: integerToHexColor(INTERSECTED_COLOR),
      text: gravityLoadsErrors["maxBeamLengthResult"],
      type: ValidationItemType.Dot,
    });
  }

  const legend = (
    <table>
      <thead>
        <tr>
          {result.legend.percentages.map(p => (
            <th key={p.type} style={{ width: `${p.percent}%` }}>
              {p.type}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        <tr className="percentage-row">
          {result.legend.percentages.map(p => (
            <TooltipCell text={`${p.percent}%`} tooltip={`${p.percent}%`} color={colorsMap[p.type]} key={p.type} />
          ))}
        </tr>
        <tr className="total-row">
          <td colSpan={result.legend.percentages.length}>Total walls length = {inches2feet(result.legend.totalWallLength, 2)} ft</td>
        </tr>
      </tbody>
    </table>
  );

  return <ValidationTab items={items} height={height} legend={legend} />;
});

export default GravityLoadsTab;
