import { action, makeObservable, observable } from "mobx";
import { DTOConvertOptions, EntityDataType, PersistentObject } from "../entities/entityManager";
import { RawLennarCorePlanData } from "../services/rawTypes";
import { kindLennarCorePlanDataRaw } from "../services/allKinds";
import { deepCopy2DTO } from "../helpers/pojo";

export class LennarCorePlan implements PersistentObject<LennarCorePlan, RawLennarCorePlanData> {
  lennar_id: number = -1;
  name: string = "";
  series: string = "";
  state: string = "";
  width: number = 0;
  length?: number = 0;
  conditioned_area?: number = 0;
  net_area?: number = 0;
  gross_area?: number = 0;
  ceiling?: number = 0.0;
  story: number = 1;
  beds: number = 3;
  bathrooms: number = 2;
  half_bathrooms?: number = 1;
  baths: number = 2.0;
  garage: number = 1;
  full_name?: string = "";
  full_series?: string = "";

  constructor(name: string = "") {
    this.name = name;

    makeObservable(this, {
      name: observable,
      series: observable,
      state: observable,
      width: observable,
      length: observable,
      conditioned_area: observable,
      net_area: observable,
      gross_area: observable,
      ceiling: observable,
      story: observable,
      beds: observable,
      bathrooms: observable,
      half_bathrooms: observable,
      baths: observable,
      garage: observable,
      full_name: observable,
      full_series: observable,
      fromDTO: action,
    });
  }

  static readonly kind = kindLennarCorePlanDataRaw;

  toDTO(format?: EntityDataType, options?: DTOConvertOptions): RawLennarCorePlanData {
    return deepCopy2DTO(
      {
        kind: LennarCorePlan.kind.id,
        lennar_id: this.lennar_id,
        name: this.name,
        series: this.series,
        state: this.state,
        width: this.width,
        length: this.length,
        conditioned_area: this.conditioned_area,
        net_area: this.net_area,
        gross_area: this.gross_area,
        ceiling: this.ceiling,
        story: this.story,
        beds: this.beds,
        bathrooms: this.bathrooms,
        half_bathrooms: this.half_bathrooms,
        baths: this.baths,
        garage: this.garage,
        full_name: this.full_name,
        full_series: this.full_series,
      },
      { scope: options?.scope, only: options?.onlyProps }
    ) as unknown as RawLennarCorePlanData;
  }

  fromDTO(dto: RawLennarCorePlanData, format?: EntityDataType): LennarCorePlan {
    if (LennarCorePlan.kind.id != dto?.kind) throw "dto:RawLennarCorePlanData: incorrect kind";
    this.lennar_id = dto.lennar_id;
    this.name = dto.name;
    this.series = dto.series;
    this.state = dto.state;
    this.width = dto.width;
    this.length = dto.length;
    this.conditioned_area = dto.conditioned_area;
    this.net_area = dto.net_area;
    this.gross_area = dto.gross_area;
    this.ceiling = dto.ceiling;
    this.story = dto.story;
    this.beds = dto.beds;
    this.bathrooms = dto.bathrooms;
    this.half_bathrooms = dto.half_bathrooms;
    this.baths = dto.baths;
    this.garage = dto.garage;
    this.full_name = dto.full_name;
    this.full_series = dto.full_series;

    return this;
  }
}
