export const enum Keys {
  A = "KeyA",
  C = "KeyC",
  S = "KeyS",
  V = "KeyV",
  Y = "KeyY",
  Z = "KeyZ",
  U = "KeyU",
  I = "KeyI",
  Delete = "Delete",
  Esc = "Escape",
}
