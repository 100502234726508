import { observer } from "mobx-react-lite";
import { useCallback, useMemo, useState } from "react";
import { Collapse, Button, Modal, Row, Col, UploadFile } from "antd";

import { revitFiles } from "../../../../entities/revitFiles";
import { VeUpload } from "../../common/VeUpload";
import { showToastMessage } from "../../../../helpers/messages";
import { UploadingRevitFileListState, isEmptyUploadFilesList, isUploadFilesListContainsValidValues } from "../../../../entities/revitFiles/utils";
import RevitFilesUploadProgressHOC from "./RevitFilesUploadProgressHOC";

import "./DEngineFilesSection.sass";

const { Panel } = Collapse;
const { confirm } = Modal;

const DENGINE_FILES_SECTION = "DENGINE_FILES_SECTION";
const UPLOAD_FILES_SECTION = "UPLOAD_FILES_SECTION";
const DENGINE_FILES_ACCEPT = ".rvt";

let DEngineFilesSection = () => {
  const isFilesExists = revitFiles.isFilesExists;

  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [uploadingState, setUploadingState] = useState<UploadingRevitFileListState>(UploadingRevitFileListState.Empty);
  const [isUploadProcessing, setIsUploadProcessing] = useState<boolean>(false);

  const onChangeFileList = useCallback(
    (evFileList: UploadFile[], origFileList?: UploadFile[]): UploadFile[] => {
      setFileList(evFileList);

      let newState: UploadingRevitFileListState;
      if (isEmptyUploadFilesList(evFileList)) {
        newState = UploadingRevitFileListState.Empty;
      } else if (isUploadFilesListContainsValidValues(evFileList)) {
        newState = UploadingRevitFileListState.Valid;
      } else {
        newState = UploadingRevitFileListState.Invalid;
      }

      if (uploadingState !== newState) {
        setUploadingState(newState);
      }

      return evFileList;
    },
    [uploadingState]
  );

  const onUpload = useCallback(async () => {
    setIsUploadProcessing(true);

    await revitFiles.uploadFiles(fileList);
    setFileList([]);
    setIsUploadProcessing(false);
    setUploadingState(UploadingRevitFileListState.Empty);
    showToastMessage("Success", "DEngine catalog files have been uploaded.");
  }, [fileList]);

  const onUploadHandler = useCallback(() => {
    if (isFilesExists) {
      confirm({
        title: `You are about to overwrite DEengine catalog files. Are you sure?`,
        okText: "Yes",
        okType: "primary",
        cancelText: "No",
        onOk: () => {
          // Upload files after dialog will be closed
          setTimeout(onUpload, 0);
        },
      });
    } else {
      onUpload();
    }
  }, [isFilesExists, onUpload]);

  const disableUploading = useMemo(() => {
    return uploadingState !== UploadingRevitFileListState.Valid || isUploadProcessing;
  }, [uploadingState, isUploadProcessing]);

  const displayWarning = useMemo(() => {
    return uploadingState === UploadingRevitFileListState.Invalid;
  }, [uploadingState]);

  const onDelete = useCallback(async () => {
    await revitFiles.deleteFiles();
    showToastMessage("Success", "DEngine catalog files have been removed.");
  }, []);

  const onDeleteIconHandler = useCallback(
    ev => {
      ev.stopPropagation();
      confirm({
        title: `Are you sure you want to remove DEngine catalog files?`,
        okText: "Yes",
        okType: "primary",
        cancelText: "No",
        onOk: onDelete,
      });
    },
    [onDelete]
  );

  const ActionButtons = useMemo(() => {
    return (
      <div className="actions">
        <Button type="primary" onClick={onDeleteIconHandler}>
          <i className="icon icon-trash" />
        </Button>
      </div>
    );
  }, [onDeleteIconHandler]);

  return (
    <Collapse defaultActiveKey={!isFilesExists ? DENGINE_FILES_SECTION : null} destroyInactivePanel>
      <Panel header="DEngine catalog files" key={DENGINE_FILES_SECTION} className="dengine-collapse-item" extra={isFilesExists && ActionButtons}>
        <>
          {isFilesExists ? (
            <>
              <ul>
                <li>
                  <strong>{revitFiles.files.catalogFile.name}</strong>
                </li>
                <li>
                  <strong>{revitFiles.files.settingsFile.name}</strong>
                </li>
                <li>
                  <strong>{revitFiles.files.templateFile.name}</strong>
                </li>
              </ul>
            </>
          ) : (
            <div>No DEngine catalog files uploaded yet.</div>
          )}
        </>

        <Collapse defaultActiveKey={!isFilesExists ? UPLOAD_FILES_SECTION : null}>
          <Panel
            header={<span className="subtitle">{isFilesExists ? "Update" : "Upload"} DEngine catalog files</span>}
            key={UPLOAD_FILES_SECTION}
            className="upload-collapse-item"
          >
            <Row>
              <Col span={12}>
                <VeUpload
                  accept={DENGINE_FILES_ACCEPT}
                  multiple={true}
                  fileList={fileList}
                  onChangeFileList={onChangeFileList}
                  disabled={isUploadProcessing}
                  beforeUpload={() => false}
                >
                  <Button className="upload">Upload or Drag &amp; Drop Files</Button>
                </VeUpload>
              </Col>
              <Col span={1}></Col>
              <Col span={11}>
                {isUploadProcessing ? (
                  <div className="progress-wrapper">
                    <RevitFilesUploadProgressHOC />
                  </div>
                ) : (
                  <Button className="upload-button" onClick={onUploadHandler} loading={isUploadProcessing} disabled={disableUploading}>
                    Upload
                  </Button>
                )}
              </Col>
            </Row>
            {displayWarning && (
              <Row>
                <Col span={24} className="warning-message-wrapper">
                  <p className="warning-message">You should upload 3 .rvt files simultaneously. Their names should match the schema:</p>
                  <p className="warning-message">
                    - <strong>&lt;filename&gt;.rvt</strong> - for catalog file
                  </p>
                  <p className="warning-message">
                    - <strong>&lt;filename&gt;.Settings.rvt</strong> - for settings file
                  </p>
                  <p className="warning-message">
                    - <strong>&lt;filename&gt;.Template.rvt</strong> - for template file
                  </p>
                  <p className="warning-message">Valid characters include A-z, 0-9, and (-_!.')</p>
                </Col>
              </Row>
            )}
          </Panel>
        </Collapse>
      </Panel>
    </Collapse>
  );
};

DEngineFilesSection = observer(DEngineFilesSection);

export default DEngineFilesSection;
