import "./ModalCorePlanWindow.sass";
import { makeAutoObservable } from "mobx";
import { observer } from "mobx-react-lite";
import { CorePlan } from "../../../models/CorePlan";
import { Modal } from "antd";
import CorePlanDetails from "./CorePlanDetails/CorePlanDetails";
import CorePlanRequestContent from "./CorePlanRequestContent";

export enum ContentMode {
  New = "new",
  Clone = "clone",
  Edit = "edit",
  Details = "details",
}

export const openModalCorePlanWindow = (corePlan: CorePlan | null, mode: ContentMode, onSuccess?: (corePlan: CorePlan) => void) => {
  if (modalCorePlanWindowState.isOpen) {
    return;
  }
  modalCorePlanWindowState.openCorePlanWindow(corePlan, mode, onSuccess);
};

export const closeModalCorePlanWindow = () => {
  modalCorePlanWindowState.closeCorePlanWindow();
};

export const getIsModalCorePlanWindowOpen = () => {
  return modalCorePlanWindowState.isOpen;
};

export type ContentModalCorePlanWindowProps = {
  corePlan: CorePlan;
  mode?: ContentMode;
  onModalOk: () => void;
  onModalCancel: () => void;
};

interface ModalCorePlanWindowProps {
  isOpen?: boolean;
  corePlan?: CorePlan;
  mode?: ContentMode;
}

class ModalCorePlanWindowState implements ModalCorePlanWindowProps {
  isOpen = false;
  corePlan?: CorePlan;
  mode?: ContentMode;
  onSuccess?: (corePlan: CorePlan) => void;

  constructor() {
    makeAutoObservable(this);
  }

  openCorePlanWindow(corePlan: CorePlan, mode: ContentMode, onSuccess?: (corePlan: CorePlan) => void) {
    this.isOpen = true;
    this.corePlan = corePlan;
    this.mode = mode;
    this.onSuccess = onSuccess;
  }

  closeCorePlanWindow() {
    this.isOpen = false;
    this.corePlan = undefined;
    this.mode = undefined;
    this.onSuccess = undefined;
  }
}

const modalCorePlanWindowState = new ModalCorePlanWindowState();

let ModalCorePlanWindow = ({ isOpen, corePlan, mode }: ModalCorePlanWindowProps) => {
  if (isOpen) {
    modalCorePlanWindowState.openCorePlanWindow(corePlan, mode);
  }
  const isModalOpen = isOpen || modalCorePlanWindowState.isOpen;

  if (!isModalOpen) return <></>;

  const isCorePlanRequestContent =
    modalCorePlanWindowState.mode === ContentMode.New ||
    modalCorePlanWindowState.mode === ContentMode.Edit ||
    modalCorePlanWindowState.mode === ContentMode.Clone;
  const isCorePlanDetailsContent = modalCorePlanWindowState.mode === ContentMode.Details;

  modalCorePlanWindowState.mode.toLocaleUpperCase();

  const modalTitle = isCorePlanDetailsContent
    ? /* Keep some title even if name is empty string to force Antd draw 'title' section in modal */
      modalCorePlanWindowState.corePlan.name || " "
    : null;

  const onSuccess = (corePlan: CorePlan) => {
    modalCorePlanWindowState.onSuccess?.(corePlan);
    closeModalCorePlanWindow();
  };

  return (
    <Modal
      className={`modal-corePlan${isCorePlanDetailsContent ? " corePlan-details" : ""}`}
      open={isModalOpen}
      onCancel={closeModalCorePlanWindow}
      onOk={closeModalCorePlanWindow}
      title={modalTitle}
      footer={[]}
      destroyOnClose
    >
      {isCorePlanRequestContent && (
        <CorePlanRequestContent corePlan={modalCorePlanWindowState.corePlan} mode={modalCorePlanWindowState.mode} onSuccess={onSuccess} />
      )}
      {isCorePlanDetailsContent && <CorePlanDetails corePlan={modalCorePlanWindowState.corePlan} />}
    </Modal>
  );
};

ModalCorePlanWindow = observer(ModalCorePlanWindow);
export default ModalCorePlanWindow;
