import { observer } from "mobx-react";
import { appModel } from "../../../models/AppModel";
import { Input, Modal } from "antd";
import React from "react";
import "./Prompt.sass";

export interface IPromptOptions {
  show: boolean;
  title?: string;
  placeholder?: string;
  addonAfter?: string;
  onClose?: (value: string | null) => void;
}

export interface IPrompConfig {
  title: string;
  placeholder?: string;
  addonAfter?: string;
}

export const prompt = (config: IPrompConfig): Promise<string> => {
  return new Promise(resolve => {
    appModel.setPromptOptions({
      show: true,
      title: config.title,
      placeholder: config.placeholder,
      addonAfter: config.addonAfter,
      onClose: resolve,
    });
  });
};

const Prompt = observer(() => {
  const [data, setData] = React.useState("");

  const state: IPromptOptions = appModel.promptOptions;

  const closePrompt = (result: string) => {
    state.onClose?.(result);
    setData("");
    appModel.setPromptOptions({ show: false });
  };

  const handleOk = () => {
    closePrompt(data);
  };

  const handleCancel = () => {
    closePrompt(null);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setData(e.target.value);
  };

  return (
    <Modal
      className="prompt-modal"
      open={state.show}
      title={state.title ?? ""}
      okText="Proceed"
      onOk={handleOk}
      onCancel={handleCancel}
      closable={false}
      destroyOnClose={true}
    >
      <div className="prompt-input">
        <Input placeholder={state.placeholder ?? ""} value={data} onChange={handleChange} onPressEnter={handleOk} addonAfter={state.addonAfter ?? ""} />
      </div>
    </Modal>
  );
});

export default Prompt;
