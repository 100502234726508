import { observer } from "mobx-react";
import React from "react";
import BaseManager from "../../../../../editor/managers/BaseManager";
import { SceneEditorMode } from "../../../../../editor/models/SceneEditorMode";
import { appModel } from "../../../../../models/AppModel";
import { RoomType, AssetsState as RoomTypeAssetsState } from "../../../../../models/RoomType";
import { addAuthParam } from "../../../../../services/api/utilities";
import { EditorContext } from "../../EditorLeftBar";
import LoadingSpinner from "../../../common/LoadingSpinner";
import "../../../common/LoadingSpinner.sass";

export const IMAGE_WIDTH = 144;

const Spinner = () => (
  <div className="spinner-container transparent" data-testid="room-selector-spinner">
    <LoadingSpinner size="medium" />
  </div>
);

export type RoomTypeItemProps = {
  imgURL?: string;
  altText?: string;
  title?: string;
  roomType: RoomType;
};

export type EditorLeftBarProps = {
  baseManager: BaseManager;
};

export const RoomTypeListItem = observer((props: RoomTypeItemProps) => {
  const baseManager = React.useContext(EditorContext).baseManager;
  const { imgURL, altText = "", roomType, title = "" } = props;
  const { assetsState } = roomType;
  const isEnabled = appModel.sceneEditorMode === SceneEditorMode.Room;

  React.useEffect(() => {
    if (assetsState === RoomTypeAssetsState.NOT_LOADED) {
      appModel.loadRoomTypeAssets(roomType);
    }
  }, [roomType, assetsState]);

  const isLoaded = React.useMemo(() => assetsState === RoomTypeAssetsState.LOADED, [assetsState]);

  return (
    <div className="preview">
      <div className={`thumbnail${isLoaded && isEnabled ? "" : " inactive"}`}>
        {isLoaded ? (
          <img
            src={addAuthParam(imgURL)}
            alt={altText}
            width={IMAGE_WIDTH}
            draggable={false}
            onMouseDown={e => isEnabled && baseManager?.roomManager?.roomDragAndDropTool?.onRoomTypeDragStart(e, roomType)}
            onError={({ currentTarget }) => {
              // prevents looping
              currentTarget.onerror = null;
              currentTarget.src = "/assets/room_placeholder.svg";
            }}
          />
        ) : (
          <Spinner />
        )}
      </div>
      <div className="room-name">{title}</div>
    </div>
  );
});
