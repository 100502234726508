import { useEffect, useState, KeyboardEvent } from "react";
import { observer } from "mobx-react";
import { Input, Select, Tooltip } from "antd";
import { SearchOutlined, CloseCircleOutlined } from "@ant-design/icons";

import { CorePlansFilterInfo, SORT_ORDER, initFilters } from "./CorePlansPage.types";
import { CorePlan } from "../../../models/CorePlan";
import { inches2feet } from "../../../helpers/measures";

import "./CorePlanFilters.sass";

const sortOptions = [
  { value: "recent", sortBy: "updatedAt", order: SORT_ORDER.DESC, label: "Recent" },
  { value: "asc", sortBy: "name", order: SORT_ORDER.ASC, label: "asc" },
  { value: "desc", sortBy: "name", order: SORT_ORDER.DESC, label: "desc" },
];

const generateNumListOptions = (corePlans: CorePlan[], [option1, option2 = ""]: string[], normalizeCount: (n: number) => number = null) => {
  const uniqueCounts = new Set(
    corePlans.map(({ attributes }) => {
      const count = attributes[option1];
      if (attributes[option2]) {
        return count + attributes[option2] / 2;
      }
      return normalizeCount ? normalizeCount(count) : count;
    })
  );

  const sortedCounts = [...uniqueCounts].sort((a, b) => a - b);

  return [
    { value: "", label: "-" },
    ...sortedCounts.map(value => {
      const label = `${value}${normalizeCount ? "'" : ""}`;
      return { value, label };
    }),
  ];
};

type CorePlanFilterProps = {
  filterHandler: (f: CorePlansFilterInfo) => void;
  corePlans?: CorePlan[];
};

let CorePlanFilter = ({ filterHandler, corePlans }: CorePlanFilterProps) => {
  const [filterInfo, setFilterInfo] = useState<CorePlansFilterInfo>(initFilters);
  const [searchValue, setSearchValue] = useState<string>(initFilters.searchBy);

  const floorsNumberOptions = generateNumListOptions(corePlans, ["floors"]);
  const bedroomsNumberOptions = generateNumListOptions(corePlans, ["bedRooms"]);
  const lotWidthOptions = generateNumListOptions(corePlans, ["lotWidth"], inches2feet);
  const bathroomsNumberOptions = generateNumListOptions(corePlans, ["bathRooms", "halfBathRooms"]);
  const carSpaceNumberOptions = generateNumListOptions(corePlans, ["garage"]);

  useEffect(() => {
    if (filterHandler) {
      filterHandler(filterInfo);
    }
  }, [filterInfo, corePlans?.length]);

  const onSearch = (event?: KeyboardEvent<HTMLInputElement>) => {
    const newFilterInfo = {
      ...filterInfo,
      searchBy: (event?.target as HTMLInputElement)?.value || initFilters.searchBy,
    };

    setFilterInfo(newFilterInfo);
  };

  const onSortChange = (value: string) => {
    const newFilterInfo = {
      ...filterInfo,
      sortBy: sortOptions.find(opt => opt.value === value)?.sortBy || initFilters.sortBy,
      order: sortOptions.find(opt => opt.value === value)?.order || initFilters.order,
    };

    setFilterInfo(newFilterInfo);
  };

  const onNumberChange = (fieldName: string, value: number) => {
    const newFilterInfo = {
      ...filterInfo,
      [fieldName]: value,
    };

    setFilterInfo(newFilterInfo);
  };

  const prefix = searchValue ? (
    <CloseCircleOutlined
      data-testid="corePlans_clear_search"
      onClick={e => {
        setSearchValue(initFilters.searchBy);
        onSearch();
      }}
    />
  ) : (
    <SearchOutlined />
  );

  return (
    <div className="filter-corePlans">
      <div className="search-container">
        <h2>Core Plan Library</h2>
        <div className="filter-item search">
          <Input
            data-testid="corePlans_search_input"
            placeholder="Search by name ..."
            onPressEnter={onSearch}
            value={searchValue}
            onChange={event => setSearchValue(event?.target?.value || initFilters.searchBy)}
            prefix={prefix}
          />
        </div>
      </div>
      <div className="filter-container">
        <div className="filter-attributes">
          <Tooltip title="Width">
            <Select
              value={filterInfo.lotWidthFeet}
              bordered={false}
              data-testid="lot_width_select"
              dropdownMatchSelectWidth={false}
              className="select-lot-width"
              onChange={(v: number) => onNumberChange("lotWidthFeet", v)}
              options={lotWidthOptions}
            />
          </Tooltip>
          <Tooltip title="Stories">
            <Select
              value={filterInfo.floorsNumber}
              bordered={false}
              data-testid="floors_select"
              className="select-floors"
              onChange={(v: number) => onNumberChange("floorsNumber", v)}
              options={floorsNumberOptions}
            />
          </Tooltip>
          <Tooltip title="Bedrooms">
            <Select
              value={filterInfo.bedroomsNumber}
              bordered={false}
              data-testid="bedrooms_select"
              className="select-bedrooms"
              onChange={(v: number) => onNumberChange("bedroomsNumber", v)}
              options={bedroomsNumberOptions}
            />
          </Tooltip>
          <Tooltip title="Bathrooms">
            <Select
              value={filterInfo.bathroomsNumber}
              bordered={false}
              data-testid="bathrooms_select"
              dropdownMatchSelectWidth={false}
              className="select-bathrooms"
              onChange={(v: number) => onNumberChange("bathroomsNumber", v)}
              options={bathroomsNumberOptions}
            />
          </Tooltip>
          <Tooltip title="Cars">
            <Select
              value={filterInfo.carSpacesNumber}
              bordered={false}
              data-testid="car_spaces_select"
              dropdownMatchSelectWidth={false}
              className="select-car-spaces"
              onChange={(v: number) => onNumberChange("carSpacesNumber", v)}
              options={carSpaceNumberOptions}
            />
          </Tooltip>
        </div>
        <div className="filter-sort">
          <label htmlFor="sorting">Sort:</label>
          <Select
            id="sorting"
            data-testid="corePlans_sort_select"
            dropdownMatchSelectWidth={false}
            defaultValue={"recent"}
            className={"select-sort"}
            bordered={false}
            onChange={onSortChange}
            options={sortOptions}
          />
        </div>
      </div>
    </div>
  );
};

CorePlanFilter = observer(CorePlanFilter);

export default CorePlanFilter;
