import { appModel } from "../../../models/AppModel";
import { Background } from "../../../models/Background";
import BackgroundManager from "../../managers/RoomManager/BackgroundManager";
import { BackgroundCommand } from "./BackgroundCommand";
import { CommandTypes } from "./CommandTypes";

export class ChangeBackgroundCommand extends BackgroundCommand {
  constructor(
    private newBackground: Background,
    private oldBackground: Background
  ) {
    super(null);

    this.type = CommandTypes.ChangeBackgroundCommand;
  }

  apply(manager: BackgroundManager): void {
    manager.setAreSubscriptionsDisabled(true);
    appModel.activeFloor.setBackground(this.newBackground);
    manager.changeBackground(this.newBackground, this.oldBackground).finally(() => {
      manager.setAreSubscriptionsDisabled(false);
    });
  }

  undo(manager: BackgroundManager): void {
    manager.setAreSubscriptionsDisabled(true);
    appModel.activeFloor.setBackground(this.oldBackground);
    manager.changeBackground(this.oldBackground, this.newBackground).finally(() => {
      manager.setAreSubscriptionsDisabled(false);
    });
  }
}
