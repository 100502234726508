import log from "loglevel";
import MathUtils from "../editor/utils/MathUtils";
import { SettingsUnits } from "../entities/settings/types";

const INCHES_IN_FOOT = 12;
const SQ_INCHES_IN_FOOT = INCHES_IN_FOOT * INCHES_IN_FOOT;
export const fractionalInchesRegex = /^(-)?(\d+)['’]?-?(?<inches>\d+)?\s*(\d+\/\d+)?(?:"|”|''|’’)?$/;
export const hexColorRegex = /^([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;

export function roundPrecision(num: number | string, precision: number = 9): number {
  let result = typeof num === "number" ? num : parseFloat(num);
  if (Number.isNaN(result)) {
    log.error(`roundPrecision: num ${num} is NaN`);
    return NaN;
  }

  for (let i = 3; i >= 0; --i) {
    result = Number(result.toFixed(precision + i));
  }

  return result;
}

export function inches2feetSq(value?: number, precision?: number): number {
  return roundPrecision((value || 0) / SQ_INCHES_IN_FOOT, precision);
}
export function feet2inchesSq(value: number): number {
  return roundPrecision((value || 0) * SQ_INCHES_IN_FOOT);
}

export function inches2feet(value: number, precision?: number) {
  return roundPrecision((value || 0) / INCHES_IN_FOOT, precision);
}

export function feet2inches(value: number) {
  return roundPrecision((value || 0) * INCHES_IN_FOOT);
}

export const showFeet = (value: any, opts: "sqft" | "ft" = "sqft", defaultValue: any = "0.00") => {
  if (value === undefined || value === null) return defaultValue;
  if (typeof value === "string") {
    if (!value.length) return defaultValue;
    value = parseFloat(value);
    if (Number.isNaN(value)) return defaultValue;
  }

  value = opts === "ft" ? inches2feet(value) : inches2feetSq(value);

  return value.toFixed(opts === "sqft" ? 1 : 2);
};

export function inchesToFeetInchesFraction(inches: number, fractionalPrecision: number = 16, floorToTwo = false): string {
  function gcd(a: number, b: number) {
    if (!b) {
      return a;
    } else {
      return gcd(b, a % b);
    }
  }
  const sign = inches < 0 ? "-" : "";
  inches = Math.abs(inches);

  let wholeFeet = Math.floor(inches / 12);
  const remainingInches = inches - wholeFeet * 12;
  let wholeInches = Math.floor(remainingInches);
  let fractionalInches = Math.round((remainingInches - wholeInches) * fractionalPrecision);

  const divisor = gcd(fractionalInches, fractionalPrecision);
  fractionalInches /= divisor;
  let denominator = fractionalPrecision / divisor;

  if (MathUtils.areNumbersEqual(fractionalInches, denominator)) {
    ++wholeInches;
    fractionalInches = 0;
    if (MathUtils.areNumbersEqual(wholeInches, 12)) {
      ++wholeFeet;
      wholeInches = 0;
    }
  }

  if (floorToTwo && denominator > 2 && fractionalInches > 2) {
    fractionalInches = 1;
    denominator = 2;
  } else if (floorToTwo && denominator > 2 && fractionalInches < 2) {
    fractionalInches = 0;
  }

  let feetInchesFractionString: string;
  if (fractionalInches !== 0) {
    feetInchesFractionString = wholeFeet + "’-" + wholeInches + " " + fractionalInches + "/" + denominator + "”";
  } else {
    feetInchesFractionString = wholeFeet + "’-" + wholeInches + "”";
  }

  return sign + feetInchesFractionString;
}
export function feetInchesFractionToInches(feetInchesFractional: string): number | null {
  const match = feetInchesFractional.match(fractionalInchesRegex);

  if (match) {
    const sign = match[1] ? -1 : 1;
    const feet = parseInt(match[2]);
    const inches = match[3] ? parseInt(match[3]) : 0;
    const fraction = match[4] ? match[4].split("/") : ["0", "1"];
    const numerator = parseInt(fraction[0]);
    const denominator = parseInt(fraction[1]);
    return sign * (feet * 12 + inches + numerator / denominator);
  }

  return null;
}

export function getDisplayValue(value: any, unit: SettingsUnits) {
  switch (unit) {
    case SettingsUnits.ft:
      return inchesToFeetInchesFraction(value);
    case SettingsUnits.sqft:
      return roundPrecision(value / 144, 4).toString();
    case SettingsUnits.plf:
      return roundPrecision(value * 12, 4).toString();
    case SettingsUnits.psf:
      return roundPrecision(value * 144, 4).toString();
    case SettingsUnits.hex:
      return value.slice(1).toString();
    case SettingsUnits.percent:
      return roundPrecision(value * 100, 4).toString();
    case SettingsUnits.units:
    default:
      return value.toString();
  }
}

export function fromDisplayValue(value: any, unit: SettingsUnits): any {
  switch (unit) {
    case SettingsUnits.ft:
      return feetInchesFractionToInches(value);
    case SettingsUnits.sqft:
      return value * 144;
    case SettingsUnits.plf:
      return value / 12;
    case SettingsUnits.psf:
      return value / 144;
    case SettingsUnits.hex:
      return `#${value}`;
    case SettingsUnits.percent:
      return value / 100;
    case SettingsUnits.units:
      return Number.parseInt(value);
    default:
      return value;
  }
}

export const numberToEnUsLocaleString = (value: number, precision: number = 1): string => {
  return MathUtils.round(value, precision).toLocaleString("en-US");
};

export const areaToEnUsLocaleString = (value: number, precision: number = 1): string => {
  return numberToEnUsLocaleString(inches2feetSq(value), precision);
};

export function createArray(min: number, max: number, increment = 1) {
  return Array.from({ length: Math.floor((max - min) / increment) + 1 }, (_, i) => min + i * increment);
}
