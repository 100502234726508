import { Checkbox, Form, Button } from "antd";
import { VeUpload } from "../../common/VeUpload";

const SupportingDocuments = ({ corePlanData, handleFormData }) => {
  const onCheckBoxChange = e => {
    e.target.value = e.target.checked;
    handleFormData("_copyAllFiles")(e);
  };

  return (
    <div className="form-group group-upload">
      <div className="ant-form-item-label label-upload">
        <label>Supporting Documents</label>
      </div>
      <VeUpload multiple={true} fileList={corePlanData._uploadFiles} beforeUpload={() => false}>
        <Button className="upload">Upload or Drag & Drop Files</Button>
      </VeUpload>
      {corePlanData._copyAllFiles !== undefined ? (
        <Checkbox checked={corePlanData._copyAllFiles} onChange={onCheckBoxChange}>
          Copy all files
        </Checkbox>
      ) : null}
    </div>
  );
};

export default SupportingDocuments;
