import { Button, Space } from "antd";
import { observer } from "mobx-react";
import { appModel } from "../../../models/AppModel";
import "./ContextMenu.sass";

export interface IContextMenuOptions {
  show: boolean;
  top?: number;
  left?: number;
  copyRooms?: () => void;
  pasteRooms?: () => void;
  deleteRooms?: () => void;
  replaceRooms?: () => void;
  toggleBelowFloorVisibility?: () => void;
  toggleAboveFloorVisibility?: () => void;
  splitLotLine?: () => void;
  removeLotLineVertex?: () => void;
}

const ContextMenu = observer(() => {
  const options = appModel.contextMenuOptions;

  if (!options || !options.show) {
    return null;
  }

  const setHidden = () => {
    appModel.setContextMenuOptions({ show: false });
  };
  const isActionBlocked = appModel.isViewOnlyMode;

  return (
    <div style={{ left: `${options.left}px`, top: `${options.top}px` }} className="context-menu" onContextMenu={e => e.preventDefault()}>
      {options.copyRooms && (
        <Button
          disabled={isActionBlocked}
          onClick={() => {
            options.copyRooms();
            setHidden();
          }}
        >
          Copy room(s)
        </Button>
      )}
      {options.pasteRooms && (
        <Button
          disabled={isActionBlocked}
          onClick={() => {
            options.pasteRooms();
            setHidden();
          }}
        >
          Paste room(s)
        </Button>
      )}
      {options.deleteRooms && (
        <Button
          disabled={isActionBlocked}
          onClick={() => {
            options.deleteRooms();
            setHidden();
          }}
        >
          Delete room(s)
        </Button>
      )}
      {options.toggleBelowFloorVisibility && (
        <Button
          onClick={() => {
            options.toggleBelowFloorVisibility();
            setHidden();
          }}
        >
          Toggle below floor visibility
        </Button>
      )}
      {options.toggleAboveFloorVisibility && (
        <Button
          onClick={() => {
            options.toggleAboveFloorVisibility();
            setHidden();
          }}
        >
          Toggle above floor visibility
        </Button>
      )}
      {options.replaceRooms && (
        <div style={{ background: "white", padding: "5px", border: "1px solid gray" }}>
          <div>Replace with existing room?</div>
          <Space>
            <Button
              disabled={isActionBlocked}
              size="small"
              onClick={() => {
                options.replaceRooms();
                setHidden();
              }}
            >
              Yes
            </Button>
            <Button
              disabled={isActionBlocked}
              size="small"
              onClick={() => {
                setHidden();
              }}
            >
              No
            </Button>
          </Space>
        </div>
      )}
      {options.splitLotLine && (
        <Button
          disabled={isActionBlocked}
          onClick={() => {
            options.splitLotLine();
            setHidden();
          }}
        >
          Split lot line
        </Button>
      )}
      {options.removeLotLineVertex && (
        <Button
          disabled={isActionBlocked}
          onClick={() => {
            options.removeLotLineVertex();
            setHidden();
          }}
        >
          Remove lot line point
        </Button>
      )}
    </div>
  );
});

export default ContextMenu;
