import * as THREE from "three";
import { ROOM_3D_BOX_FALLBACK_COLOR, ROOM_3D_EDGE_COLOR } from "../../consts";
import { SoPreviewWall } from "./SoPreviewWall";
import { SoPreviewGable } from "./SoPreviewGable";
import { ColorHandler } from "../../../models/DesignStyle";

export class SoPreviewRoom extends THREE.Group {
  private roomMaterial: THREE.MeshStandardMaterial;
  public walls: SoPreviewWall[] = null;
  public gables: SoPreviewGable[] = null;
  public roomId: string;
  public isIndoor: boolean;

  constructor(bb?: THREE.Box3, roomId?: string, isIndoor?: boolean) {
    super();

    this.roomId = roomId;
    this.isIndoor = isIndoor;
    this.walls = [];

    if (!bb) {
      return;
    }

    const min = bb.min;
    const max = bb.max;
    const center = bb.getCenter(new THREE.Vector3());

    this.roomMaterial = new THREE.MeshStandardMaterial({
      side: THREE.DoubleSide,
      color: ROOM_3D_BOX_FALLBACK_COLOR,
      metalness: 0.092,
      roughness: 0.5,
      polygonOffset: true,
      polygonOffsetFactor: isIndoor ? 1.0 : 0,
      polygonOffsetUnits: isIndoor ? 1.0 : -1.0,
    });
    const wallsMiddleZ = 0;

    const colorString = "255,0,0,0.0001";
    const colorHandler = new ColorHandler(colorString);
    const transparentMaterial = colorHandler.createMaterial();
    const offset = 0.01;

    this.walls = [
      new SoPreviewWall(
        4,
        "top",
        this,
        max.x - min.x,
        max.y - min.y,
        new THREE.Vector3(0, 0, wallsMiddleZ + (max.z - min.z) / 2),
        new THREE.Euler(0, 0, 0),
        isIndoor ? this.roomMaterial.clone() : transparentMaterial,
        isIndoor
      ),
      new SoPreviewWall(
        5,
        "bottom",
        this,
        max.x - min.x,
        max.y - min.y,
        new THREE.Vector3(0, 0, wallsMiddleZ - (max.z - min.z) / 2),
        new THREE.Euler(0, 0, 0),
        this.roomMaterial.clone(),
        isIndoor
      ),
      new SoPreviewWall(
        2,
        "back",
        this,
        max.x - min.x,
        max.z - min.z,
        isIndoor ? new THREE.Vector3(0, -(max.y - min.y) / 2, wallsMiddleZ) : new THREE.Vector3(0, -(max.y - min.y) / 2 + offset, wallsMiddleZ),
        new THREE.Euler(Math.PI / 2, 0, 0),
        isIndoor ? this.roomMaterial.clone() : transparentMaterial,
        isIndoor
      ),
      new SoPreviewWall(
        0,
        "front",
        this,
        max.x - min.x,
        max.z - min.z,
        isIndoor ? new THREE.Vector3(0, (max.y - min.y) / 2, wallsMiddleZ) : new THREE.Vector3(0, (max.y - min.y) / 2 - offset, wallsMiddleZ),
        new THREE.Euler(Math.PI / 2, 0, 0),
        isIndoor ? this.roomMaterial.clone() : transparentMaterial,
        isIndoor
      ),
      new SoPreviewWall(
        1,
        "right",
        this,
        max.y - min.y,
        max.z - min.z,
        isIndoor ? new THREE.Vector3((max.x - min.x) / 2, 0, wallsMiddleZ) : new THREE.Vector3((max.x - min.x) / 2 - offset, 0, wallsMiddleZ),
        new THREE.Euler(Math.PI / 2, Math.PI / 2, 0),
        isIndoor ? this.roomMaterial.clone() : transparentMaterial,
        isIndoor
      ),
      new SoPreviewWall(
        3,
        "left",
        this,
        max.y - min.y,
        max.z - min.z,
        isIndoor ? new THREE.Vector3(-(max.x - min.x) / 2, 0, wallsMiddleZ) : new THREE.Vector3(-(max.x - min.x) / 2 + offset, 0, wallsMiddleZ),
        new THREE.Euler(Math.PI / 2, Math.PI / 2, 0),
        isIndoor ? this.roomMaterial.clone() : transparentMaterial,
        isIndoor
      ),
    ];
    this.walls.forEach(wall => this.add(wall));

    // Add edges for visual clarity
    const edgesGeometry = new THREE.EdgesGeometry(new THREE.BoxBufferGeometry(max.x - min.x, max.y - min.y, max.z - min.z));
    const edges = new THREE.LineSegments(edgesGeometry, new THREE.LineBasicMaterial({ color: ROOM_3D_EDGE_COLOR }));
    this.add(edges);

    // Center the group at the bounding box center, adjusting for any offsets in your scene setup
    this.position.set(center.x, center.y, center.z);
  }

  public updateRoofSlopes(slopes) {
    for (let i = 0; i < slopes.length; i++) {
      for (const face of this.walls) {
        if (face.index == i) {
          face.roofSlope = slopes[i];
        }
      }
    }
  }
  public updateDutchGableDepths(depths) {
    for (let i = 0; i < depths.length; i++) {
      for (const face of this.walls) {
        if (face.index == i) {
          face.dutchGableDepth = depths[i];
        }
      }
    }
  }
}
