import { observer } from "mobx-react";
import { useEffect } from "react";
import { IArcGapsValidationResult } from "../../../../../editor/models/ValidationResult";
import { ValidationMode } from "../../../../../editor/models/ValidationType";
import { Size, useWindowSize } from "../../../../../helpers/utilities";
import { appModel } from "../../../../../models/AppModel";
import { ValidationItemType } from "./ValidationItem";
import { ArcGapStatus } from "../../../../../editor/tools/ValidationTools/GapValidationTool";
import ValidationTab from "./ValidationTab";
import TooltipCell from "./TooltipCell";

export const arcGapColors = {
  [ArcGapStatus.Hight]: "#FF3B47",
  [ArcGapStatus.Medium]: "#FFBD00",
  [ArcGapStatus.Low]: "#FFEB36",
  [ArcGapStatus.Unevaluated]: "#9747FF",
  [ArcGapStatus.Unverified]: "#9747FF",
  [ArcGapStatus.None]: "#03CB6A",
};

export const formatRoomGapDescription = (
  item: IArcGapsValidationResult
): {
  itemDescription: string;
  legendDescription: string;
} => {
  const roomNumber = item.roomNumber.toString().padStart(2, "0");
  const floor = appModel.activeCorePlan.floors.find(f => f.id === item.floorId);
  const floorName = floor.name;
  const roomName = floor.rooms.find(r => r.id === item.roomId).name;
  return {
    itemDescription: `${roomNumber} - ${floorName}: ${item.description}`,
    legendDescription: `${roomNumber} - ${floor.name} - ${roomName}`,
  };
};

const ArcGapValidationTab = observer(() => {
  const size: Size = useWindowSize();
  const height = size.height ? size.height - 504 : "auto";

  useEffect(() => {
    appModel.setActiveValidationMode(ValidationMode.ArcGap);

    return () => {
      appModel.setActiveValidationMode(ValidationMode.None);
    };
  }, []);

  const result = appModel.validationResult as IArcGapsValidationResult[];
  if (!result?.length) {
    return;
  }

  const totalRoomsCount = result.length;

  const evaluatedItems = result.filter(r => r.status !== ArcGapStatus.None);
  const noneGapRoomsCount = totalRoomsCount - evaluatedItems.length;
  const legendItemsMap = new Map<string, string[]>();
  const items = [];
  evaluatedItems.forEach(item => {
    const descriptions = formatRoomGapDescription(item);
    items.push({
      color: arcGapColors[item.status],
      text: descriptions.itemDescription,
      tooltip: item.description,
      type: ValidationItemType.Dot,
      status: item.status,
    });
    if (legendItemsMap.has(item.status)) {
      legendItemsMap.get(item.status).push(descriptions.legendDescription);
    } else {
      legendItemsMap.set(item.status, [descriptions.legendDescription]);
    }
  });

  if (noneGapRoomsCount) {
    legendItemsMap.set(ArcGapStatus.None, [`${noneGapRoomsCount} room${noneGapRoomsCount === 1 ? "" : "s"}`]);
  }

  const legendItemEntries = [...legendItemsMap.entries()].reverse();
  const legend = (
    <table>
      <thead>
        <tr>
          {legendItemEntries.map(statusItems => (
            <th style={{ textAlign: "left", paddingRight: 2 }} colSpan={statusItems[1].length} key={statusItems[0]}>
              {statusItems[0]}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        <tr className="points-row">
          {[...legendItemsMap.entries()]
            .reverse()
            .flatMap(statusItems => statusItems[1].map(item => <TooltipCell key={item} text={" "} tooltip={item} color={arcGapColors[statusItems[0]]} />))}
        </tr>
        <tr className="total-row">
          <td colSpan={evaluatedItems.length + (noneGapRoomsCount ? 1 : 0)}>Total rooms = {totalRoomsCount}</td>
        </tr>
      </tbody>
    </table>
  );

  return <ValidationTab items={items} height={height} legend={legend} />;
});

export default ArcGapValidationTab;
