import LotLineManager from "../../managers/RoomManager/LotLineManager";
import { CommandTypes } from "./CommandTypes";
import { LotItemCommand } from "./LotItemCommand";

export class TranslateLotItemCommand extends LotItemCommand {
  constructor(
    entityId: string,
    private delta: THREE.Vector3
  ) {
    super(entityId);

    this.type = CommandTypes.TranslateBackgroundCommand;
  }

  apply(manager: LotLineManager): void {
    const soItem = manager.getSoLotItem(this.entityId);
    soItem.move(this.delta);

    manager.updateLotItemsProperties([this.entityId], true);
  }

  undo(manager: LotLineManager): void {
    const soItem = manager.getSoLotItem(this.entityId);
    soItem.move(this.delta.clone().negate());

    manager.updateLotItemsProperties([this.entityId], true);
  }
}
