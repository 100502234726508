import { IReactionDisposer, reaction, runInAction } from "mobx";
import * as THREE from "three";
import { inchesToFeetInchesFraction } from "../../../helpers/measures";
import { MessageKindsEnum, showToastMessage } from "../../../helpers/messages";
import { appModel } from "../../../models/AppModel";
import { CursorStyle } from "../../../models/CursorStyle";
import { Floor } from "../../../models/Floor";
import { LotItem, LotLine, LotLineOffsetVertex, LotLineVertex } from "../../../models/LotLine";
import { CorePlan } from "../../../models/CorePlan";
import { LotLineSide } from "../../../models/CorePlanAttributes";
import { Side } from "../../../models/Side";
import { Vector3V } from "../../../models/Vector3V";
import DialogCorePlanAttribute from "../../../ui/components/CorePlans/DialogCorePlanAttribute";
import { IContextMenuOptions } from "../../../ui/components/common/ContextMenu";
import {
  EPSILON,
  LOT_LINE_LABEL_DISPLAY_SIDE_THRESHOLD,
  LOT_LINE_LABEL_OFFSET_LINE_COLOR,
  LOT_LINE_LABEL_RENDER_ORDER,
  LOT_LINE_LABEL_TEXT_COLOR,
  LOT_LINE_LABEL_TEXT_OFFSET,
  LOT_LINE_LABEL_TEXT_SIZE,
  LOT_LINE_NOT_ADDED_MESSAGE,
  LOT_LINE_SELECTED_COLOR,
  LOT_LINE_VERTEX_REMOVE_MESSAGE,
} from "../../consts";
import { DragMode } from "../../models/DragMode";
import { Keys } from "../../models/Keys";
import { SceneEditorMode } from "../../models/SceneEditorMode";
import { SceneEntityType } from "../../models/SceneEntityType";
import { DeleteLotVertexCommand } from "../../models/commands/DeleteLotVertexCommand";
import { LotItemCommand } from "../../models/commands/LotItemCommand";
import { MultiCommand } from "../../models/commands/MultiCommand";
import { SplitLotEdgeCommand } from "../../models/commands/SplitLotEdgeCommand";
import { TranslateLotItemCommand } from "../../models/commands/TranslateLotItemCommand";
import { SoLotItem, SoLotLineEdge, SoLotLineOffset, SoLotLineVertex } from "../../models/scene/SoLotLine";
import GeometryUtils from "../../utils/GeometryUtils";
import MathUtils from "../../utils/MathUtils";
import SceneUtils from "../../utils/SceneUtils";
import UnitsUtils from "../../utils/UnitsUtils";
import { CommandManagerEvents, Scope } from "../CommandManager/CommandManager";
import { LotLineCommandManager } from "../CommandManager/LotLineCommandManager";
import RoomManager from "./RoomManager";

export default class LotLineManager {
  private reactions: IReactionDisposer[] = [];
  private selectedLotItemReactions: IReactionDisposer[] = [];
  private corePlan: CorePlan = null;
  private commandManager: LotLineCommandManager = new LotLineCommandManager(this);

  private soRoot = new THREE.Group();
  private dragMode: DragMode = DragMode.none;
  private checkpoint: LotLine = null;

  constructor(public roomManager: RoomManager) {
    this.soRoot.name = "Lot Line Manager Root";
    this.roomManager.getSoRoot().add(this.soRoot); // ???

    this.commandManager.addEventListener(CommandManagerEvents.ScopesChanged, e => {
      if (appModel.sceneEditorMode !== SceneEditorMode.LotLine) {
        return;
      }

      if (e.scopes.some((scope: [string, Scope<LotItemCommand>]) => scope[1].undos.length) || this.checkpoint === null) {
        appModel.setPendingChanges(true);
      } else {
        appModel.setPendingChanges(false);
      }
    });

    reaction(() => appModel.activeCorePlan, this.onActiveCorePlanChanged.bind(this));
    reaction(() => appModel.sceneEditorMode, this.onEditModeChanged.bind(this));
    this.onActiveCorePlanChanged(appModel.activeCorePlan);
  }

  public onMouseDown(e: MouseEvent) {}

  public onMouseMove(e: MouseEvent) {
    if (this.roomManager.baseManager.isMouseHandlersEnabled) {
      if (this.dragMode === DragMode.none) {
        if (e.buttons === 1) {
          const intersectedSoItem = this.getIntersectedLotItem();
          if (this.isSoLotItemSelected(intersectedSoItem)) {
            this.dragMode = DragMode.movingLotLine;

            const soVertices = this.getSelectedSoLotLineVertices();
            soVertices.forEach(soVertex => {
              soVertex.userData.startPosition = soVertex.position.clone();
              soVertex.userData.mouseOffset = soVertex.position.clone().sub(this.roomManager.intersectionPoint);
            });
          } else if (appModel.selectedLotItemsIds.length > 0) {
            appModel.clearSelectedLotItemsIds();
          }
        }
      } else if (this.dragMode === DragMode.movingLotLine) {
        (e.target as HTMLCanvasElement).style.cursor = CursorStyle.Pointer;

        const soVertices = this.getSelectedSoLotLineVertices();
        const newPosition = this.roomManager.intersectionPoint.clone().add(soVertices[0].userData.mouseOffset);
        // Lock specific axis
        if (e.shiftKey) {
          const startOffset = newPosition.clone().sub(soVertices[0].userData.startPosition);
          if (Math.abs(startOffset.x) < Math.abs(startOffset.y)) {
            newPosition.x = soVertices[0].userData.startPosition.x;
          } else {
            newPosition.y = soVertices[0].userData.startPosition.y;
          }
        }
        const delta = newPosition.clone().sub(soVertices[0].position);

        soVertices.forEach(soItem => soItem.move(delta));
        this.updateLotItemsProperties(
          soVertices.map(so => so.userData.id),
          true
        );
        this.updateOffsetLines();
        this.updateLotLineLabels();
      }
    }
  }

  public onMouseLeave(e: MouseEvent): void {
    if (this.roomManager.baseManager.isMouseHandlersEnabled) {
      this.handleDragFinish();
    }
  }

  public onMouseUp(e: MouseEvent) {
    e.preventDefault();

    if (this.roomManager.baseManager.isMouseHandlersEnabled) {
      if (this.dragMode === DragMode.none) {
        if (!this.roomManager.isPanning) {
          const isSingleMode = !e.ctrlKey;

          if (e.button === 0) {
            this.performLotItemSelection(isSingleMode);
          } else if (e.button === 2) {
            this.performLotItemSelection(isSingleMode, true);

            const intersectedSoItem = this.getIntersectedLotItem();
            const options: IContextMenuOptions = {
              show: true,
              left: e.clientX,
              top: e.clientY,
            };

            if (intersectedSoItem instanceof SoLotLineEdge) {
              options.splitLotLine = this.splitLotLine.bind(this, intersectedSoItem.userData.id);
            } else if (intersectedSoItem instanceof SoLotLineVertex) {
              options.removeLotLineVertex = this.removeLotLineVertex.bind(this, intersectedSoItem.userData.id);
            }

            appModel.setContextMenuOptions(options);
          }
        }
      } else {
        this.handleDragFinish();
      }
    }
  }

  public onKeyUp(e: KeyboardEvent) {
    if (appModel.isViewOnlyMode) {
      return;
    }
    if (!e.ctrlKey && !e.metaKey) {
      return;
    }

    switch (e.code) {
      case Keys.Y: {
        this.redo();
        break;
      }
      case Keys.Z: {
        this.undo();
        break;
      }
    }
  }

  public getSoRoot(): THREE.Object3D {
    return this.soRoot;
  }
  public getSoLotItem(id?: string): SoLotItem {
    if (!id) {
      return null;
    }

    return this.soRoot.children.find(it => it.userData.id === id) as SoLotItem;
  }
  public getSoLotItems(): SoLotItem[] {
    return this.soRoot.children.filter(
      child =>
        child.userData.type === SceneEntityType.LotLineEdge ||
        child.userData.type === SceneEntityType.LotLineVertex ||
        child.userData.type === SceneEntityType.LotLineOffset
    ) as SoLotItem[];
  }
  public getSelectedSoLotLineVertices(): SoLotLineVertex[] {
    return this.getSoLotItems().filter(
      soItem => soItem instanceof SoLotLineVertex && appModel.selectedLotItemsIds.includes(soItem.userData.id)
    ) as SoLotLineVertex[];
  }

  public getSelectedEdge() {
    const result = [];
    appModel.selectedLotItemsIds.forEach(id => {
      const edge = appModel.activeFloor.lotLine.edges.find(edge => edge.id === id);
      if (edge) {
        result.push(edge);
      }
    });
    return result;
  }

  public updateOffsetLines() {
    if (appModel.activeFloor?.lotLine) {
      const offsetlines = this.soRoot.children.filter(
        child => child.userData.type === SceneEntityType.LotLineOffset && child.userData.floorId === appModel.activeFloor.id
      );
      this.soRoot.remove(...offsetlines);
      this.addOffsetLine();
      this.addSoOffsetLine();
    }
  }

  public updateLotLineLabels(isVisible: boolean = true): void {
    const soLabels = this.soRoot.children.filter(child => child.userData.type === SceneEntityType.LotLineLabel);
    soLabels.forEach(it => this.soRoot.remove(it));

    if (isVisible && appModel.activeFloor.lotLine) {
      const addSegmentLabels = (vertices: LotLineVertex[] | LotLineOffsetVertex[], offset: number) => {
        for (let i = 0; i < vertices.length; i++) {
          const start = vertices[i].point;
          const end = vertices[(i + 1) % vertices.length].point;

          const soLabel = this.createLotLineTextLabel(new THREE.Vector3(start.x, start.y), new THREE.Vector3(end.x, end.y), offset);
          if (soLabel) {
            this.soRoot.add(soLabel);
          }
        }
      };

      const labelOffset = LOT_LINE_LABEL_TEXT_OFFSET * UnitsUtils.getConversionFactor();
      addSegmentLabels(appModel.activeFloor.lotLine.vertices, -labelOffset);
      addSegmentLabels(appModel.activeFloor.lotLine.offsetVertices, labelOffset);

      const lotLineEdges = appModel.activeFloor.lotLine.edges.map(edge => {
        const start = appModel.activeFloor.lotLine.vertices.find(vertex => vertex.id === edge.startId).point;
        const end = appModel.activeFloor.lotLine.vertices.find(vertex => vertex.id === edge.endId).point;

        return { line: new THREE.Line3(new THREE.Vector3(start.x, start.y), new THREE.Vector3(end.x, end.y)), side: edge.side };
      });

      // Assign an associated side to each edge of the offset contour.
      const sideToOffsetsMap = new Map<Side, { offset: THREE.Line3; externalLine: THREE.Line3 }[]>();
      const offsetVertices = appModel.activeFloor.lotLine.offsetVertices;

      for (let i = 0; i < offsetVertices.length; i++) {
        const start = offsetVertices[i].point;
        const end = offsetVertices[(i + 1) % offsetVertices.length].point;
        const offsetLine = new THREE.Line3(new THREE.Vector3(start.x, start.y), new THREE.Vector3(end.x, end.y));
        const offsetDelta = offsetLine.delta(new THREE.Vector3()).normalize();

        // Get parallel line that lies on the external side of offset contour.
        const lotLineEdge = lotLineEdges.find(
          edge =>
            MathUtils.areNumbersEqual(edge.line.delta(new THREE.Vector3()).normalize().dot(offsetDelta), 1) &&
            GeometryUtils.isRightOf(edge.line.start, offsetLine.start, offsetLine.end)
        );

        if (!lotLineEdge) {
          continue;
        }

        const projectedStart = offsetLine.closestPointToPointParameter(lotLineEdge.line.start, false);
        const projectedEnd = offsetLine.closestPointToPointParameter(lotLineEdge.line.end, false);

        const offsetStartP = Math.max(projectedStart, 0);
        const offsetEndP = Math.min(projectedEnd, 1);

        if (offsetStartP <= offsetEndP) {
          const overlap = new THREE.Line3(
            GeometryUtils.evaluateLineParameter(offsetStartP, offsetLine.start, offsetLine.end),
            GeometryUtils.evaluateLineParameter(offsetEndP, offsetLine.start, offsetLine.end)
          );

          if (sideToOffsetsMap.has(lotLineEdge.side)) {
            sideToOffsetsMap.get(lotLineEdge.side).push({ offset: overlap, externalLine: lotLineEdge.line });
          } else {
            sideToOffsetsMap.set(lotLineEdge.side, [{ offset: overlap, externalLine: lotLineEdge.line }]);
          }
        }
      }

      sideToOffsetsMap.forEach(offsets => {
        let longestOffset: { offset: THREE.Line3; externalLine: THREE.Line3 };
        let maxLength = Number.NEGATIVE_INFINITY;

        for (const offsetData of offsets) {
          const length = offsetData.offset.distance();

          if (length > maxLength) {
            maxLength = length;
            longestOffset = offsetData;
          }
        }

        if (!longestOffset) {
          return;
        }

        const soLabel = this.createLotLineOffsetLabel(longestOffset.offset, longestOffset.externalLine);
        if (soLabel) {
          this.soRoot.add(soLabel);
        }
      });
    }
  }

  public updateLotItemsProperties(ids: string[], forceNestedUpdate: boolean = false, needSync: boolean = true): void {
    const items = ids.map(id => appModel.activeFloor.lotLine.getLotItem(id));
    items.forEach(item => {
      const soItem = this.getSoLotItem(item.id);
      this.populateLotItemProperties(item, soItem);

      if (forceNestedUpdate) {
        // update dependent items
        if (soItem instanceof SoLotLineVertex) {
          this.updateLotItemsProperties(
            soItem.edges.map(soEdge => soEdge.userData.id),
            false,
            false
          );
        } else if (soItem instanceof SoLotLineEdge) {
          this.updateLotItemsProperties([soItem.start.userData.id, soItem.end.userData.id], false, false);
        }
      }
    });

    if (needSync) {
      this.syncLotLines(appModel.activeFloor.lotLine);
    }
  }

  public undo(): void {
    if (this.dragMode === DragMode.none) {
      appModel.clearSelectedLotItemsIds();
      this.commandManager.undo();
    }
  }
  public redo(): void {
    if (this.dragMode === DragMode.none) {
      appModel.clearSelectedLotItemsIds();
      this.commandManager.redo();
    }
  }

  public setCheckpoint(): void {
    this.checkpoint = appModel.activeFloor.lotLine?.clone() || null;
    this.commandManager.clearScopes();
    this.commandManager.setScope(LotLineManager.name);
  }
  public rollbackChanges(): void {
    GeometryUtils.disposeObject(this.soRoot);
    this.corePlan.floors.forEach(floor => (floor.lotLine = this.checkpoint?.clone() || null));
    this.addSoLotLine();
  }

  public updateLotLineSetbacks(): void {
    const lotLine = appModel.activeFloor.lotLine;
    if (!lotLine) {
      return;
    }

    const setbacks = this.getLotLineSetbacks();
    const vertices = lotLine.vertices.map(v => new THREE.Vector3(v.point.x, v.point.y));
    const isCounterclockwise = GeometryUtils.calculateSignedArea(vertices) >= 0;

    if (!isCounterclockwise) {
      lotLine.vertices.reverse();
      lotLine.edges.reverse();
      lotLine.edges.forEach(edge => {
        const tmp = edge.startId;
        edge.startId = edge.endId;
        edge.endId = tmp;
      });

      const soEdges = this.getSoLotItems().filter(so => so instanceof SoLotLineEdge) as SoLotLineEdge[];
      soEdges.forEach(soEdge => {
        const { start, end } = soEdge;
        [soEdge.start, soEdge.end] = [end, start];
        [soEdge.start.edges, soEdge.end.edges] = [end.edges, start.edges];
      });
    }

    const lotLineSides = GeometryUtils.assignSidesToLotLinePolygon(vertices);
    lotLineSides.forEach((side, idx) => {
      const edge = lotLine.edges[idx];
      edge.setOffset(setbacks[side]);
      edge.side = side;
    });

    // update other floors
    this.corePlan.floors.forEach(floor => {
      if (floor?.lotLine !== lotLine) {
        floor.lotLine = lotLine.clone();
      }
    });
  }

  /**
   * Perform necessary operations based on the current drag mode and reset the drag mode to none.
   */
  private handleDragFinish(): void {
    if (this.dragMode === DragMode.none) {
      return;
    }

    if (this.dragMode === DragMode.movingLotLine) {
      const soVertices = this.getSelectedSoLotLineVertices();
      const delta = soVertices[0].position.clone().sub(soVertices[0].userData.startPosition);

      soVertices.forEach(so => {
        delete so.userData.startPosition;
        delete so.userData.mouseOffset;
      });

      this.commandManager.add(new MultiCommand(soVertices.map(so => new TranslateLotItemCommand(so.userData.id, delta))));
    }

    this.dragMode = DragMode.none;
    this.roomManager.controls.noPan = false;
    this.roomManager.baseManager.setCursorStyle(CursorStyle.Default);
  }

  private getLotLineSetbacks() {
    const setbacks = this.corePlan.attributes?.lotLineSetback;
    const sides = this.corePlan.attributes?.lotLineSideAssociation;
    const topSetback = setbacks?.[sides?.top || LotLineSide.Rear] || 0;
    const leftSetback = setbacks?.[sides?.left || LotLineSide.Side] || 0;
    const bottomSetback = setbacks?.[LotLineSide.Front] || 0;
    const rightSetback = setbacks?.[sides?.right || LotLineSide.Side] || 0;

    return {
      [Side.Top]: topSetback,
      [Side.Right]: rightSetback,
      [Side.Bottom]: bottomSetback,
      [Side.Left]: leftSetback,
    };
  }

  private onActiveCorePlanChanged(corePlan?: CorePlan): void {
    this.corePlan = corePlan;

    this.commandManager.clearScopes();
    this.commandManager.setScope(LotLineManager.name);
    this.unsubscribe(this.reactions);
    GeometryUtils.disposeObject(this.soRoot);

    if (this.corePlan) {
      const lotLine = this.corePlan.floors.find(floor => floor.lotLine)?.lotLine;
      if (lotLine) {
        appModel.activeFloor.lotLine = lotLine;
        this.updateLotLineSetbacks();
      }

      this.addSoLotLine();

      this.reactions.push(reaction(() => appModel.selectedLotItemsIds.length, this.onSelectedLotItemsChanged.bind(this)));
      this.reactions.push(reaction(() => appModel.activeFloor, this.onActiveFloorChanged.bind(this)));
      this.reactions.push(reaction(() => appModel.activeCorePlan.attributes?.lotLineSetback, this.onLotLineSetbackChanged.bind(this)));
      this.onActiveFloorChanged(appModel.activeFloor);
    }
  }

  private onActiveFloorChanged(activeFloor?: Floor): void {
    this.soRoot.children.forEach(child => {
      if (child.userData.type === SceneEntityType.LotLineOffset) {
        if (child.userData.floorId !== activeFloor.id) {
          child.visible = false;
        }
      }
    });
    this.updateOffsetLines();

    if (appModel.sceneEditorMode === SceneEditorMode.LotLine) {
      this.updateLotLineLabels();
    }
  }
  private onEditModeChanged(mode: SceneEditorMode, oldMode: SceneEditorMode): void {
    if (mode === SceneEditorMode.LotLine) {
      this.setCheckpoint();

      if (appModel.activeFloor.lotLine) {
        this.updateLotLineLabels();
        return;
      }

      const successAdded = () => {
        this.addSoLotLine();
        this.updateLotLineLabels();
      };

      const failAdded = () => {
        appModel.setSceneEditorMode(oldMode);
        showToastMessage(MessageKindsEnum.Error, LOT_LINE_NOT_ADDED_MESSAGE, { autoClose: 700 });
      };

      const addAction = (showDialog?: boolean) => {
        this.addLotLine();
        if (appModel.activeFloor.lotLine) {
          successAdded();
          return;
        }

        if (showDialog && !this.corePlan.attributes.lotSize) {
          DialogCorePlanAttribute(this.corePlan, "lotSize", addAction, failAdded);
          return;
        }

        failAdded();
      };

      addAction(true);
    } else {
      if (oldMode === SceneEditorMode.LotLine) {
        this.updateLotLineLabels(false);
      }

      if (appModel.selectedLotItemsIds.length) {
        appModel.clearSelectedLotItemsIds();
      }
    }
  }
  private onLotLineSetbackChanged(): void {
    this.updateOffsetLines();

    if (appModel.sceneEditorMode === SceneEditorMode.LotLine) {
      this.updateLotLineLabels();
    }
  }
  private onSelectedLotItemsChanged(): void {
    const soItems = this.getSoLotItems();
    soItems.forEach(soItem => {
      if (soItem instanceof SoLotLineVertex || soItem instanceof SoLotLineEdge) {
        const soOffset = (soItem instanceof SoLotLineEdge && soItems.find(item => item.userData.edgeId === soItem.userData.id)) || null;
        if (this.isSoLotItemSelected(soItem)) {
          SceneUtils.setObjectColor(soItem, LOT_LINE_SELECTED_COLOR);
          SceneUtils.setObjectColor(soOffset, LOT_LINE_SELECTED_COLOR);
        } else {
          SceneUtils.setObjectColor(soItem);
          SceneUtils.setObjectColor(soOffset);
        }
      }
    });

    this.unsubscribe(this.selectedLotItemReactions);

    if (appModel.selectedLotItemsIds.length === 1) {
      const lotItem = appModel.activeFloor.lotLine.getLotItem(appModel.selectedLotItemsIds[0]);

      this.selectedLotItemReactions.push(
        reaction(() => lotItem.x, this.onSelectedLotItemXChanged.bind(this)),
        reaction(() => lotItem.y, this.onSelectedLotItemYChanged.bind(this))
      );
    }
  }

  private onSelectedLotItemXChanged(x: number): void {
    if (this.dragMode !== DragMode.none) {
      return;
    }

    const soItem = this.getSoLotItem(appModel.selectedLotItemsIds[0]);
    const soItemX = soItem.getPosition().x;

    if (MathUtils.areNumbersEqual(x, soItemX)) {
      return;
    }

    this.commandManager.apply(new TranslateLotItemCommand(soItem.userData.id, new THREE.Vector3(x - soItemX, 0, 0)));
  }

  private onSelectedLotItemYChanged(y: number): void {
    if (this.dragMode !== DragMode.none) {
      return;
    }

    const soItem = this.getSoLotItem(appModel.selectedLotItemsIds[0]);
    const soItemY = soItem.getPosition().y;

    if (MathUtils.areNumbersEqual(y, soItemY)) {
      return;
    }

    this.commandManager.apply(new TranslateLotItemCommand(soItem.userData.id, new THREE.Vector3(0, y - soItemY, 0)));
  }

  private populateLotItemProperties(item: LotItem, soItem: SoLotItem): void {
    const position = soItem.getPosition();

    item.setX(position.x);
    item.setY(position.y);
  }

  private syncLotLines(lotLine: LotLine): void {
    appModel.activeCorePlan.floors.forEach(floor => {
      if (floor.lotLine !== lotLine) {
        floor.lotLine.sync(lotLine);
      }
    });
  }

  private unsubscribe(reactions: IReactionDisposer[]): void {
    reactions.forEach(r => r());
    reactions.length = 0;
  }

  private addLotLine(): void {
    const width = this.corePlan.attributes.lotWidth || 0;
    const length = this.corePlan.attributes.lotLength || 0;

    if (width > 0 && length > 0) {
      const halfW = width / 2;
      const halfL = length / 2;

      appModel.activeFloor.lotLine = new LotLine().build([
        new Vector3V(-halfW, -halfL, 0),
        new Vector3V(halfW, -halfL, 0),
        new Vector3V(halfW, halfL, 0),
        new Vector3V(-halfW, halfL, 0),
      ]);
    } else {
      const bb = new THREE.Box3();
      this.roomManager.getCorePlanSoRooms().forEach(soRoom => bb.union(SceneUtils.getRoomBoundingBoxByModelLines(soRoom)));

      if (!bb.isEmpty()) {
        appModel.activeFloor.lotLine = new LotLine().build([
          new Vector3V(bb.min.x, bb.min.y, 0),
          new Vector3V(bb.max.x, bb.min.y, 0),
          new Vector3V(bb.max.x, bb.max.y, 0),
          new Vector3V(bb.min.x, bb.max.y, 0),
        ]);
      }
    }
  }

  private addOffsetLine() {
    this.updateLotLineSetbacks();

    const lotline = appModel.activeFloor.lotLine;
    lotline.offsetVertices.length = 0;
    const edges = lotline.edges;
    if (edges.length > 0) {
      const offsetLines: THREE.Vector3[][] = [];
      for (const edge of edges) {
        const start = this.getSoLotItem(edge.startId) as SoLotLineVertex;
        const end = this.getSoLotItem(edge.endId) as SoLotLineVertex;
        const offsetLine = GeometryUtils.getParallelPointsWithOffset(start.position, end.position, edge.offset);
        offsetLines.push(offsetLine);
      }

      let prevIndex = edges.length - 1;
      for (let fwdIndex = 0; fwdIndex < edges.length; ++fwdIndex) {
        while (prevIndex !== fwdIndex) {
          const intersectPoint = GeometryUtils.getLineIntersectionPoint(
            offsetLines[prevIndex][0],
            offsetLines[prevIndex][1],
            offsetLines[fwdIndex][0],
            offsetLines[fwdIndex][1]
          );

          if (intersectPoint == null) {
            // Line either are parallel or overlap
            if ((prevIndex + 1) % edges.length === fwdIndex) {
              // edges are adjacent, thus lines overlap. Just add the end of prev point as an intersection.
              lotline.offsetVertices.push(new LotLineOffsetVertex(GeometryUtils.Vector3ToVector3V(offsetLines[prevIndex][1]), edges[fwdIndex].id));
              prevIndex = fwdIndex;
            } else {
              // Configuration of offset must be producing empty polygon.
              lotline.offsetVertices = [];
              return;
            }
          } else {
            const dot = intersectPoint.clone().sub(offsetLines[prevIndex][0]).dot(offsetLines[prevIndex][1].clone().sub(offsetLines[prevIndex][0]));
            if (dot < 0) {
              // Intersection point is behind previous segment. Means that next offset steps over the segment. Remove the segment and check the one before it.
              prevIndex = prevIndex > 0 ? prevIndex - 1 : prevIndex + edges.length - 1;
              continue;
            } else {
              lotline.offsetVertices.push(new LotLineOffsetVertex(GeometryUtils.Vector3ToVector3V(intersectPoint), edges[fwdIndex].id));
              prevIndex = fwdIndex;
            }
          }

          break; // Just for safety
        }
      }
    }
  }

  private addSoLotLine(): void {
    if (!appModel.activeFloor?.lotLine) {
      return;
    }

    appModel.activeFloor.lotLine.vertices.forEach(vertex => {
      const soVertex = new SoLotLineVertex(GeometryUtils.Vector3VToVector3(vertex.point), vertex.id);
      this.populateLotItemProperties(vertex, soVertex);
      this.soRoot.add(soVertex);
    });

    appModel.activeFloor.lotLine.edges.forEach(edge => {
      const start = this.getSoLotItem(edge.startId) as SoLotLineVertex;
      const end = this.getSoLotItem(edge.endId) as SoLotLineVertex;
      const soEdge = new SoLotLineEdge(start, end, edge.id);
      this.populateLotItemProperties(edge, soEdge);
      this.soRoot.add(soEdge);
    });

    this.addOffsetLine();
    this.addSoOffsetLine();
    this.syncLotLines(appModel.activeFloor.lotLine);
  }

  private addSoOffsetLine() {
    const selectedSoEdges = this.getSoLotItems().filter(soItem => soItem instanceof SoLotLineEdge && this.isSoLotItemSelected(soItem));
    const vertices = appModel.activeFloor.lotLine.offsetVertices;
    for (let i = 0; i < vertices.length; i++) {
      let next = i + 1;
      if (next >= vertices.length) {
        next = 0;
      }
      const soOffline = new SoLotLineOffset(
        new THREE.Vector3(vertices[i].point.x, vertices[i].point.y),
        new THREE.Vector3(vertices[next].point.x, vertices[next].point.y),
        vertices[i].edgeId,
        appModel.activeFloor.id
      );
      soOffline.computeLineDistances();

      if (selectedSoEdges.some(soEdge => soEdge.userData.id === vertices[i].edgeId)) {
        SceneUtils.setObjectColor(soOffline, LOT_LINE_SELECTED_COLOR);
      }

      this.soRoot.add(soOffline);
    }
  }

  private getIntersectedLotItem(): SoLotItem {
    const vertices = this.soRoot.children.filter(child => child.userData.type === SceneEntityType.LotLineVertex);
    let intersections = this.roomManager.raycaster.intersectObjects(vertices);

    if (intersections.length === 0) {
      const edges = this.soRoot.children.filter(child => child.userData.type === SceneEntityType.LotLineEdge);
      intersections = this.roomManager.raycaster.intersectObjects(edges);
    }

    return intersections[0]?.object as SoLotItem;
  }

  private updateSoItemSelection(soItem: SoLotItem, isSelected: boolean): void {
    const updateVertexSelection = (soVertex: SoLotLineVertex) => {
      if (isSelected) {
        appModel.addSelectedLotItemId(soVertex.userData.id);
      } else {
        appModel.deleteSelectedLotItemId(soVertex.userData.id);
      }
    };

    runInAction(() => {
      if (soItem instanceof SoLotLineEdge) {
        updateVertexSelection(soItem.start);
        updateVertexSelection(soItem.end);
      } else if (soItem instanceof SoLotLineVertex) {
        updateVertexSelection(soItem);
      }
    });
  }
  private performLotItemSelection(selectSingleItem: boolean, isContextMenuEnabled: boolean = false): void {
    const soItem = this.getIntersectedLotItem();

    if (selectSingleItem || isContextMenuEnabled) {
      appModel.clearSelectedLotItemsIds();
      this.updateSoItemSelection(soItem, true);
      return;
    }

    this.updateSoItemSelection(soItem, !this.isSoLotItemSelected(soItem));
  }
  private isSoLotItemSelected(soItem: SoLotItem): boolean {
    return (
      (soItem instanceof SoLotLineVertex && appModel.selectedLotItemsIds.includes(soItem.userData.id)) ||
      (soItem instanceof SoLotLineEdge &&
        appModel.selectedLotItemsIds.includes(soItem.start.userData.id) &&
        appModel.selectedLotItemsIds.includes(soItem.end.userData.id))
    );
  }

  private splitLotLine(id: string): void {
    appModel.clearSelectedLotItemsIds();
    this.commandManager.apply(new SplitLotEdgeCommand(id));
  }

  private removeLotLineVertex(id: string): void {
    if (appModel.activeFloor.lotLine.vertices.length === 3) {
      showToastMessage(MessageKindsEnum.Error, LOT_LINE_VERTEX_REMOVE_MESSAGE, { autoClose: 700 });
      return;
    }

    appModel.clearSelectedLotItemsIds();
    this.commandManager.apply(new DeleteLotVertexCommand(id));
  }

  private createLotLineTextLabel(startPoint: THREE.Vector3, endPoint: THREE.Vector3, offset: number): THREE.Mesh | null {
    const distance = startPoint.distanceTo(endPoint);
    const center = startPoint.clone().add(endPoint).multiplyScalar(0.5);
    const angle = Math.atan2(endPoint.y - startPoint.y, endPoint.x - startPoint.x);

    if (distance < LOT_LINE_LABEL_DISPLAY_SIDE_THRESHOLD * UnitsUtils.getConversionFactor()) {
      return null;
    }

    const label = SceneUtils.createTextMesh(
      inchesToFeetInchesFraction(distance, UnitsUtils.getRoundFractionPrecision()),
      LOT_LINE_LABEL_TEXT_COLOR,
      LOT_LINE_LABEL_TEXT_SIZE * UnitsUtils.getConversionFactor()
    );
    label.renderOrder = LOT_LINE_LABEL_RENDER_ORDER;
    label.userData.type = SceneEntityType.LotLineLabel;

    const bb = GeometryUtils.getGeometryBoundingBox2D(label);
    const labelCenter = bb.getCenter(new THREE.Vector3());
    const isAngleInRightHalf = MathUtils.isNumberInRange(angle, -Math.PI / 2, Math.PI / 2);

    const matrix = new THREE.Matrix4().makeTranslation(-labelCenter.x, -labelCenter.y, 0);

    if (!isAngleInRightHalf) {
      matrix.premultiply(new THREE.Matrix4().makeRotationZ(Math.PI));
    }

    matrix.premultiply(new THREE.Matrix4().makeTranslation(0, offset, 0));
    matrix.premultiply(new THREE.Matrix4().makeRotationZ(angle));
    matrix.premultiply(new THREE.Matrix4().makeTranslation(center.x, center.y, 0));

    label.applyMatrix4(matrix);

    return label;
  }

  private createLotLineOffsetLabel(offsetLine: THREE.Line3, externalLine: THREE.Line3): THREE.Group | null {
    const centerOnOffset = offsetLine.getCenter(new THREE.Vector3());
    const centerOnExternal = externalLine.closestPointToPoint(centerOnOffset, false, new THREE.Vector3());
    const distance = centerOnOffset.distanceTo(centerOnExternal);

    if (distance < LOT_LINE_LABEL_DISPLAY_SIDE_THRESHOLD * UnitsUtils.getConversionFactor()) {
      return null;
    }

    const soLabel = new THREE.Group();

    // Place text on the right side.
    const angle = Math.atan2(centerOnOffset.y - centerOnExternal.y, centerOnOffset.x - centerOnExternal.x);
    const [start, end] =
      angle + EPSILON < 0 || MathUtils.areNumbersEqual(Math.abs(angle), Math.PI) ? [centerOnOffset, centerOnExternal] : [centerOnExternal, centerOnOffset];

    soLabel.add(this.createLotLineTextLabel(start, end, -LOT_LINE_LABEL_TEXT_OFFSET * UnitsUtils.getConversionFactor()));

    const line = new THREE.Line(
      new THREE.BufferGeometry().setFromPoints([start, end]),
      new THREE.LineDashedMaterial({
        color: LOT_LINE_LABEL_OFFSET_LINE_COLOR,
        dashSize: 0.15 * UnitsUtils.getConversionFactor(),
        gapSize: 0.2 * UnitsUtils.getConversionFactor(),
        transparent: true,
        opacity: 1.0,
      })
    );
    line.computeLineDistances();

    soLabel.add(line);

    soLabel.userData.type = SceneEntityType.LotLineLabel;
    GeometryUtils.setRenderOrder(soLabel, LOT_LINE_LABEL_RENDER_ORDER);

    return soLabel;
  }
}
