import { runInAction } from "mobx";
import { showToastMessage } from "../helpers/messages";
import apiProvider from "../services/api/utilities/Provider";
import { CorePlan } from "../models/CorePlan";
import axiosClient from "../services/api/utilities/axios-wrapper";
import { getAxiosRequestConfig } from "../services/api/utilities";
import axios from "axios";

export const costEstimateManager = {
  getAvailablePackages: async (): Promise<string[]> => {
    try {
      const result = await axios.get(`${apiProvider.host}available-packages`, getAxiosRequestConfig());

      if (result.status !== 200 || !result.data) {
        showToastMessage("Error", "Failed to get available packages.");
        return null;
      }
      return result.data;
    } catch (error) {
      showToastMessage("Error", "Failed to get available packages.");
      return null;
    }
  },
  updateCorePlanCost: async (corePlan: CorePlan, costs: { cost: number; costPerSqft: number; co2Emission: number }): Promise<boolean> => {
    try {
      const result = await axiosClient.put(`${apiProvider.host}corePlan/${corePlan.id}/cost/`, costs, getAxiosRequestConfig());
      const updateResult = result.data;

      runInAction(() => {
        corePlan.cost = updateResult.cost;
        corePlan.costPerSqft = updateResult.costPerSqft;
        corePlan.isCostOutdated = updateResult.isCostOutdated;
        corePlan.co2Emission = updateResult.co2Emission;
      });
      return true;
    } catch (error) {
      showToastMessage("Error", "Failed to update corePlan cost.");
    }
  },
  getCostEstimate: async (corePlanData: { [key: string]: any }): Promise<{ cost: number; co2Emission: number }> => {
    try {
      const result = await axios.post<{ cost: number; co2Emission: number }>(`${apiProvider.host}calculate-cost`, corePlanData, getAxiosRequestConfig());

      if (result.status !== 200 || typeof result.data?.cost !== "number") {
        showToastMessage("Error", "Cost estimation failed.");
        return null;
      }

      return result.data;
    } catch (error) {
      showToastMessage("Error", "Cost estimation failed.");
      return null;
    }
  },
};
