import * as THREE from 'three';

const CUBE_COLOR = 0xdddddd;
const OUTLINE_COLOR = 0xaaaaaa;
const HOVER_COLOR = 0xF2F5CE;
const FONT_SIZE = 55;
const FONT_FAMILY = "Arial Narrow, sans-serif";
const ROTATION_DURATION = 300;
const toRad = Math.PI / 180;
const TWOPI = 2 * Math.PI;
const xAxis = new THREE.Vector3(1, 0, 0);
const yAxis = new THREE.Vector3(0, 1, 0);
const zAxis = new THREE.Vector3(0, 0, 1);

enum SceneViewType {
	TOP = "TOP",
	FRONT = "FRONT",
	RIGHT = "RIGHT",
	BACK = "BACK",
	LEFT = "LEFT",
	BOTTOM = "BOTTOM",

	TOP_FRONT_EDGE = "TOP_FRONT_EDGE",
	TOP_RIGHT_EDGE = "TOP_RIGHT_EDGE",
	TOP_BACK_EDGE = "TOP_BACK_EDGE",
	TOP_LEFT_EDGE = "TOP_LEFT_EDGE",

	BOTTOM_BACK_EDGE = "BOTTOM_BACK_EDGE",
	BOTTOM_RIGHT_EDGE = "BOTTOM_RIGHT_EDGE",
	BOTTOM_FRONT_EDGE = "BOTTOM_FRONT_EDGE",
	BOTTOM_LEFT_EDGE = "BOTTOM_LEFT_EDGE",

	FRONT_RIGHT_EDGE = "FRONT_RIGHT_EDGE",
	BACK_RIGHT_EDGE = "BACK_RIGHT_EDGE",
	BACK_LEFT_EDGE = "BACK_LEFT_EDGE",
	FRONT_LEFT_EDGE = "FRONT_LEFT_EDGE",

	TOP_FRONT_RIGHT_CORNER = "TOP_FRONT_RIGHT_CORNER",
	TOP_BACK_RIGHT_CORNER = "TOP_BACK_RIGHT_CORNER",
	TOP_BACK_LEFT_CORNER = "TOP_BACK_LEFT_CORNER",
	TOP_FRONT_LEFT_CORNER = "TOP_FRONT_LEFT_CORNER",

	BOTTOM_FRONT_RIGHT_CORNER = "BOTTOM_FRONT_RIGHT_CORNER",
	BOTTOM_BACK_RIGHT_CORNER = "BOTTOM_BACK_RIGHT_CORNER",
	BOTTOM_BACK_LEFT_CORNER = "BOTTOM_BACK_LEFT_CORNER",
	BOTTOM_FRONT_LEFT_CORNER = "BOTTOM_FRONT_LEFT_CORNER"
};

function calculateAngleDelta(from: number, to: number): number {
	const direct = to - from;
	const altA = direct - TWOPI;
	const altB = direct + TWOPI;

	if (Math.abs(direct) > Math.abs(altA)) {
		return altA;
	}
	else if (Math.abs(direct) > Math.abs(altB)) {
		return altB;
	}

	return direct;
}
function createTextSprite(text: string): THREE.Texture {
	const fontface = FONT_FAMILY;
	const fontsize = FONT_SIZE;
	const width = 200;
	const height = 200;
	const bgColor = "255, 255, 255, 1.0";
	const fgColor = "0, 0, 0, 1.0";
	
	const canvas = document.createElement("canvas");
	canvas.width = width;
	canvas.height = height;
	
	const context = canvas.getContext("2d");
	if (!context) return null;

	context.font = `bold ${fontsize}px ${fontface}`;
	context.fillStyle = `rgba(${bgColor})`;
	context.fillRect(0, 0, width, height);
	
	// get size data (height depends only on font size)
	const metrics = context.measureText(text);
	const textWidth = metrics.width;
	
	// text color
	context.fillStyle = `rgba(${fgColor})`;
	context.fillText(text, width / 2 - textWidth / 2, height / 2 + fontsize / 2 - 2);
	
	// canvas contents will be used for a texture
	const texture = new THREE.Texture(canvas);
	texture.minFilter = THREE.LinearFilter;
	texture.needsUpdate = true;
	return texture;
}

class ViewCube extends THREE.Object3D {
	private cubeSize: number;
	private edgeSize: number;
	private outline: boolean;
	private bgColor: THREE.Color;
	private outlineColor: THREE.Color;
	
	constructor(size: number,	edge: number,	outline: boolean, bgColor: THREE.Color,	outlineColor: THREE.Color) {
		super();
		
		this.cubeSize = size;
		this.edgeSize = edge;
		this.outline = outline;
		this.bgColor = bgColor;
		this.outlineColor = outlineColor;

		this.build();
	}

	private build() {
		// 6 faces
		const cubeFaces = this.createCubeFaces();
		this.add(cubeFaces);

		// 4 top corners
		const topCorners = new THREE.Group();
		topCorners.add(this.createCornerFaces(SceneViewType.TOP_FRONT_RIGHT_CORNER, 0, true));
		topCorners.add(this.createCornerFaces(SceneViewType.TOP_BACK_RIGHT_CORNER, 90, true));
		topCorners.add(this.createCornerFaces(SceneViewType.TOP_BACK_LEFT_CORNER, 180, true));
		topCorners.add(this.createCornerFaces(SceneViewType.TOP_FRONT_LEFT_CORNER, 270, true));
		this.add(topCorners);
		// 4 bottom corners
		const bottomCorners = new THREE.Group();
		bottomCorners.add(this.createCornerFaces(SceneViewType.BOTTOM_FRONT_RIGHT_CORNER, 0, false));
		bottomCorners.add(this.createCornerFaces(SceneViewType.BOTTOM_BACK_RIGHT_CORNER, 90, false));
		bottomCorners.add(this.createCornerFaces(SceneViewType.BOTTOM_BACK_LEFT_CORNER, 180, false));
		bottomCorners.add(this.createCornerFaces(SceneViewType.BOTTOM_FRONT_LEFT_CORNER, 270, false));
		this.add(bottomCorners);

		// 4 top edges
		const topEdges = new THREE.Group();
		topEdges.add(this.createHorizontalEdgeFacesPair(SceneViewType.TOP_FRONT_EDGE, 0, true));
		topEdges.add(this.createHorizontalEdgeFacesPair(SceneViewType.TOP_RIGHT_EDGE, 90, true));
		topEdges.add(this.createHorizontalEdgeFacesPair(SceneViewType.TOP_BACK_EDGE, 180, true));
		topEdges.add(this.createHorizontalEdgeFacesPair(SceneViewType.TOP_LEFT_EDGE, 270, true));
		this.add(topEdges);
		// 4 bottom edges
		const bottomEdges = new THREE.Group();
		topEdges.add(this.createHorizontalEdgeFacesPair(SceneViewType.BOTTOM_FRONT_EDGE, 0, false));
		topEdges.add(this.createHorizontalEdgeFacesPair(SceneViewType.BOTTOM_RIGHT_EDGE, 90, false));
		topEdges.add(this.createHorizontalEdgeFacesPair(SceneViewType.BOTTOM_BACK_EDGE, 180, false));
		topEdges.add(this.createHorizontalEdgeFacesPair(SceneViewType.BOTTOM_LEFT_EDGE, 270, false));
		this.add(bottomEdges);

		// 4 vertical edges
		const sideEdges = new THREE.Group();
		sideEdges.add(this.createVerticalEdgeFacesPair(SceneViewType.FRONT_RIGHT_EDGE, 0));
		sideEdges.add(this.createVerticalEdgeFacesPair(SceneViewType.BACK_RIGHT_EDGE, 90));
		sideEdges.add(this.createVerticalEdgeFacesPair(SceneViewType.BACK_LEFT_EDGE, 180));
		sideEdges.add(this.createVerticalEdgeFacesPair(SceneViewType.FRONT_LEFT_EDGE, 270));
		this.add(sideEdges);

		// 8 outlines
		if (this.outline) {
			this.add(this.createOutline());
		}
	}

	private createOutline(): THREE.LineSegments {
		return new THREE.LineSegments(
			new THREE.EdgesGeometry(new THREE.BoxGeometry(this.cubeSize, this.cubeSize, this.cubeSize)),
			new THREE.LineBasicMaterial({ color: this.outlineColor, linewidth: 1.0 })
		);
	}
	private createCubeFaces(): THREE.Object3D {
		const faceSize = this.cubeSize - this.edgeSize * 2;
		const offset = this.cubeSize / 2; // from cube center

		const faces = new THREE.Object3D();
		faces.add(this.createFace(SceneViewType.TOP, faceSize, faceSize, new THREE.Vector3(0, 0, offset), new THREE.Vector3(0, 0, 0), {color: this.bgColor, map: createTextSprite(SceneViewType.TOP)}));
		faces.add(this.createFace(SceneViewType.BOTTOM, faceSize, faceSize, new THREE.Vector3(0, 0, -offset), new THREE.Vector3(0, 180, 180), {color: this.bgColor, map: createTextSprite(SceneViewType.BOTTOM)}));
		faces.add(this.createFace(SceneViewType.LEFT, faceSize, faceSize, new THREE.Vector3(-offset, 0, 0), new THREE.Vector3(90, 270, 0), {color: this.bgColor, map: createTextSprite(SceneViewType.LEFT)}));
		faces.add(this.createFace(SceneViewType.RIGHT, faceSize, faceSize, new THREE.Vector3(offset, 0, 0), new THREE.Vector3(90, 90, 0), {color: this.bgColor, map: createTextSprite(SceneViewType.RIGHT)}));
		faces.add(this.createFace(SceneViewType.BACK, faceSize, faceSize, new THREE.Vector3(0, offset, 0), new THREE.Vector3(-90, 0, 180), {color: this.bgColor, map: createTextSprite(SceneViewType.BACK)}));
		faces.add(this.createFace(SceneViewType.FRONT, faceSize, faceSize, new THREE.Vector3(0, -offset, 0), new THREE.Vector3(90, 0, 0), {color: this.bgColor, map: createTextSprite(SceneViewType.FRONT)}));
		return faces;
	}
	private createCornerFaces(name: string, rotation: number, isTop: boolean): THREE.Object3D {
		const offset = this.cubeSize / 2; // from cube center
		const borderOffset = offset - this.edgeSize / 2;
		
		const corner = new THREE.Object3D();
		corner.add(this.createFace(name, this.edgeSize, this.edgeSize, new THREE.Vector3(borderOffset, -offset, isTop ? borderOffset : -borderOffset), new THREE.Vector3(90, 0, 0), { color: this.bgColor })); // front
		corner.add(this.createFace(name, this.edgeSize, this.edgeSize, new THREE.Vector3(offset, -borderOffset, isTop ? borderOffset : -borderOffset), new THREE.Vector3(0, 90, 0), { color: this.bgColor })); // right
		corner.add(this.createFace(name, this.edgeSize, this.edgeSize, new THREE.Vector3(borderOffset, -borderOffset, isTop ? offset : -offset), new THREE.Vector3(isTop ? 0 : 180, 0, 0), { color: this.bgColor })); // top/bottom
		if (rotation != 0.0) {
			corner.rotateOnAxis(zAxis, rotation * toRad);
		}
		return corner;
	}
	private createHorizontalEdgeFacesPair(name: string, rotation: number, isTop: boolean): THREE.Object3D {
		const width = this.cubeSize - this.edgeSize * 2;
		const offset = this.cubeSize / 2; // from cube center
		const borderOffset = offset - this.edgeSize / 2;

		const edge = new THREE.Object3D();
		edge.add(this.createFace(name, width, this.edgeSize, new THREE.Vector3(0, -offset, isTop ? borderOffset : -borderOffset), new THREE.Vector3(90, 0, 0), { color: this.bgColor })); // front
		edge.add(this.createFace(name, width, this.edgeSize, new THREE.Vector3(0, -borderOffset, isTop ? offset : -offset), new THREE.Vector3(isTop ? 0 : 180, 0, 0), { color: this.bgColor })); // top/bottom
		if (rotation != 0.0) {
			edge.rotateOnAxis(zAxis, rotation * toRad);
		}
		return edge;
	}
	private createVerticalEdgeFacesPair(name: string, rotation: number): THREE.Object3D {
		const height = this.cubeSize - this.edgeSize * 2;
		const offset = this.cubeSize / 2; // from cube center
		const borderOffset = offset - this.edgeSize / 2;
		
		const edge = new THREE.Object3D();
		edge.add(this.createFace(name, this.edgeSize, height, new THREE.Vector3(borderOffset, -offset, 0), new THREE.Vector3(90, 0, 0), { color: this.bgColor })); // front
		edge.add(this.createFace(name, this.edgeSize, height, new THREE.Vector3(offset, -borderOffset, 0), new THREE.Vector3(90, 90, 0), { color: this.bgColor })); // right
		if (rotation != 0.0) {
			edge.rotateOnAxis(zAxis, rotation * toRad);
		}
		return edge;
	}
	private createFace(name: string, width: number, height: number, position: THREE.Vector3, rotations: THREE.Vector3, materialProps = {}): THREE.Mesh {
		const face = new THREE.Mesh(
			new THREE.PlaneBufferGeometry(width, height),
			new THREE.MeshBasicMaterial(materialProps)
		);
		face.name = name;
		
		// initially Up axis is zAxis; rotate in required direction
		if (rotations.x != 0.0) {
			face.rotateOnAxis(xAxis, rotations.x * toRad);
		}
		if (rotations.y != 0.0) {
			face.rotateOnAxis(yAxis, rotations.y * toRad);
		}
		if (rotations.z != 0.0) {
			face.rotateOnAxis(zAxis, rotations.z * toRad);
		}

		face.position.copy(position);
		
		return face;
	}
}

export default class ViewCubeControls extends THREE.EventDispatcher {
	private canvasSize: number;
	private cubeSize: number;
	private edgeSize: number;
	private cube: ViewCube = null;;
	private animation: any = null;
	private enabled: boolean = true;

  private renderer: THREE.WebGLRenderer = null;
	private scene: THREE.Scene = null;
	private camera: THREE.PerspectiveCamera = null;
	
	public get domElement() { return this.renderer?.domElement; }

	constructor(cubeSize: number, edgeSize: number, canvasSize: number) {
		super();
		
		this.cubeSize = cubeSize || 35.0;
		this.edgeSize = edgeSize || 5.0;
		this.canvasSize = canvasSize || 170;

		this.scene = new THREE.Scene();
		
		this.camera = new THREE.PerspectiveCamera(35, 1.0, 0.1, 1000);
		this.camera.position.set(0, 0, 100);
		this.camera.lookAt(this.scene.position);

		this.cube = new ViewCube(this.cubeSize,	this.edgeSize, true, new THREE.Color(CUBE_COLOR), new THREE.Color(OUTLINE_COLOR));
		this.scene.add(this.cube);

		this.onMouseMove = this.onMouseMove.bind(this);
		this.onMouseClick = this.onMouseClick.bind(this);
	}

	public init(): void {
    this.renderer = new THREE.WebGLRenderer({ alpha: true });
    this.renderer.setSize(this.canvasSize, this.canvasSize);
    this.renderer.domElement.style.position = "absolute";
    this.renderer.domElement.style.top = "20px";
    this.renderer.domElement.style.right = "20px";
    this.renderer.domElement.style.width = this.canvasSize + "px";
    this.renderer.domElement.style.height = this.canvasSize + "px";

		this.renderer.domElement.addEventListener('mousemove', this.onMouseMove);
		this.renderer.domElement.addEventListener('mouseleave', this.onMouseMove);
		this.renderer.domElement.addEventListener('click', this.onMouseClick);
	}
	public setQuaternion(quaternion: THREE.Quaternion): void {
		this.cube.setRotationFromQuaternion(quaternion.clone().invert());
		
		// wip
		// const base = { x: this.cube.rotation.x, y: this.cube.rotation.y, z: this.cube.rotation.z };
		// const object = new THREE.Object3D();
		// object.setRotationFromQuaternion(quaternion);
		// const delta = {
		// 	x: calculateAngleDelta(base.x, object.rotation.x),
		// 	y: calculateAngleDelta(base.y, object.rotation.y),
		// 	z: calculateAngleDelta(base.z, object.rotation.z)
		// };
		// let angleX = -TWOPI + base.x + delta.x;
		// let angleY = -TWOPI + base.y + delta.y;
		// let angleZ = -TWOPI + base.z + delta.z;
		// log.log('camera:', (angleX % TWOPI).toFixed(3), (angleY % TWOPI).toFixed(3), (angleZ % TWOPI).toFixed(3));
		// this.cube.rotation.set(angleX % TWOPI, angleY % TWOPI, angleZ % TWOPI);
	}
	public setActive(val: boolean): void {
    this.enabled = val;
		if (this.renderer) {
      this.renderer.domElement.style.display = val ? "block" : "none";
    }
  }
	public update(): void {
		if (this.animation) {
			const now = Date.now();
			const alpha = Math.min(((now - this.animation.time) / ROTATION_DURATION), 1);
			
			const ease = (Math.sin(((alpha * 2) - 1) * Math.PI * 0.5) + 1) * 0.5;
			let angleX = -TWOPI + this.animation.base.x + this.animation.delta.x * ease
			let angleY = -TWOPI + this.animation.base.y + this.animation.delta.y * ease;
			let angleZ = -TWOPI + this.animation.base.z + this.animation.delta.z * ease;
			this.cube.rotation.set(angleX % TWOPI, angleY % TWOPI, angleZ % TWOPI);

			if (alpha == 1) this.animation = null;

			this.dispatchEvent({
				type: 'angle-changed',
				quaternion: this.cube.quaternion.clone()
			});
		}
	}
	public render(): void {
		this.renderer?.render(this.scene, this.camera);
	}
	public dispose(): void {
		if (this.renderer) {
			this.renderer.domElement.removeEventListener('mousemove', this.onMouseMove);
			this.renderer.domElement.removeEventListener('mouseleave', this.onMouseMove);
			this.renderer.domElement.removeEventListener('click', this.onMouseClick);
		}
		
		this.renderer = null;
	}
	
	private onMouseMove(e): void {
		if (!this.enabled) return;

		// unhover
		this.cube.traverse((obj: THREE.Mesh) => {
			if (obj.name) {
				(obj.material as THREE.MeshBasicMaterial).color.setHex(CUBE_COLOR);
			}
		});

		// check hover
		const raycaster = new THREE.Raycaster();
		const x = (e.offsetX / e.target.clientWidth) * 2 - 1;
		const y = -(e.offsetY / e.target.clientHeight) * 2 + 1;
		raycaster.setFromCamera({ x, y }, this.camera);
		const intersects = raycaster.intersectObjects(this.cube.children, true);
		
		if (intersects.length) {
			for (let { object } of intersects) {
				if (object.name) {
					object.parent.children.forEach((child: THREE.Mesh) => {
						if (child.name === object.name) {
							(child.material as THREE.MeshBasicMaterial).color.setHex(HOVER_COLOR);
						}
					});
					break;
				}
			}
		}
	}
	private onMouseClick(e): void {
		if (!this.enabled) return;

		const x = (e.offsetX / e.target.clientWidth) * 2 - 1;
		const y = -(e.offsetY / e.target.clientHeight) * 2 + 1;
		
		const raycaster = new THREE.Raycaster();
		raycaster.setFromCamera({ x, y }, this.camera);
		
		const intersects = raycaster.intersectObjects(this.cube.children, true);
		if (intersects.length) {
			for (let { object } of intersects) {
				if (object.name) {
					this.processAreaClick(object.name);
					break;
				}
			}
		}
	}

	private processAreaClick(sceneViewType: string): void {
		const xOffset = -90;

		switch (sceneViewType) {
			// faces
			case SceneViewType.FRONT:	this.setCubeAngles(xOffset + 0, 0, 0); break;
			case SceneViewType.RIGHT: this.setCubeAngles(xOffset + 0, 0, -90); break;
			case SceneViewType.BACK: this.setCubeAngles(xOffset + 0, 0, -180); break;
			case SceneViewType.LEFT: this.setCubeAngles(xOffset + 0, 0, -270); break;
			case SceneViewType.TOP: this.setCubeAngles(xOffset + 90, 0, 0); break;
			case SceneViewType.BOTTOM: this.setCubeAngles(xOffset - 90, 0, 0); break;

			// vertical edges
			case SceneViewType.FRONT_RIGHT_EDGE: this.setCubeAngles(xOffset + 0, 0, -45); break;
			case SceneViewType.BACK_RIGHT_EDGE: this.setCubeAngles(xOffset + 0, 0, -135); break;
			case SceneViewType.BACK_LEFT_EDGE: this.setCubeAngles(xOffset + 0, 0, -225); break;
			case SceneViewType.FRONT_LEFT_EDGE: this.setCubeAngles(xOffset + 0, 0, -315); break;

			// horizontal top edges
			case SceneViewType.TOP_FRONT_EDGE: this.setCubeAngles(xOffset + 45, 0, 0); break;
			case SceneViewType.TOP_RIGHT_EDGE: this.setCubeAngles(xOffset + 45, 0, -90); break;
			case SceneViewType.TOP_BACK_EDGE: this.setCubeAngles(xOffset + 45, 0, -180); break;
			case SceneViewType.TOP_LEFT_EDGE: this.setCubeAngles(xOffset + 45, 0, -270); break;
			// horizontal bottom edges
			case SceneViewType.BOTTOM_FRONT_EDGE: this.setCubeAngles(xOffset - 45, 0, 0); break;
			case SceneViewType.BOTTOM_RIGHT_EDGE: this.setCubeAngles(xOffset - 45, 0, -90); break;
			case SceneViewType.BOTTOM_BACK_EDGE: this.setCubeAngles(xOffset - 45, 0, -180); break;
			case SceneViewType.BOTTOM_LEFT_EDGE: this.setCubeAngles(xOffset - 45, 0, -270); break;

			// top corners
			case SceneViewType.TOP_FRONT_RIGHT_CORNER: this.setCubeAngles(xOffset + 45, 0, -45); break;
			case SceneViewType.TOP_BACK_RIGHT_CORNER: this.setCubeAngles(xOffset + 45, 0, -135); break;
			case SceneViewType.TOP_BACK_LEFT_CORNER: this.setCubeAngles(xOffset + 45, 0, -225); break;
			case SceneViewType.TOP_FRONT_LEFT_CORNER: this.setCubeAngles(xOffset + 45, 0, -315); break;
			// bottom corners
			case SceneViewType.BOTTOM_FRONT_RIGHT_CORNER: this.setCubeAngles(xOffset - 45, 0, -45); break;
			case SceneViewType.BOTTOM_BACK_RIGHT_CORNER: this.setCubeAngles(xOffset - 45, 0, -135); break;
			case SceneViewType.BOTTOM_BACK_LEFT_CORNER: this.setCubeAngles(xOffset - 45, 0, -225); break;
			case SceneViewType.BOTTOM_FRONT_LEFT_CORNER: this.setCubeAngles(xOffset - 45, 0, -315); break;

			default: break;
		}
	}
	private setCubeAngles(x: number, y: number, z: number): void {
		const base = this.cube.rotation;
		
		this.animation = {
			time: Date.now(),
			base: {
				x: base.x,
				y: base.y,
				z: base.z
			},
			delta: {
				x: calculateAngleDelta(base.x, x * toRad),
				y: calculateAngleDelta(base.y, y * toRad),
				z: calculateAngleDelta(base.z, z * toRad)
			}
		};
	}
}
