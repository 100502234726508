const ErrorContainer = ({ type }) => {
  const errorMessageType = {
    geometrySmall: (
      <div>
        <span>Geometry Error</span>: Dutch Gable width is too small Try clicking adjacent wall to increase the width
      </div>
    ),
  };
  return (
    <div className="error-container">
      {errorMessageType[type]}
      <img src="/assets/icons/alert.svg" alt="alert" />
    </div>
  );
};

export default ErrorContainer;
