import { asSingle } from "../helpers/pojo";

const allKinds = {
  enums: {
    "1": {
      name: "kind",
      list: [
        { id: 0, name: "Unknown", table: "dictionary" },
        { id: 1, name: "Kind", table: "@kind" },
        { id: 10, name: "Message", table: "@message" },
        { id: 11, name: "Success", table: "@message" },
        { id: 12, name: "Info", table: "@message" },
        { id: 14, name: "Warning", table: "@message" },
        { id: 16, name: "Error", table: "@message" },
        { id: 18, name: "Server error", table: "@message" },
        { id: 19, name: "FATAL", table: "@message" },
        { id: 22, name: "Command", table: "!command" },
        { id: 30, name: "Mark record", table: "@mark" },
        { id: 33, name: "Status", table: "@status" },
        { id: 35, name: "Type of relations", table: "@rel-type" },
        { id: 77, name: "JSON Validation&transformation schemes", table: "@json-scheme" },
        { id: 82, name: "Log records", table: "velog" },
        { id: 83, name: "Log types", table: "@log-type" },
        { id: 88, name: "Key-value store", table: "key_values" },
        { id: 100, name: "User", table: "users" },
        { id: 110, name: "Client", table: "clients" },
        { id: 200, name: "Location", table: "locations" },
        { id: 300, name: "File", table: "files" },
        { id: 301, name: "File type", table: "@file-type" },
        { id: 400, name: "State", table: "states" },
        { id: 1000, name: "CorePlan", table: "core_plans" },
        { id: 1010, name: "Layout type", table: "@layout-type" },
        { id: 1100, name: "Floor plan", table: "floors" },
        { id: 1200, name: "Room type", table: "room_types" },
        { id: 1210, name: "Room entity", table: "room_entities" },
        { id: 1290, name: "Category", table: "categories" },
        { id: 1295, name: "Building type", table: "@buil_type" },
        { id: 1300, name: "Room", table: "rooms" },
        { id: 1400, name: "Variation", table: "variations" },
        { id: 1500, name: "Lennar Core Plan Data", table: "lennar_core_plan" },
        { id: 31000, name: "Relation with files", table: "rel_files" },
        { id: -100, name: "Settings", table: "@" },
        { id: -555, name: "DB migration", table: "knex_migrations" },
      ],
    },
    "35": {
      name: "Type of relations",
      list: [
        { id: 1, name: "Owner->driven,files", description: "Default" },
        { id: 100, name: "Person->driven", description: "User/client owns building entities" },
        { id: 1000, name: "thumbnail", description: "Thumbnail object" },
        { id: 1010, name: "background", description: "Background object" },
      ],
    },
    "30": {
      name: "Mark",
      list: [
        { id: 1, name: "Delete" },
        { id: 2, name: "Draft" },
        { id: 4, name: "Hidden" },
        { id: 8, name: "Pending" },
        { id: 16, name: "Inside" },
        { id: 32, name: "Public" },
        { id: 64, name: "Archive" },
      ],
    },
    "83": {
      name: "Log types",
      list: [
        { id: 10, name: "Trace" },
        { id: 20, name: "Debug" },
        { id: 30, name: "Info" },
        { id: 40, name: "Warning" },
        { id: 50, name: "Error" },
        { id: 100, name: "Backup" },
        { id: 1000, name: "User act" },
        { id: 2000, name: "Editor act" },
      ],
    },
    "301": {
      name: "File type",
      list: [{ id: 9, name: "reserved" }],
    },
    "1010": {
      name: "Layout type",
      list: [{ id: 100, name: "Single family home", forKind: 1000, description: "3 BRD|2 BA" }],
    },
    "1295": {
      name: "Building type",
      list: [
        { id: 1, name: "Unknown" },
        { id: 100, name: "SFH", forKind: 1000 },

        { id: 210, name: "Minus(-)", forKind: 1100, sort: 210, code: "m(-)" },
        { id: 220, name: "Additional level", forKind: 1100, sort: 220, code: "A" },
        { id: 230, name: "Basement", forKind: 1100, sort: 230, code: "B" },
        { id: 240, name: "Ground Floor", forKind: 1100, sort: 240, code: "G" },
        { id: 241, name: "Lower Ground Floor", forKind: 1100, sort: 241, code: "LG" },
        { id: 242, name: "Upper Ground Floor", forKind: 1100, sort: 242, code: "UG" },
        { id: 250, name: "Lobby", forKind: 1100, sort: 250, code: "L", description: "Lobby|Level" },
        { id: 252, name: "Second Level", forKind: 1100, sort: 252, code: "L2" },
        { id: 253, name: "Third Level", forKind: 1100, sort: 253, code: "L3" },
        { id: 260, name: "Mezzanine Floor", forKind: 1100, sort: 260, code: "M" },
        { id: 270, name: "Parking", forKind: 1100, sort: 270, code: "P", description: "Podium|Parking|Platform|Garage level" },
        { id: 280, name: "Roof", forKind: 1100, sort: 280, code: "R" },
        { id: 290, name: "Terrace", forKind: 1100, sort: 290, code: "T" },
      ],
    },
  },
  other: [],
};
export type KindRecordRaw = {
  id: number;
  kind: number;
  name: string;
  table: string;
  code?: string;
  class?: string;
  description?: string;
  forKind?: string;
  sort?: number;
  mark?: number;
};

function getDictList(kindData?: typeof allKinds) {
  if (kindData === undefined) kindData = allKinds;

  const dataKinds = kindData;

  const result: KindRecordRaw[] = [];

  Object.keys(dataKinds.enums).forEach(elem => {
    const kindId = parseInt(elem);

    dataKinds.enums[elem].list.forEach((row: any) => {
      const kR = {
        ...row,
        ...{
          id: row.id,
          kind: kindId,
          name: row.name || dataKinds.enums[elem].name,
          table: row.table || dataKinds.enums[elem].table,
        },
      };

      result.push(kR);
    });
  });

  return result;
}

const allRawData = getDictList();

type FindRawRecordArg = {
  table?: string;
  description?: string;
  name?: string;
  id?: number;
  kind?: number;
  forKind?: number;
  sort?: number;
  code?: string;
};

type FuncFilter = (elem: KindRecordRaw) => boolean;
const addCheck = (func: FuncFilter, funcAdd: FuncFilter): FuncFilter => {
  return (elem: KindRecordRaw) => funcAdd(elem) && func(elem);
};

export const findRawRecord = (find: FindRawRecordArg): KindRecordRaw[] => {
  let filter: FuncFilter = elem => true;

  for (const [key, val] of Object.entries(find)) {
    filter = addCheck(filter, elem => elem[key] === val);
  }

  return allRawData.filter(filter);
};

const kindCorePlanRaw = asSingle(findRawRecord({ table: "core_plans" }));
const kindFileRaw = asSingle(findRawRecord({ table: "files" }));
const kindCategoryRaw = asSingle(findRawRecord({ table: "categories" }));
const kindRoomTypeRaw = asSingle(findRawRecord({ table: "room_types" }));
const kindRoomEntityRaw = asSingle(findRawRecord({ table: "room_entities" }));
const kindRoomRaw = asSingle(findRawRecord({ table: "rooms" }));
const kindFloorRaw = asSingle(findRawRecord({ table: "floors" }));
const kindBackgroundRaw = asSingle(findRawRecord({ name: "background" }));
const kindVariationRaw = asSingle(findRawRecord({ table: "variations" }));
const kindLennarCorePlanDataRaw = asSingle(findRawRecord({ table: "lennar_core_plan" }));

const markPublic = asSingle(findRawRecord({ name: "Public" }));
const markPending = asSingle(findRawRecord({ name: "Pending" }));
const markDelete = asSingle(findRawRecord({ name: "Delete" }));
const markHidden = asSingle(findRawRecord({ name: "Hidden" }));

export {
  markPublic,
  markPending,
  markHidden,
  kindCorePlanRaw,
  kindFloorRaw,
  kindBackgroundRaw,
  kindFileRaw,
  kindCategoryRaw,
  kindRoomTypeRaw,
  kindRoomEntityRaw,
  kindRoomRaw,
  kindVariationRaw,
  kindLennarCorePlanDataRaw,
  markDelete,
};

const _cacheById: { [key: number]: KindRecordRaw } = {};

export function searchKindByID(id: number) {
  if (_cacheById[id]) return _cacheById[id];

  return (_cacheById[id] = asSingle(findRawRecord({ id, kind: 1 })));
}
