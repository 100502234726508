import { Col, Row } from "antd";
import { observer } from "mobx-react-lite";
import { CorePlan } from "../../../../models/CorePlan";
import "./TabCorePlanVariations.sass";
import CorePlanVariationItem from "./CorePlanVariationItem";
import { useEffect, useState } from "react";
import { appModel } from "../../../../models/AppModel";
import { Variation } from "../../../../models/Variation";

interface ITabCorePlanVariationsProps {
  corePlan: CorePlan;
}

const TabCorePlanVariations = observer(({ corePlan: corePlan }: ITabCorePlanVariationsProps) => {
  const variations = [...corePlan.variations].sort((a: Variation, b: Variation) => a.name.localeCompare(b.name));
  const [loadingCount, setLoadingCount] = useState(variations.length);

  useEffect(() => {
    if (loadingCount === 0) {
      appModel.setIsBusy(false);
    } else {
      appModel.setIsBusy(true);
    }
  }, [loadingCount]);

  return (
    <>
      <Row gutter={24} className="tab-core-plan-variations">
        {!variations.length && (
          <Col span={24}>
            <p>No variations</p>
          </Col>
        )}
      </Row>
      {variations.length > 0 && (
        <>
          <Row className="tab-core-plan-variations">
            <Col>
              <p>Click on a variation to view package files</p>
            </Col>
          </Row>
          <Row gutter={24} className="tab-core-plan-variations">
            {variations.map(item => (
              <CorePlanVariationItem variation={item} corePlan={corePlan} onLoadComplete={() => setLoadingCount(prev => prev - 1)} />
            ))}
          </Row>
        </>
      )}
    </>
  );
});
export default TabCorePlanVariations;
