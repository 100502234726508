export type KeyValue = { [key: string]: any };
export type KeyString = { [key: string]: string };
export enum SettingsUnits {
  ft = "ft",
  sqft = "sqft",
  units = "units",
  hex = "#hex",
  plf = "plf",
  percent = "%",
  psf = "psf",
}

export type TWebAppUISettings = {
  displayUnits: "feet" | "inch";
  selectionColor: string;
  highlightColor: string;
  wallsColor: string;
  floorsColor: string;
  openingsColor: string;
  gridPrimaryColor: string;
  gridSecondaryColor: string;
  lotLinesColor: string;
  lotSetbackColor: string;
  obsoleteRoomsColor: string;
  glassColor: string;
  doorColor: string;
  roofSurface: string;
  roofEdge: string;
  gravityExteriorLBColor: string;
  gravityStackedLBColor: string;
  gravityNonLBColor: string;
  gravityBeamColor: string;
  shearWalls: string;
  shearObstructionOnCurrentLevel: string;
  shearObstructionOnLevelBelow: string;
  livingSpaces: string;
  garage: string;
  porchesAndPatio: string;
  roof: string;
};

export type TCorePlanRanges = {
  storiesMax: number;
  netFloorToCeilingMin: number;
  netFloorToCeilingMax: number;
  netFloorToCeilingIncrement: number;
  bedroomMin: number;
  bedroomMax: number;
  bathroomMin: number;
  bathroomMax: number;
  carsMax: number;
};

export type TCorePlanDefaults = {
  numOfStories: number;
  numOfBedrooms: number;
  numOfBathrooms: number;
  numOfHalfBathrooms: number;
  numOfCars: number;
  minBuildingNetArea: number;
  maxBuildingGrossArea: number;
  lotArea: number;
  lotWidth: number;
  lotLength: number;
  netFloorToCeiling: number;
  netCeilingToFloor: number;
  lotFrontSetback: number;
  lotBackSetback: number;
  lotSideSetback: number;
  lotFront2Setback: number;
};

export type TValidationSettings = {
  gravityMaxFloorSpan: number;
  gravityMaxBeamSpan: number;
  gravityMaxCantileverSpan: number;
  shearF1cap: number;
  shearF2cap: number;
  shearSeismicFactorCd: number;
  shearComboDesignFactor: number;
  shearPreliminaryFactor: number;
  exteriorWallWeight: number;
  interiorWallWeight: number;
  beamWeight: number;
  floorWeight: number;
  roofWeight: number;
  openingAlignmentMinGap: number;
  areaCalculationExteriorOffset: number;
  gridCellSizeForNewCorePlans: number;
  stretchAllowance: number;
  frameSize: number;
  openingStructuralTolerance: number;
  edgeOfExteriorWallTolerance: number;
  firstFloorPlumbingPointMaxOffset: number;
  pipeFallTolerance: number;
  minimalWallSegmentLength: number;
  roofDefaultSlope: number;
  roofDefaultOverhang: number;
  minimumGableSeparation: number;
  windowEdgeOffset: number;
  doorEdgeOffset: number;
};

export type AreaTakeOffSettings = {
  livingSpaces: string;
  garage: string;
  porchesAndPatio: string;
  roof: string;
};

export type TSettingsValues = {
  corePlanRangesSettings: TCorePlanRanges;
  corePlanDefaults: TCorePlanDefaults;
  validationSettings: TValidationSettings;
  webAppUISettings: TWebAppUISettings;
};

export type TSettingsDescriptions = {
  corePlanRangesSettings: { [Key in keyof TCorePlanRanges]: string };
  corePlanDefaults: { [Key in keyof TCorePlanDefaults]: string };
  validationSettings: { [Key in keyof TValidationSettings]: string };
  webAppUISettings: { [Key in keyof TWebAppUISettings]: string };
};

export type TSettings = {
  values: TSettingsValues;
  descriptions: TSettingsDescriptions;
};
