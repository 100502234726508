import ApiCore from "./utilities/Core";
import log from "loglevel";

const url = "logs";
const plural = "logs";
const single = "logs";

export const APIlogs = new ApiCore({
  getAll: false,
  getSingle: false,
  post: true,
  put: true,
  patch: true,
  delete: false,
  url: url,
  plural: plural,
  single: single,
});

APIlogs.post = data => {
  log.info(data);
};
