import log from "loglevel";
import * as THREE from "three";
import { settings } from "../../../entities/settings";
import { appModel } from "../../../models/AppModel";
import { Floor } from "../../../models/Floor";
import { MIN_SHEAR_WALL_LENGTH_FACTOR } from "../../consts";
import RoomManager from "../../managers/RoomManager/RoomManager";
import { IShearCapacityValidationResult } from "../../models/ValidationResult";
import { Segment } from "../../models/segments/Segment";
import { StrSegment } from "../../models/segments/StrSegment";
import GeometryUtils from "../../utils/GeometryUtils";
import MathUtils from "../../utils/MathUtils";
import SceneUtils from "../../utils/SceneUtils";
import SegmentsUtils from "../../utils/SegmentsUtils";
import UnitsUtils from "../../utils/UnitsUtils";
import { WallAnalysisUtils } from "../../utils/WallAnalysisUtils";
import GravityLoadsValidationTool from "./GravityLoadsValidationTool";
import { IValidationTool } from "./IValidationTool";

const zeroLevelHeight = 12; //in inches

interface IWallsLength {
  horizontalExternalLength: number;
  verticalExternalLength: number;
  horizontalInternalLength: number;
  verticalInternalLength: number;
  horizontalPlmLength: number;
  verticalPlmLength: number;
}

export default class ShearCapacityValidationTool implements IValidationTool {
  private validationResult: Map<string, IShearCapacityValidationResult> = new Map<string, IShearCapacityValidationResult>();
  private nonShearWalls: Map<string, Segment[]> = new Map<string, Segment[]>();
  private shearWalls: Map<string, Segment[]> = new Map<string, Segment[]>();
  private lowerFloorShearWalls: Map<string, Segment[]> = new Map<string, Segment[]>();

  constructor(
    private roomManager: RoomManager,
    private gravityLoadsValidationTool: GravityLoadsValidationTool
  ) {}

  public performValidation(): void {
    this.resetResult();
    this.gravityLoadsValidationTool.performValidation(false);
    const bearingWalls = this.gravityLoadsValidationTool.getSegmentsResult();
    this.shearCapacityCheck(bearingWalls);
    this.setValidationResult();
    this.performCalculations();
  }

  public visualizeValidationResult(container: THREE.Group, floorId: string): void {
    const thickness = UnitsUtils.getSyntheticWallHalfSize();
    this.shearWalls.get(floorId)?.forEach(r => {
      container.add(SceneUtils.createSegmentPlane(r, thickness, settings.getColorNumber("shearWalls")));
    });
    this.nonShearWalls.get(floorId)?.forEach(r => {
      container.add(SceneUtils.createSegmentPlane(r, thickness, settings.getColorNumber("shearObstructionOnLevelBelow")));
    });
    this.lowerFloorShearWalls.get(floorId)?.forEach(r => {
      container.add(SceneUtils.createSegmentPlane(r, thickness, settings.getColorNumber("shearObstructionOnCurrentLevel")));
    });
  }

  public getFloorValidationResult(floorId: string): IShearCapacityValidationResult {
    const result = this.validationResult.get(floorId);
    return result;
  }

  public resetResult() {
    this.shearWalls.clear();
    this.nonShearWalls.clear();
    this.lowerFloorShearWalls.clear();
    this.validationResult.clear();
  }

  public shearCapacityCheck(bearingWalls: { stacked: Map<string, StrSegment[]>; nonStacked: Map<string, StrSegment[]> }): Map<string, Segment[]> {
    const stackedWalls = new Map<string, Segment[]>();
    const minShearLength = appModel.activeCorePlan.floorToFloorHeight / MIN_SHEAR_WALL_LENGTH_FACTOR;

    appModel.activeCorePlan.floors.forEach(f => {
      const soFloor = this.roomManager.getSoFloor(f.id);
      const openings = SceneUtils.getRoomsOpeningsAsLines(soFloor.children);
      this.nonShearWalls.set(
        f.id,
        bearingWalls.nonStacked.get(f.id).flatMap(wall => SegmentsUtils.getNotOverlappedSegmentParts(wall, openings))
      );
      const newStackedWalls = bearingWalls.stacked.get(f.id).flatMap(wall => SegmentsUtils.getNotOverlappedSegmentParts(wall, openings));
      this.nonShearWalls.get(f.id).push(...newStackedWalls.filter(w => w.length() < minShearLength));
      stackedWalls.set(
        f.id,
        newStackedWalls.filter(w => w.length() >= minShearLength)
      );
    });

    // Sorted bottom up.
    const floors = [...appModel.activeCorePlan.floors].sort((a, b) => a.index - b.index);
    for (let i = 0; i < floors.length; i++) {
      const floorId = floors[i].id;
      const lowerFloorId = floors[i - 1]?.id;
      const walls = stackedWalls.get(floorId);

      if (lowerFloorId) {
        this.shearWalls.set(floorId, []);
        this.lowerFloorShearWalls.set(floorId, []);
        const lowerShearWalls = this.shearWalls.get(lowerFloorId).map(sw => new Segment(sw.start.clone(), sw.end.clone()));
        walls.forEach(wall => {
          ShearCapacityValidationTool.extractShearNonShearWalls(
            wall,
            lowerShearWalls,
            minShearLength,
            this.shearWalls.get(floorId),
            this.nonShearWalls.get(floorId)
          );
        });
        this.lowerFloorShearWalls.set(
          floorId,
          lowerShearWalls.flatMap(wall => SegmentsUtils.getNotOverlappedSegmentParts(wall, this.nonShearWalls.get(floorId)))
        );
      } else {
        this.shearWalls.set(floorId, walls);
      }
    }
    return this.shearWalls;
  }

  private performCalculations(): void {
    const forceOnEachFloor = this.calculateShearForce();
    this.calculateFloorCapacity(forceOnEachFloor);
    this.calculateEccentricityPercentage();
  }

  private calculateShearForce(): { floorId: string; forceOnFloor: number }[] {
    const floors = [...appModel.activeCorePlan.floors].sort((a, b) => a.index - b.index);
    const wallsLength = this.getFloorWallsLength(floors);
    const wallHeight = appModel.activeCorePlan.floorToFloorHeight;
    const { exteriorWallWeight, interiorWallWeight, floorWeight, roofWeight, shearSeismicFactorCd } = settings.values.validationSettings;
    if (log.getLevel() < log.levels.INFO) {
      console.groupCollapsed("SHEAR FORCE CALCULATIONS");
    }

    const levelWeights = [];
    for (let i = 0; i < floors.length; i++) {
      const lengths = wallsLength[i];
      const floor = floors[i];
      const floorArea = floor.getIndoorArea();
      const internalWallLength = lengths.horizontalInternalLength + lengths.verticalInternalLength + lengths.horizontalPlmLength + lengths.verticalPlmLength;
      const externalWallLength = lengths.horizontalExternalLength + lengths.verticalExternalLength;
      const levelWallsWeight = externalWallLength * wallHeight * exteriorWallWeight * 0.5 + internalWallLength * wallHeight * interiorWallWeight * 0.5;

      if (i === 0) {
        const levelFloorWeight = floorArea * floorWeight;
        levelWeights.push(levelFloorWeight + levelWallsWeight);
        log.debug(
          `m0 = A0 * f0 + externalWallLength${i + 1} * wallHeight * EXTERNAL_WALL_WEIGHT * 0.5 + internalWallLengthLevel${
            i + 1
          } * wallHeight * INTERNAL_WALL_WEIGHT * 0.5 = ` +
            `${floorArea} * ${floorWeight} + ${externalWallLength} * ${wallHeight} * ${exteriorWallWeight} * 0.5 + ${internalWallLength} * ${wallHeight} * ${interiorWallWeight} * 0.5 = ${
              levelFloorWeight + levelWallsWeight
            }`
        );
      }

      const ceilingLoad = floor.index === appModel.activeCorePlan.floors.length - 1 ? roofWeight : floorWeight;
      const ceilingWeight = floorArea * ceilingLoad;
      let levelWeight = ceilingWeight + levelWallsWeight;

      if (i < floors.length - 1) {
        const upperLengths = wallsLength[i + 1];
        const upperInternalWallLength =
          upperLengths.horizontalInternalLength + upperLengths.verticalInternalLength + upperLengths.horizontalPlmLength + upperLengths.verticalPlmLength;
        const upperExternalWallLength = upperLengths.horizontalExternalLength + upperLengths.verticalExternalLength;
        const upperLevelWallWeights =
          upperExternalWallLength * wallHeight * exteriorWallWeight * 0.5 + upperInternalWallLength * wallHeight * interiorWallWeight * 0.5;
        levelWeight += upperLevelWallWeights;
        log.debug(
          `m${i + 1} = A${i + 1} * f${i + 1} + externalWallLengthLevel${i + 1} * wallHeight * EXTERNAL_WALL_WEIGHT * 0.5 + internalWallLengthLevel${
            i + 1
          } * wallHeight * INTERNAL_WALL_WEIGHT * 0.5 + ` +
            `externalWallLengthLevel${i + 2} * wallHeight * EXTERNAL_WALL_WEIGHT * 0.5 + internalWallLengthLevel${
              i + 2
            } * wallHeight * INTERNAL_WALL_WEIGHT * 0.5  = ` +
            `${floorArea} * ${ceilingLoad} + ${externalWallLength} * ${wallHeight} * ${exteriorWallWeight} * 0.5 + ${internalWallLength} * ${wallHeight} * ${interiorWallWeight} * 0.5 ` +
            `+ ${upperExternalWallLength} * ${wallHeight} * ${exteriorWallWeight} * 0.5 + ${upperInternalWallLength} * ${wallHeight} * ${interiorWallWeight} * 0.5 = ${levelWeight}`
        );
      } else {
        log.debug(
          `m${i + 1} = A${i + 1} * f${i + 1} + externalWallLengthLevel${i + 1} * wallHeight * EXTERNAL_WALL_WEIGHT * 0.5 + internalWallLength${
            i + 1
          } * wallHeight * INTERNAL_WALL_WEIGHT * 0.5 = ` +
            `${floorArea} * ${ceilingLoad} + ${externalWallLength} * ${wallHeight} * ${exteriorWallWeight} * 0.5 + ${internalWallLength} * ${wallHeight} * ${interiorWallWeight} * 0.5 = ${levelWeight}`
        );
      }

      levelWeights.push(levelWeight);
    }

    const buildingWeight = levelWeights.reduce((sum, weight) => sum + weight, 0);
    log.debug(`W = ${levelWeights.join(" + ")} = ${buildingWeight}`);
    const shearForceAtTheBase = buildingWeight * shearSeismicFactorCd;
    log.debug(`F_base = W * Cd = ${buildingWeight} * ${shearSeismicFactorCd} = ${shearForceAtTheBase}`);
    let sumOfCeilingWeightsMultipliedByHeight = levelWeights[0] * zeroLevelHeight;

    const forceOnFloors = [];
    for (let i = 0; i < floors.length; i++) {
      const levelWeightByHeight = levelWeights[i + 1] * wallHeight;
      sumOfCeilingWeightsMultipliedByHeight += levelWeightByHeight;
      const forceOnFloor = (levelWeightByHeight / sumOfCeilingWeightsMultipliedByHeight) * shearForceAtTheBase;
      log.debug(
        `F${i + 1} = (m${i + 1} * h${i + 1})/sum${i + 1}OfWeightsByHeight * F_base = ${
          levelWeights[i + 1]
        } * ${wallHeight} / ${sumOfCeilingWeightsMultipliedByHeight} * ${shearForceAtTheBase} = ${forceOnFloor}`
      );
      forceOnFloors.push({ floorId: floors[i].id, forceOnFloor });
    }
    if (log.getLevel() < log.levels.INFO) {
      console.groupEnd();
    }

    return forceOnFloors;
  }

  private calculateFloorCapacity(forceOnFloor: { floorId: string; forceOnFloor: number }[]) {
    if (log.getLevel() < log.levels.INFO) {
      console.groupCollapsed("FLOOR CAPACITY CALCULATIONS");
    }
    const { shearF1cap, shearComboDesignFactor } = settings.values.validationSettings;

    for (let i = 0; i < forceOnFloor.length; i++) {
      if (log.getLevel() < log.levels.INFO) {
        console.groupCollapsed(`Floor ${i + 1}`);
      }
      const floorForce = forceOnFloor[i];
      const shearLength = this.getDirectionalWallsLength(this.shearWalls.get(floorForce.floorId));
      const horizontalCapacity = shearLength.horizontal * shearF1cap;
      const verticalCapacity = shearLength.vertical * shearF1cap;
      log.debug(`horizontalCapacity = horizontalShearLength * wallsCapacity = ${shearLength.horizontal} * wallsCapacity = ${horizontalCapacity}`);
      log.debug(`verticalCapacity = verticalShearLength * wallsCapacity = ${shearLength.vertical} * wallsCapacity = ${verticalCapacity}`);

      const minCapacity = floorForce.forceOnFloor * shearComboDesignFactor;
      log.debug(`desiredMinCapacity = forceOnFloor * FLOOR_CAPACITY_COEFFICIENT = ${floorForce.forceOnFloor} * ${shearComboDesignFactor} = ${minCapacity}`);

      const horizontalWallDeviation = (minCapacity - horizontalCapacity) / shearF1cap;
      const verticalWallDeviation = (minCapacity - verticalCapacity) / shearF1cap;

      log.debug(
        `horizontalWallDeviation = (minCapacity - horizontalCapacity) / wallsCapacity = (${minCapacity} - ${horizontalCapacity}) / ${shearF1cap} = ${horizontalWallDeviation}`
      );
      log.debug(
        `verticalWallDeviation = (minCapacity - verticalCapacity) / wallsCapacity = (${minCapacity} - ${verticalCapacity}) / ${shearF1cap} = ${verticalWallDeviation}`
      );

      if (log.getLevel() < log.levels.INFO) {
        console.groupEnd();
      }

      this.validationResult.get(floorForce.floorId).horizontalWallDeviation = -horizontalWallDeviation;
      this.validationResult.get(floorForce.floorId).verticalWallDeviation = -verticalWallDeviation;

      const nonShear = this.getDirectionalWallsLength(this.nonShearWalls.get(floorForce.floorId));

      const totalHorizontalLength = shearLength.horizontal + nonShear.horizontal;
      const totalVerticalLength = shearLength.vertical + nonShear.vertical;

      const desiredHorizontal = ((shearLength.horizontal + horizontalWallDeviation) / totalHorizontalLength) * 100;
      const desiredVertical = ((shearLength.vertical + verticalWallDeviation) / totalVerticalLength) * 100;
      this.validationResult.get(floorForce.floorId).desiredHorizontalPercentage = desiredHorizontal;
      this.validationResult.get(floorForce.floorId).desiredVerticalPercentage = desiredVertical;
    }

    if (log.getLevel() < log.levels.INFO) {
      console.groupEnd();
    }
  }

  private calculateEccentricityPercentage(): void {
    if (log.getLevel() < log.levels.INFO) {
      console.groupCollapsed("ECCENTRICITIES PERCENTAGE CALCULATIONS");
    }

    log.debug("Formula for relative rigidity of each wall = 4 * (wallHeight / wallLength)^3 + 3 * (wallHeight / wallLength)");

    appModel.activeCorePlan.floors.forEach(floor => {
      if (log.getLevel() < log.levels.INFO) {
        console.groupCollapsed(floor.name);
      }

      const { floorWeight, roofWeight, exteriorWallWeight } = settings.values.validationSettings;

      // Ceiling of the current floor is a diaphragm.
      const floorArea = floor.rooms.reduce((sum, room) => (appModel.getRoomType(room.roomTypeId).attributes.indoor ? sum + room.area : sum), 0);
      const ceilingLoad = floor.index === appModel.activeCorePlan.floors.length - 1 ? roofWeight : floorWeight;
      const ceilingWeight = floorArea * ceilingLoad;
      log.debug(`diaphragmWeight = floorArea * ceilingLoad = ${floorArea} * ${ceilingLoad} = ${ceilingWeight}`);

      const wallHeight = appModel.activeCorePlan.floorToFloorHeight;
      const floorBbox = this.getFloorIndoorBoundingBox(floor);
      const floorCenter = floorBbox.getCenter(new THREE.Vector3());
      const floorSize = floorBbox.getSize(new THREE.Vector3());

      const shearWalls = this.shearWalls.get(floor.id);
      const walls = shearWalls.map(wall => {
        const isHorizontal = wall.isHorizontal();
        // For shear walls weight took EXTERNAL_WALL_WEIGHT.
        const length = wall.length();
        const weight = length * wallHeight * exteriorWallWeight;
        const heightByLength = wallHeight / length;
        return {
          weight,
          isHorizontal,
          centerX: (wall.start.x + wall.end.x) / 2,
          centerY: (wall.start.y + wall.end.y) / 2,
          relativeRigidity: 1 / (4 * heightByLength * heightByLength * heightByLength + 3 * heightByLength),
        };
      });

      const wallWeightsSum = walls.reduce((sum, wall) => sum + wall.weight, 0);
      const weightsSum = wallWeightsSum + ceilingWeight;
      const centerOfMassX = (walls.reduce((sum, wall) => sum + wall.centerX * wall.weight, 0) + ceilingWeight * floorCenter.x) / weightsSum;
      const centerOfMassY = (walls.reduce((sum, wall) => sum + wall.centerY * wall.weight, 0) + ceilingWeight * floorCenter.y) / weightsSum;
      log.debug(`centerOfMassX = (sum(wallWeight * wallCenterX) + ceilingWeight * floorCenter.x) / (sum(wallWeight) + ceilingWeight)
        = (${walls.reduce((sum, wall) => sum + wall.centerX * wall.weight, 0)} + ${ceilingWeight} * ${
          floorCenter.x
        }) / (${wallWeightsSum} + ${ceilingWeight}) = ${centerOfMassX}`);
      log.debug(`centerOfMassY = (sum(wallWeight * wallCenterY) + ceilingWeight * floorCenter.y) / (sum(wallWeight) + ceilingWeight)
        = (${walls.reduce((sum, wall) => sum + wall.centerY * wall.weight, 0)} + ${ceilingWeight} * ${
          floorCenter.y
        }) / (${wallWeightsSum} + ${ceilingWeight}) = ${centerOfMassY}`);

      const [sumOfRigiditiesX, sumOfRigiditiesY] = walls.reduce(
        (sum, seg) => {
          const index = seg.isHorizontal ? 0 : 1;
          sum[index] = sum[index] + seg.relativeRigidity;
          return sum;
        },
        [0, 0]
      );

      const centerOfRigidityX = walls.reduce((sum, wall) => (!wall.isHorizontal ? sum + wall.centerX * wall.relativeRigidity : sum), 0) / sumOfRigiditiesY;
      const centerOfRigidityY = walls.reduce((sum, wall) => (wall.isHorizontal ? sum + wall.centerY * wall.relativeRigidity : sum), 0) / sumOfRigiditiesX;
      log.debug(
        `centerOfRigidityX = sum(wallRelativeRigidityY * wallCenterX) / sum(wallRelativeRigidityY) = ${walls.reduce(
          (sum, wall) => (!wall.isHorizontal ? sum + wall.centerX * wall.relativeRigidity : sum),
          0
        )} / ${sumOfRigiditiesY} = ${centerOfRigidityX}`
      );
      log.debug(
        `centerOfRigidityY = sum(wallRelativeRigidityX * wallCenterY) / sum(wallRelativeRigidityX) = ${walls.reduce(
          (sum, wall) => (wall.isHorizontal ? sum + wall.centerY * wall.relativeRigidity : sum),
          0
        )} / ${sumOfRigiditiesX} = ${centerOfRigidityY}`
      );
      const eccentricityPercentageX = Math.abs(centerOfRigidityX - centerOfMassX) / floorSize.x;
      const eccentricityPercentageY = Math.abs(centerOfRigidityY - centerOfMassY) / floorSize.y;
      log.debug(
        `eccentricityPercentageX = abs(centerOfRigidityX - centerOfMassX) / floorSizeX = abs(${centerOfRigidityX} - ${centerOfMassX}) / ${floorSize.x} = ${eccentricityPercentageX}`
      );
      log.debug(
        `eccentricityPercentageY = abs(centerOfRigidityY - centerOfMassY) / floorSizeY = abs(${centerOfRigidityY} - ${centerOfMassY}) / ${floorSize.y} = ${eccentricityPercentageY}`
      );
      this.validationResult.get(floor.id).eccentricityPercentageX = eccentricityPercentageX;
      this.validationResult.get(floor.id).eccentricityPercentageY = eccentricityPercentageY;

      if (log.getLevel() < log.levels.INFO) {
        console.groupEnd();
      }
    });

    if (log.getLevel() < log.levels.INFO) {
      console.groupEnd();
    }
  }

  private getFloorIndoorBoundingBox(floor: Floor): THREE.Box3 {
    const floorBbox = new THREE.Box3();
    floor.rooms.forEach(room => {
      if (appModel.getRoomType(room.roomTypeId).attributes.indoor) {
        const soRoom = this.roomManager.getCorePlanSoRoom(room.id);

        const roomBbox = SceneUtils.getRoomBoundingBoxByModelLines(soRoom);
        floorBbox.union(roomBbox);
      }
    });
    return floorBbox;
  }

  private setValidationResult() {
    appModel.activeCorePlan.floors.forEach(floor => {
      const [horizontalShearLength, verticalShearLength] = this.shearWalls.get(floor.id).reduce(
        (sum, seg) => {
          const index = seg.isHorizontal() ? 0 : 1;
          sum[index] = sum[index] + seg.length();
          return sum;
        },
        [0, 0]
      );

      const [horizontalNonShearLength, verticalNonShearLength] = this.nonShearWalls.get(floor.id).reduce(
        (sum, seg) => {
          const index = seg.isHorizontal() ? 0 : 1;
          sum[index] = sum[index] + seg.length();
          return sum;
        },
        [0, 0]
      );

      const totalHorizontalLength = horizontalShearLength + horizontalNonShearLength;
      const totalVerticalLength = verticalShearLength + verticalNonShearLength;
      const totalWallLength = totalHorizontalLength + totalVerticalLength;

      this.validationResult.set(floor.id, {
        presentSegmentTypes: [
          ...(this.shearWalls.get(floor.id)?.length > 0 ? ["Shear"] : []),
          ...(this.lowerFloorShearWalls.get(floor.id)?.length > 0 ? ["NonContinuantCurrent"] : []),
          ...(this.nonShearWalls.get(floor.id)?.length > 0 ? ["NonContinuantBelow"] : []),
        ],
        totalWallLength: MathUtils.round(totalWallLength),
        "X-axis": [
          { type: "Shear", percent: MathUtils.round((horizontalShearLength / totalHorizontalLength) * 100) },
          { type: "Non", percent: MathUtils.round((horizontalNonShearLength / totalHorizontalLength) * 100) },
        ].filter(f => f.percent > 0),
        "Y-axis": [
          { type: "Shear", percent: MathUtils.round((verticalShearLength / totalVerticalLength) * 100) },
          { type: "Non", percent: MathUtils.round((verticalNonShearLength / totalVerticalLength) * 100) },
        ].filter(f => f.percent > 0),
        directions: [
          { name: "X-axis", percent: MathUtils.round((totalHorizontalLength / totalWallLength) * 100) },
          { name: "Y-axis", percent: MathUtils.round((totalVerticalLength / totalWallLength) * 100) },
        ],
      });
    });
  }

  private getFloorWallsLength(floors: Floor[]): IWallsLength[] {
    const walls = [];

    floors.forEach(f => {
      const soRooms = this.roomManager.getSoFloor(f.id).children;
      const { externalSegments, internalSegments } = WallAnalysisUtils.collectSegments(soRooms);
      const [horizontalPlm, verticalPlm] = soRooms
        .flatMap(r => SceneUtils.getRoomPLMWalls(r))
        .map(wall => GeometryUtils.getBoundingBoxCenterLine(GeometryUtils.getGeometryBoundingBox2D(wall)))
        .reduce(
          (sum, line) => {
            const index = GeometryUtils.isLineHorizontal(line) ? 0 : 1;
            sum[index] = sum[index] + line.distance();
            return sum;
          },
          [0, 0]
        );
      const [horizontalExternal, verticalExternal] = externalSegments.reduce(
        (sum, seg) => {
          const index = seg.isHorizontal() ? 0 : 1;
          sum[index] = sum[index] + seg.length();
          return sum;
        },
        [0, 0]
      );
      const [horizontalInternal, verticalInternal] = internalSegments.reduce(
        (sum, seg) => {
          if (!seg.hasWall) {
            return sum;
          }
          const index = seg.isHorizontal() ? 0 : 1;
          sum[index] = sum[index] + seg.length();
          return sum;
        },
        [0, 0]
      );

      walls.push({
        horizontalExternalLength: horizontalExternal,
        verticalExternalLength: verticalExternal,
        horizontalInternalLength: horizontalInternal,
        verticalInternalLength: verticalInternal,
        horizontalPlmLength: horizontalPlm,
        verticalPlmLength: verticalPlm,
      });
    });

    return walls;
  }

  private getDirectionalWallsLength(walls: Segment[]) {
    const [horizontal, vertical] = walls.reduce(
      (sum, seg) => {
        const index = seg.isHorizontal() ? 0 : 1;
        sum[index] = sum[index] + seg.length();
        return sum;
      },
      [0, 0]
    );
    return { horizontal, vertical };
  }

  static extractShearNonShearWalls(wall: Segment, lowerFloorWalls: Segment[], minLength: number, shearWalls: Segment[], nonShearWalls: Segment[]) {
    const isHorizontal = wall.isHorizontal();
    const comp = isHorizontal ? "x" : "y";
    const otherComp = isHorizontal ? "y" : "x";

    const overlappedLowerWalls = lowerFloorWalls
      .filter(
        lw =>
          isHorizontal === lw.isHorizontal() &&
          MathUtils.areNumbersEqual(wall.start[otherComp], lw.end[otherComp], UnitsUtils.getMaxWallDeviation()) &&
          wall.end[comp] >= lw.start[comp] &&
          lw.end[comp] >= wall.start[comp]
      )
      .sort((a, b) => a.start[comp] - b.start[comp]);

    if (!overlappedLowerWalls.length) {
      nonShearWalls.push(new Segment(wall.start.clone(), wall.end.clone(), wall.roomId));
      return;
    }

    let lastStartValue = wall.start[comp];

    for (const lowerSegment of overlappedLowerWalls) {
      const overlap = [Math.max(wall.start[comp], lowerSegment.start[comp]), Math.min(wall.end[comp], lowerSegment.end[comp])];

      const newShearStart = isHorizontal ? new THREE.Vector2(overlap[0], wall.start.y) : new THREE.Vector2(wall.start.x, overlap[0]);
      const newShearEnd = isHorizontal ? new THREE.Vector2(overlap[1], wall.start.y) : new THREE.Vector2(wall.start.x, overlap[1]);
      const newShear = new Segment(newShearStart, newShearEnd, wall.roomId);
      (newShear.length() < minLength ? nonShearWalls : shearWalls).push(newShear);

      if (lastStartValue < overlap[0]) {
        const newNonShearStart = isHorizontal ? new THREE.Vector2(lastStartValue, wall.start.y) : new THREE.Vector2(wall.start.x, lastStartValue);
        const newNonShearEnd = isHorizontal ? new THREE.Vector2(overlap[0], wall.start.y) : new THREE.Vector2(wall.start.x, overlap[0]);
        const newNonShear = new Segment(newNonShearStart, newNonShearEnd, wall.roomId);
        nonShearWalls.push(newNonShear);
      }

      lastStartValue = overlap[1];

      if (MathUtils.areNumbersEqual(overlap[1] - overlap[0], lowerSegment.length())) {
        lowerFloorWalls.splice(lowerFloorWalls.indexOf(lowerSegment), 1);
      } else if (!MathUtils.areNumbersEqual(overlap[0], lowerSegment.start[comp]) && !MathUtils.areNumbersEqual(overlap[1], lowerSegment.end[comp])) {
        const newIntSegmentStart = isHorizontal ? new THREE.Vector2(overlap[1], lowerSegment.start.y) : new THREE.Vector2(lowerSegment.start.x, overlap[1]);
        const newIntSegmentEnd = isHorizontal
          ? new THREE.Vector2(lowerSegment.end.x, lowerSegment.start.y)
          : new THREE.Vector2(lowerSegment.start.x, lowerSegment.end.y);
        const newInternalSegment = new Segment(newIntSegmentStart, newIntSegmentEnd);
        lowerFloorWalls.push(newInternalSegment);
        lowerSegment.end[comp] = overlap[0];
      } else if (MathUtils.areNumbersEqual(overlap[0], lowerSegment.start[comp])) {
        lowerSegment.start[comp] = overlap[1];
      } else if (MathUtils.areNumbersEqual(overlap[1], lowerSegment.end[comp])) {
        lowerSegment.end[comp] = overlap[0];
      }
    }

    if (lastStartValue < wall.end[comp] && !MathUtils.areNumbersEqual(lastStartValue, wall.end[comp])) {
      const newNonShearStart = isHorizontal ? new THREE.Vector2(lastStartValue, wall.start.y) : new THREE.Vector2(wall.start.x, lastStartValue);
      const newNonShearEnd = isHorizontal ? new THREE.Vector2(wall.end[comp], wall.start.y) : new THREE.Vector2(wall.start.x, wall.end[comp]);
      const newNonShear = new Segment(newNonShearStart, newNonShearEnd, wall.roomId);
      nonShearWalls.push(newNonShear);
    }
  }
}
