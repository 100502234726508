import { observer } from "mobx-react";
import { FileEntity } from "../../../../models/FileEntity";
import { Document, Page } from "react-pdf";
import { useEffect, useState } from "react";
import apiProvider from "../../../../services/api/utilities/Provider";
import { FolderFilled } from "@ant-design/icons";
import log from "loglevel";

export interface IPdfItem {
  file: FileEntity;
  onSelectPdf: (file?: FileEntity) => void;
  selectFileAsBackground: (file?: FileEntity, convertPdf?: boolean, pageNumber?: number) => void;
}

const PdfItem = observer(({ file, onSelectPdf, selectFileAsBackground }: IPdfItem) => {
  const [numPages, setNumPages] = useState(null);
  const [fileContent, setFileContent] = useState<string>();

  useEffect(() => {
    const strippedUri = file.URI.replace("/files/", "");
    apiProvider.getFile(`files/${strippedUri}?encode=base64`).then(content => {
      setFileContent("data:/plain;base64," + content);
    });
  }, [file]);

  const onPdfClick = async () => {
    if (numPages === 1) {
      selectFileAsBackground(file, true, 1);
    } else {
      onSelectPdf(file);
    }
  };

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  if (!fileContent) {
    return null;
  }

  return (
    <div className="pdf-item" onClick={onPdfClick}>
      <Document className="pdf-view" file={fileContent} onLoadSuccess={onDocumentLoadSuccess} onLoadError={log.error} key={file.id}>
        <Page width={100} pageNumber={1} renderTextLayer={false} renderAnnotationLayer={false} />
      </Document>
      {numPages > 1 && <FolderFilled />}
    </div>
  );
});
export default PdfItem;
