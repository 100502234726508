import { observer } from "mobx-react-lite";
import { appModel } from "../../../models/AppModel";
import LoadingSpinner from "../common/LoadingSpinner";
import "../common/LoadingSpinner.sass";

const EditorSpinner = observer(() => {
  if (appModel.isBusy) {
    return (
      <div className="spinner-container" data-testid="spinner">
        <LoadingSpinner />
      </div>
    );
  }
  return null;
});

export default EditorSpinner;
