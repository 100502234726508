import { appModel } from "../../models/AppModel";
import RoomManager from "../managers/RoomManager/RoomManager";
import SceneUtils from "../utils/SceneUtils";
import { showToastMessage } from "../../helpers/messages";
import { costEstimateManager } from "../../entities/costEstimateManager";
import { CorePlan } from "../../models/CorePlan";
import { MergeSegmentsMode } from "../models/SegmentsMergeMode";
import { WallAnalysisUtils } from "../utils/WallAnalysisUtils";
import { VeevParameters } from "../../models/RevitRoomType";

const inchesInSqft = 144;
interface ICorePlanCostJson {
  amount_of_homes: 100 | 1000;
  building_sqft: number;
  roof_sqft: number;
  foundation_sqft: number;
  //location: "California" | "Texas";
  number_of_garages: number;
  number_of_staircases: number;
  number_of_entrances: number;
  room_packages: string[];
}

export default class CorePlanCostTool {
  constructor(private roomManager: RoomManager) {}

  public generateCorePlanCostJson(): ICorePlanCostJson {
    appModel.setIsBusy(true);
    this.roomManager.updateCostStamp();
    const floors = [...appModel.activeCorePlan.floors].sort((a, b) => a.index - b.index);
    const allFloorsGrossArea = floors.map(floor => this.roomManager.tmlTool.calculateGrossArea(floor) / inchesInSqft);
    const allRooms = floors.flatMap(f => f.rooms);
    const roomsPackageNames = allRooms.map(r => {
      const roomType = appModel.getRoomType(r.roomTypeId);
      return roomType?.settings?.veevParameters[VeevParameters.CostRoomType];
    });

    const garages = allRooms.filter(r => {
      const roomType = appModel.getRoomType(r.roomTypeId);
      const roomCategory = appModel.getRoomCategory(roomType.roomCategoryId);
      return roomCategory.isGarage;
    });

    const firstSoFloor = this.roomManager.getSoFloor(floors[0].id);
    const rooms = floors[0].rooms.filter(room => appModel.getRoomType(room.roomTypeId).attributes.indoor);
    const foundationFloorSoRooms = firstSoFloor.children.filter(child => rooms.some(room => room.id === child.userData.id));
    const { externalSegments } = WallAnalysisUtils.collectSegments(foundationFloorSoRooms, MergeSegmentsMode.All);
    const firstFloorDoors = SceneUtils.getRoomsOpeningsAsLines(firstSoFloor.children, true);
    const entrenches = firstFloorDoors.filter(d => externalSegments.some(es => es.overlapsSegment(d)));
    const floorsWithRoomsLength = floors.filter(f => f.rooms.length).length;

    const json = {
      amount_of_homes: 100,
      building_sqft: Math.round(allFloorsGrossArea.reduce((sum, area) => sum + area, 0)),
      roof_sqft: Math.round(allFloorsGrossArea[allFloorsGrossArea.length - 1]),
      foundation_sqft: Math.round(allFloorsGrossArea[0]),
      //location: "California",
      number_of_garages: garages.length,
      number_of_staircases: floorsWithRoomsLength > 0 ? floorsWithRoomsLength - 1 : 0,
      number_of_entrances: entrenches.length,
      room_packages: roomsPackageNames,
    } as ICorePlanCostJson;
    appModel.setIsBusy(false);

    return json;
  }

  public async updateCorePlanCost(corePlan: CorePlan, corePlanCostJson: ICorePlanCostJson): Promise<void> {
    const availablePackages = await costEstimateManager.getAvailablePackages();
    if (!availablePackages) {
      return;
    }
    const filteredPackaged = corePlanCostJson.room_packages.filter(rn => availablePackages.includes(rn));
    if (filteredPackaged.length !== corePlanCostJson.room_packages.length) {
      showToastMessage(
        "Error",
        `Cost for ${corePlanCostJson.room_packages.length - filteredPackaged.length} rooms in the '${corePlan.name}' corePlan can not be calculated`
      );
    }
    corePlanCostJson.room_packages = filteredPackaged;
    const costResult = await costEstimateManager.getCostEstimate(corePlanCostJson);
    if (typeof costResult?.cost !== "number") {
      return;
    }
    const newCosts = {
      cost: costResult.cost,
      costPerSqft: corePlanCostJson.building_sqft > 0 ? costResult.cost / corePlanCostJson.building_sqft : 0,
      co2Emission: costResult.co2Emission,
    };
    const result = await costEstimateManager.updateCorePlanCost(corePlan, newCosts);
    if (result && this.roomManager.isCostStampOutdated()) {
      corePlan.setIsCostOutdated(true);
    }
  }
}
