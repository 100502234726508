import { useState } from "react";
import { Button, Card, Dropdown, Menu, Modal, Tooltip } from "antd";
import { observer } from "mobx-react-lite";
import { useHistory } from "react-router-dom";

import { entityManager } from "../../../entities/entityManager";
import { arrayIdSet, getDropdownTrigger } from "../../../helpers/utilities";
import { appModel } from "../../../models/AppModel";
import { CorePlan } from "../../../models/CorePlan";
import { ContentMode, openModalCorePlanWindow } from "./ModalCorePlanWindow";
import { corePlanLockModalState } from "./CorePlanLockModal";
import LoadingSpinner from "../common/LoadingSpinner";
import { openModalCorePlanPackageWindow } from "./ModalCorePlanPackageWindow";
import { CorePlanPackageDetailTabs } from "./CorePlanDetails/CorePlanPackageDetails";
import { US_STATES } from "../../../editor/consts";
import { inches2feet } from "../../../helpers/measures";

import "./CorePlanListItem.sass";

type CorePlanItemProps = {
  corePlan: CorePlan;
};

let CorePlanListItem = ({ corePlan }: CorePlanItemProps) => {
  const history = useHistory();
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isCorePlanDeleting, setIsCorePlanDeleting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dropdownTrigger = getDropdownTrigger();

  const isLockedByCurrentUser = corePlan.isLockedByCurrentUser();
  const isLockedByAnotherUser = !isLockedByCurrentUser && corePlan.isLocked();

  const { floors, bathRooms, halfBathRooms, bedRooms, garage, lotWidth } = corePlan.attributes;
  const totalBathrooms = (bathRooms ? bathRooms : 0) + (halfBathRooms ? halfBathRooms / 2 : 0);

  const onCorePlanClick = async () => {
    try {
      setIsLoading(true);
      const newest = await entityManager.getCorePlan(corePlan.id);
      if (!newest) {
        return;
      }
      arrayIdSet(appModel.corePlans, newest);

      if (newest.isLockedByCurrentUser() || !newest.isLocked()) {
        history.push(`/editor/${newest.id}`);
        return;
      }

      // Open corePlan lock dialog
      corePlanLockModalState.setCorePlan(newest);
    } finally {
      setIsLoading(false);
    }
  };
  const onCorePlanUnlockClick = async e => {
    e.domEvent.stopPropagation();
    try {
      setIsLoading(true);
      await entityManager.unlockCorePlan(corePlan);
    } finally {
      setIsLoading(false);
    }
  };
  const onCorePlanEditClick = e => {
    e.domEvent.stopPropagation();
    openModalCorePlanWindow(corePlan, ContentMode.Edit);
  };
  const onCorePlanCloneClick = e => {
    e.domEvent.stopPropagation();

    openModalCorePlanWindow(corePlan, ContentMode.Clone);
  };
  const onCorePlanDetails = e => {
    e.domEvent.stopPropagation();
    openModalCorePlanPackageWindow(corePlan, CorePlanPackageDetailTabs.CorePlanOverview);
  };
  const onCorePlanDeleteClick = e => {
    e.domEvent.stopPropagation();
    setIsConfirmModalOpen(true);
  };
  const onConfirmFloorsDeletion = async () => {
    setIsCorePlanDeleting(true);
    await entityManager.delete(corePlan);
    setIsCorePlanDeleting(false);
    setIsConfirmModalOpen(false);
  };
  const onCancelFloorsDeletion = () => {
    setIsConfirmModalOpen(false);
  };

  let editSettingKey = 1;
  const editSetting = (
    <Menu
      className="dropdown-edit"
      items={[
        {
          label: "Edit Request",
          key: editSettingKey++,
          onClick: onCorePlanEditClick,
          disabled: isLockedByAnotherUser,
        },
        // {
        //   label: "Edit Layout",
        //   key: editSettingKey++,
        //   onClick: onCorePlanClick, // NOSONAR
        // },
        isLockedByCurrentUser && {
          label: "Unlock",
          key: editSettingKey++,
          onClick: onCorePlanUnlockClick, // NOSONAR
        },
        {
          label: "Package",
          key: editSettingKey++,
          onClick: onCorePlanDetails,
        },
        {
          label: "Duplicate Core Plan",
          key: editSettingKey++,
          onClick: onCorePlanCloneClick,
        },
        {
          label: "Delete Core Plan",
          key: editSettingKey++,
          onClick: onCorePlanDeleteClick,
          className: "withSeparator",
        },
      ]}
    />
  );

  // Function to get the state abbreviation with a "#"
  const getStateAbbreviation = stateName => {
    const state = US_STATES.find(([name]) => name === stateName);
    return state ? `#${state[1]}` : "--";
  };

  const getCorePlanConcatenatedName = corePlan => {
    return `${corePlan.lennar_id.toString()} ${corePlan.name}`;
  };

  const getCorePlanConcatenatedSeries = corePlan => {
    return `${inches2feet(lotWidth).toString()}ft ${corePlan.series}`;
  };

  const handleOnCorePlanClick = () => {
    onCorePlanClick();
  };

  return (
    <div className="card">
      {isLoading && (
        <div className="spinner-container spinner-container-global">
          <LoadingSpinner />
        </div>
      )}
      <Modal open={isConfirmModalOpen} confirmLoading={isCorePlanDeleting} onOk={onConfirmFloorsDeletion} onCancel={onCancelFloorsDeletion}>
        Are you sure you want to delete corePlan {corePlan.name}?<br />
        All data related to it will be deleted.
      </Modal>
      <Card onClick={handleOnCorePlanClick} className="card-corePlan" title={getStateAbbreviation(corePlan.state)} bordered={false} data-testid="corePlanItem">
        {/* <div className="client">{corePlan.client && <p>{corePlan.client}</p>}</div> */}
        <div>
          <span className="corePlan-name">{getCorePlanConcatenatedName(corePlan)}</span>
        </div>
        <div>
          <span className="corePlan-series">{getCorePlanConcatenatedSeries(corePlan)}</span>
        </div>
        <div className="attributes-wrapper">
          <div className="attributes-list">
            <Tooltip placement="bottom" title={`${floors ? floors : 0} Story`}>
              <div className="room-type-element">
                <i className="icon icon-floors"></i>
                <span className="room-type-info">{floors ? floors : 0}</span>
              </div>
            </Tooltip>
            <Tooltip placement="bottom" title={`${bedRooms ? bedRooms : 0} Bedrooms`}>
              <div className="room-type-element">
                <i className="icon icon-bedrooms"></i>
                <span className="room-type-info">{bedRooms ? bedRooms : 0}</span>
              </div>
            </Tooltip>
            <Tooltip placement="bottom" title={`${totalBathrooms} Bathrooms`}>
              <div className="room-type-element">
                <i className="icon icon-bathrooms"></i>
                <span className="room-type-info">{totalBathrooms}</span>
              </div>
            </Tooltip>
            <Tooltip placement="bottom" title={`${garage ? garage : 0} Cars`}>
              <div className="room-type-element">
                <i className="icon icon-garage"></i>
                <span className="room-type-info">{garage ? garage : 0}</span>
              </div>
            </Tooltip>
            <Tooltip placement="bottom" title={`${corePlan.variations ? corePlan.variations.length : 0} Variations`}>
              <div className="room-type-element">
                <i className="icon icon-variations"></i>
                <span className="room-type-info">{corePlan.variations ? corePlan.variations.length : 0}</span>
              </div>
            </Tooltip>
          </div>
          <Dropdown overlay={editSetting} placement="bottomLeft" trigger={dropdownTrigger as any}>
            <Button className="dropdown-edit-button" onClick={e => e.stopPropagation()} data-testid="dropdown-edit-button">
              ...
            </Button>
          </Dropdown>
        </div>
      </Card>
    </div>
  );
};

CorePlanListItem = observer(CorePlanListItem);

export default CorePlanListItem;
