import axios from "../utilities/axios-wrapper";
import { getAxiosRequestConfig } from "./";
import { Envelope } from "../../Envelope";
import { handleResponse, handleError } from "./Response";

const getAll = (resource: string, hRes?: any): Promise<Envelope> => {
  return axios
    .get(`${apiProvider.host}${resource}/`, getAxiosRequestConfig())
    .then(hRes || handleResponse)
    .catch(handleError);
};

/** @param {string} resource */
/** @param {string} id */
const getSingle = (resource: any, id: any) => {
  return axios.get(`${apiProvider.host}${resource}/${id}`, getAxiosRequestConfig()).then(handleResponse).catch(handleError);
};

const getFile = (resource: any) => {
  return axios.get(`${apiProvider.host}${resource}`, getAxiosRequestConfig()).then(handleResponse).catch(handleError);
};

/** @param {string} resource */
/** @param {object} model */
const post = (resource: any, model: any) => {
  return axios.post(`${apiProvider.host}${resource}`, model, getAxiosRequestConfig()).then(handleResponse).catch(handleError);
};

/** @param {string} resource */
/** @param {object} model */
const put = (resource: any, model: any) => {
  return axios.put(`${apiProvider.host}${resource}`, model, getAxiosRequestConfig()).then(handleResponse).catch(handleError);
};

/** @param {string} resource */
/** @param {object} model */
const patch = (resource: any, model: any) => {
  return axios.patch(`${apiProvider.host}${resource}`, model, getAxiosRequestConfig()).then(handleResponse).catch(handleError);
};

/** @param {string} resource */
/** @param {string} id */
const remove = (resource: any, id: string) => {
  return axios.delete(`${apiProvider.host}${resource}/${id}`, getAxiosRequestConfig()).then(handleResponse).catch(handleError);
};

const clearCatalog = () => {
  return axios.delete(`${apiProvider.host}catalog/clear/`, getAxiosRequestConfig()).then(handleResponse).catch(handleError);
};

const apiProvider = {
  _host: undefined,
  get host(): string {
    return this._host || (this._host = `${process.env.REACT_APP_API_HOST}/`);
  },
  getAll,
  getSingle,
  getFile,
  post,
  put,
  patch,
  remove,
  clearCatalog,
};

export default apiProvider;
