import { Direction } from "./Direction";

export class SnapData {
  isSameFloor = true;
  direction: Direction = Direction.Horizontal;

  distance: number = Number.MAX_VALUE; // distance in given direction
  alignDistance: number = Number.MAX_VALUE; // distance in perpendicular direction
  cornerDistance: number = Number.MAX_VALUE; // distance in perpendicular direction

  hasSharedObjects: boolean = false;
  hasIntersectedSharedObjects: boolean = false;

  roomId: string = "";
  otherRoomId: string = "";

  constructor(direction: Direction = Direction.Horizontal, distance: number = Number.MAX_VALUE) {
    this.direction = direction;
    this.distance = distance;
  }

  get absDistance(): number {
    return Math.abs(this.distance);
  }
  get absAlignDistance(): number {
    return Math.abs(this.alignDistance);
  }
}
