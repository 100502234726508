import { appModel } from "../models/AppModel";
import { kindCorePlanRaw } from "../services/allKinds";

type ModelType = { id?: string; name: string; kind?: number };
export function createNameSuffixNumber(model: ModelType | string, suffix: string, opts?: any) {
  if (typeof model === "string") model = { name: model, kind: kindCorePlanRaw.id };
  let collection: ModelType[] = appModel.corePlans;
  if (model.kind === kindCorePlanRaw.id && opts?.[kindCorePlanRaw.id]) collection = opts?.[kindCorePlanRaw.id];

  const posStartSuffix = model.name.indexOf(suffix);
  const cleanedName = (posStartSuffix < 0 ? model.name : model.name.substring(0, posStartSuffix)).trimEnd();

  const findingCollection = collection.filter(el => el.name.startsWith(cleanedName));

  let number = 2;
  let result = cleanedName + suffix;
  while (findingCollection.some(el => el.name.startsWith(result))) {
    result = cleanedName + suffix + ` (${number})`;
    ++number;
  }

  return result;
}
