import { defaultSettings } from "../entities/settings/defaults";
import nextBuilID from "./builSequence";

const prepCorePlans = [
  {
    id: nextBuilID(),
    name: "PRJ 1",
    lennar_id: "123",
    notes: "some text",
    attributes: {
      lotSize: 0,
      floors: 3,
      bedRooms: 3,
      garage: 1,
      bathRooms: 3.5,
      floorHeight: defaultSettings.corePlanDefaults.netFloorToCeiling,
    },
    floors: [],
  },
  {
    id: nextBuilID(),
    name: "PRJ 2",
    lennar_id: "124",
    notes: "some text",
    attributes: {
      lotSize: 0,
      floors: 3,
      bedRooms: 3,
      garage: 1,
      bathRooms: 3.5,
      floorHeight: defaultSettings.corePlanDefaults.netFloorToCeiling,
    },
  },
  {
    id: nextBuilID(),
    name: "PRJ 3",
    lennar_id: "125",
    notes: "some text",
    attributes: {
      lotSize: 0,
      floors: 3,
      bedRooms: 3,
      garage: 1,
      bathRooms: 3.5,
      floorHeight: defaultSettings.corePlanDefaults.netFloorToCeiling,
    },
  },
  {
    id: nextBuilID(),
    name: "PRJ 4",
    lennar_id: "126",
    notes: "some text",
    attributes: {
      lotSize: 0,
      floors: 3,
      bedRooms: 3,
      garage: 1,
      bathRooms: 3.5,
      floorHeight: defaultSettings.corePlanDefaults.netFloorToCeiling,
    },
  },
  {
    id: nextBuilID(),
    name: "PRJ 5",
    lennar_id: "127",
    notes: "some text",
    attributes: {
      lotSize: 0,
      floors: 3,
      bedRooms: 3,
      garage: 1,
      bathRooms: 3.5,
      floorHeight: defaultSettings.corePlanDefaults.netFloorToCeiling,
    },
  },
  {
    id: nextBuilID(),
    name: "PRJ 6",
    lennar_id: "128",
    notes: "some text",
    attributes: {
      lotSize: 0,
      floors: 3,
      bedRooms: 3,
      garage: 1,
      bathRooms: 3.5,
      floorHeight: defaultSettings.corePlanDefaults.netFloorToCeiling,
    },
  },
];

const listCorePlan = prepCorePlans.map(elem => Object.assign(elem, { kind: 1000, mark: 32 }));

export default listCorePlan;
