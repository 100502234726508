import * as THREE from "three";
import { settings } from "../../../entities/settings";
import { LOT_LINE_EDGE_RENDER_ORDER, LOT_LINE_OFFSET_RENDER_ORDER, LOT_LINE_VERTEX_RENDER_ORDER } from "../../consts";
import UnitsUtils from "../../utils/UnitsUtils";
import { SceneEntityType } from "../SceneEntityType";

export interface SoLotItem extends THREE.Object3D {
  redraw(): void;
  move(offset: THREE.Vector3, needRedraw?: boolean): void;
  getPosition(): THREE.Vector3;
}

export class SoLotLineVertex extends THREE.Mesh implements SoLotItem {
  public edges: SoLotLineEdge[] = [];

  constructor(point: THREE.Vector3 = new THREE.Vector3(), lotItemId?: string) {
    super(
      new THREE.CircleGeometry(0.35 * UnitsUtils.getConversionFactor(), 32),
      new THREE.MeshBasicMaterial({ color: settings.getColorNumber("lotLinesColor"), transparent: true, opacity: 1.0 })
    );

    this.userData.id = lotItemId;
    this.userData.type = SceneEntityType.LotLineVertex;
    this.renderOrder = LOT_LINE_VERTEX_RENDER_ORDER;
    this.position.copy(point);
  }

  public redraw(): void {
    this.edges.forEach(edge => edge.redraw());
  }

  public move(offset: THREE.Vector3, needRedraw: boolean = true): void {
    this.position.add(offset);
    this.edges.forEach(edge => edge.invalidate());

    if (needRedraw) {
      this.redraw();
    }
  }

  public linkEdge(soEdge: SoLotLineEdge): void {
    const index = this.edges.indexOf(soEdge);
    if (index === -1) {
      this.edges.push(soEdge);
    }
  }

  public unlinkEdge(soEdge: SoLotLineEdge): void {
    const index = this.edges.indexOf(soEdge);
    if (index !== -1) {
      this.edges.splice(index, 1);
    }
  }

  public getPosition(): THREE.Vector3 {
    return this.position;
  }
}

export class SoLotLineEdge extends THREE.Line implements SoLotItem {
  private needRedraw: boolean = false;

  public start: SoLotLineVertex;
  public end: SoLotLineVertex;

  constructor(start: SoLotLineVertex, end: SoLotLineVertex, lotItemId?: string) {
    super(
      new THREE.BufferGeometry().setFromPoints([start.position.clone(), end.position.clone()]),
      new THREE.LineBasicMaterial({ color: settings.getColorNumber("lotLinesColor"), transparent: true, opacity: 1.0 })
    );

    this.start = start;
    this.end = end;
    this.userData.id = lotItemId;
    this.userData.type = SceneEntityType.LotLineEdge;
    this.renderOrder = LOT_LINE_EDGE_RENDER_ORDER;

    this.start.linkEdge(this);
    this.end.linkEdge(this);
  }

  public redraw(): void {
    if (this.needRedraw) {
      this.needRedraw = false;
      this.geometry.setFromPoints([this.start.position.clone(), this.end.position.clone()]);
      this.geometry.computeBoundingBox();
      this.geometry.computeBoundingSphere();

      this.start.redraw();
      this.end.redraw();
    }
  }

  public invalidate(): void {
    this.needRedraw = true;
  }

  public move(offset: THREE.Vector3, needRedraw: boolean = true): void {
    this.start.move(offset, false);
    this.end.move(offset, false);

    if (needRedraw) {
      this.redraw();
    }
  }

  public getPosition(): THREE.Vector3 {
    return this.start.position.clone().add(this.end.position).multiplyScalar(0.5);
  }
}

export class SoLotLineOffset extends THREE.Line {
  start: THREE.Vector3;
  end: THREE.Vector3;

  constructor(start: THREE.Vector3, end: THREE.Vector3, edgeId: string, floorId?: string, lotItemId?: string) {
    super(
      new THREE.BufferGeometry().setFromPoints([start.clone(), end.clone()]),
      new THREE.LineDashedMaterial({
        color: settings.getColorNumber("lotSetbackColor"),
        dashSize: 0.5 * UnitsUtils.getConversionFactor(),
        gapSize: 0.3 * UnitsUtils.getConversionFactor(),
        transparent: true,
        opacity: 1.0,
      })
    );

    this.start = start;
    this.end = end;
    this.userData.edgeId = edgeId;
    this.userData.id = lotItemId;
    this.userData.floorId = floorId;
    this.userData.type = SceneEntityType.LotLineOffset;
    this.renderOrder = LOT_LINE_OFFSET_RENDER_ORDER;
  }
}
