import * as THREE from "three";

export class ExteriorFinish {
  id: string;
  name: string;
  index: number;
  color: string;
  image: string;
  panelFamily: string;
  panelType: string;
  createdAt: string;
  updatedAt: string;

  get colorRGB(): { r: number; g: number; b: number } {
    const rgb = this.color?.split(",")?.map(it => Number.parseInt(it)) || [];

    return {
      r: rgb[0] || 0,
      g: rgb[1] || 0,
      b: rgb[2] || 0,
    };
  }

  get colorCSS(): string {
    const color = this.colorRGB;
    return `rgb(${color.r}, ${color.g}, ${color.b})`;
  }

  clone(): ExteriorFinish {
    const clone = new ExteriorFinish();

    clone.id = this.id;
    clone.name = this.name;
    clone.index = this.index;
    clone.color = this.color;
    clone.image = this.image;
    clone.panelFamily = this.panelFamily;
    clone.panelType = this.panelType;
    clone.createdAt = this.createdAt;
    clone.updatedAt = this.updatedAt;

    return clone;
  }

  static fromJs(data: Partial<ExteriorFinish>): ExteriorFinish {
    const result = new ExteriorFinish();
    result.id = data.id;
    result.name = data.name;
    result.index = data.index;
    result.color = data.color;
    result.image = data.image;
    result.panelFamily = data.panelFamily;
    result.panelType = data.panelType;
    result.createdAt = data.createdAt;
    result.updatedAt = data.updatedAt;

    return result;
  }

  static createFallback(): ExteriorFinish {
    return ExteriorFinish.fromJs({
      id: "00000000-0000-0000-0000-000000000000",
      name: null,
      index: -1,
      color: "200,200,200",
      image: null,
      panelFamily: null,
      panelType: null,
    });
  }
}

export class Roof {
  public id: string;
  public imageClassName: string;
  public displayName: string;
  color: string;

  get colorRGB(): { r: number; g: number; b: number } {
    const rgb = this.color?.split(",")?.map(it => Number.parseInt(it)) || [];

    return {
      r: rgb[0] || 216,
      g: rgb[1] || 216,
      b: rgb[2] || 216,
    };
  }

  get colorCSS(): string {
    const color = this.colorRGB;
    return `rgb(${color.r}, ${color.g}, ${color.b})`;
  }

  public static getHip(): Roof {
    const gable = new Roof();
    gable.id = "hip";
    gable.imageClassName = "hip-image";
    gable.displayName = "Hip";
    return gable;
  }

  public static getGable(): Roof {
    const gable = new Roof();
    gable.id = "gable";
    gable.imageClassName = "gable-image";
    gable.displayName = "Gable";
    return gable;
  }

  public static getDutchGable(): Roof {
    const gable = new Roof();
    gable.id = "ducthGable";
    gable.imageClassName = "dutch-gable-image";
    gable.displayName = "Dutch Gable";
    return gable;
  }
}

export class DesignStyle {
  id: string;
  name: string;
  description: string;
  finishes: ExteriorFinish[];
  roofs: Roof[];

  static fromJS(data: any): DesignStyle {
    const result = new DesignStyle();
    result.id = data.id;
    result.name = data.name;
    result.description = data.description;
    result.finishes = data.finishes.map(it => ExteriorFinish.fromJs(it));
    result.roofs = [Roof.getHip(), Roof.getGable(), Roof.getDutchGable()];

    return result;
  }
}

export class ColorHandler {
  private color: string;

  constructor(color: string) {
    this.color = color;
  }

  get colorRGBA(): { r: number; g: number; b: number; a: number } {
    const rgba = this.color?.split(",")?.map(it => parseFloat(it)) || [];

    return {
      r: rgba[0] || 0,
      g: rgba[1] || 0,
      b: rgba[2] || 0,
      a: rgba[3] !== undefined ? rgba[3] : 1,
    };
  }

  createMaterial(): THREE.MeshStandardMaterial {
    const { r, g, b, a } = this.colorRGBA;
    return new THREE.MeshStandardMaterial({
      color: new THREE.Color(`rgb(${r}, ${g}, ${b})`),
      side: THREE.DoubleSide,
      transparent: a < 1, // Set to true if the alpha value is less than 1
      opacity: a,
    });
  }
}
