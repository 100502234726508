import { Tooltip } from "antd";
import "./ValidationItem.sass";

export type ValidationItemProps = {
  color: string;
  text: string;
  tooltip?: string;
  type: ValidationItemType;
  borderColor?: string;
  compact?: boolean;
};
export enum ValidationItemType {
  Segment = "segment",
  DashedSegment = "dashedSegment",
  Dot = "dot",
  PlumbingPoint = "plumbingPoint",
  Room = "room",
  DashedLine = "dashedLine",
  Area = "area",
}

const ValidationItem = ({ color, text, tooltip, type, compact, borderColor }: ValidationItemProps) => {
  let itemExample: JSX.Element;
  switch (type) {
    case ValidationItemType.PlumbingPoint:
      itemExample = (
        <span className={type}>
          <span className="point" style={{ backgroundColor: color }} />
          <span className="point-segment" style={{ backgroundColor: color }} />
          <span className="ring" style={{ borderColor: color }} />
        </span>
      );
      break;
    case ValidationItemType.DashedLine:
      itemExample = <span className={type} style={{ borderColor: color }} />;
      break;
    default:
      itemExample = <span className={type} style={{ backgroundColor: color }} />;
  }

  return (
    <div className={`validation-item${compact ? " compact" : ""}`}>
      {itemExample}
      {!!tooltip ? (
        <Tooltip placement="rightBottom" title={tooltip}>
          <span className="title">{text}</span>
        </Tooltip>
      ) : (
        <span>{text}</span>
      )}
    </div>
  );
};

export default ValidationItem;
