export class DxfItem {
  id: string = "";
  data: string = "";

  constructor(id: string, data: string) {
    this.id = id;
    this.data = data;
  }

  toDto(): any {
    return {
      id: this.id,
      data: this.data,
    };
  }
  static fromDto(dto: any): DxfItem {
    return new DxfItem(dto.id, dto.data);
  }
}
