import { showToastMessage } from "./messages";

const transportErrors = {
  timeoutId: undefined,
  errors: [] as APIerrorType[],
};

type TypeOfSource = "sendWithFiles" | "fileManager.updateCorePlanFiles" | "entityManager.delete" | "entityManager.save" | "Response.handleError" | undefined;

const sortSourceCode = (source: TypeOfSource) => {
  if (!source) return 99;
  if (source.startsWith("entity")) return 10;
  if (source.startsWith("send")) return 20;
  if (source.startsWith("fileManager")) return 20;
  if (source.startsWith("Response")) return 40;
  return 50;
};

const _showAPIErrors = () => {
  transportErrors.timeoutId = undefined;

  if (transportErrors.errors?.length) {
    transportErrors.errors.sort((a, b) => sortSourceCode(a.source) - sortSourceCode(b.source));
    showToastMessage("Error", transportErrors.errors.map(e => e.message).join("; "));
  } else {
    showToastMessage("Error", "Network error. Please repeat Your last action.");
  }
  transportErrors.errors = [];
};

type APIerrorType = { source?: TypeOfSource; message: string; errorResult?: any; name?: string };
export function showAPIErrors(message?: APIerrorType) {
  if (!transportErrors.timeoutId) {
    transportErrors.timeoutId = setTimeout(_showAPIErrors, 200);
  }

  if (message) transportErrors.errors.push(message);
}
