import { useRef, useState } from "react";
import { Input, InputRef } from "antd";

import { feetInchesFractionToInches, inchesToFeetInchesFraction } from "../../../../../../helpers/measures";
import { appModel } from "../../../../../../models/AppModel";

const DEFAULT_DEPTH = `1'-0"`;

const CustomInput = ({ setRoofId, cardRef, cardsContainerRef }) => {
  const [dutchGableDepth, setDutchGableDepth] = useState<string>(inchesToFeetInchesFraction(appModel.dutchGableDepth) || DEFAULT_DEPTH);

  const inputRef = useRef<InputRef>(null);

  const updateGableDepth = (e: any) => {
    setDutchGableDepth(e.target.value);

    const parsed = feetInchesFractionToInches(e.target.value);
    if (parsed !== null) {
      appModel.setDutchGableDepth(parsed);
    }
    setRoofId();
  };

  const onKeyUp = (e: any) => {
    e.stopPropagation();
  };

  const onUpdated = e => {
    const parsed = feetInchesFractionToInches(dutchGableDepth);
    if (!dutchGableDepth) {
      setDutchGableDepth(DEFAULT_DEPTH);
      appModel.setDutchGableDepth(feetInchesFractionToInches(DEFAULT_DEPTH));
    } else if (parsed !== null) {
      appModel.setDutchGableDepth(parsed);
      setDutchGableDepth(inchesToFeetInchesFraction(parsed));
    } else {
      setDutchGableDepth(DEFAULT_DEPTH);
    }
  };

  const handleOnClick = e => {
    cardsContainerRef.current.querySelectorAll(".roof-image-selected").forEach(child => {
      child.classList.remove("roof-image-selected");
      child.classList.add("roof-image");
    });
    cardRef.current.classList.add("roof-image-selected");
  };

  return (
    <div className="dutch-gable-input-container">
      <span>Depth</span>
      <Input
        ref={inputRef}
        className="width-input"
        data-testid="inputWidth"
        readOnly={appModel.isViewOnlyMode}
        value={dutchGableDepth}
        onChange={updateGableDepth}
        onPressEnter={onUpdated}
        onBlur={onUpdated}
        onKeyUp={onKeyUp}
        onClick={handleOnClick}
        onFocus={handleOnClick}
      />
    </div>
  );
};

export default CustomInput;
