import { action, makeObservable, observable } from "mobx";
import { Vector3V } from "./Vector3V";

export class Background {
  floorId: string = "";
  width: number = 0.0; // m
  length: number = 0.0; // m
  imageFileId: string;
  opacity: number = 0.5; // 0...1
  position: Vector3V = new Vector3V();
  rotation: number = 0; // grad
  scale: Vector3V = new Vector3V();

  constructor(imageFileId: string = "") {
    this.imageFileId = imageFileId;

    makeObservable(this, {
      width: observable,
      length: observable,
      imageFileId: observable,
      opacity: observable,
      position: observable,
      rotation: observable,
      scale: observable,

      setImageFileId: action,
      setOpacity: action,
      setPosition: action,
      setScale: action,
      setWidth: action,
      setLength: action,
      setRotation: action,
      fromDTO: action,
    });
  }

  setImageFileId(fileId: string) {
    this.imageFileId = fileId;
  }

  setWidth(width: number): void {
    this.width = width;
  }
  setLength(length: number): void {
    this.length = length;
  }
  setOpacity(opacity: number): void {
    this.opacity = opacity;
  }
  setPosition(position: Vector3V): void {
    this.position = position;
  }
  setRotation(rotation: number): void {
    this.rotation = rotation;
  }
  setScale(scale: Vector3V): void {
    this.scale = scale;
  }

  fromDTO(dto: any): this {
    this.floorId = dto.floorId;
    this.width = dto.width;
    this.length = dto.length;
    this.imageFileId = dto.imageFileId;
    this.opacity = dto.opacity;
    this.position = new Vector3V().fromDTO(dto.position || {});
    this.rotation = dto.rotation;
    this.scale = new Vector3V().fromDTO(dto.scale || {});

    return this;
  }

  clone(): Background {
    const clone = new Background();
    clone.floorId = this.floorId;
    clone.width = this.width;
    clone.length = this.length;
    clone.imageFileId = this.imageFileId;
    clone.opacity = this.opacity;
    clone.position = this.position.clone();
    clone.rotation = this.rotation;
    clone.scale = this.scale.clone();

    return clone;
  }
}
