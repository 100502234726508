export type TooltipCellProps = {
  text: string;
  tooltip: string;
  color?: string;
  colSpan?: number;
};

const TooltipCell = ({ text, color = "#ffffff", tooltip, colSpan = 1 }: TooltipCellProps) => {
  return (
    <td colSpan={colSpan} style={{ backgroundColor: color }}>
      <div className="cell-content">{text}</div>
      <span className="tooltip">{tooltip}</span>
    </td>
  );
};

export default TooltipCell;
