import { observer } from "mobx-react";
import { appModel } from "../../../../../models/AppModel";
import { useEffect } from "react";
import { ValidationMode } from "../../../../../editor/models/ValidationType";
import { Size, useWindowSize } from "../../../../../helpers/utilities";
import { IShearCapacityValidationResult } from "../../../../../editor/models/ValidationResult";
import { settings } from "../../../../../entities/settings";
import ValidationTab from "./ValidationTab";
import { ValidationItemProps, ValidationItemType } from "./ValidationItem";
import TooltipCell from "./TooltipCell";
import { inches2feet, roundPrecision } from "../../../../../helpers/measures";

export const shearCapacityLegendItems = {
  Shear: "Shear walls",
  NonContinuantCurrent: "Non continuant on this level",
  NonContinuantBelow: "Non continuant on level below",
  // 'NonContinuantAll': 'Non continuant on all levels',
};

export function getShearCapacityLegendColors() {
  return {
    Shear: settings.values.webAppUISettings.shearWalls,
    NonContinuantCurrent: settings.values.webAppUISettings.shearObstructionOnCurrentLevel,
    NonContinuantBelow: settings.values.webAppUISettings.shearObstructionOnLevelBelow,
    Non: "#BFBFBF",
  };
}

const ShearCapacityTab = observer(() => {
  const colorsMap = getShearCapacityLegendColors();
  const size: Size = useWindowSize();
  const height = size.height ? size.height - 528 : "auto";
  useEffect(() => {
    appModel.setActiveValidationMode(ValidationMode.StrShearCapacity);
    return () => {
      appModel.setActiveValidationMode(ValidationMode.None);
    };
  }, []);

  const result = appModel.validationResult as IShearCapacityValidationResult;
  if (!result?.["X-axis"]?.length && !result?.["Y-axis"]?.length) {
    return;
  }

  const floorName = appModel.activeFloor.name;
  const items: ValidationItemProps[] = result?.presentSegmentTypes.map(st => ({
    color: colorsMap[st],
    text: shearCapacityLegendItems[st],
    type: ValidationItemType.Segment,
  }));
  items.push({
    color: colorsMap["Shear"],
    type: ValidationItemType.Dot,
    text: `${floorName}: horizontal eccentricity percentage = ${roundPrecision(result.eccentricityPercentageX, 2)}`,
  });
  items.push({
    color: colorsMap["Shear"],
    type: ValidationItemType.Dot,
    text: `${floorName}: vertical eccentricity percentage = ${roundPrecision(result.eccentricityPercentageY, 2)}`,
  });

  const getDeviation = (value: number): string => `${value > 0 ? "+" : ""}${inches2feet(value, 2)} ft`;
  const getMinCapacityLineClasses = (value: number): string => `min-capacity-line ${value < 0 ? "red" : ""}`;

  const horizontalCapacityPercent = (result.directions[0].percent / 100) * result.desiredHorizontalPercentage;
  const verticalCapacityPercent = (result.directions[1].percent / 100) * result.desiredVerticalPercentage;
  const horizontalCapacityPercentCalc = `calc(${horizontalCapacityPercent}% - 20px)`;
  const verticalCapacityPercentCalc = `calc(${verticalCapacityPercent + result.directions[0].percent}% - 20px)`;

  const legend = (
    <>
      <div className="min-capacity-text" style={{ left: horizontalCapacityPercentCalc }}>
        {getDeviation(result.horizontalWallDeviation)}
      </div>
      <div className={getMinCapacityLineClasses(result.horizontalWallDeviation)} style={{ left: `${horizontalCapacityPercent}%` }} />
      <div className="min-capacity-text" style={{ left: verticalCapacityPercentCalc }}>
        {getDeviation(result.verticalWallDeviation)}
      </div>
      <div className={getMinCapacityLineClasses(result.verticalWallDeviation)} style={{ left: `${verticalCapacityPercent + result.directions[0].percent}%` }} />
      <table>
        <thead>
          <tr>
            {result["X-axis"].map(p => (
              <th key={p.type} style={{ width: `${(p.percent / 100) * result.directions[0].percent}%` }}>
                {p.type}
              </th>
            ))}
            {result["Y-axis"].map(p => (
              <th key={p.type} style={{ width: `${(p.percent / 100) * result.directions[1].percent}%` }}>
                {p.type}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr className="percentage-row">
            {result["X-axis"].map(p => (
              <TooltipCell text={`${p.percent}%`} tooltip={`${p.percent}%`} color={colorsMap[p.type]} key={p.type} />
            ))}
            {result["Y-axis"].map(p => (
              <TooltipCell text={`${p.percent}%`} tooltip={`${p.percent}%`} color={colorsMap[p.type]} key={p.type} />
            ))}
          </tr>
          <tr className="total-direction-row">
            {result.directions.map(p => (
              <TooltipCell colSpan={result[p.name].length} text={`${p.name} ${p.percent}%`} tooltip={`${p.name} ${p.percent}%`} key={p.name} />
            ))}
          </tr>
          <tr className="total-row">
            <td colSpan={result["X-axis"].length + result["Y-axis"].length}>Total walls length = {inches2feet(result.totalWallLength, 2)} ft</td>
          </tr>
        </tbody>
      </table>
    </>
  );
  return <ValidationTab items={items} height={height} legend={legend} />;
});

export default ShearCapacityTab;
