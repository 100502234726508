import { observer } from "mobx-react";
import { appModel } from "../../../../../models/AppModel";
import { useEffect } from "react";
import { ValidationMode } from "../../../../../editor/models/ValidationType";
import { Size, useWindowSize } from "../../../../../helpers/utilities";
import { IPlumbingValidationResult } from "../../../../../editor/models/ValidationResult";
import ValidationTab from "./ValidationTab";
import { ValidationItemProps, ValidationItemType } from "./ValidationItem";
import TooltipCell from "./TooltipCell";

const validPointColor = "#03CB6A";
const invalidPointColor = "#FF3B47";

export const plumbingLegendItemsMap = {
  ValidPoint: {
    text: "Valid Plumbing point",
    type: ValidationItemType.PlumbingPoint,
    color: validPointColor,
  },
  InvalidPoint: {
    text: "Invalid Plumbing point",
    type: ValidationItemType.PlumbingPoint,
    color: invalidPointColor,
  },
  OptionalExteriorWall: {
    text: "Optional Exterior wall",
    type: ValidationItemType.Segment,
    color: "#BDFB6E",
  },
  BlockedExteriorWall: {
    text: "Blocked Exterior wall",
    type: ValidationItemType.Segment,
    color: "#FFBD00",
  },
  RoomBelow: {
    text: "Room Below",
    type: ValidationItemType.Room,
    color: "#FFBD0021",
  },
  PipeExtent: {
    text: "Pipe extent on ceiling",
    type: ValidationItemType.DashedLine,
    color: "#6A6A6A",
  },
};

const PlumbingTab = observer(() => {
  const size: Size = useWindowSize();
  const height = size.height ? size.height - 528 : "auto";
  useEffect(() => {
    appModel.setActiveValidationMode(ValidationMode.MepPlumbing);
    return () => {
      appModel.setActiveValidationMode(ValidationMode.None);
    };
  }, []);

  const result = appModel.validationResult as IPlumbingValidationResult[];
  if (!result?.length) {
    return;
  }

  const items: ValidationItemProps[] = [];
  const validPoints = result.filter(r => r.isValid);
  const invalidPoints = result.filter(r => !r.isValid);
  const floorItems = result.filter(r => r.floorId === appModel.activeFloor.id);

  if (floorItems.some(i => i.isValid)) {
    items.push(plumbingLegendItemsMap["ValidPoint"]);
  }
  if (floorItems.some(i => !i.isValid)) {
    items.push(plumbingLegendItemsMap["InvalidPoint"]);
  }
  if (floorItems.some(i => i.optionalExteriorWalls?.length)) {
    items.push(plumbingLegendItemsMap["OptionalExteriorWall"]);
  }
  if (floorItems.some(i => i.blockedExteriorWalls?.length)) {
    items.push(plumbingLegendItemsMap["BlockedExteriorWall"]);
  }
  if (floorItems.some(i => i.roomBelowId)) {
    items.push(plumbingLegendItemsMap["RoomBelow"]);
  }
  if (floorItems.some(i => i.pipeExtent)) {
    items.push(plumbingLegendItemsMap["PipeExtent"]);
  }
  const cellWidth = 100 / result.length;

  const legend = (
    <table>
      <thead>
        <tr>
          {!!validPoints.length && (
            <th style={{ width: `${cellWidth * validPoints.length}%`, maxWidth: `${cellWidth * validPoints.length}%` }} colSpan={validPoints.length}>
              Valid
            </th>
          )}
          {!!invalidPoints.length && (
            <th style={{ width: `${cellWidth * invalidPoints.length}%`, maxWidth: `${cellWidth * invalidPoints.length}%` }} colSpan={invalidPoints.length}>
              Invalid
            </th>
          )}
        </tr>
      </thead>
      <tbody>
        <tr className="points-row">
          {validPoints.map(p => (
            <TooltipCell key={p.plumbingPointId} text={" "} tooltip={p.roomName} color={validPointColor} />
          ))}
          {invalidPoints.map(p => (
            <TooltipCell key={p.plumbingPointId} text={" "} tooltip={p.roomName} color={invalidPointColor} />
          ))}
        </tr>
        <tr className="total-row">
          <td colSpan={result.length}>Total plumbing points = {result.length}</td>
        </tr>
      </tbody>
    </table>
  );
  return <ValidationTab items={items} height={height} legend={legend} />;
});

export default PlumbingTab;
