import { observer } from "mobx-react";
import { Tooltip } from "antd";

import { appModel } from "../../../models/AppModel";

import "./Tooltip.sass";

export interface ITooltipOptions {
  show: boolean;
  top?: number;
  left?: number;
  text?: string;
}

const CustomTooltip = observer(() => {
  const options = appModel.tooltipOptions;
  if (!options || !options.show) {
    return null;
  }

  return (
    <Tooltip open={options.show} placement="bottom" title={options.text ?? ""}>
      <div style={{ left: `${options.left + 5}px`, top: `${options.top + 10}px` }} className="tooltip-container" />
    </Tooltip>
  );
});
export default CustomTooltip;
