import { useContext, useEffect, useMemo, useState } from "react";
import { Button } from "antd";
import AuthContext from "../../../store/auth-context";
import { isEnabledInnerUser } from "../../../store/fake-user-support";
import { checkOnceAuthSessionTerminationState } from "../../../store/localStoreHelper";
import { AUTH_SESSION_TERMINATION_STATE, AuthTerminationStateValues } from "../../../helpers/auth";
import "./LogInComponent.sass";

const LogInComponent = () => {
  const authCtx = useContext(AuthContext);
  const loginHandler = loginType => {
    authCtx.login(loginType);
  };

  const { loginType, buttonText } = isEnabledInnerUser();

  const [terminationState, setTerminationState] = useState<AuthTerminationStateValues | null>(null);

  useEffect(() => {
    const state = checkOnceAuthSessionTerminationState();
    setTerminationState(state);
  }, []);

  const warningMessage = useMemo(() => {
    switch (terminationState) {
      case AUTH_SESSION_TERMINATION_STATE.IDLE_TIMEOUT:
        return "You were signed out since you were an idle long period of time. Please sign in again.";

      case AUTH_SESSION_TERMINATION_STATE.REFRESH_TOKEN_EXPIRED:
        return "Sorry, your authorization session has expired. You have to sign in again.";

      default:
        return null;
    }
  }, [terminationState]);

  return (
    <section className="section-auth">
      <div className="logo">
        <img src="/Digital ops logo (color).svg" alt="DCP" height="160" />
      </div>
      <h1>
        Welcome!
        <br />
        Click the Sign in button to proceed
      </h1>
      {terminationState && <p className="expired-session">{warningMessage}</p>}
      <div>
        <Button type="primary" onClick={() => loginHandler("")}>
          Sign In
        </Button>
      </div>
      <br />
      {loginType && (
        <div>
          <Button onClick={() => loginHandler(loginType)}>{buttonText}Sign in</Button>
        </div>
      )}
    </section>
  );
};

export default LogInComponent;
