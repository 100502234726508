import classes from "./UserProfile.sass";
import AuthContext from "../../../store/auth-context";
import { useContext } from "react";
import { UserDepot } from "../../../store/UserDepot";

const UserProfile = () => {
  const authCtx = useContext(AuthContext);

  return (
    <section className={classes.profile}>
      <h1>Your User Profile</h1>
      <p>some information about user will be here</p>
      <div>
        <pre>{JSON.stringify(authCtx.user, null, 2)}</pre>
      </div>
      <div>
        <pre>UserDepot.current {JSON.stringify(UserDepot.current.user, null, 2)}</pre>
      </div>
    </section>
  );
};

export default UserProfile;
