import * as THREE from "three";

export default class RoomColorScheme {
  public roomId: string;
  public roomBox: THREE.Box3;
  public colorIndex: number;

  constructor(roomId?: string, roomBox?: THREE.Box3, colorIndex?: number) {
    this.roomId = roomId;
    this.roomBox = roomBox;
    this.colorIndex = colorIndex;
  }

  public fromJS(data: any): RoomColorScheme {
    this.roomId = data.roomId;
    this.roomBox = new THREE.Box3().copy(data.roomBox);
    this.colorIndex = data.colorIndex;

    return this;
  }
}
