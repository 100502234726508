import { Typography } from "antd";
import { observer } from "mobx-react";
import { useEffect } from "react";
import Scrollbar from "react-scrollbars-custom";

import { MESSAGE_DURATION } from "../../../../../editor/consts";
import { IArcAreaValidationResult } from "../../../../../editor/models/ValidationResult";
import { ValidationMode } from "../../../../../editor/models/ValidationType";
import { MessageKindsEnum, showToastMessage } from "../../../../../helpers/messages";
import { Size, useWindowSize } from "../../../../../helpers/utilities";
import { appModel } from "../../../../../models/AppModel";
import ValidationItem, { ValidationItemProps, ValidationItemType } from "./ValidationItem";
import LevelAreaItems from "./LevelAreaItems";

export const arcValidationItems: ValidationItemProps[] = [
  {
    color: "#D351D6",
    borderColor: "#000000",
    text: "Livable area",
    type: ValidationItemType.Area,
  },
  {
    color: "#EA8B34",
    borderColor: "#000000",
    text: "Garage area",
    type: ValidationItemType.Area,
  },
  {
    color: "#005DAA",
    borderColor: "#000000",
    text: "Porches & Patios area",
    type: ValidationItemType.Area,
  },
];

const AreaValidationTab = observer(() => {
  const size: Size = useWindowSize();
  const height = size.height ? size.height - 364 : "auto";

  useEffect(() => {
    appModel.setActiveValidationMode(ValidationMode.ArcAreaValidation);

    return () => {
      appModel.setActiveValidationMode(ValidationMode.None);
    };
  }, []);

  const result = appModel.validationResult as IArcAreaValidationResult;

  useEffect(() => {
    const currentFloorLevelArea = result?.levelArea?.find(la => la.floorId === appModel.activeFloor.id);
    if (currentFloorLevelArea?.hasIntersections) {
      showToastMessage(MessageKindsEnum.Error, "Some rooms are too close, resulting in the inaccurate gross area calculation.", {
        autoClose: MESSAGE_DURATION,
      });
    }
  }, [result]);

  if (!result?.levelArea?.length) {
    return;
  }

  const getLevelAreaSumByType = type => {
    const { levelArea } = result;
    return { title: levelArea[0][type].title, value: levelArea.reduce((sum, la) => sum + la[type].value, 0) };
  };

  const livableArea = getLevelAreaSumByType("livableArea");
  const garageArea = getLevelAreaSumByType("garageArea");
  const porchesArea = getLevelAreaSumByType("porchesArea");

  return (
    <div className="validation-tab">
      <Scrollbar style={{ height }}>
        <div className="validation-items">
          <Typography.Paragraph className="legend-text">Legend</Typography.Paragraph>
          {appModel.activeAreaColors.map((item, index) => (
            <ValidationItem key={index} color={item.color} text={item.text} type={item.type} borderColor={item.borderColor} compact />
          ))}
        </div>
        <div className="area-items">
          <p className="area-items-title">Area Take-Off</p>
          <LevelAreaItems {...{ livableArea, garageArea, porchesArea }} />
          {result.levelArea.map(item => {
            const levelArea = {
              livableArea: item.livableArea,
              garageArea: item.garageArea,
              porchesArea: item.porchesArea,
              floorId: item.floorId,
            };

            return <LevelAreaItems {...levelArea} />;
          })}
        </div>
      </Scrollbar>
    </div>
  );
});

export default AreaValidationTab;
