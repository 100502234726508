import { Typography } from "antd";
import { observer } from "mobx-react";
import { ReactNode } from "react";
import { Scrollbar } from "react-scrollbars-custom";
import ValidationItem, { ValidationItemProps } from "./ValidationItem";

export type ValidationTabProps = {
  items: ValidationItemProps[];
  legend?: ReactNode;
  height: number | string;
};

const ValidationTab = observer(({ items, legend, height }: ValidationTabProps) => {
  return (
    <div className="validation-tab">
      <div className="validation-items">
        <Typography.Paragraph className="legend-text">Legend</Typography.Paragraph>
        <Scrollbar style={{ height }}>
          {items.map((item, index) => (
            <ValidationItem key={index} color={item.color} text={item.text} type={item.type} tooltip={item.tooltip} />
          ))}
        </Scrollbar>
      </div>
      {legend && <div className="legend">{legend}</div>}
    </div>
  );
});

export default ValidationTab;
