import LeftBar from "./LeftBar/LeftBar";
import ModalErrorFloorSave from "./ModalErrorFloorSave";
import { EditorLeftBarProps } from "./LeftBar/RoomCatalog/RoomTypeListItem";
import React from "react";

export const EditorContext = React.createContext<EditorLeftBarProps>({ baseManager: null });

const EditorLeftBar = ({ baseManager }: EditorLeftBarProps) => {
  const ctx = { baseManager };

  return (
    <>
      <EditorContext.Provider value={ctx}>
        <LeftBar />
      </EditorContext.Provider>
      <ModalErrorFloorSave />
    </>
  );
};

export default EditorLeftBar;
