import { observer } from "mobx-react-lite";
import { useState } from "react";
import { Button, Row, Modal, Col, Input, FormInstance, Form, Popover } from "antd";
import { appModel } from "../../../../models/AppModel";
import LoadingSpinner from "../../common/LoadingSpinner";
import { fractionalInchesRegex, fromDisplayValue, hexColorRegex } from "../../../../helpers/measures";
import { settings } from "../../../../entities/settings";
import "./SettingsTab.sass";
import { Rule } from "rc-field-form/lib/interface";
import { SettingsUnits, TSettingsValues } from "../../../../entities/settings/types";
import { defaultSettings, defaultUnits } from "../../../../entities/settings/defaults";
import { ChromePicker } from "react-color";
import { showToastMessage } from "../../../../helpers/messages";

const { confirm } = Modal;

export type SubCategorySettings = {
  subCategoryDisplayName?: string;
  subCategoryName: string;
  settings: string[];
  className?: string;
};

export type SettingsTabProps = {
  tabSettings: SubCategorySettings[];
  handleModalClose: () => void;
  form: FormInstance;
  resetToDefaults: (values: TSettingsValues) => void;
};

const greaterThanZeroValidator = {
  message: "Value must be greater or equal to 0.",
  validator: (_, value) => (value >= 0 ? Promise.resolve() : Promise.reject()),
};
const unitValidationRules: { [key: string]: Rule[] } = {
  [SettingsUnits.ft]: [
    {
      message: "Incorrect value format.",
      validator: (_, value) => (value.match(fractionalInchesRegex) ? Promise.resolve() : Promise.reject()),
    },
  ],
  [SettingsUnits.sqft]: [greaterThanZeroValidator],
  [SettingsUnits.units]: [
    {
      message: "Value must be an integer.",
      validator: (_, value) => (Number.isInteger(Number.parseFloat(value)) ? Promise.resolve() : Promise.reject()),
    },
    greaterThanZeroValidator,
  ],
  [SettingsUnits.hex]: [{ message: "Incorrect value format.", validator: (_, value) => (value.match(hexColorRegex) ? Promise.resolve() : Promise.reject()) }],
  [SettingsUnits.plf]: [greaterThanZeroValidator],
  [SettingsUnits.percent]: [
    {
      message: "Value must be between 0 and 100.",
      validator: (_, value) => (value >= 0 && value <= 100 ? Promise.resolve() : Promise.reject()),
    },
  ],
  [SettingsUnits.psf]: [greaterThanZeroValidator],
};

const disabledInputs = ["shearF2cap", "shearPreliminaryFactor"];

let SettingsTab = ({ tabSettings, handleModalClose, form, resetToDefaults }: SettingsTabProps) => {
  const [isUpdatingSettings, setIsUpdatingSettings] = useState<boolean>(false);

  const onColorChange = (categoryName: string, settingName: string, newColor: string) => {
    form.setFieldValue([categoryName, settingName], newColor.slice(1).toUpperCase());
  };
  const onCancelClick = () => {
    handleModalClose();
  };
  const onResetToDefaults = () => {
    setIsUpdatingSettings(true);
    resetToDefaults(defaultSettings);
    setIsUpdatingSettings(false);
    showToastMessage("Info", "The settings have been reset. Remember to save them.");
  };

  // Watch for changes to color inputs to rerender the color rectangles.
  Form.useWatch(["webAppUISettings"], form);

  const updateSettings = async formFields => {
    confirm({
      title: `Are you sure you want to update system settings?`,
      okText: "Yes",
      okType: "primary",
      cancelText: "No",
      className: "confirm-dialog",
      async onOk() {
        setIsUpdatingSettings(true);
        const newSettingsValues: TSettingsValues = Object.assign({}, settings.values);
        for (const settingCategoryKey in formFields) {
          if (Object.prototype.hasOwnProperty.call(newSettingsValues, settingCategoryKey)) {
            const apiSettings = Object.entries(formFields[settingCategoryKey]).reduce((acc, key: [string, string]) => {
              const unit = defaultUnits[settingCategoryKey][key[0]];
              acc[key[0]] = fromDisplayValue(key[1], unit);
              return acc;
            }, {});

            newSettingsValues[settingCategoryKey] = { ...newSettingsValues[settingCategoryKey], ...apiSettings };
          }
        }

        await settings.updateSettings(newSettingsValues);
        setIsUpdatingSettings(false);
        handleModalClose();
      },
    });
  };

  if (appModel.isBusy) {
    return (
      <div style={{ textAlign: "center" }}>
        <LoadingSpinner />
        <p>Loading settings...</p>
      </div>
    );
  }

  const { descriptions } = settings;

  return (
    <Form form={form} className="settings-tab" onFinish={updateSettings}>
      <Row className="settings-panel">
        {tabSettings.map((subCategory, index) => (
          <Col key={index} span={8} className={subCategory.className || ""}>
            <div className="sub-category-name">{subCategory.subCategoryDisplayName}</div>
            {subCategory.settings.map((setting, index2) => {
              if (!setting) {
                return <div key={`break${index2}`} className="sub-category-break" />;
              }
              const value = form.getFieldValue([subCategory.subCategoryName, setting]);

              const description = descriptions[subCategory.subCategoryName][setting];
              const unit = defaultUnits[subCategory.subCategoryName][setting];

              const rules: Rule[] = [{ required: true, message: "Field is required." }, ...unitValidationRules[unit]];
              if (unit === SettingsUnits.hex) {
                return (
                  <Row key={setting} className="color-input">
                    <Form.Item name={[subCategory.subCategoryName, setting]} rules={rules}>
                      <Input className="setting-input" addonBefore={`${description}:`} addonAfter={unit} disabled={disabledInputs.includes(setting)} />
                    </Form.Item>
                    <Popover
                      placement="bottomLeft"
                      overlayClassName="color-picker-popover"
                      trigger="click"
                      destroyTooltipOnHide
                      content={
                        <ChromePicker
                          color={`#${value}`}
                          onChange={newColor => {
                            onColorChange(subCategory.subCategoryName, setting, newColor.hex);
                          }}
                          disableAlpha
                        />
                      }
                    >
                      <Button className="color-rectangle" style={{ backgroundColor: value.match(hexColorRegex) ? `#${value}` : "unset" }}></Button>
                    </Popover>
                  </Row>
                );
              }
              return (
                <Row key={setting}>
                  <Form.Item name={[subCategory.subCategoryName, setting]} rules={rules}>
                    <Input className="setting-input" addonBefore={`${description}:`} addonAfter={unit} disabled={disabledInputs.includes(setting)} />
                  </Form.Item>
                </Row>
              );
            })}
          </Col>
        ))}
      </Row>

      <Row className="buttons-row">
        <Button className="left-button" onClick={onResetToDefaults} type="dashed">
          Reset to default
        </Button>
        <div className="right-buttons">
          <Button loading={isUpdatingSettings} type="primary" htmlType="submit">
            Save & Close
          </Button>
          <Button onClick={onCancelClick}>Cancel</Button>
        </div>
      </Row>
    </Form>
  );
};

SettingsTab = observer(SettingsTab);

export default SettingsTab;
