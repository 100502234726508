import * as THREE from "three";
import { settings } from "../../entities/settings";
import { Floor } from "../../models/Floor";
import { RoomEntityType } from "../../models/RoomEntityType";
import { Side } from "../../models/Side";
import GeometryUtils from "../utils/GeometryUtils";
import MathUtils from "../utils/MathUtils";
import SceneUtils from "../utils/SceneUtils";
import UnitsUtils from "../utils/UnitsUtils";
import { WallAnalysisUtils } from "../utils/WallAnalysisUtils";
import RoomManager from "../managers/RoomManager/RoomManager";

type Opening = {
  soObject: THREE.Object3D;
  soWall: THREE.Object3D;
  zone: THREE.Line3;
  line: THREE.Line3;
};
type LineRange = {
  start: number;
  end: number;
};
type Group = {
  start: number;
  end: number;
  items: Line[];
  startItem: Line;
  endItem: Line;
};
enum AlignmentType {
  AlignToSide,
  PushAway,
}

class FloorWall {
  public start: THREE.Vector2;
  public end: THREE.Vector2;
  public openingIds: string[] = [];
  public floorId: string;
  public side: Side;

  constructor(floorId: string, side: Side) {
    this.floorId = floorId;
    this.side = side;
  }

  public setStart(start: THREE.Vector2) {
    if (!this.start) {
      this.start = start;
    } else {
      if (this.side === Side.Top || this.side === Side.Bottom) {
        if (start.x < this.start.x) {
          this.start = start;
        }
      } else {
        if (start.y < this.start.y) {
          this.start = start;
        }
      }
    }
  }
  public setEnd(end: THREE.Vector2) {
    if (!this.end) {
      this.end = end;
    } else {
      if (this.side === Side.Top || this.side === Side.Bottom) {
        if (end.x > this.end.x) {
          this.end = end;
        }
      } else {
        if (end.y > this.end.y) {
          this.end = end;
        }
      }
    }
  }
}
class Wall {
  public side: Side;
  public position: number;
  public lines: Line[] = [];

  constructor(side: Side, position: number) {
    this.side = side;
    this.position = position;
  }
}

export class Line {
  public segment: LineRange;
  public shiftZone: LineRange;
  public maxShiftZone: LineRange;
  public shiftDistance: number = 0;
  public id: string;
  public isAligned = false;
  public isPushed = false;

  constructor(id: string, segment: LineRange, shiftZone: LineRange, maxShiftZone: LineRange) {
    this.id = id;
    this.segment = segment;
    this.shiftZone = shiftZone;
    this.maxShiftZone = maxShiftZone;
  }

  public get start() {
    return this.segment.start + this.shiftDistance;
  }
  public get end() {
    return this.segment.end + this.shiftDistance;
  }
  public get center() {
    return (this.segment.end - this.segment.start) / 2 + this.segment.start;
  }
  public get segmentWithDistance() {
    return { start: this.start, end: this.end };
  }
  public get absDistance() {
    return Math.abs(this.shiftDistance);
  }
  public get maxMovable() {
    return {
      [Side.Left]: this.start - this.shiftZone.start < 0 ? 0 : this.start - this.shiftZone.start,
      [Side.Right]: this.shiftZone.end - this.end < 0 ? 0 : this.shiftZone.end - this.end,
    };
  }
  public move(movement: number) {
    this.shiftDistance += movement;
  }
  public setAlignment(alignType: AlignmentType.AlignToSide | AlignmentType.PushAway) {
    if (alignType === AlignmentType.AlignToSide) {
      this.isAligned = true;
    } else {
      this.isPushed = true;
    }
  }
  public clone() {
    const temp = new Line(this.id, { ...this.segment }, { ...this.shiftZone }, { ...this.maxShiftZone });
    temp.shiftDistance = this.shiftDistance;
    temp.isAligned = this.isAligned;
    temp.isPushed = this.isPushed;
    return temp;
  }
}

export class LineAlignment {
  public static alignLines(lines: Line[], gap: number) {
    //sort left to right
    this.sort(lines, 0, lines.length - 1, (a: Line, b: Line) => {
      return a.start > b.start;
    });

    //apply exterior wall gap
    this.limitByExteriorPointGap(lines, gap);

    //create groups
    const groups = this.groupByIntersection(lines);
    this.alignGroups(groups, gap);
  }

  private static sort(arr: Line[], startIndex: number, endIndex: number, compareFn: (a: Line, b: Line) => boolean) {
    if (startIndex < endIndex) {
      const pivot = this.partition(arr, startIndex!, endIndex!, compareFn);
      this.sort(arr, startIndex, pivot - 1, compareFn);
      this.sort(arr, pivot + 1, endIndex, compareFn);
    }
  }
  private static swap(arr: Line[], firstIndex: number, secondIndex: number) {
    const temp = arr[firstIndex];
    arr[firstIndex] = arr[secondIndex];
    arr[secondIndex] = temp;
  }
  private static partition(arr: Line[], start: number, end: number, compareFn: (a: Line, b: Line) => boolean) {
    const pivot = end;
    let i = start - 1;
    let j = start;

    while (j < pivot) {
      if (compareFn(arr[j], arr[pivot])) {
        j++;
      } else {
        i++;
        this.swap(arr, j, i);
        j++;
      }
    }
    this.swap(arr, i + 1, pivot);
    return i + 1;
  }
  private static isIntersect(a: { start: number; end: number }, b: { start: number; end: number }) {
    if (
      MathUtils.isNumberInRange(a.start, b.start, b.end) ||
      MathUtils.isNumberInRange(a.end, b.start, b.end) ||
      MathUtils.isNumberInRange(b.start, a.start, a.end) ||
      MathUtils.isNumberInRange(b.end, a.start, a.end)
    ) {
      return true;
    }
    return false;
  }
  private static limitByExteriorPointGap(lines: Line[], gap: number) {
    lines.forEach(line => {
      const gapPositionLeft = line.maxShiftZone.start + gap;
      const gapPositionRight = line.maxShiftZone.end - gap;
      //left
      if (line.shiftZone.start < gapPositionLeft) {
        if (line.start < gapPositionLeft) {
          //push to right
          const diff = gapPositionLeft - line.start;
          const maxMoveable = line.shiftZone.end - line.end;
          if (maxMoveable >= diff) {
            line.move(diff);
            line.shiftZone.start = line.start;
          }
        } else {
          //update shiftZone
          const diff = gapPositionLeft - line.shiftZone.start;
          line.shiftZone.start += diff;
        }
      }
      //right
      if (line.shiftZone.end > gapPositionRight) {
        if (line.end > gapPositionRight) {
          //push to left
          const diff = line.end - gapPositionRight;
          const maxMoveable = line.start - line.shiftZone.start;
          if (maxMoveable >= diff) {
            line.move(-diff);
            line.shiftZone.end = line.end;
          }
        } else {
          //update shiftZone
          const diff = line.shiftZone.end - gapPositionRight;
          line.shiftZone.end -= diff;
        }
      }
    });
  }
  private static groupByIntersection(lines: Line[]) {
    const groups: Group[] = [];
    let group: Group | null = null;
    for (let i = 0; i < lines.length; i++) {
      const w = lines[i];
      if (!group) {
        group = {
          start: w.start,
          end: w.end,
          items: [w],
          startItem: w,
          endItem: w,
        };
      } else {
        if (this.isIntersect(w.segment, { start: group.start, end: group.end })) {
          if (w.end > group.end) {
            group.end = w.end;
            group.endItem = w;
          }
          group.items.push(w);
        } else {
          groups.push(group);
          group = {
            start: w.start,
            end: w.end,
            items: [w],
            startItem: w,
            endItem: w,
          };
        }
      }
    }
    if (group) {
      groups.push(group);
    }
    return groups;
  }
  private static alignGroups(groups: Group[], gap: number) {
    if (groups.length > 0) {
      const maxLeft = MathUtils.ceil(groups.length / 2, 1) - 1;
      for (let i = 0; i <= maxLeft; i++) {
        this.alignGroup(groups[i], Side.Left, gap);
        if (i + 1 <= groups.length - 1) {
          this.pushAwayGroups(groups[i], groups[i + 1], gap);
        }
      }
      if (groups.length > 1) {
        for (let i = groups.length - 1; i > maxLeft; i--) {
          this.alignGroup(groups[i], Side.Right, gap);
        }
      }
    }
  }
  private static alignGroup(group: Group, alignSide: Side.Right | Side.Left, gap: number) {
    if (group.items.length < 2) {
      return;
    }
    if (alignSide === Side.Right) {
      //resort the group
      this.sort(group.items, 0, group.items.length - 1, (a: Line, b: Line) => {
        return a.end < b.end;
      });
    }
    for (let i = 1; i < group.items.length; i++) {
      for (let j = 0; j < i; j++) {
        const item = group.items[i];
        const w = group.items[j];
        const left = this.alignSide(item, w, Side.Left);
        const right = this.alignSide(item, w, Side.Right);
        const away = this.pushAway(item, w, gap);
        const totalLeft = left ? Math.abs(left[0]) + Math.abs(left[1]) : Number.MAX_VALUE;
        const totalRight = right ? Math.abs(right[0]) + Math.abs(right[1]) : Number.MAX_VALUE;
        const totalAway = away ? Math.abs(away[0]) + Math.abs(away[1]) : Number.MAX_VALUE;
        const updateData = (line: Line, distance: number, alignType: AlignmentType.AlignToSide | AlignmentType.PushAway) => {
          line.move(distance);
          line.setAlignment(alignType);
        };
        const updateGroup = (gitem: Line) => {
          if (group.start > gitem.start) {
            group.start = gitem.start;
            group.startItem = gitem;
          }
          if (group.end > gitem.end) {
            group.end = gitem.end;
            group.endItem = gitem;
          }
        };
        const min = Math.min(totalLeft, totalRight, totalAway);
        if (min > 0 && min !== Number.MAX_VALUE) {
          if (min === totalLeft && left) {
            updateData(item, left[0], AlignmentType.AlignToSide);
            updateData(w, left[1], AlignmentType.AlignToSide);
          } else if (min === totalRight && right) {
            updateData(item, right[0], AlignmentType.AlignToSide);
            updateData(w, right[1], AlignmentType.AlignToSide);
          } else if (min === totalAway && away) {
            updateData(item, away[0], AlignmentType.PushAway);
            updateData(w, away[1], AlignmentType.PushAway);
          }
          updateGroup(item);
          updateGroup(w);
        }
      }
    }
  }
  private static pushAwayGroups(g1: Group, g2: Group, gap: number) {
    //get most left in g1
    for (let i = 0; i < g1.items.length; i++) {
      const g1item = g1.items[i];
      if (g2.items[0].start - g1item.end < gap) {
        //needs to push
        for (let j = 0; j < g2.items.length; j++) {
          const g2item = g2.items[j];
          if (g2item.start - g1item.end < gap) {
            const away = this.pushAway(g1item, g2item, gap);
            if (away) {
              g1item.move(away[0]);
              g2item.move(away[1]);

              //update group shiftZone
              if (g1.start > g1item.start) {
                g1.start = g1item.start;
                g1.startItem = g1item;
              }
              if (g1.end > g1item.end) {
                g1.end = g1item.end;
                g1.endItem = g1item;
              }
              if (g2.start > g2item.start) {
                g2.start = g2item.start;
                g2.startItem = g2item;
              }
              if (g2.end > g2item.end) {
                g2.end = g2item.end;
                g2.endItem = g2item;
              }
            }
          }
        }
      }
    }
  }
  private static reverseSide(s: Side.Left | Side.Right) {
    if (s === Side.Left) {
      return Side.Right;
    }
    return Side.Left;
  }
  private static alignSide(cur: Line, next: Line, side: Side.Left | Side.Right): [number, number] | null {
    if (!this.isIntersect(cur.segmentWithDistance, next.segmentWithDistance)) {
      return null;
    }
    const curMaxMove = cur.maxMovable;
    const nextMaxMove = next.maxMovable;

    let curPoint = cur.start;
    let nextPoint = next.start;
    if (side === Side.Right) {
      curPoint = cur.end;
      nextPoint = next.end;
    }
    const diff = nextPoint - curPoint;
    const absDiff = Math.abs(diff);
    if (absDiff === 0) {
      //aligned already
      return [0, 0];
    } else {
      const divDiff = absDiff / 2;
      let curMax = 0,
        nextMax = 0;
      let curDir = 1,
        nextDir = -1;
      if (diff > 0) {
        //cur right,next left
        curMax = curMaxMove[this.reverseSide(side)];
        nextMax = nextMaxMove[side];
        if (side === Side.Right) {
          curMax = curMaxMove[side];
          nextMax = nextMaxMove[this.reverseSide(side)];
        }
      } else {
        //cur left,next right
        curMax = curMaxMove[side];
        nextMax = nextMaxMove[this.reverseSide(side)];
        if (side === Side.Right) {
          curMax = curMaxMove[this.reverseSide(side)];
          nextMax = nextMaxMove[side];
        }
        curDir = -1;
        nextDir = 1;
      }
      if (cur.isAligned || cur.isPushed) {
        curMax = 0;
      }
      if (divDiff <= curMax) {
        if (divDiff <= nextMax) {
          return [curDir * divDiff, nextDir * divDiff];
        } else {
          const nextOverMove = divDiff - nextMax;
          if (nextOverMove <= curMax - divDiff) {
            return [curDir * (divDiff + nextOverMove), nextDir * nextMax];
          }
        }
      } else {
        const curOverMove = divDiff - curMax;
        if (curOverMove <= nextMax - divDiff) {
          return [curDir * curMax, nextDir * (divDiff + curOverMove)];
        }
      }
    }
    return null;
  }
  private static pushAway(cur: Line, next: Line, gap: number): [number, number] | null {
    if (!this.isIntersect({ start: cur.shiftZone.start - gap, end: cur.shiftZone.end + gap }, next.shiftZone)) {
      return null;
    }
    const diff = cur.center >= next.center ? cur.segment.start - next.segment.end : next.segment.start - cur.segment.end;
    if (diff >= gap) {
      //already has gap
      return [0, 0];
    }
    //cur right / next left
    let side: Side.Right | Side.Left = Side.Right;
    const curMaxMove = cur.maxMovable;
    const nextMaxMove = next.maxMovable;
    let curMax = curMaxMove[side];
    let nextMax = nextMaxMove[this.reverseSide(side)];
    let curDir = 1,
      nextDir = -1;

    if (cur.center < next.center) {
      //cur left / next right
      side = Side.Left;
      curDir = -1;
      nextDir = 1;
      curMax = curMaxMove[side];
      nextMax = nextMaxMove[this.reverseSide(side)];
    }
    if (cur.isAligned || cur.isPushed) {
      curMax = 0;
    }
    const divDiff = diff >= 0 ? (gap - diff) / 2 : (gap + Math.abs(diff)) / 2;
    if (divDiff <= curMax) {
      if (divDiff <= nextMax) {
        return [curDir * divDiff, nextDir * divDiff];
      } else {
        const nextOverMove = divDiff - nextMax;
        if (nextOverMove <= curMax - divDiff) {
          return [curDir * (divDiff + nextOverMove), nextDir * nextMax];
        }
      }
    } else {
      const curOverMove = divDiff - curMax;
      if (curOverMove <= nextMax - divDiff) {
        return [curDir * curMax, nextDir * (divDiff + curOverMove)];
      }
    }
    return null;
  }
}

export default class OpeningAlignmentTool {
  private openings = new Map<string, Opening>();
  private floorsWalls: Map<Side, FloorWall[]>[] = [];
  private soFloorsRoot: THREE.Group;
  private floors: Floor[] = [];

  constructor(private roomManager: RoomManager) {}

  public alignRoomsOpenings(floors: Floor[], soFloorsRoot: THREE.Group): void {
    this.floors = floors;
    this.soFloorsRoot = soFloorsRoot;

    this.prepareOpeningsAndWalls();

    const gap = settings.values.validationSettings.openingAlignmentMinGap;
    const sides = new Map<Side, Wall[]>([
      [Side.Top, []],
      [Side.Right, []],
      [Side.Bottom, []],
      [Side.Left, []],
    ]);

    this.floorsWalls.forEach(floor => {
      floor.forEach((floorWalls, side) => {
        let axis = "y";
        let otherAxis = "x";
        if (side === Side.Left || side === Side.Right) {
          axis = "x";
          otherAxis = "y";
        }
        floorWalls.forEach(floorWall => {
          let wall = sides.get(side).find(sideWall => MathUtils.areNumbersEqual(floorWall.start[axis], sideWall.position));
          if (!wall) {
            wall = new Wall(side as Side, floorWall.start[axis]);
            sides.get(side).push(wall);
          }
          floorWall.openingIds.forEach(openingId => {
            const opening = this.openings.get(openingId);
            const shiftZone = { start: opening.zone.start[otherAxis], end: opening.zone.end[otherAxis] };
            if (shiftZone.start > shiftZone.end) {
              const temp = shiftZone.start;
              shiftZone.start = shiftZone.end;
              shiftZone.end = temp;
            }
            const segment = { start: opening.line.start[otherAxis], end: opening.line.end[otherAxis] };
            if (segment.start > segment.end) {
              const temp = segment.start;
              segment.start = segment.end;
              segment.end = temp;
            }

            const maxShiftZone = { start: floorWall.start[otherAxis], end: floorWall.end[otherAxis] } as LineRange;
            const line = new Line(openingId, segment, shiftZone, maxShiftZone);
            wall.lines.push(line);
          });
        });
      });
    });

    const movedLineIds = [];
    sides.forEach((walls, side) => {
      walls.forEach(wall => {
        if (wall.lines.length > 0) {
          LineAlignment.alignLines(wall.lines, gap);

          // Move opening based on each line distance.
          wall.lines.forEach(line => {
            const soOpening = this.openings.get(line.id).soObject;
            if (soOpening.userData.isLocked) {
              movedLineIds.push(line.id);
              return;
            }

            const curShift = Math.abs(soOpening.userData.shiftDistance ?? 0);
            if (curShift === Math.abs(line.shiftDistance) && curShift === 0) {
              movedLineIds.push(line.id);
            } else {
              const distance = wall.side === Side.Bottom || wall.side === Side.Right ? line.shiftDistance * -1 : line.shiftDistance;
              SceneUtils.moveOpening(this.openings.get(line.id).soObject, distance);
            }
            movedLineIds.push(line.id);
          });
        }
      });
    });

    this.moveOpeningToOriginalPosition(movedLineIds);
  }

  private getSoFloor(floorId: string): THREE.Object3D {
    return this.soFloorsRoot.children.find(x => x.userData.id === floorId);
  }
  private moveOpeningToOriginalPosition(movedLineIds: string[]): void {
    const outdatedRoomsIds = new Set<string>();

    this.floors.forEach(floor => {
      const soFloor = this.getSoFloor(floor.id);
      soFloor?.children.forEach(soRoom => {
        soRoom.children.forEach(child => {
          if (child.userData.type === RoomEntityType.Door || child.userData.type === RoomEntityType.Window) {
            if (movedLineIds.includes(child.uuid)) {
              outdatedRoomsIds.add(child.parent.userData.id);
              return;
            }
            if (child.userData.shiftDistance && Math.abs(child.userData.shiftDistance) !== 0) {
              // child.userData.isLocked = false; //Leave here for now, in case it is turned off by mistake
              // SceneUtils.moveOpening(child, 0);
              outdatedRoomsIds.add(child.parent.userData.id);
              return;
            }
          }
        });
      });
    });

    this.roomManager.updateRoomsProperties(Array.from(outdatedRoomsIds.values()));
  }
  private prepareOpeningsAndWalls(): void {
    this.openings.clear();
    this.floorsWalls = [];
    this.floors.forEach(floor => {
      const floorSides = new Map<Side, FloorWall[]>([
        [Side.Top, []],
        [Side.Right, []],
        [Side.Bottom, []],
        [Side.Left, []],
      ]);
      const soFloor = this.getSoFloor(floor.id);
      if (!soFloor) {
        return;
      }
      const { externalSegments } = WallAnalysisUtils.collectSegments(soFloor.children);

      const rooms = new Map<string, { lines: { [key in Side]: THREE.Line3 }; openings: THREE.Object3D[]; walls: THREE.Object3D[]; center: THREE.Vector3 }>();
      for (const segment of externalSegments) {
        let roomObjs = rooms.get(segment.roomId);
        if (!roomObjs) {
          const soRoom = soFloor.children.find(r => r.userData.id === segment.roomId);
          if (!soRoom) {
            continue;
          }
          const lines = SceneUtils.getRoomModelLines(soRoom);
          const openings = soRoom.children.filter(child => child.userData.type === RoomEntityType.Door || child.userData.type === RoomEntityType.Window);
          const walls = soRoom.children.filter(child => child.userData.type === RoomEntityType.Wall);
          const center = GeometryUtils.getGeometryBoundingBox3D(soRoom).getCenter(new THREE.Vector3());
          roomObjs = {
            lines: { [Side.Top]: lines.top, [Side.Bottom]: lines.bottom, [Side.Left]: lines.left, [Side.Right]: lines.right },
            openings,
            walls,
            center,
          };
          rooms.set(segment.roomId, roomObjs);
        }
        let segmentSide;
        if (segment.isHorizontal()) {
          if (segment.start.y > roomObjs.center.y) {
            segmentSide = Side.Top;
          } else {
            segmentSide = Side.Bottom;
          }
        } else {
          if (segment.start.x > roomObjs.center.x) {
            segmentSide = Side.Right;
          } else {
            segmentSide = Side.Left;
          }
        }
        const [axis, mainAxis] = segment.isHorizontal() ? ["y", "x"] : ["x", "y"];

        let fWall = floorSides.get(segmentSide).find(wall => MathUtils.areNumbersEqual(segment.start[axis], wall.start[axis]));
        if (!fWall) {
          fWall = new FloorWall(floor.id, segmentSide);
          floorSides.get(segmentSide).push(fWall);
        }
        fWall.setStart(segment.start);
        fWall.setEnd(segment.end);

        const segLine = new THREE.Line3(GeometryUtils.Vector2ToVector3(segment.start), GeometryUtils.Vector2ToVector3(segment.end));
        const wallHalfSize = UnitsUtils.getSyntheticWallHalfSize();
        segLine.start[mainAxis] += wallHalfSize;
        segLine.end[mainAxis] -= wallHalfSize;
        if (MathUtils.areNumbersEqual(segLine.start[axis], roomObjs.lines[segmentSide].start[axis])) {
          // Find wall and openings.
          roomObjs.openings.forEach(soOpening => {
            const wall = roomObjs.walls.find(w => w.userData.revitId === soOpening.userData.wallBindingRevitId);
            if (wall) {
              const isOpeningLocked = !!soOpening.userData.isLocked;
              const openingData = SceneUtils.getOpeningZoneAndLine(soOpening);

              if (openingData) {
                // Limit the opening movement zone to the size of opening to prevent moving.
                if (isOpeningLocked) {
                  openingData.zone = openingData.line.clone();
                }

                const wallBb = GeometryUtils.getGeometryBoundingBox3D(wall);
                const openingBox = GeometryUtils.getGeometryBoundingBox3D(soOpening);
                const openingZone = openingData.zone.clone();
                const openingLine = openingData.line.clone();
                const originalCenter = openingData.center.clone();
                const lineCenter = openingLine.getCenter(new THREE.Vector3());
                if (!isOpeningLocked && !MathUtils.areNumbersEqual(originalCenter[mainAxis], lineCenter[mainAxis])) {
                  const diff = originalCenter[mainAxis] - lineCenter[mainAxis];
                  openingLine.start[mainAxis] += diff;
                  openingLine.end[mainAxis] += diff;
                  openingBox.min[mainAxis] += diff;
                  openingBox.max[mainAxis] += diff;
                }
                if (openingBox.intersectsBox(wallBb)) {
                  if (openingBox.min[axis] > wallBb.min[axis]) {
                    openingBox.min[axis] = wallBb.min[axis];
                  }
                  if (openingBox.max[axis] < wallBb.max[axis]) {
                    openingBox.max[axis] = wallBb.max[axis];
                  }
                  if (
                    GeometryUtils.lineIntersectsBoundingBox(segLine, openingBox) &&
                    openingBox.min[mainAxis] >= segLine.start[mainAxis] &&
                    openingBox.max[mainAxis] <= segLine.end[mainAxis]
                  ) {
                    //limit zone based on segment size
                    if (openingZone.start[mainAxis] > openingZone.end[mainAxis]) {
                      GeometryUtils.swapLineVectors(openingZone);
                    }
                    if (openingLine.start[mainAxis] > openingLine.end[mainAxis]) {
                      GeometryUtils.swapLineVectors(openingLine);
                    }
                    if (openingZone.start[mainAxis] < segLine.start[mainAxis]) {
                      openingZone.start[mainAxis] =
                        openingLine.start[mainAxis] < segLine.start[mainAxis] ? openingLine.start[mainAxis] : segLine.start[mainAxis];
                    }
                    if (openingZone.end[mainAxis] > segLine.end[mainAxis]) {
                      openingZone.end[mainAxis] = openingLine.end[mainAxis] > segLine.end[mainAxis] ? openingLine.end[mainAxis] : segLine.end[mainAxis];
                    }
                    fWall.openingIds.push(soOpening.uuid);
                    this.openings.set(soOpening.uuid, { soObject: soOpening, soWall: wall, zone: openingZone, line: openingLine });
                  }
                }
              }
            }
          });
        }
      }
      this.floorsWalls.push(floorSides);
    });
  }
}
