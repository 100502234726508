import axios from "../services/api/utilities/axios-wrapper";
import log from "loglevel";
import { FolderOptions } from "../ui/components/Editor/LeftBar/LeftBar";
import { showToastMessage } from "../helpers/messages";
import { asSingle } from "../helpers/pojo";
import { FileEntity } from "../models/FileEntity";
import { handlerMessagesFromInfo } from "../services";
import { findRawRecord, kindFileRaw } from "../services/allKinds";
import { getAxiosRequestConfig } from "../services/api/utilities";
import apiProvider from "../services/api/utilities/Provider";
import { appModel } from "../models/AppModel";
import { runInAction } from "mobx";
import { RawFile } from "../services/rawTypes";

const relType = asSingle(findRawRecord({ kind: 1, table: "@rel-type" })).id;
const kindRelDefault = asSingle(findRawRecord({ kind: relType, description: "Default" })).id;

type CorePlanFilesManager = {
  getCorePlanFileList: (corePlanId: string, all?: boolean) => Promise<FileEntity[]>;
  getVariationFileList: (variationId: string, all?: boolean) => Promise<FileEntity[]>;
  getCorePlanFiles: (corePlanId: string) => Promise<any>;
  getCorePlanFile: (fullFileUri: string) => Promise<string>;
  updateCorePlanFiles: (corePlanId: string, files: File[], folder?: FolderOptions, handleResponse?: boolean) => Promise<any>;
  updateVariationFiles: (variationId: string, files: File[], folder?: FolderOptions, handleResponse?: boolean) => Promise<any>;
  deleteCorePlanFiles: (corePlanId: string, fileList: string[]) => Promise<any>;
  deleteVariationFiles: (variationId: string, fileList: string[]) => Promise<any>;
  getRoomTypeDxfs: (roomTypeId: string) => Promise<any>;
  deleteCorePlanFile: (fullFileUri: string) => Promise<boolean>;
  deleteVariationFile: (fullFileUri: string) => Promise<boolean>;
  getPdfPageImage: (corePlanId: string, pdfId: string, pageNumber: number) => Promise<FileEntity>;
};

export const fileManager: CorePlanFilesManager = {
  getCorePlanFileList: async function (corePlanId: string, all = true): Promise<FileEntity[]> {
    try {
      const allParam = all ? "?all=true" : "";
      const result = await axios.get(`${apiProvider.host}files/corePlan/${corePlanId}/list${allParam}`, getAxiosRequestConfig());
      return result.data;
    } catch (err) {
      log.error("getCorePlanFileList", err);
      showToastMessage("Error", err.message + (err.response?.data ? ` ${err.response.data}` : ""));
      return null;
    }
  },
  getVariationFileList: async function (variationId: string, all = true): Promise<FileEntity[]> {
    try {
      const allParam = all ? "?all=true" : "";
      const result = await axios.get(`${apiProvider.host}files/variation/${variationId}/list${allParam}`, getAxiosRequestConfig());
      return result.data;
    } catch (err) {
      log.error("getVariationFileList", err);
      showToastMessage("Error", err.message + (err.response?.data ? ` ${err.response.data}` : ""));
      return null;
    }
  },
  getCorePlanFiles: async function (corePlanId: string): Promise<{ [key: string]: any }> {
    try {
      const result = await axios.get(`${apiProvider.host}files/corePlan/${corePlanId}`, getAxiosRequestConfig());
      return result.data;
    } catch (err) {
      log.error("getCorePlanFiles", err);
      showToastMessage("Error", err.message + (err.response?.data ? ` ${err.response.data}` : ""));
      return null;
    }
  },
  getCorePlanFile: async function (fullFileUri: string): Promise<string> {
    const strippedName = fullFileUri.replace("/files/", "").replaceAll("/", "%2F");
    const result = await axios.get(`${apiProvider.host}files/corePlan-file?fileId=${strippedName}&encode=base64`, getAxiosRequestConfig());
    return result.data;
  },
  getRoomTypeDxfs: async function (roomTypeId: string): Promise<{ [key: string]: any }> {
    const result = await axios.get(`${apiProvider.host}files/roomType/${roomTypeId}`, getAxiosRequestConfig());
    return result.data;
  },
  updateCorePlanFiles: async function (
    corePlanId: string,
    files: File[],
    folder: FolderOptions = FolderOptions.CorePlanFolder,
    handleResponse = true
  ): Promise<any> {
    try {
      const result = await updateCorePlanFiles(corePlanId, files, folder);
      handlerMessagesFromInfo(result.data);

      return result.data;
    } catch (err) {
      log.error("updateCorePlanFiles", err);
      if (handleResponse) {
        showToastMessage("Error", "CorePlan files update failed: " + (err.message || err));
      }
    }
  },
  updateVariationFiles: async function (
    variationId: string,
    files: File[],
    folder: FolderOptions = FolderOptions.VariationFolder,
    handleResponse = true
  ): Promise<any> {
    try {
      const result = await updateVariationFiles(variationId, files, folder);
      handlerMessagesFromInfo(result.data);

      return result.data;
    } catch (err) {
      log.error("updateVariationFiles", err);
      if (handleResponse) {
        showToastMessage("Error", "Variation files update failed: " + (err.message || err));
      }
    }
  },
  deleteCorePlanFiles: async function (corePlanId: string, fileList: string[]): Promise<any> {
    try {
      const config = getAxiosRequestConfig() || {};
      config.data = {};
      config.data.fileIds = fileList;

      const result = await axios.delete(`${apiProvider.host}files/corePlan/${corePlanId}`, config);

      const envelope = result.data;
      handlerMessagesFromInfo(envelope);

      return envelope.hasErrors ? null : envelope.data?.[0]?.deletedFileIds;
    } catch (err) {
      log.error("deleteCorePlanFiles", err);
      showToastMessage("Error", "Delete corePlan files: " + err.message);
      return null;
    }
  },
  deleteVariationFiles: async function (variationId: string, fileList: string[]): Promise<any> {
    try {
      const config = getAxiosRequestConfig() || {};
      config.data = {};
      config.data.fileIds = fileList;

      const result = await axios.delete(`${apiProvider.host}files/variation/${variationId}`, config);

      const envelope = result.data;
      handlerMessagesFromInfo(envelope);

      return envelope.hasErrors ? null : envelope.data?.[0]?.deletedFileIds;
    } catch (err) {
      log.error("deleteVariationFiles", err);
      showToastMessage("Error", "Delete variation files: " + err.message);
      return null;
    }
  },
  deleteCorePlanFile: async function (fullFileUri: string): Promise<boolean> {
    try {
      const config = getAxiosRequestConfig() || {};
      config.data = {};
      config.data.fileId = fullFileUri;

      const result = await axios.delete(`${apiProvider.host}files/corePlan-file`, config);
      if (result.status === 204) {
        return true;
      }

      showToastMessage("Error", "Error on corePlan file delete");
      return false;
    } catch (err) {
      log.error("deleteCorePlanFiles", err);
      showToastMessage("Error", "Error on corePlan file delete");
      return false;
    }
  },
  deleteVariationFile: async function (fullFileUri: string): Promise<boolean> {
    try {
      const config = getAxiosRequestConfig() || {};
      config.data = {};
      config.data.fileId = fullFileUri;

      const result = await axios.delete(`${apiProvider.host}files/corePlan-file`, config);
      if (result.status === 204) {
        return true;
      }

      showToastMessage("Error", "Error on variation file delete");
      return false;
    } catch (err) {
      log.error("deleteVariationFile", err);
      showToastMessage("Error", "Error on variation file delete");
      return false;
    }
  },
  getPdfPageImage: async function (corePlanId: string, fileId: string, pageNumber: number): Promise<FileEntity> {
    try {
      const data = { corePlanId, fileId, pageNumber };
      const result = await axios.post<RawFile>(`${apiProvider.host}files/pdf-to-image/`, data, getAxiosRequestConfig());

      if (result.status === 200) {
        const file = new FileEntity().fromDTO(result.data);
        const corePlan = appModel.corePlans.find(p => p.id === corePlanId);
        const fileIndex = corePlan.files.findIndex(f => f.id === file.id);
        if (fileIndex < 0) {
          runInAction(() => {
            corePlan.files.push(file);
          });
        }
        return file;
      }

      showToastMessage("Error", "Error on pdf file conversion.");
      return null;
    } catch (err) {
      log.error("getPdfPageImage", err);
      showToastMessage("Error", "Error on pdf file conversion.");
      return null;
    }
  },
};

async function updateCorePlanFiles(corePlanId: string, uploadedFiles: File[], folder: FolderOptions = FolderOptions.CorePlanFolder): Promise<any> {
  if (!uploadedFiles?.length) {
    return;
  }

  const bodyFormData = new FormData();
  const files = [];
  const blobs = [];
  let fileId = -1;

  for (const file of uploadedFiles) {
    const obj = {
      id: fileId,
      kind: kindFileRaw.id,
      lastModified: file.lastModified,
      name: file.name,
      fileSize: file.size,
      mimeType: file.type,
      filePath: folder,
      relType: kindRelDefault,
    };

    files.push(obj);
    blobs.push([`${fileId}`, file]);
    fileId -= 1;
  }

  const envelope = {
    meta: { v: 1 },
    data: files,
  };

  // 'envelope' should be placed before blobs in multipart/form-data
  // to allow server extract envelope  data before handlings binary parts
  bodyFormData.append("envelope", JSON.stringify(envelope));
  blobs.forEach(([id, blob]) => {
    bodyFormData.append(id, blob);
  });

  try {
    const config = getAxiosRequestConfig();
    config["Content-Type"] = "multipart/form-data";

    return await axios.put(`${apiProvider.host}files/corePlan/${corePlanId}`, bodyFormData, config);
  } catch (err) {
    log.error(err);
    return null;
  }
}

async function updateVariationFiles(variationId: string, uploadedFiles: File[], folder: FolderOptions = FolderOptions.VariationFolder): Promise<any> {
  if (!uploadedFiles?.length) {
    return;
  }

  const bodyFormData = new FormData();
  const files = [];
  const blobs = [];
  let fileId = -1;

  for (const file of uploadedFiles) {
    const obj = {
      id: fileId,
      kind: kindFileRaw.id,
      lastModified: file.lastModified,
      name: file.name,
      fileSize: file.size,
      mimeType: file.type,
      filePath: folder,
      relType: kindRelDefault,
    };

    files.push(obj);
    blobs.push([`${fileId}`, file]);
    fileId -= 1;
  }

  const envelope = {
    meta: { v: 1 },
    data: files,
  };

  // 'envelope' should be placed before blobs in multipart/form-data
  // to allow server extract envelope  data before handlings binary parts
  bodyFormData.append("envelope", JSON.stringify(envelope));
  blobs.forEach(([id, blob]) => {
    bodyFormData.append(id, blob);
  });

  try {
    const config = getAxiosRequestConfig();
    config["Content-Type"] = "multipart/form-data";

    return await axios.put(`${apiProvider.host}files/variation/${variationId}`, bodyFormData, config);
  } catch (err) {
    log.error(err);
    return null;
  }
}
