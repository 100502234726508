import { Tabs, Menu, Modal } from "antd";
import { observer } from "mobx-react";
import type { MenuProps } from "antd";

import RoomCatalogView from "./RoomCatalog/RoomCatalog";
import ProductParams from "./ProductParams/ProductParams";
import ValidationPanel from "./ValidationPanel/ValidationPanel";
import Configure from "./Configure/Configure";
import Manage from "./Manage/Manage";

import { appModel } from "../../../../models/AppModel";
import { entityManager } from "../../../../entities/entityManager";
import { getDropdownTrigger } from "../../../../helpers/utilities";
import { fileManager } from "../../../../entities/fileManager";
import { showToastMessage } from "../../../../helpers/messages";
import { FileExportType } from "../../../../models/FileExportType";
import { SceneMode } from "../../../../models/SceneMode";
import { RenderJob } from "../../../../entities/renderJob";
import { SceneEditorMode } from "../../../../editor/models/SceneEditorMode";
import { handlePendingChanges } from "../../../../helpers/appUtils";
import { useHistory } from "react-router-dom";
import { openModalCorePlanPackageWindow } from "../../CorePlans/ModalCorePlanPackageWindow";
import { CorePlanPackageDetailTabs } from "../../CorePlans/CorePlanDetails/CorePlanPackageDetails";

import "./LeftBar.sass";

const RENDER_3D_MODEL_MENU_KEY = "RENDER_3D_MODEL_MENU_KEY";

const { confirm } = Modal;

export const enum FolderOptions {
  CorePlanFolder = "",
  VariationFolder = "",
  FloorPlanFolder = "FloorPlans",
  TmlFolder = "TML",
  JsonFolder = "JSON",
  DataModelFolder = "DataModels",
  ThreeDModelsFolder = "3dModels",
  RenderingsFolder = "Renderings",
  ThumbnailsFolder = "Thumbnails",
}

const fileTypeFolderMap = {
  [FileExportType.PdfCorePlan]: FolderOptions.FloorPlanFolder,
  [FileExportType.PdfPlan]: FolderOptions.FloorPlanFolder,
  [FileExportType.Json]: FolderOptions.JsonFolder,
  [FileExportType.Tml]: FolderOptions.TmlFolder,
};

const LeftBar = observer(() => {
  const history = useHistory();
  const dropdownTrigger = getDropdownTrigger();

  const onMenuClick: MenuProps["onClick"] = async e => {
    // Handle Start Render 3D model case
    if (e.key === RENDER_3D_MODEL_MENU_KEY) {
      if (!(await RenderJob.canStartJob())) {
        return;
      }
      appModel.setIsBusy(true);
      await RenderJob.startJob();
      appModel.setIsBusy(false);
      return;
    }

    appModel.setIsBusy(true);
    // Handle file exporting cases
    let result;
    try {
      const file = await appModel.createExportFile(e.key as FileExportType);
      result = await fileManager.updateCorePlanFiles(appModel.activeCorePlan.id, [file], fileTypeFolderMap[e.key]);
    } catch (e) {
      showToastMessage("Error", "Report file creation failed.");
    } finally {
      appModel.setIsBusy(false);
    }

    const downloadFile = async () => {
      if (result && !result.hasErrors && result.data[0]?.attached[0]?.URI) {
        const fileName = result.data[0].attached[0].name;
        confirm({
          title: `File ${fileName} successfully uploaded. Do you want to download a copy?`,
          okText: "Yes",
          okType: "primary",
          cancelText: "No",
          okButtonProps: { href: result.data[0].attached[0].presignedDownloadUrl, download: fileName },
          async onOk() {
            if (result.data[0].attached[0].presignedDownloadUrl) {
              return;
            }

            const content = await fileManager.getCorePlanFile(result.data[0].attached[0].URI);
            if (!content) {
              showToastMessage("Error", `Error on file download ${fileName}`);
              return;
            }
            const link = document.createElement("a");
            link.href = "data:/plain;base64," + content;
            link.download = fileName;
            link.click();
          },
        });
      }
    };

    await downloadFile();
  };

  const onSaveCorePlanClick = async () => {
    if (!(await handlePendingChanges())) {
      return;
    }

    appModel.baseManager.roomManager.updateRoomDrawings(true);
    const { notFound } = await entityManager.saveCorePlan(appModel.activeCorePlan, true);
    if (notFound) {
      await appModel.setActiveCorePlan(null, false);
      history.push("/");
      return;
    }
    appModel.updateCorePlanCost();
    appModel.setSceneEditorMode(SceneEditorMode.Room);
  };

  const exportMenu = (
    <Menu
      onClick={onMenuClick}
      items={[
        { label: "PDF of current story", key: FileExportType.PdfPlan, className: "pdf" },
        { label: "PDF of the Core Plan", key: FileExportType.PdfCorePlan, className: "pdf" },
        { label: "Json", key: FileExportType.Json, className: "json" },
      ]}
    />
  );

  const onOpenModalCorePlanPackageWindow = () => {
    openModalCorePlanPackageWindow(appModel.activeCorePlan, CorePlanPackageDetailTabs.CorePlanOverview);
  };

  return (
    <div className="left-bar">
      <div className="tabs-holder">
        {appModel.isSceneLoaded && (
          <>
            {appModel.sceneMode == SceneMode.Editor && (
              <Tabs
                defaultActiveKey={appModel.isViewOnlyMode ? "PrjP" : "RC"}
                className="left-bar-tabs"
                destroyInactiveTabPane
                items={[
                  { label: "Room Catalog", key: "RC", children: <RoomCatalogView />, disabled: appModel.isViewOnlyMode },
                  { label: "Parameters", key: "PrjP", children: <ProductParams /> },
                  { label: "Validation", key: "VP", children: <ValidationPanel /> },
                ]}
              />
            )}
            {appModel.sceneMode !== SceneMode.Editor && (
              <Tabs
                defaultActiveKey="SV"
                className="left-bar-tabs"
                destroyInactiveTabPane
                items={[
                  { label: "Configure", key: "CO", children: <Configure /> },
                  { label: "Manage", key: "MA", children: <Manage /> },
                ]}
              />
            )}
          </>
        )}
      </div>

      <div className="buttons-holder">
        <button className="btn" disabled={!appModel.activeCorePlan || appModel.isViewOnlyMode} onClick={onSaveCorePlanClick}>
          <div className="icon-container">
            <img src="/assets/save-one.svg" alt="Save button" />
          </div>
          <span>Save</span>
        </button>
        <button className="btn" disabled={!appModel.activeCorePlan} onClick={onOpenModalCorePlanPackageWindow}>
          <div className="icon-container">
            <img src="/assets/folder-open.svg" alt="Package button" />
          </div>
          <span>Package</span>
        </button>
      </div>
    </div>
  );
});

export default LeftBar;
