import { AuthTerminationStateValues } from "../helpers/auth";

const ACCESS_TOKEN_KEY = "VEEV_ACCESS_TOKEN";
const ID_TOKEN_KEY = "VEEV_ID_TOKEN";
const AUTH_SESSION_TERMINATION_KEY = "AUTH_SESSION_TERMINATION_KEY";

/**
 * Gets access token from local store
 * @returns Value for access token if it's stored in local store,
 * otherwise - empty string
 */
export const getStoredAccessToken = (): string => localStorage.getItem(ACCESS_TOKEN_KEY) || "";
export const getStoredIdToken = (): string => localStorage.getItem(ID_TOKEN_KEY) || "";

/**
 * Stores provided string value as access token in local store
 * @param token - access token value
 * @returns
 */
export const storeAccessToken = (token: string): void => localStorage.setItem(ACCESS_TOKEN_KEY, token);
export const storeIdToken = (token: string): void => localStorage.setItem(ID_TOKEN_KEY, token);

/**
 * Removes access token from local store
 * @returns
 */
export const removeAccessToken = (): void => localStorage.removeItem(ACCESS_TOKEN_KEY);

/**
 * Set mark about expiration of auth session
 */
export const setAuthSessionTerminationState = (state: AuthTerminationStateValues): void => localStorage.setItem(AUTH_SESSION_TERMINATION_KEY, state);

/**
 * Returns auth session termination state if it was terminated, otherwise returns null
 * (!) Has mutation side effect - remove state when used, so it can be retrieved only once
 */
export const checkOnceAuthSessionTerminationState = (): AuthTerminationStateValues | null => {
  const state: AuthTerminationStateValues | null = localStorage.getItem(AUTH_SESSION_TERMINATION_KEY);
  localStorage.removeItem(AUTH_SESSION_TERMINATION_KEY);
  return state;
};
