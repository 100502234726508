import { Col, Form, Input, Row } from "antd";

const BuildingDefinition = ({ corePlanData, formData, setFormData, setCorePlanData }) => {
  const handleAttributeChange = e => {
    const lotDifinition = Number.parseFloat(e.target.value);
    setCorePlanData(e.target.name, lotDifinition, true);
    setFormData({ ...formData, [e.target.name]: lotDifinition });
  };

  return (
    <div>
      <div className="ant-form-item-label mid-label">
        <label>Building Definition</label>
      </div>
      <Row className="area-group">
        <Col span={4}>
          <Form.Item rules={[{ required: false }]}>
            <Input
              data-testid="minNetArea"
              addonBefore="Net Area"
              addonAfter="sqft"
              onChange={handleAttributeChange}
              name="minNetArea"
              defaultValue={corePlanData.attributes.minNetArea}
              value={formData.minNetArea}
              type="number"
              step="any"
              min={0}
            />
          </Form.Item>
        </Col>
        <Col span={4} offset={5}>
          <Form.Item rules={[{ required: false }]}>
            <Input
              data-testid="maxGrossArea"
              addonBefore="Gross Area"
              addonAfter="sqft"
              onChange={handleAttributeChange}
              name="maxGrossArea"
              defaultValue={corePlanData.attributes.maxGrossArea}
              value={formData.maxGrossArea}
              type="number"
              step="any"
              min={0}
              required={true}
            />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};

export default BuildingDefinition;
