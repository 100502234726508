import { useState } from "react";
import { UploadFile } from "antd/lib/upload";
import { observer } from "mobx-react";

import { UserDepot } from "../../store/UserDepot";
import ModalBulkRoomUpload from "../components/CorePlans/ModalBulkRoomUpload";
import { ContentMode, openModalCorePlanWindow } from "../components/CorePlans/ModalCorePlanWindow";
import ModalSystemSettings from "../components/CorePlans/ModalSystemSettings/ModalSystemSettings";
import CorePlansListView from "../components/CorePlans/CorePlansListView";
import Header from "../components/HomePage/Header";

const HomePage = observer(() => {
  const [isSystemSettingsModalOpen, setIsSystemSettingsModalOpen] = useState(false);
  const [bulkAddModalOpen, setBulkAddModalOpen] = useState(false);
  const [bulkUploadFolders, setBulkUploadFolders] = useState<Map<string, UploadFile[]>>(null);
  const [uploadRevitFiles, setUploadRevitFiles] = useState<UploadFile[]>([]);

  const onCreateCorePlanClick = () => {
    openModalCorePlanWindow(null, ContentMode.New);
  };

  const onSystemSettingsClick = () => {
    setIsSystemSettingsModalOpen(true);
  };

  const onBulkUploadModalOpen = (uploadedCategoriesMap: Map<string, UploadFile[]>, uploadedRevitFilesList: UploadFile<any>[]) => {
    setBulkAddModalOpen(true);
    setIsSystemSettingsModalOpen(false);
    setBulkUploadFolders(uploadedCategoriesMap);
    setUploadRevitFiles(uploadedRevitFilesList);
  };

  const onSystemSettingsModalClose = () => {
    setIsSystemSettingsModalOpen(false);
  };

  const onBulkUploadCancel = () => {
    setBulkAddModalOpen(false);
    setIsSystemSettingsModalOpen(true);
    setBulkUploadFolders(null);
  };

  return (
    <>
      <Header userName={UserDepot.current?.userData.name} onSystemSettingsClick={onSystemSettingsClick} onCreateCorePlanClick={onCreateCorePlanClick} />
      <CorePlansListView />
      <ModalSystemSettings isOpen={isSystemSettingsModalOpen} handleModalClose={onSystemSettingsModalClose} handleBulkUploadModalOpen={onBulkUploadModalOpen} />
      <ModalBulkRoomUpload isOpen={bulkAddModalOpen} handleClose={onBulkUploadCancel} folders={bulkUploadFolders} revitFiles={uploadRevitFiles} />
    </>
  );
});

export default HomePage;
