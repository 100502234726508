import RoomManager from "../../managers/RoomManager/RoomManager";
import { CommandTypes } from "./CommandTypes";
import { RoomCommand } from "./RoomCommand";

export class TranslateRoomCommand extends RoomCommand {
  constructor(
    entityId: string,
    private diff: THREE.Vector3
  ) {
    super(entityId);

    this.type = CommandTypes.TranslateRoomCommand;
  }

  apply(roomManager: RoomManager): void {
    const soRoom = roomManager.getActiveFloorSoRoom(this.entityId);
    soRoom.position.add(this.diff);
    soRoom.updateMatrixWorld();

    roomManager.updateRoomsProperties([this.entityId]);
    roomManager.checkRoomsSharedObjects([this.entityId]);
  }
  undo(roomManager: RoomManager): void {
    const soRoom = roomManager.getActiveFloorSoRoom(this.entityId);
    soRoom.position.sub(this.diff);
    soRoom.updateMatrixWorld();

    roomManager.updateRoomsProperties([this.entityId]);
    roomManager.checkRoomsSharedObjects([this.entityId]);
  }
}
