import { Fragment, useContext } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import LogInComponent from "./ui/components/common/LogInComponent";
import UserProfile from "./ui/components/Profile/UserProfile";
import LoadingSpinner from "./ui/components/common/LoadingSpinner";
import EditorPage from "./ui/pages/EditorPage";
import HomePage from "./ui/pages/HomePage";
import AuthContext from "./store/auth-context";
import IdleSession from "./ui/components/common/IdleSession";
import Prompt from "./ui/components/common/Prompt";
import "./ui/components/common/LoadingSpinner.sass";

function App() {
  const authCtx = useContext(AuthContext);

  return authCtx.isUserDataLoading ? (
    <div className="spinner-container">
      <LoadingSpinner />
    </div>
  ) : (
    <BrowserRouter>
      <Fragment>
        <ToastContainer />
        <IdleSession />
        <div className="main-content">
          <Switch>
            {authCtx.isLoggedIn ? (
              <>
                <Route path="/" exact>
                  <HomePage />
                </Route>
                <Route path="/profile">
                  <UserProfile />
                </Route>
                <Route path="/editor/:corePlanId">
                  <EditorPage />
                </Route>
              </>
            ) : (
              <LogInComponent />
            )}
            <Route path="*">
              <Redirect to="/" />
            </Route>
          </Switch>
          <Prompt />
        </div>
      </Fragment>
    </BrowserRouter>
  );
}

export default App;
