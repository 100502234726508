import * as THREE from "three";
import { inchesToFeetInchesFraction } from "../../../helpers/measures";
import { DIMENSION_INDICATOR_RENDER_ORDER, DIMENSION_LABEL_COLOR, DIMENSION_LINE_COLOR } from "../../consts";
import GeometryUtils from "../../utils/GeometryUtils";
import MathUtils from "../../utils/MathUtils";
import SceneUtils from "../../utils/SceneUtils";
import UnitsUtils from "../../utils/UnitsUtils";

const TEXT_LABEL_SIZE = 0.5;
const TEXT_LABEL_OFFSET = 0.5;
const OPENING_TRIANGLE_WIDTH = 0.178;
const OPENING_TRIANGLE_HEIGHT = 0.357;

export class MeasureLine extends THREE.Group {
  private startPoint: THREE.Vector3 = new THREE.Vector3();
  private endPoint: THREE.Vector3 = new THREE.Vector3();

  private line: THREE.Line;
  private label: THREE.Mesh;
  private startTriangle: THREE.Mesh;
  private endTriangle: THREE.Mesh;

  constructor(startPoint: THREE.Vector3) {
    super();

    this.startPoint = startPoint.clone();
    this.endPoint = startPoint.clone();

    this.line = new THREE.Line(
      new THREE.BufferGeometry(),
      new THREE.LineDashedMaterial({
        color: DIMENSION_LINE_COLOR,
        dashSize: 0.15 * UnitsUtils.getConversionFactor(),
        gapSize: 0.2 * UnitsUtils.getConversionFactor(),
        transparent: true,
        opacity: 1.0,
      })
    );

    [this.startTriangle, this.endTriangle] = this.createTriangles();

    this.add(this.startTriangle, this.line, this.endTriangle);
    GeometryUtils.setRenderOrder(this, DIMENSION_INDICATOR_RENDER_ORDER);

    this.update();
  }

  public setEndPoint(endPoint: THREE.Vector3): void {
    this.endPoint = endPoint.clone();
    this.update();
  }

  private update(): void {
    const h = OPENING_TRIANGLE_HEIGHT * UnitsUtils.getConversionFactor();
    const length = Math.max(this.startPoint.distanceTo(this.endPoint), 2 * h);
    const halfLength = length / 2 - h;
    const angle = Math.atan2(this.endPoint.y - this.startPoint.y, this.endPoint.x - this.startPoint.x);

    this.startTriangle.position.x = -halfLength;
    this.endTriangle.position.x = halfLength;

    this.line.geometry.dispose();
    this.line.geometry = new THREE.BufferGeometry().setFromPoints([new THREE.Vector3(-halfLength, 0, 0), new THREE.Vector3(halfLength, 0, 0)]);

    this.line.computeLineDistances();

    this.disposeLabel();
    if (length > 2 * h) {
      this.generateLabel(inchesToFeetInchesFraction(length, UnitsUtils.getRoundFractionPrecision()), angle);
    }

    this.rotation.z = angle;
    this.position.copy(this.endPoint.clone().add(this.startPoint).multiplyScalar(0.5));
  }

  private createTriangles(): THREE.Mesh[] {
    const w = OPENING_TRIANGLE_WIDTH * UnitsUtils.getConversionFactor();
    const h = OPENING_TRIANGLE_HEIGHT * UnitsUtils.getConversionFactor();

    const geometry = new THREE.BufferGeometry().setFromPoints([new THREE.Vector3(0, w, 0), new THREE.Vector3(-h, 0, 0), new THREE.Vector3(0, -w, 0)]);
    const material = new THREE.MeshBasicMaterial({ color: DIMENSION_LINE_COLOR, transparent: true, opacity: 1.0 });

    const startTriangle = new THREE.Mesh(geometry, material);
    const endTriangle = new THREE.Mesh(geometry, material);

    endTriangle.rotateZ(Math.PI);

    return [startTriangle, endTriangle];
  }
  private generateLabel(text: string, angle: number): void {
    this.label = SceneUtils.createTextMesh(text, DIMENSION_LABEL_COLOR, TEXT_LABEL_SIZE * UnitsUtils.getConversionFactor());
    this.label.renderOrder = DIMENSION_INDICATOR_RENDER_ORDER;

    const offset = TEXT_LABEL_OFFSET * UnitsUtils.getConversionFactor();
    const bb = GeometryUtils.getGeometryBoundingBox2D(this.label);
    const labelCenter = bb.getCenter(new THREE.Vector3());
    const isAngleInRightHalf = MathUtils.isNumberInRange(angle, -Math.PI / 2, Math.PI / 2);

    this.label.position.copy(labelCenter.clone().negate());
    this.label.position.y += offset;

    if (!isAngleInRightHalf) {
      this.label.applyMatrix4(new THREE.Matrix4().makeRotationZ(Math.PI));
    }

    this.add(this.label);
  }
  private disposeLabel(): void {
    if (this.label) {
      this.remove(this.label);
      GeometryUtils.disposeObject(this.label);
      this.label = null;
    }
  }
}
