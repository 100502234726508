function initModule() {
  const buildVersion = process.env.REACT_APP_BUILD_MODE ? `.${process.env.REACT_APP_BUILD_MODE}` : null;
  if (buildVersion === null) return {};
  return require(`./fake-user${buildVersion}`);
}

const localModule = initModule();

export const AUTH_FAKE_USER_TOKEN = localModule.APP_AUTH_FAKE_USER_TOKEN || `header.payload${+Date.now()}.signature`;

export function userByLoginType(loginType: string) {
  return loginType === "fake" ? localModule.faUser : null;
}

export function isEnabledInnerUser(): { loginType?: string; buttonText?: string } {
  return localModule.faUser
    ? {
        loginType: "fake",
        buttonText: "Fake Login",
      }
    : {};
}
