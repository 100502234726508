import "./ModalCorePlanPackageWindow.sass";
import { makeAutoObservable } from "mobx";
import { observer } from "mobx-react-lite";
import { CorePlan } from "../../../models/CorePlan";
import { Modal } from "antd";
import CorePlanDetails, { CorePlanPackageDetailTabs } from "./CorePlanDetails/CorePlanPackageDetails";

export const openModalCorePlanPackageWindow = (
  corePlan: CorePlan | null,
  defaultActiveTab: CorePlanPackageDetailTabs,
  onSuccess?: (corePlan: CorePlan) => void
) => {
  if (modalCorePlanPackageWindowState.isOpen) {
    return;
  }
  modalCorePlanPackageWindowState.openCorePlanPackageWindow(corePlan, defaultActiveTab, onSuccess);
};

export const closeModalCorePlanPackageWindow = () => {
  modalCorePlanPackageWindowState.closeCorePlanPackageWindow();
};

export const getIsModalCorePlanPackageWindowOpen = () => {
  return modalCorePlanPackageWindowState.isOpen;
};

export type ContentModalCorePlanPackageWindowProps = {
  corePlan: CorePlan;
  onModalOk: () => void;
  onModalCancel: () => void;
};

interface ModalCorePlanPackageWindowProps {
  isOpen?: boolean;
  corePlan?: CorePlan;
  defaultActiveTab?: CorePlanPackageDetailTabs;
}

class ModalCorePlanPackageWindowState implements ModalCorePlanPackageWindowProps {
  isOpen = false;
  corePlan?: CorePlan;
  onSuccess?: (corePlan: CorePlan) => void;
  defaultActiveTab?: CorePlanPackageDetailTabs;

  constructor() {
    makeAutoObservable(this);
  }

  openCorePlanPackageWindow(corePlan: CorePlan, defaultActiveTab: CorePlanPackageDetailTabs, onSuccess?: (corePlan: CorePlan) => void) {
    this.isOpen = true;
    this.corePlan = corePlan;
    this.onSuccess = onSuccess;
    this.defaultActiveTab = defaultActiveTab;
  }

  closeCorePlanPackageWindow() {
    this.isOpen = false;
    this.corePlan = undefined;
    this.onSuccess = undefined;
    this.defaultActiveTab = undefined;
  }
}

const modalCorePlanPackageWindowState = new ModalCorePlanPackageWindowState();

let ModalCorePlanPackageWindow = ({ isOpen, corePlan: corePlan, defaultActiveTab: defaultActiveTab }: ModalCorePlanPackageWindowProps) => {
  if (isOpen) {
    modalCorePlanPackageWindowState.openCorePlanPackageWindow(corePlan, defaultActiveTab);
  }
  const isModalOpen = isOpen || modalCorePlanPackageWindowState.isOpen;

  if (!isModalOpen) return <></>;

  const modalTitle = <div className="modal-title">{modalCorePlanPackageWindowState.corePlan?.name}</div>;

  const onSuccess = (corePlan: CorePlan) => {
    modalCorePlanPackageWindowState.onSuccess?.(corePlan);
    closeModalCorePlanPackageWindow();
  };

  return (
    <Modal
      className={`modal-corePlan${" corePlan-details"}`}
      open={isModalOpen}
      onCancel={closeModalCorePlanPackageWindow}
      onOk={closeModalCorePlanPackageWindow}
      title={modalTitle}
      footer={[]}
      destroyOnClose
    >
      {<CorePlanDetails corePlan={modalCorePlanPackageWindowState.corePlan} defaultActiveTab={modalCorePlanPackageWindowState.defaultActiveTab} />}
    </Modal>
  );
};

ModalCorePlanPackageWindow = observer(ModalCorePlanPackageWindow);
export default ModalCorePlanPackageWindow;
