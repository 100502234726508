import "./LoadingSpinner.sass";

type Props = {
  size?: "large" | "medium" | "small";
};

const LoadingSpinner = ({ size = "large" }: Props) => {
  const cssClasses = `spinner ${size}`;
  return <div className={cssClasses}></div>;
};

export default LoadingSpinner;
