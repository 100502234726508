import { Col, Row } from "antd";
import { observer } from "mobx-react-lite";
import { FileEntity } from "../../../../models/FileEntity";
import { CorePlan } from "../../../../models/CorePlan";
import FileItem from "./FileItem";
import { RenderJobStatus, TRenderJob } from "../../../../entities/renderJob/types";
import RenderJobItem from "./JobItem";
import { FolderOptions } from "../../Editor/LeftBar/LeftBar";
import "./TabCorePlanFiles.sass";
import { markPublic } from "../../../../services/allKinds";

interface ITabCorePlanFilesProps {
  corePlan: CorePlan;
  folderName: string;
  files: FileEntity[];
  removeFile: (fileId: string, folderName: string) => void;
  jobs?: TRenderJob[];
  removeJob?: (jobId: string) => void;
}

const TabCorePlanFiles = observer(({ corePlan, folderName, files = [], jobs = [], removeJob, removeFile }: ITabCorePlanFilesProps) => {
  const isJob = (item: FileEntity | TRenderJob): item is TRenderJob => "status" in item;
  const getTime = (item: FileEntity | TRenderJob): number => (isJob(item) ? item.updatedAt.getTime() : Number.parseInt(item.lastModified));

  const completedJobs = jobs.filter(j => j.status === RenderJobStatus.Completed);
  const completedJobFiles = jobs.length ? files.filter(f => completedJobs.some(j => f.URI.includes(j.id))) : files;
  const allItems = [...completedJobFiles.filter(f => f.mark === markPublic.id), ...jobs].sort((a, b) => getTime(b) - getTime(a));

  return (
    <Row gutter={24} className="tab-corePlan-files">
      {!allItems.length && (
        <Col span={6}>
          <p>no files</p>
        </Col>
      )}
      {allItems.map((item, index) =>
        isJob(item) ? (
          <RenderJobItem corePlanId={corePlan.id} renderJob={item} removeJob={removeJob} key={index} />
        ) : (
          <FileItem
            file={item}
            corePlan={corePlan}
            isAttachedFile={folderName === FolderOptions.CorePlanFolder}
            removeFile={removeFile}
            key={index}
            folderName={folderName}
          />
        )
      )}
    </Row>
  );
});
export default TabCorePlanFiles;
