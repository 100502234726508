import log from "loglevel";
import { showToastMessage } from "../../../helpers/messages";

export function handleResponse(response) {
  return response.results || response.data || response;
}

export function handleError(error) {
  const result = error.data || error;
  log.error("Response handleError", result);
  let errorMessage = "Response";
  if (result.name) errorMessage += " " + result.name;

  if (result.message) errorMessage += " " + result.message;

  if (errorMessage === "Response") errorMessage += " handleError";

  showToastMessage("Error", errorMessage);
  return result;
}
