import log from 'loglevel';
import * as THREE from 'three';

const EPS = 0.000001;
// const ServiceKeys = { Shift: 16, Control: 17, Alt: 18 };
// const MouseButtons = { Left: 0, Middle: 1, Right: 2, Back: 3, Forward: 4 };
const STATE = {
  NONE: -1,
  ZOOM: 0,
  PAN: 1,
  ROTATE: 2,
  TOUCH_ROTATE: 3,
  TOUCH_ZOOM_PAN: 4
};

class TrackballControls extends THREE.EventDispatcher {
    private touchZoomFactor = 0.06;
    // private ctrlPressed = false;
    // private altPressed = false;
    // private shiftPressed = false;

    private changed = true;
    private lastPosition = new THREE.Vector3();

    private screen = { left: 0, top: 0, width: 0, height: 0 };
    // private mappings = {
    //     zoom: [],
    //     pan: [
    //         { keys: [], mouseButtons: [MouseButtons.Middle] }
    //     ],
    //     rotate: [
    //         { keys: [], mouseButtons: [MouseButtons.Right] }
    //     ]
    // };

    private state = STATE.NONE;
    private prevState = STATE.NONE;
    private eye = new THREE.Vector3();
    private movePrev = new THREE.Vector2();
    private moveCurr = new THREE.Vector2();
    private lastAxis = new THREE.Vector3();
    private lastAngle = 0.0;
    private zoomStart = new THREE.Vector2();
    private zoomEnd = new THREE.Vector2();
    private touchZoomDistanceStart = 0.0;
    private touchZoomDistanceEnd = 0.0;
    private panStart = new THREE.Vector2();
    private panEnd = new THREE.Vector2();

    // for orthographic camera
    private left0 = 0.0;
    private right0 = 0.0;
    private top0 = 0.0;
    private bottom0 = 0.0;

    // for reset
    private target0 = new THREE.Vector3();
    private position0 = new THREE.Vector3();
    private up0 = new THREE.Vector3();

    // events
    private changeEvent = { type: "change" };
    private startEvent = { type: "start" };
    private endEvent = { type: "end" };

    //--------------------------

    public camera: THREE.Camera;
    public domElement: any;
    
    public target = new THREE.Vector3();
    public zoomPoint = new THREE.Vector3();

    public enabled = true;

    public rotateSpeed = 1.0;
    public zoomSpeed = 1.2;
    public panSpeed = 0.3;

    public invertZoom = false;

    public noRotate = false;
    public noZoom = false;
    public noPan = false;
    public noRoll = false;

    public staticMoving = false;
    public dynamicDampingFactor = 0.2;

    public minDistance = 0.0;
    public maxDistance = Infinity;

    public onRender = null; // external render callback

    constructor(camera: THREE.Camera, domElement?: HTMLElement) {
        super();

        this.camera = camera;
        this.domElement = (domElement !== undefined) ? domElement : document;
    
        this.target0 = this.target.clone();

        this.position0 = this.camera.position.clone();
        this.up0 = this.camera.up.clone();
        if (this.isOrthographicCamera()) {
            const cam = this.camera as THREE.OrthographicCamera;
            this.left0 = cam.left;
            this.right0 = cam.right;
            this.top0 = cam.top;
            this.bottom0 = cam.bottom;
        }
    
        this.onMouseDown = this.onMouseDown.bind(this);
        this.onMouseMove = this.onMouseMove.bind(this);
        this.onMouseUp = this.onMouseUp.bind(this);
        this.onMouseWheel = this.onMouseWheel.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.onKeyUp = this.onKeyUp.bind(this);
    
        this.init();
    }

    init(): void {
        if (this.domElement === document) return;

        this.domElement.addEventListener('mousedown', this.onMouseDown, false);
        this.domElement.addEventListener('wheel', this.onMouseWheel, {passive: false});
        this.domElement.addEventListener('contextmenu', this.contextmenu, false);
        this.domElement.addEventListener('touchstart', this.touchstart, false);
        this.domElement.addEventListener('touchmove', this.touchmove, false);
        this.domElement.addEventListener('touchend', this.touchend, false);
        
        window.addEventListener('keydown', this.onKeyDown, false);
        window.addEventListener('keyup', this.onKeyUp, false);

        this.handleResize();

        // force an update at start
        this.update();
    }
    dispose(): void {
        if (this.domElement === document) return;
        
        this.domElement.removeEventListener('mousedown', this.onMouseDown, false);
        this.domElement.removeEventListener('wheel', this.onMouseWheel, false);
        this.domElement.removeEventListener('contextmenu', this.contextmenu, false);
        this.domElement.removeEventListener('touchstart', this.touchstart, false);
        this.domElement.removeEventListener('touchmove', this.touchmove, false);
        this.domElement.removeEventListener('touchend', this.touchend, false);
        
        document.removeEventListener('mousemove', this.onMouseMove, false);
        document.removeEventListener('mouseup', this.onMouseUp, false);
        
        window.removeEventListener('keydown', this.onKeyDown, false);
        window.removeEventListener('keyup', this.onKeyUp, false);
    };

    handleResize(): void {
        if (this.domElement === document) {
            this.screen.left = 0;
            this.screen.top = 0;
            this.screen.width = window.innerWidth;
            this.screen.height = window.innerHeight;
        } else {
            const box = this.domElement.getBoundingClientRect();
            const d = this.domElement.ownerDocument.documentElement;

            this.screen.left = box.left + window.pageXOffset - d.clientLeft;
            this.screen.top = box.top + window.pageYOffset - d.clientTop;
            this.screen.width = box.width;
            this.screen.height = box.height;
        }

        //this.radius = 0.5 * Math.min(this.screen.width, this.screen.height);

        if (this.isOrthographicCamera()) {
            const cam = this.camera as THREE.OrthographicCamera;
            this.left0 = cam.left;
            this.right0 = cam.right;
            this.top0 = cam.top;
            this.bottom0 = cam.bottom;
        }
    }
    update(): void {
        if (this.screen.width === 0 || this.screen.height === 0) {
            this.handleResize();
        }

        this.eye.subVectors(this.camera.position, this.target);

        if (!this.noRotate)
            this.rotateCamera();
        if (!this.noZoom)
            this.zoomCamera();
        if (!this.noPan)
            this.panCamera();

        if (this.isPerspectiveCamera()) {
            this.camera.position.addVectors(this.target, this.eye);
            this.checkDistances();
            this.camera.lookAt(this.target);

            if (this.lastPosition.distanceToSquared(this.camera.position) > EPS) {
                this.dispatchEvent(this.changeEvent);
                this.lastPosition.copy(this.camera.position);
            }
        } else {
            this.camera.position.addVectors(this.target, this.eye);
            this.camera.lookAt(this.target);

            if (this.changed) {
                this.dispatchEvent(this.changeEvent);
                this.changed = false;
            }
        }
    }
    reset(): void {
        this.state = STATE.NONE;
        this.prevState = STATE.NONE;

        this.target.copy(this.target0);
        this.camera.position.copy(this.position0);
        this.camera.up.copy(this.up0);

        this.eye.subVectors(this.camera.position, this.target);

        if (this.isOrthographicCamera()) {
            const cam = this.camera as THREE.OrthographicCamera;
            cam.left = this.left0;
            cam.right = this.right0;
            cam.top = this.top0;
            cam.bottom = this.bottom0;
        }

        this.camera.lookAt(this.target);

        this.dispatchEvent(this.changeEvent);

        this.lastPosition.copy(this.camera.position);
    }
    // resetServiceKeysState(): void {
    //     this.shiftPressed = false;
    //     this.ctrlPressed = false;
    //     this.altPressed = false;
    // }

    private isPerspectiveCamera(): boolean {
        return this.camera instanceof THREE.PerspectiveCamera;
    }
    private isOrthographicCamera(): boolean {
        return this.camera instanceof THREE.OrthographicCamera;
    }
    private checkDistances(): void {
        if (!this.noZoom || !this.noPan) {
            if (this.eye.lengthSq() > this.maxDistance * this.maxDistance) {
                this.camera.position.addVectors(this.target, this.eye.setLength(this.maxDistance));
                this.zoomStart.copy(this.zoomEnd);
            }

            if (this.eye.lengthSq() < this.minDistance * this.minDistance) {
                this.camera.position.addVectors(this.target, this.eye.setLength(this.minDistance));
                this.zoomStart.copy(this.zoomEnd);
            }
        }
    }
    private getMouseOnScreen(pageX: number, pageY: number): THREE.Vector2 {
        return new THREE.Vector2(
            (pageX - this.screen.left) / this.screen.width,
            (pageY - this.screen.top) / this.screen.height
        );
    }
    private getMouseOnCircle(pageX: number, pageY: number): THREE.Vector2 {
        return new THREE.Vector2(
            ((pageX - this.screen.width * 0.5 - this.screen.left) / (this.screen.width * 0.5)),
            ((this.screen.height + 2 * (this.screen.top - pageY)) / this.screen.width) // screen.width intentional
        );
    }

    private rotateCamera(): void {
        let axis = new THREE.Vector3(),
        quaternion = new THREE.Quaternion(),
        eyeDirection = new THREE.Vector3(),
        objectUpDirection = new THREE.Vector3(),
        objectSidewaysDirection = new THREE.Vector3(),
        moveDirection = new THREE.Vector3(),
        angle;

        moveDirection.set(this.moveCurr.x - this.movePrev.x, this.moveCurr.y - this.movePrev.y, 0.0);
        angle = moveDirection.length();

        if (angle) {
            this.eye.copy(this.camera.position).sub(this.target);

            eyeDirection.copy(this.eye).normalize();
            objectUpDirection.copy(this.camera.up).normalize();
            objectSidewaysDirection.crossVectors(objectUpDirection, eyeDirection).normalize();

            objectUpDirection.setLength(this.moveCurr.y - this.movePrev.y);
            objectSidewaysDirection.setLength(this.moveCurr.x - this.movePrev.x);

            moveDirection.copy(objectUpDirection.add(objectSidewaysDirection));

            axis.crossVectors(moveDirection, this.eye).normalize();

            angle *= this.rotateSpeed;
            quaternion.setFromAxisAngle(axis, angle);

            this.eye.applyQuaternion(quaternion);
            this.camera.up.applyQuaternion(quaternion);

            this.lastAxis.copy(axis);
            this.lastAngle = angle;
        } else if (!this.staticMoving && this.lastAngle) {
            this.lastAngle *= Math.sqrt(1.0 - this.dynamicDampingFactor);
            this.eye.copy(this.camera.position).sub(this.target);
            quaternion.setFromAxisAngle(this.lastAxis, this.lastAngle);
            this.eye.applyQuaternion(quaternion);
            this.camera.up.applyQuaternion(quaternion);
        }

        this.movePrev.copy(this.moveCurr);
    }
    private panCamera(): void {
        let mouseChange = new THREE.Vector2(),
            objectUp = new THREE.Vector3(),
            pan = new THREE.Vector3();

        mouseChange.copy(this.panEnd).sub(this.panStart);
    
        if (mouseChange.lengthSq()) {
            if (this.isPerspectiveCamera())
                mouseChange.multiplyScalar(this.eye.length() * this.panSpeed);
            else if (this.isOrthographicCamera()) {
                const cam = this.camera as THREE.OrthographicCamera;
                // Scale movement to keep clicked/dragged position under cursor
                const scale_x = (cam.right - cam.left) / cam.zoom;
                const scale_y = (cam.top - cam.bottom) / cam.zoom;
                mouseChange.x *= scale_x;
                mouseChange.y *= scale_y;
            }

            pan.copy(this.eye).cross(this.camera.up).setLength(mouseChange.x);
            pan.add(objectUp.copy(this.camera.up).setLength(mouseChange.y));

            this.camera.position.add(pan);
            this.target.add(pan);

            if (this.staticMoving)
                this.panStart.copy(this.panEnd);
            else
                this.panStart.add(mouseChange.subVectors(this.panEnd, this.panStart).multiplyScalar(this.dynamicDampingFactor));
        }
    }
    private zoomCamera(): void {
        if (this.state === STATE.TOUCH_ZOOM_PAN) {
            const factor = this.touchZoomDistanceStart / this.touchZoomDistanceEnd;
            const diff = this.touchZoomDistanceStart - this.touchZoomDistanceEnd;
            this.touchZoomDistanceStart = this.touchZoomDistanceEnd;

            if (this.isPerspectiveCamera()) {
                this.eye.multiplyScalar(factor);
            } else if (this.isOrthographicCamera()) {
                if (Math.abs(diff) > 4) {
                    this.customZoom(this.target, diff > 0 ? -this.touchZoomFactor : this.touchZoomFactor);
                    if (this.onRender)
                        this.onRender();
                }
                // this.object.zoom /= factor; // origin _this.object.zoom *= factor;
                // this.object.updateProjectionMatrix();
            } else {
                log.warn( 'THREE.TrackballControls: Unsupported camera type' );
            }
        }
    }
    private customZoom(zoomPoint: THREE.Vector3, zoomFactor: number): void {
        let newCameraPosition = new THREE.Vector3(),
            newCameraDirection = new THREE.Vector3(),
            newTarget = new THREE.Vector3(),
            newTargetDirection = new THREE.Vector3();

        const factor = zoomFactor * this.zoomSpeed;

        newCameraDirection.subVectors(zoomPoint, this.camera.position);
        newCameraPosition.addVectors(this.camera.position, newCameraDirection.multiplyScalar(factor));
        newTargetDirection.subVectors(zoomPoint, this.target);
        newTarget.addVectors(this.target, newTargetDirection.multiplyScalar(factor));

        this.target.copy(newTarget);
        this.camera.position.copy(newCameraPosition);
        this.camera.lookAt(this.target);

        if (this.isOrthographicCamera()) {
            const cam = this.camera as THREE.OrthographicCamera;
            const vFov = (35 * Math.PI) / 180.0, // 35 is value of fov defined in base_scene_manager.dart
            aspect = this.screen.width / this.screen.height,
            height = 2.0 * Math.tan(vFov / 2.0) * this.camera.position.distanceTo(newTarget),
            width = height * aspect;

            cam.left = -width / 2.0;
            cam.right = width / 2.0;
            cam.top = height / 2.0;
            cam.bottom = -height / 2.0;

            // if (factor > 0) {
            //     cam.left /= 1 + factor;
            //     cam.right /= 1 + factor;
            //     cam.top /= 1 + factor;
            //     cam.bottom /= 1 + factor;
            // }
            // else {
            //     cam.left *= 1 + Math.abs(factor);
            //     cam.right *= 1 + Math.abs(factor);
            //     cam.top *= 1 + Math.abs(factor);
            //     cam.bottom *= 1 + Math.abs(factor);
            // }

            cam.updateProjectionMatrix();
        }
    }

    // event handlers
    private onKeyDown(event: KeyboardEvent): void {
        if (!this.enabled) return;

        // if (event.keyCode === 16) {
        //     this.shiftPressed = true;
        //     return;
        // }
        // if (event.keyCode === 17) {
        //     this.ctrlPressed = true;
        //     return;
        // }
        // if (event.keyCode === 18) {
        //     this.altPressed = true;
        //     return;
        // }

        window.removeEventListener('keydown', this.onKeyDown);
        this.prevState = this.state;
    }
    private onKeyUp(event: KeyboardEvent): void {
        if (!this.enabled) return;

        // if (event.keyCode === 16) {
        //     this.shiftPressed = false;
        //     return;
        // }
        // if (event.keyCode === 17) {
        //     this.ctrlPressed = false;
        //     return;
        // }
        // if (event.keyCode === 18) {
        //     this.altPressed = false;
        //     return;
        // }

        this.state = this.prevState;
        window.addEventListener('keydown', this.onKeyDown, false);
    }
    private onMouseDown(event: MouseEvent): void {
        if (!this.enabled) return;

        // event.preventDefault();
        event.stopPropagation();

        if (this.state === STATE.NONE) {
            this.state = event.button;
            // setState();
        }
        if (this.state === STATE.NONE) {
            return;
        }

        if (this.state === STATE.ROTATE && !this.noRotate) {
            this.moveCurr.copy(this.getMouseOnCircle(event.pageX, event.pageY));
            this.movePrev.copy(this.moveCurr);
        }
        else if (this.state === STATE.ZOOM && !this.noZoom) {
            this.zoomStart.copy(this.getMouseOnScreen(event.pageX, event.pageY));
            this.zoomEnd.copy(this.zoomStart);
        }
        else if (this.state === STATE.PAN && !this.noPan) {
            this.panStart.copy(this.getMouseOnScreen(event.pageX, event.pageY));
            this.panEnd.copy(this.panStart);
        }

        document.addEventListener('mousemove', this.onMouseMove, false);
        document.addEventListener('mouseup', this.onMouseUp, false);

        this.dispatchEvent(this.startEvent);
        if (this.state === STATE.PAN && !this.noPan) {
            event.preventDefault();
        }

        // function setState(): void {
        //     const keys = [];
        //     if (this.shiftPressed) keys.push(ServiceKeys.Shift);
        //     if (this.ctrlPressed) keys.push(ServiceKeys.Control);
        //     if (this.altPressed) keys.push(ServiceKeys.Alt);
        //     keys.sort(compareNumbers);

        //     const mouseButtons = [event.button];
        //     mouseButtons.sort(compareNumbers);

        //     if (this.state === STATE.NONE)
        //         if (compareMapping(this.mappings.rotate))
        //             this.state = STATE.ROTATE;

        //     if (this.state === STATE.NONE)
        //         if (compareMapping(this.mappings.pan))
        //             this.state = STATE.PAN;

        //     if (this.state === STATE.NONE)
        //         if (compareMapping(this.mappings.zoom))
        //             this.state = STATE.ZOOM;

        //     function compareMapping(mapping): boolean {
        //         let result = false;

        //         mapping.forEach(function (item, idx) {
        //             item.keys.sort(compareNumbers);
        //             item.mouseButtons.sort(compareNumbers);

        //             if (compareArrays(item.keys, keys) && compareArrays(item.mouseButtons, mouseButtons)) {
        //                 result = true;
        //                 return false;
        //             }
        //         });

        //         return result;
        //     }
        //     function compareNumbers(a: number, b: number): number {
        //         return a - b;
        //     }
        //     function compareArrays(array1, array2): boolean {
        //         if (!array1 || !array2) {
        //             return false;
        //         }
        //         if (array1.length !== array2.length) {
        //             return false;
        //         }
        //         for (let i = 0, l = array1.length; i < l; i++) {
        //             if (array1[i] instanceof Array && array2[i] instanceof Array) {
        //                 if (!compareArrays(array1[i], array2[i])) {
        //                     return false;
        //                 }
        //             }
        //             else if (array1[i] !== array2[i]) {
        //                 return false;
        //             }
        //         }
        //         return true;
        //     };
        // }
    }
    private onMouseMove(event: MouseEvent): void {
        if (!this.enabled) return;

        event.preventDefault();
        event.stopPropagation();

        if (this.state === STATE.ROTATE && !this.noRotate) {
            this.movePrev.copy(this.moveCurr);
            this.moveCurr.copy(this.getMouseOnCircle(event.pageX, event.pageY));
        }
        else if (this.state === STATE.ZOOM && !this.noZoom) {
            this.zoomEnd.copy(this.getMouseOnScreen(event.pageX, event.pageY));
        }
        else if (this.state === STATE.PAN && !this.noPan) {
            this.panEnd.copy(this.getMouseOnScreen(event.pageX, event.pageY));
        }

        this.update();
        if (this.onRender)
            this.onRender();
    }
    private onMouseUp(event: MouseEvent): void {
        if (!this.enabled) return;

        event.preventDefault();
        event.stopPropagation();

        this.state = STATE.NONE;

        document.removeEventListener('mousemove', this.onMouseMove);
        document.removeEventListener('mouseup', this.onMouseUp);

        this.dispatchEvent(this.endEvent);
    }
    private onMouseWheel(event): void {
        if (!this.enabled || this.noZoom) return;

        event.preventDefault();
        event.stopPropagation();

        let delta = 0;
        switch (event.deltaMode) {
            case 2:
                // Zoom in pages
                delta = event.deltaY * 0.025;
                break;
            case 1:
                // Zoom in lines
                delta = event.deltaY * 0.01;
                break;
            default:
                // undefined, 0, assume pixels
                delta = event.deltaY * 0.00025;
                break;
        }

        const zoomFactor = (this.invertZoom ? 1 : -1) * delta;
        this.zoomStart.y -= delta;

        this.dispatchEvent(this.startEvent);
        this.dispatchEvent(this.endEvent);

        if (!this.noZoom)
            this.customZoom(this.zoomPoint, zoomFactor);
        
        this.update();
        if (this.onRender)
            this.onRender();
    }
    private contextmenu(event): void {
        if (!this.enabled) return;
        event.preventDefault();
    }
    private touchstart(event): void {
        if (!this.enabled) return;

        event.preventDefault();

        switch (event.touches.length) {
            case 1:
                this.state = STATE.TOUCH_ROTATE;
                this.moveCurr.copy(this.getMouseOnCircle(event.touches[0].pageX, event.touches[0].pageY));
                this.movePrev.copy(this.moveCurr);
                break;
            default: // 2 or more
                this.state = STATE.TOUCH_ZOOM_PAN;
                const dx = event.touches[0].pageX - event.touches[1].pageX;
                const dy = event.touches[0].pageY - event.touches[1].pageY;
                this.touchZoomDistanceEnd = this.touchZoomDistanceStart = Math.sqrt(dx * dx + dy * dy);

                const x = (event.touches[0].pageX + event.touches[1].pageX) / 2;
                const y = (event.touches[0].pageY + event.touches[1].pageY) / 2;
                this.panStart.copy(this.getMouseOnScreen(x, y));
                this.panEnd.copy(this.panStart);
                break;
        }

        this.dispatchEvent(this.startEvent);
    }
    private touchmove(event): void {
        if (!this.enabled) return;

        event.preventDefault();
        event.stopPropagation();

        switch (event.touches.length) {
            case 1:
                this.movePrev.copy(this.moveCurr);
                this.moveCurr.copy(this.getMouseOnCircle(event.touches[0].pageX, event.touches[0].pageY));
                break;
            default: // 2 or more
                const dx = event.touches[0].pageX - event.touches[1].pageX;
                const dy = event.touches[0].pageY - event.touches[1].pageY;
                this.touchZoomDistanceEnd = Math.sqrt(dx * dx + dy * dy);

                const x = (event.touches[0].pageX + event.touches[1].pageX) / 2;
                const y = (event.touches[0].pageY + event.touches[1].pageY) / 2;
                this.panEnd.copy(this.getMouseOnScreen(x, y));
                break;
        }

        if (this.onRender)
            this.onRender();
    }
    private touchend(event): void {
        if (!this.enabled) return;

        switch (event.touches.length) {
            case 0:
                this.state = STATE.NONE;
                break;
            case 1:
                this.state = STATE.TOUCH_ROTATE;
                this.moveCurr.copy(this.getMouseOnCircle(event.touches[0].pageX, event.touches[0].pageY));
                this.movePrev.copy(this.moveCurr);
                break;
            default:
                break;
        }

        this.dispatchEvent(this.endEvent);
    }
}

export default TrackballControls;
