import * as THREE from "three";
import SceneUtils from "../../utils/SceneUtils";
import GeometryUtils from "../../utils/GeometryUtils";
import UnitsUtils from "../../utils/UnitsUtils";
import {
  ROOM_OPENING_SELECTED_COLOR,
  ROOM_OPENING_SELECTED_RANGE_COLOR,
  OPENING_SELECTED_RENDER_ORDER,
  OPENING_SELECTED_RANGE_RENDER_ORDER,
} from "../../consts";

const OPENING_TRIANGLE_OFFSET = 2;
const OPENING_TRIANGLE_WIDTH = 3.5;
const OPENING_TRIANGLE_HEIGHT = 4;

export class SoOpeningSelectionMarker extends THREE.Group {
  private triangles: THREE.Mesh[];
  private openingFrame: THREE.Mesh;

  constructor(soOpening?: THREE.Object3D) {
    super();

    this.userData.openingUuid = soOpening.uuid;

    if (!soOpening) {
      return;
    }
    const openingData = SceneUtils.getOpeningZoneAndLine(soOpening);
    if (!openingData) {
      return;
    }
    const { line: openingLine, zone } = openingData;

    const openingWallSegment = SceneUtils.findOpeningWallSegment(soOpening, zone, openingLine);
    const openingZone = SceneUtils.getLimitedOpeningZoneBySegment(soOpening, zone, openingLine, openingWallSegment);

    const center = openingZone.getCenter(new THREE.Vector3());
    const matrix = new THREE.Matrix4().makeTranslation(-center.x, -center.y, 0);

    // Move all items to a coordinate system where the origin (O) is located at the center of the opening zone range line.
    const box = SceneUtils.getRoomOpeningBoundingBoxInsideHostingWall(soOpening).applyMatrix4(matrix);
    openingLine.applyMatrix4(matrix);
    openingZone.applyMatrix4(matrix);

    const openingMarker = this.createOpeningMarkers(box, openingLine);
    const rangeMarker = this.createRangeMarkers(openingZone);

    this.add(openingMarker, rangeMarker);

    this.position.copy(center);
  }

  public raycast(raycaster: THREE.Raycaster, intersects: THREE.Intersection[]): void {
    const triangleIntersects: THREE.Intersection[] = [];
    this.triangles.forEach(triangle => triangle.raycast(raycaster, triangleIntersects));

    if (triangleIntersects.length) {
      intersects.push({
        point: triangleIntersects[0].point,
        distance: triangleIntersects[0].distance,
        object: this,
      });
      return;
    }

    const bb = GeometryUtils.getGeometryBoundingBox2D(this.openingFrame);
    const point = raycaster.ray.intersectBox(bb, new THREE.Vector3());

    if (point) {
      const distance = raycaster.ray.origin.distanceTo(point);

      if (distance < raycaster.near || distance > raycaster.far) {
        return;
      }

      intersects.push({
        point,
        distance,
        object: this,
      });
    }
  }

  private createOpeningMarkers(openingBox: THREE.Box3, openingLine: THREE.Line3): THREE.Group {
    const result = new THREE.Group();
    const borderSize = UnitsUtils.getRoomOpeningSelectedBorderSize();

    this.triangles = this.createTriangles(openingLine);
    this.openingFrame = SceneUtils.createBoxBorder2d(openingBox, borderSize, ROOM_OPENING_SELECTED_COLOR);
    result.add(this.openingFrame, ...this.triangles);

    GeometryUtils.setRenderOrder(result, OPENING_SELECTED_RENDER_ORDER);

    return result;
  }
  private createTriangles(openingLine: THREE.Line3): THREE.Mesh[] {
    const w = OPENING_TRIANGLE_WIDTH * UnitsUtils.getConversionFactorFromInches();
    const h = OPENING_TRIANGLE_HEIGHT * UnitsUtils.getConversionFactorFromInches();
    const triangleOffset = openingLine.delta(new THREE.Vector3()).normalize().multiplyScalar(OPENING_TRIANGLE_OFFSET);

    const geometry = new THREE.BufferGeometry().setFromPoints([new THREE.Vector3(0, w, 0), new THREE.Vector3(0, -w, 0), new THREE.Vector3(h, 0, 0)]);
    const material = new THREE.MeshBasicMaterial({ color: ROOM_OPENING_SELECTED_COLOR, transparent: true, opacity: 1.0 });

    const triangle1 = new THREE.Mesh(geometry, material);
    const triangle2 = new THREE.Mesh(geometry, material);

    triangle1.position.copy(openingLine.start).sub(triangleOffset);
    triangle2.position.copy(openingLine.end).add(triangleOffset);

    const localOrigin = openingLine.getCenter(new THREE.Vector3());
    const position1 = triangle1.position.clone().sub(localOrigin);
    const position2 = triangle2.position.clone().sub(localOrigin);

    triangle1.rotateZ(Math.atan2(position1.y, position1.x));
    triangle2.rotateZ(Math.atan2(position2.y, position2.x));

    return [triangle1, triangle2];
  }
  private createRangeMarkers(openingZone: THREE.Line3): THREE.Group {
    const result = new THREE.Group();
    const width = UnitsUtils.getRoomOpeningSelectedBorderSize();
    const height = UnitsUtils.getRoomOpeningSelectedRangeHeight();

    const isHorizontal = GeometryUtils.isLineHorizontal(openingZone);
    const size = isHorizontal ? new THREE.Vector2(width, height) : new THREE.Vector2(height, width);
    const material = new THREE.MeshBasicMaterial({ color: ROOM_OPENING_SELECTED_RANGE_COLOR, transparent: true, opacity: 1.0 });

    const start = new THREE.Mesh(new THREE.PlaneBufferGeometry(size.x, size.y), material);
    const end = new THREE.Mesh(new THREE.PlaneBufferGeometry(size.x, size.y), material);

    start.position.copy(openingZone.start);
    end.position.copy(openingZone.end);

    result.add(start, end);
    GeometryUtils.setRenderOrder(result, OPENING_SELECTED_RANGE_RENDER_ORDER);

    return result;
  }
}
