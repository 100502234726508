import { Button } from "antd";

const FormButtons = ({ isSaving, isEditMode, handleGoToEditor, handleSave }) => {
  return (
    <div className="btn-group right">
      <Button type="primary" htmlType="submit" disabled={isSaving} className="btn-finish" onClick={handleGoToEditor} style={{ marginRight: "10px" }}>
        {isEditMode ? "Update, Go to Editor" : "Save, Go to Editor"}
      </Button>
      <Button htmlType="submit" disabled={isSaving} className="btn-finish" onClick={handleSave}>
        {isEditMode ? "Update & Close" : "Save & Close"}
      </Button>
    </div>
  );
};

export default FormButtons;
