import "./DialogCorePlanAttribute.sass";
import { Modal, Form, Input } from "antd";
import { useState } from "react";
import { CorePlan } from "../../../models/CorePlan";
import { entityManager } from "../../../entities/entityManager";
import { feet2inchesSq, inches2feetSq } from "../../../helpers/measures";

type voidFunc = () => void;

const DialogCorePlanAttribute = (corePlan: CorePlan, attrName: string, confirmSet: voidFunc, closeConfirmationModal: voidFunc) => {
  const sqFt = inches2feetSq(corePlan.attributes[attrName]).toFixed(1);

  const ctx: ContextEditType = { sqFt, attrName };

  const onOk = () => {
    corePlan.attributes[attrName] = feet2inchesSq(parseFloat(ctx.sqFt));

    entityManager.savePartially(corePlan, ["attributes"]);

    confirmSet();
  };

  return Modal.confirm({
    title: "Specify lot size",
    width: 400,
    centered: true,
    content: DialogCorePlanAttributeContent(ctx),
    onOk,
    onCancel: closeConfirmationModal,
  });
};

type ContextEditType = { attrName: string; sqFt: string };

const InputAttribute = ({ ctx }: { ctx: ContextEditType }) => {
  const [inputValue, setInputValue] = useState(ctx.sqFt);
  const handleAttributeChange = e => {
    ctx.sqFt = e.target.value;
    setInputValue(ctx.sqFt);
  };

  return (
    <Input
      data-testid="lotSizeInput"
      addonAfter="SqFt"
      onChange={handleAttributeChange}
      name="lotSize"
      defaultValue={inputValue}
      placeholder="Specify lot size"
      type="number"
    />
  );
};

export const DialogCorePlanAttributeContent = (ctx: ContextEditType) => {
  return (
    <div className="edit-attribute">
      <Form name="basic" labelCol={{ span: 8 }} wrapperCol={{ span: 14 }} initialValues={{ remember: true }} className="form-corePlan">
        <Form.Item>
          <InputAttribute ctx={ctx} />
        </Form.Item>
      </Form>
    </div>
  );
};

export default DialogCorePlanAttribute;
