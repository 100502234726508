export enum DragMode {
  none = "none",
  addingRoom = "addingRoom",
  movingRooms = "movingRooms",
  movingRoomOpening = "movingRoomOpening",
  stretchingRoom = "stretchingRoom",
  stretchingBackground = "stretchingBackground",
  movingBasePoint = "movingBasePoint",
  selecting = "selecting",
  movingFloorBackground = "movingFloorBackground",
  scalingFloorBackground = "scalingFloorBackground",
  movingLotLine = "movingLotLine",
  measurement = "measurement",
}
