import { UploadFile } from "antd";
import axios from "../utilities/axios-wrapper";
import log from "loglevel";
import { APIoptions } from "../..";
import { asSingle, DTORecord } from "../../../helpers/pojo";
import { showAPIErrors } from "../../../helpers/showAPIErrors";
import { findRawRecord, kindBackgroundRaw, kindFileRaw } from "../../allKinds";
import { Envelope } from "../../Envelope";
import { getAxiosRequestConfig } from "./AuthHeader";
import apiProvider from "./Provider";

const kindFile = kindFileRaw.id;
const relType = asSingle(findRawRecord({ kind: 1, table: "@rel-type" })).id;
const kindRelDefault = asSingle(findRawRecord({ kind: relType, description: "Default" })).id;
const kindRelThumb = asSingle(findRawRecord({ kind: relType, name: "thumbnail" })).id;
const kindRelBackground = kindBackgroundRaw.id;

async function sendWithFiles(dto: DTORecord, opts: APIoptions): Promise<Envelope> {
  const bodyFormData = opts.multiPartAppend || new FormData();

  const dataSection = [dto];

  const _uploadFiles: UploadFile[] = (dto._uploadFiles || []) as unknown as UploadFile[];
  if (_uploadFiles.length && !Array.isArray(dto.attached)) dto.attached = [];

  const blobs = [];
  let fileId = -1;

  for (const upFile of _uploadFiles || []) {
    const file = upFile.originFileObj;

    const fileName = upFile.uid?.charAt(0) === "!" ? upFile.uid : file.name;

    const obj = {
      id: fileId,
      kind: kindFile,
      lastModified: file.lastModified,
      name: fileName,
      fileSize: file.size,
      mimeType: file.type,
      filePath: file.webkitRelativePath,
      relType: kindRelDefault,
    };

    if (file.type.indexOf("image/") >= 0) {
      switch (opts.imageRelType) {
        case "background":
          obj.relType = kindRelBackground;
          break;
        case "thumbnail":
          obj.relType = kindRelThumb;
          break;
      }
    }

    dto.attached.push(obj);
    blobs.push([`${fileId}`, file, fileName]);
    fileId -= 1;
  }

  delete dto._uploadFiles;
  const envelope: Envelope = {
    meta: { v: 1 },
    data: dataSection,
  };

  // 'envelope' should be placed before blobs in multipart/form-data
  // to allow server extract envelope data before handlings binary parts
  bodyFormData.append("envelope", JSON.stringify(envelope));
  blobs.forEach(([id, blob, name]) => {
    bodyFormData.append(id, blob, name);
  });

  try {
    const config = getAxiosRequestConfig() || {};
    config["Content-Type"] = "multipart/form-data";

    const response = await axios.post(`${apiProvider.host}${opts.url}`, bodyFormData, config);

    return response.data as Envelope;
  } catch (err) {
    showAPIErrors({ source: "sendWithFiles", message: err.message || "Network error", errorResult: err, name: err.name || "AxiosError" });
    log.error(err);
  }

  return null;
}

export { sendWithFiles };
