import log from 'loglevel';
import * as THREE from 'three';

const EPS = 0.000001;
const STATE = {
	NONE: - 1,
	ROTATE: 0,
	DOLLY: 1,
	PAN: 2,
	TOUCH_ROTATE: 3,
	TOUCH_PAN: 4,
	TOUCH_DOLLY_PAN: 5,
	TOUCH_DOLLY_ROTATE: 6
};

class OrbitControls extends THREE.EventDispatcher {
	private changeEvent = { type: 'change' };
	private startEvent = { type: 'start' };
	private endEvent = { type: 'end' };

	private state = STATE.NONE;

	// current position in spherical coordinates
	private spherical = new THREE.Spherical();
	private sphericalDelta = new THREE.Spherical();

	private scale = 1.0;
	private panOffset = new THREE.Vector3();
	private zoomChanged = false;

	private rotateStart = new THREE.Vector2();
	private rotateEnd = new THREE.Vector2();
	private rotateDelta = new THREE.Vector2();

	private panStart = new THREE.Vector2();
	private panEnd = new THREE.Vector2();
	private panDelta = new THREE.Vector2();

	private dollyStart = new THREE.Vector2();
	private dollyEnd = new THREE.Vector2();
	private dollyDelta = new THREE.Vector2();

	// for reset
	private target0: THREE.Vector3;
	private position0: THREE.Vector3;
	private zoom0: number;

	// ----------------------------------------

	camera: THREE.Camera;
	domElement: HTMLElement | Document;

	enabled: boolean = true;
	target = new THREE.Vector3(); // sets the location of focus, where the object orbits around

	// deprecated
	center: THREE.Vector3;

	minDistance: number = 0.0;
	maxDistance: number = Infinity;

	// How far you can zoom in and out (OrthographicCamera only)
	minZoom: number = 0.0;
	maxZoom: number = Infinity;

	// How far you can orbit vertically, upper and lower limits. Range is 0 to Math.PI radians.
	minPolarAngle: number = 0.0;
	maxPolarAngle: number = Math.PI;

	// How far you can orbit horizontally, upper and lower limits.
	// If set, the interval [ min, max ] must be a sub-interval of [ - 2 PI, 2 PI ], with ( max - min < 2 PI )
	minAzimuthAngle: number = Infinity;
	maxAzimuthAngle: number = Infinity;

	// Set to true to enable damping (inertia)
	// If damping is enabled, you must call controls.update() in your animation loop
	enableDamping: boolean = false;
	dampingFactor: number = 0.05;

	// This option actually enables dollying in and out; left as "zoom" for backwards compatibility.
	// Set to false to disable zooming
	enableZoom: boolean = true;
	zoomSpeed: number = 1.0;

	enableRotate: boolean = true;
	rotateSpeed: number = 1.0;

	enablePan: boolean = true;
	panSpeed: number = 1.0;
	screenSpacePanning: boolean = true; // if false, pan orthogonal to world-space direction camera.up
	keyPanSpeed: number = 7.0;	// pixels moved per arrow key push

	// Set to true to automatically rotate around the target
	// If auto-rotate is enabled, you must call controls.update() in your animation loop
	autoRotate: boolean = false;
	autoRotateSpeed: number = 2.0; // 30 seconds per round when fps is 60

	enableKeys: boolean = true; // Set to false to disable use of the keys
	keys = { LEFT: 37, UP: 38, RIGHT: 39, BOTTOM: 40 }; // The four arrow keys
	mouseButtonActions = { LEFT: THREE.MOUSE.ROTATE, MIDDLE: THREE.MOUSE.DOLLY, RIGHT: THREE.MOUSE.PAN };
	touches = { ONE: THREE.TOUCH.ROTATE, TWO: THREE.TOUCH.DOLLY_PAN }; // Touch fingers
	
	constructor(camera: THREE.Camera, domElement?: HTMLElement ) {
		super();

		this.camera = camera;
		this.domElement = (domElement !== undefined) ? domElement : document;

		this.target0 = this.target.clone();
		this.position0 = this.camera.position.clone();
		this.zoom0 = this.isOrthographicCamera() ? (this.camera as THREE.OrthographicCamera).zoom : null;

		this.onContextMenu = this.onContextMenu.bind(this);
		this.onPointerDown = this.onPointerDown.bind(this);
		this.onPointerMove = this.onPointerMove.bind(this);
		this.onPointerUp = this.onPointerUp.bind(this);
		this.onMouseWheel = this.onMouseWheel.bind(this);
		this.onTouchStart = this.onTouchStart.bind(this);
		this.onTouchMove = this.onTouchMove.bind(this);
		this.onTouchEnd = this.onTouchEnd.bind(this);
		this.onKeyDown = this.onKeyDown.bind(this);

		this.init();
	}

	init(): void {
		if (this.domElement === document) return;

		this.domElement.addEventListener('contextmenu', this.onContextMenu, false);
		this.domElement.addEventListener('pointerdown', this.onPointerDown, false);
		this.domElement.addEventListener('wheel', this.onMouseWheel, false);
		this.domElement.addEventListener('touchstart', this.onTouchStart, false);
		this.domElement.addEventListener('touchmove', this.onTouchMove, false);
		this.domElement.addEventListener('touchend', this.onTouchEnd, false);
		this.domElement.addEventListener('keydown', this.onKeyDown, false);
	
		this.update();
	}
	dispose(): void {
		if (this.domElement === document) return;

		this.domElement.removeEventListener('contextmenu', this.onContextMenu, false);
		this.domElement.removeEventListener('pointerdown', this.onPointerDown, false);
		this.domElement.removeEventListener('wheel', this.onMouseWheel, false);
		this.domElement.removeEventListener('touchstart', this.onTouchStart, false);
		this.domElement.removeEventListener('touchend', this.onTouchEnd, false);
		this.domElement.removeEventListener('touchmove', this.onTouchMove, false);
		document.removeEventListener('pointermove', this.onPointerMove, false);
		document.removeEventListener('pointerup', this.onPointerUp, false);
		this.domElement.removeEventListener('keydown', this.onKeyDown, false);

		this.dispatchEvent({ type: "dispose" }); // should this be added here?
	}

	update(): boolean {
		const offset = new THREE.Vector3();

		// so camera.up is the orbit axis
		const quat = new THREE.Quaternion().setFromUnitVectors(this.camera.up, new THREE.Vector3(0, 1, 0));
		const quatInverse = quat.clone().invert();

		const lastPosition = new THREE.Vector3();
		const lastQuaternion = new THREE.Quaternion();

		const twoPI = 2 * Math.PI;

		const position = this.camera.position;
		offset.copy(position).sub(this.target);

		// rotate offset to "y-axis-is-up" space
		offset.applyQuaternion(quat);

		// angle from z-axis around y-axis
		this.spherical.setFromVector3(offset);

		if (this.autoRotate && this.state === STATE.NONE) {
			this.rotateLeft(this.getAutoRotationAngle());
		}
		if (this.enableDamping) {
			this.spherical.theta += this.sphericalDelta.theta * this.dampingFactor;
			this.spherical.phi += this.sphericalDelta.phi * this.dampingFactor;
		} else {
			this.spherical.theta += this.sphericalDelta.theta;
			this.spherical.phi += this.sphericalDelta.phi;
		}

		// restrict theta to be between desired limits
		let min = this.minAzimuthAngle;
		let max = this.maxAzimuthAngle;
		if (isFinite(min) && isFinite(max)) {
			if (min < - Math.PI) min += twoPI; else if (min > Math.PI) min -= twoPI;
			if (max < - Math.PI) max += twoPI; else if (max > Math.PI) max -= twoPI;
			if (min <= max) {
				this.spherical.theta = Math.max(min, Math.min(max, this.spherical.theta));
			} else {
				this.spherical.theta = (this.spherical.theta > ( min + max ) / 2) ?
					Math.max(min, this.spherical.theta) :
					Math.min(max, this.spherical.theta);
			}
		}

		// restrict phi to be between desired limits
		this.spherical.phi = Math.max(this.minPolarAngle, Math.min(this.maxPolarAngle, this.spherical.phi));
		this.spherical.makeSafe();
		this.spherical.radius *= this.scale;

		// restrict radius to be between desired limits
		this.spherical.radius = Math.max(this.minDistance, Math.min(this.maxDistance, this.spherical.radius));

		// move target to panned location
		if (this.enableDamping) {
			this.target.addScaledVector(this.panOffset, this.dampingFactor);
		} else {
			this.target.add(this.panOffset);
		}

		offset.setFromSpherical(this.spherical);

		// rotate offset back to "camera-up-vector-is-up" space
		offset.applyQuaternion( quatInverse );
		position.copy(this.target).add(offset);

		this.camera.lookAt(this.target);

		if (this.enableDamping) {
			this.sphericalDelta.theta *= (1 - this.dampingFactor);
			this.sphericalDelta.phi *= (1 - this.dampingFactor);
			this.panOffset.multiplyScalar(1 - this.dampingFactor);
		} else {
			this.sphericalDelta.set(0, 0, 0);
			this.panOffset.set(0, 0, 0);
		}

		this.scale = 1.0;

		// update condition is:
		// min(camera displacement, camera rotation in radians)^2 > EPS
		// using small-angle approximation cos(x/2) = 1 - x^2 / 8
		if (this.zoomChanged ||
			lastPosition.distanceToSquared(this.camera.position) > EPS ||
			8 * (1 - lastQuaternion.dot(this.camera.quaternion)) > EPS) {
			this.dispatchEvent(this.changeEvent);

			lastPosition.copy(this.camera.position);
			lastQuaternion.copy(this.camera.quaternion);
			this.zoomChanged = false;

			return true;
		}

		return false;
	}
	saveState(): void {
		this.target0.copy(this.target);
		this.position0.copy(this.camera.position);
		this.zoom0 = this.isOrthographicCamera() ? (this.camera as THREE.OrthographicCamera).zoom: null;
	}
	reset(): void {
		this.target.copy(this.target0);
		this.camera.position.copy(this.position0);

		if (this.isOrthographicCamera()) {
			const cam = this.camera as THREE.OrthographicCamera;
			cam.zoom = this.zoom0;
			cam.updateProjectionMatrix();
		} else {
			(this.camera as THREE.PerspectiveCamera).updateProjectionMatrix();
		}

		this.dispatchEvent(this.changeEvent);
		this.update();

		this.state = STATE.NONE;
	}

	getPolarAngle(): number {
		return this.spherical.phi;
	}
	getAzimuthalAngle(): number {
		return this.spherical.theta;
	}

	//-------------------------------

	private isPerspectiveCamera(): boolean {
		return this.camera instanceof THREE.PerspectiveCamera;
	}
	private isOrthographicCamera(): boolean {
		return this.camera instanceof THREE.OrthographicCamera;
	}

	private getAutoRotationAngle(): number {
		return 2 * Math.PI / 60.0 / 60.0 * this.autoRotateSpeed;
	}
	private getZoomScale(): number {
		return Math.pow(0.95, this.zoomSpeed);
	}
	private rotateLeft(angle: number): void {
		this.sphericalDelta.theta -= angle;
	}
	private rotateUp(angle: number): void {
		this.sphericalDelta.phi -= angle;
	}
	private panLeft(distance: number, objectMatrix: THREE.Matrix4): void {
		const v = new THREE.Vector3();
		v.setFromMatrixColumn(objectMatrix, 0); // get X column of objectMatrix
		v.multiplyScalar(- distance);
		
		this.panOffset.add(v);
	}
	private panUp(distance: number, objectMatrix: THREE.Matrix4): void {
		const v = new THREE.Vector3();
		if (this.screenSpacePanning) {
			v.setFromMatrixColumn(objectMatrix, 1);
		} else {
			v.setFromMatrixColumn(objectMatrix, 0);
			v.crossVectors(this.camera.up, v);
		}
		v.multiplyScalar(distance);

		this.panOffset.add(v);
	}
	private pan(deltaX: number, deltaY: number): void {
		const offset = new THREE.Vector3();

		const element = this.domElement as HTMLElement;
		if (this.isPerspectiveCamera()) {
			// perspective
			const cam = this.camera as THREE.PerspectiveCamera;

			const position = this.camera.position;
			offset.copy(position).sub(this.target);
			
			let targetDistance = offset.length();

			// half of the fov is center to top of screen
			targetDistance *= Math.tan((cam.fov / 2 ) * Math.PI / 180.0);

			// we use only clientHeight here so aspect ratio does not distort speed
			this.panLeft(2 * deltaX * targetDistance / element.clientHeight, this.camera.matrix);
			this.panUp(2 * deltaY * targetDistance / element.clientHeight, this.camera.matrix);
		} else if (this.isOrthographicCamera()) {
			// orthographic
			const cam = this.camera as THREE.OrthographicCamera;

			this.panLeft(deltaX * (cam.right - cam.left) / cam.zoom / element.clientWidth, this.camera.matrix);
			this.panUp(deltaY * (cam.top - cam.bottom) / cam.zoom / element.clientHeight, this.camera.matrix);
		} else {
			// camera neither orthographic nor perspective
			log.warn( 'WARNING: OrbitControls.js encountered an unknown camera type - pan disabled.' );
			this.enablePan = false;
		}
	}

	private dollyOut(dollyScale: number): void {
		if (this.isPerspectiveCamera()) {
			this.scale /= dollyScale;
		} else if (this.isOrthographicCamera()) {
			const cam = this.camera as THREE.OrthographicCamera;
			cam.zoom = Math.max(this.minZoom, Math.min(this.maxZoom, cam.zoom * dollyScale));
			cam.updateProjectionMatrix();
			this.zoomChanged = true;
		} else {
			log.warn("WARNING: OrbitControls.js encountered an unknown camera type - dolly/zoom disabled.");
			this.enableZoom = false;
		}
	}
	private dollyIn(dollyScale: number): void {
		if (this.isPerspectiveCamera()) {
			this.scale *= dollyScale;
		} else if (this.isOrthographicCamera()) {
			const cam = this.camera as THREE.OrthographicCamera;
			cam.zoom = Math.max(this.minZoom, Math.min(this.maxZoom, cam.zoom / dollyScale));
			cam.updateProjectionMatrix();
			this.zoomChanged = true;
		} else {
			log.warn( 'WARNING: OrbitControls.js encountered an unknown camera type - dolly/zoom disabled.');
			this.enableZoom = false;
		}
	}

	// ------------------------

	private handleMouseDownRotate(event): void {
		this.rotateStart.set(event.clientX, event.clientY);
	}
	private handleMouseDownDolly(event): void {
		this.dollyStart.set(event.clientX, event.clientY);
	}
	private handleMouseDownPan(event): void {
		this.panStart.set(event.clientX, event.clientY);
	}
	private handleMouseMoveRotate(event): void {
		this.rotateEnd.set(event.clientX, event.clientY);
		this.rotateDelta.subVectors(this.rotateEnd, this.rotateStart).multiplyScalar(this.rotateSpeed);

		const element = this.domElement as HTMLElement;

		this.rotateLeft(2 * Math.PI * this.rotateDelta.x / element.clientHeight); // yes, height
		this.rotateUp(2 * Math.PI * this.rotateDelta.y / element.clientHeight);

		this.rotateStart.copy(this.rotateEnd);
		this.update();
	}
	private handleMouseMoveDolly(event): void {
		this.dollyEnd.set(event.clientX, event.clientY);
		this.dollyDelta.subVectors(this.dollyEnd, this.dollyStart);

		if (this.dollyDelta.y > 0) {
			this.dollyOut(this.getZoomScale());
		} else if (this.dollyDelta.y < 0) {
			this.dollyIn(this.getZoomScale());
		}

		this.dollyStart.copy(this.dollyEnd);
		this.update();
	}
	private handleMouseMovePan(event): void {
		this.panEnd.set(event.clientX, event.clientY);
		this.panDelta.subVectors(this.panEnd, this.panStart).multiplyScalar(this.panSpeed);

		this.pan(this.panDelta.x, this.panDelta.y);
		this.panStart.copy(this.panEnd);

		this.update();

	}
	private handleMouseUp(event): void {
		// no-op
	}
	private handleMouseWheel(event): void {
		if (event.deltaY < 0) {
			this.dollyIn(this.getZoomScale());
		} else if (event.deltaY > 0) {
			this.dollyOut(this.getZoomScale());
		}

		this.update();
	}
	private handleKeyDown(event): void {
		let needsUpdate = false;

		switch (event.keyCode) {
			case this.keys.UP:
				this.pan(0, this.keyPanSpeed);
				needsUpdate = true;
				break;

			case this.keys.BOTTOM:
				this.pan(0, - this.keyPanSpeed);
				needsUpdate = true;
				break;

			case this.keys.LEFT:
				this.pan(this.keyPanSpeed, 0);
				needsUpdate = true;
				break;

			case this.keys.RIGHT:
				this.pan(-this.keyPanSpeed, 0);
				needsUpdate = true;
				break;
		}

		if (needsUpdate) {
			// prevent the browser from scrolling on cursor keys
			event.preventDefault();
			this.update();
		}
	}
	private handleTouchStartRotate(event): void {
		if (event.touches.length == 1) {
			this.rotateStart.set(event.touches[0].pageX, event.touches[0].pageY);
		} else {
			const x = 0.5 * (event.touches[0].pageX + event.touches[1].pageX);
			const y = 0.5 * (event.touches[0].pageY + event.touches[1].pageY);
			this.rotateStart.set(x, y);
		}
	}
	private handleTouchStartPan(event): void {
		if (event.touches.length == 1) {
			this.panStart.set(event.touches[0].pageX, event.touches[0].pageY);
		} else {
			const x = 0.5 * (event.touches[0].pageX + event.touches[1].pageX);
			const y = 0.5 * (event.touches[0].pageY + event.touches[1].pageY);
			this.panStart.set(x, y);
		}
	}
	private handleTouchStartDolly(event): void {
		const dx = event.touches[0].pageX - event.touches[1].pageX;
		const dy = event.touches[0].pageY - event.touches[1].pageY;
		const distance = Math.sqrt(dx * dx + dy * dy);

		this.dollyStart.set(0, distance);
	}
	private handleTouchStartDollyPan(event): void {
		if (this.enableZoom) this.handleTouchStartDolly(event);
		if (this.enablePan) this.handleTouchStartPan(event);
	}
	private handleTouchStartDollyRotate(event): void {
		if (this.enableZoom ) this.handleTouchStartDolly(event);
		if (this.enableRotate) this.handleTouchStartRotate(event);
	}
	private handleTouchMoveRotate(event): void {
		if (event.touches.length == 1) {
			this.rotateEnd.set(event.touches[0].pageX, event.touches[0].pageY);
		} else {
			const x = 0.5 * (event.touches[0].pageX + event.touches[1].pageX);
			const y = 0.5 * (event.touches[0].pageY + event.touches[1].pageY);
			this.rotateEnd.set(x, y);
		}

		this.rotateDelta.subVectors(this.rotateEnd, this.rotateStart).multiplyScalar(this.rotateSpeed);

		const element = this.domElement as HTMLElement;
		this.rotateLeft(2 * Math.PI * this.rotateDelta.x / element.clientHeight); // yes, height
		this.rotateUp(2 * Math.PI * this.rotateDelta.y / element.clientHeight);

		this.rotateStart.copy(this.rotateEnd);
	}
	private handleTouchMovePan(event): void {
		if (event.touches.length == 1) {
			this.panEnd.set(event.touches[0].pageX, event.touches[0].pageY);
		} else {
			const x = 0.5 * (event.touches[0].pageX + event.touches[1].pageX);
			const y = 0.5 * (event.touches[0].pageY + event.touches[1].pageY);
			this.panEnd.set(x, y);
		}

		this.panDelta.subVectors(this.panEnd, this.panStart).multiplyScalar(this.panSpeed);
		this.pan(this.panDelta.x, this.panDelta.y);
		this.panStart.copy(this.panEnd);
	}
	private handleTouchMoveDolly(event): void {
		const dx = event.touches[0].pageX - event.touches[1].pageX;
		const dy = event.touches[0].pageY - event.touches[1].pageY;
		const distance = Math.sqrt(dx * dx + dy * dy);

		this.dollyEnd.set(0, distance);
		this.dollyDelta.set(0, Math.pow(this.dollyEnd.y / this.dollyStart.y, this.zoomSpeed));
		this.dollyOut(this.dollyDelta.y);
		this.dollyStart.copy(this.dollyEnd);
	}
	private handleTouchMoveDollyPan(event): void {
		if (this.enableZoom) this.handleTouchMoveDolly(event);
		if (this.enablePan) this.handleTouchMovePan(event);
	}
	private handleTouchMoveDollyRotate(event): void {
		if (this.enableZoom) this.handleTouchMoveDolly(event);
		if (this.enableRotate) this.handleTouchMoveRotate(event);
	}
	private handleTouchEnd(event): void {
		// no-op
	}

	// -------------------------

	private onPointerDown(event): void {
		if (!this.enabled) return;

		switch (event.pointerType) {
			case 'mouse':
			case 'pen':
				this.onMouseDown(event);
				break;
			// TODO touch
		}
	}
	private onPointerMove(event): void {
		if (!this.enabled) return;

		switch (event.pointerType) {
			case 'mouse':
			case 'pen':
				this.onMouseMove(event);
				break;
			// TODO touch
		}
	}
	private onPointerUp(event): void {
		switch (event.pointerType ) {
			case 'mouse':
			case 'pen':
				this.onMouseUp(event);
				break;
			// TODO touch
		}
	}
	private onMouseDown(event): void {
		// Prevent the browser from scrolling.
		// pointerdownEvent.preventDefault() - disable mousedown event
		// event.preventDefault();

		// Manually set the focus since calling preventDefault above
		// prevents the browser from setting it automatically.
		const el: any = this.domElement;
		el.focus ? el.focus() : window?.focus();

		let mouseAction;
		switch (event.button) {
			case 0:	mouseAction = this.mouseButtonActions.LEFT; break;
			case 1:	mouseAction = this.mouseButtonActions.MIDDLE; break;
			case 2: mouseAction = this.mouseButtonActions.RIGHT; break;
			default: mouseAction = - 1; break;
		}

		switch (mouseAction) {
			case THREE.MOUSE.DOLLY:
				if (!this.enableZoom) return;
				this.handleMouseDownDolly(event);
				this.state = STATE.DOLLY;
				break;

			case THREE.MOUSE.ROTATE:
				if (event.ctrlKey || event.metaKey || event.shiftKey) {
					if (!this.enablePan) return;
					this.handleMouseDownPan(event);
					this.state = STATE.PAN;
				} else {
					if (!this.enableRotate) return;
					this.handleMouseDownRotate(event);
					this.state = STATE.ROTATE;
				}
				break;

			case THREE.MOUSE.PAN:
				if (event.ctrlKey || event.metaKey || event.shiftKey) {
					if (!this.enableRotate) return;
					this.handleMouseDownRotate(event);
					this.state = STATE.ROTATE;
				} else {
					if (!this.enablePan) return;
					this.handleMouseDownPan(event);
					this.state = STATE.PAN;
				}
				break;

			default:
				this.state = STATE.NONE;
		}

		if (this.state !== STATE.NONE ) {
			document.addEventListener('pointermove', this.onPointerMove, false);
			document.addEventListener('pointerup', this.onPointerUp, false);
			this.dispatchEvent(this.startEvent);
		}
		if ((this.state === STATE.PAN && this.enablePan) || (this.state === STATE.ROTATE && THREE.MOUSE.PAN)) {
			event.preventDefault();
		}
	}
	private onMouseMove(event): void {
		if (!this.enabled) return;

		event.preventDefault();

		switch (this.state) {
			case STATE.ROTATE:
				if (!this.enableRotate) return;
				this.handleMouseMoveRotate(event);
				break;

			case STATE.DOLLY:
				if (!this.enableZoom) return;
				this.handleMouseMoveDolly(event);
				break;

			case STATE.PAN:
				if (!this.enablePan) return;
				this.handleMouseMovePan(event);
				break;
		}
	}
	private onMouseUp(event): void {
		document.removeEventListener('pointermove', this.onPointerMove, false);
		document.removeEventListener('pointerup', this.onPointerUp, false);

		if (!this.enabled) return;

		this.handleMouseUp(event);
		this.dispatchEvent(this.endEvent);

		this.state = STATE.NONE;
	}
	private onMouseWheel(event): void {
		if (!this.enabled || !this.enableZoom || (this.state !== STATE.NONE && this.state !== STATE.ROTATE)) return;

		event.preventDefault();
		event.stopPropagation();

		this.dispatchEvent(this.startEvent);
		this.handleMouseWheel(event);
		this.dispatchEvent(this.endEvent);
	}
	private onKeyDown(event): void {
		if (!this.enabled || !this.enableKeys || !this.enablePan) return;

		this.handleKeyDown(event);
	}
	private onTouchStart(event): void {
		if (!this.enabled) return;

		event.preventDefault(); // prevent scrolling

		switch (event.touches.length) {
			case 1:
				switch (this.touches.ONE) {
					case THREE.TOUCH.ROTATE:
						if (!this.enableRotate) return;
						this.handleTouchStartRotate(event);
						this.state = STATE.TOUCH_ROTATE;
						break;

					case THREE.TOUCH.PAN:
						if (!this.enablePan) return;
						this.handleTouchStartPan(event);
						this.state = STATE.TOUCH_PAN;
						break;

					default:
						this.state = STATE.NONE;
				}
				break;

			case 2:
				switch (this.touches.TWO) {
					case THREE.TOUCH.DOLLY_PAN:
						if (!this.enableZoom && !this.enablePan) return;
						this.handleTouchStartDollyPan(event);
						this.state = STATE.TOUCH_DOLLY_PAN;
						break;

					case THREE.TOUCH.DOLLY_ROTATE:
						if (!this.enableZoom && !this.enableRotate) return;
						this.handleTouchStartDollyRotate(event);
						this.state = STATE.TOUCH_DOLLY_ROTATE;
						break;

					default:
						this.state = STATE.NONE;
				}
				break;

			default:
				this.state = STATE.NONE;
		}

		if (this.state !== STATE.NONE) {
			this.dispatchEvent(this.startEvent);
		}
	}
	private onTouchMove(event): void {
		if (!this.enabled) return;

		event.preventDefault(); // prevent scrolling
		event.stopPropagation();

		switch (this.state) {
			case STATE.TOUCH_ROTATE:
				if (!this.enableRotate) return;
				this.handleTouchMoveRotate(event);
				this.update();
				break;

			case STATE.TOUCH_PAN:
				if (!this.enablePan) return;
				this.handleTouchMovePan(event);
				this.update();
				break;

			case STATE.TOUCH_DOLLY_PAN:
				if (!this.enableZoom && !this.enablePan) return;
				this.handleTouchMoveDollyPan(event);
				this.update();
				break;

			case STATE.TOUCH_DOLLY_ROTATE:
				if (!this.enableZoom && !this.enableRotate) return;
				this.handleTouchMoveDollyRotate(event);
				this.update();
				break;

			default:
				this.state = STATE.NONE;
		}
	}
	private onTouchEnd(event): void {
		if (!this.enabled) return;

		this.handleTouchEnd(event);
		this.dispatchEvent(this.endEvent);

		this.state = STATE.NONE;
	}
	private onContextMenu(event): void {
		if (!this.enabled) return;

		event.preventDefault();
	}
}

export default OrbitControls;