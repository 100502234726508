import DxfArrayScanner, { IGroup } from '../DxfArrayScanner';
import * as helpers from '../ParseHelpers';
import IGeometry, { IEntity, IPoint } from './geomtry';

enum GradientColorMode
{
    TwoColorGradient = 0,
    SingleColorGradient = 1
}

enum HatchPatternFillMode
{
    PatternFill = 0,
    SolidFill = 1
}

enum HatchPatternType
{
    UserDefined = 0,
    Predefined = 1,
    Custom = 2
}

enum HatchStyle
{
    OddParity = 0,
    OutermostAreaOnly = 1,
    EntireArea = 2
}

enum BoundaryPathType
{
    Default = 0,
    External = 1,
    Polyline = 2, // handled manually, not exposed
    Derived = 4,
    Textbox = 8,
    Outermost = 16,
}

enum BoundaryPathEdgeType
{
    Line = 1,
    Arc = 2, // handled manually, not exposed
    Ellipse = 3,
    Spline = 4,
}

// See folllowing link for codes definitions
// http://docs.autodesk.com/ACD/2011/ENU/filesDXF/WS1a9193826455f5ff18cb41610ec0a2e719-7a13.htm
export interface IHatchEntity extends IEntity {
    elevationPoint: IPoint;  //Codes="10,20,30" />
    extrusionDirection: IPoint; //Code="210,220,230" 
    patternName: string; //Code="2" 
    fillMode: HatchPatternFillMode; //Code="70"
    fillColor: number; //Code="63"
    isAssociative: boolean; //Code="71"
    boundaryPathCount: number; //Code="91"
    boundaryPaths: BoundaryPath[];  //Code="92" 
    hatchStyle : HatchStyle; //Code="75"
    patternType : HatchPatternType; //Code="76" 
    patternAngle : number; //Code="52" 
    patternScale : number; //Code="41" 
    isAnnotatedBoundary : boolean // Code = "73" (boundary is an annotated boundary = 1; boundary is not an annotated boundary = 0)
    isPatternDoubled : boolean; //Code="77"
    patternDefinitionLineCount : number;  //Code="78"
    patternDefinitionLines: PatternDefinitionLine[]; //Code="53" 
    pixelSize : number; //Code="47" 
    seedPointCount : number; //Code="98" Type="int" Accessibility="private" DefaultValue="0" />
    seedPoints: IPoint[]; //Code="10, 20" />
    //degenerateBoundaryPathCount : number // Code = "99"
    //degenerateBoundaryPathSeedPoints : IPoint[]
    isGradient : boolean; //Code="450" 
    zero : number; //Code="451" Reserved for future use.
    gradientColorMode : GradientColorMode; //Code="452"
    numberOfColors : number; //Code="453" Number of colors: 0 = Solid hatch; 2 = Gradient
    gradientRotationAngle : number; //Code="460" Rotation angle for gradients in radians.
    gradientDefinitionShift : number; //Code="461"
    colorTint : number; //Code="462"
    reserved : number; //Code="463" Comment="Reserved for future use.
    stringValue : string; //Code="470"
}

export abstract class BoundaryPath
{
    pathType: BoundaryPathType;
    boundaryHandleCount: number = 0;
    boundaryHandles: number[] = [];

    public processCodeValue(curr: IGroup): boolean
    {
        switch (curr.code)
        {
            case 97:
                this.boundaryHandleCount = curr.value as number;
                break;
            case 330:
                this.boundaryHandles.push(curr.value as number);
                break;
            default:
                return false;
        }
    
        return true;
    }
};

export class PolylineBoundaryPath extends BoundaryPath
{
    hasBulge : boolean;
    isClosed : boolean;
    vertexCount : number = 0;
    vertices : IPoint[] = [];
    bulge : number = 0; // Not sure what it is for...

    constructor(){
        super();
        super.pathType = BoundaryPathType.Polyline;
    }

    override processCodeValue(curr: IGroup) : boolean
    {
        switch (curr.code)
        {
            case 72:
                this.hasBulge = curr.value as boolean;;
                break;
            case 73:
                this.isClosed = curr.value as boolean;
                break;
            case 93:
                this.vertexCount = curr.value as  number;
                break;
            case 10:
                this.vertices.push( {x: curr.value as number, y: 0.0, z: 0.0} as IPoint);
                break;
            case 20:
                this.vertices[this.vertices.length -1 ].y = curr.value as number;
                break;
            case 42:
                this.bulge = curr.value as  number;
                break;
            default:
                return super.processCodeValue(curr);
        }

        return true;
    }
};

export class NonPolylineBoundaryPath extends BoundaryPath
{
    public edges: IBoundaryPathEdge[] = [];
    public edgesCnt: number = 0;

    override processCodeValue(curr: IGroup): boolean
    {
        switch (curr.code)
        {
            case 93:
                this.edgesCnt = curr.value as number;
                break;
            case 72:
                const edgeType = curr.value;
                switch (edgeType) {
                    case 1:
                        this.edges.push(new LineBoundaryPathEdge());
                        break;
                    case 2:
                        this.edges.push(new CircularArcBoundaryPathEdge());
                        break;
                    case 3:
                        this.edges.push(new EllipticArcBoundaryPathEdge());
                        break;
                    case 4:
                        this.edges.push(new SplineBoundaryPathEdge());
                        break;
                    default:                        
                        return false;
                }
                break;
            default:
                return this.edges[this.edges.length - 1]?.processCodeValue(curr) || super.processCodeValue(curr);
        }

        return true;
    }
}

interface IBoundaryPathEdge
{
    processCodeValue(curr:IGroup) : boolean;
    getType(): BoundaryPathEdgeType;
}

class LineBoundaryPathEdge implements IBoundaryPathEdge
{
    public startPoint : IPoint = {x: NaN, y :NaN} as IPoint
    public endPoint : IPoint = {x: NaN, y :NaN} as IPoint

    getType(): BoundaryPathEdgeType{
        return BoundaryPathEdgeType.Line;
    }

    processCodeValue(curr:IGroup) :  boolean
    {
        switch (curr.code)
        {
            case 10:
                this.startPoint.x = curr.value as number;
                break;
            case 20:
                this.startPoint.y = curr.value as number;
                break;
            case 11:
                this.endPoint.x = curr.value as number;
                break;
            case 21:
                this.endPoint.y = curr.value as number;
                break;
            default:
                return false;
        }

        return true;
    }
}

class CircularArcBoundaryPathEdge implements IBoundaryPathEdge
{
    center : IPoint = {x: NaN, y :NaN} as IPoint;
    radius : number;
    startAngle : number;
    endAngle : number;
    isCounterClockwise: boolean;

    getType(): BoundaryPathEdgeType{
        return BoundaryPathEdgeType.Arc;
    }

    processCodeValue(curr:IGroup) : boolean
    {
        switch (curr.code)
        {
            case 10:
                this.center.x = curr.value as number;
                break;
            case 20:
                this.center.y = curr.value as number;
                break;
            case 40:
                this.radius = curr.value as number;
                break;
            case 50:
                this.startAngle = curr.value as number;
                break;
            case 51:
                this.endAngle = curr.value as number;
                break;
            case 73:
                this.isCounterClockwise = curr.value as boolean;
                break;
            default:
                return false;
        }

        return true;
    }
}

class EllipticArcBoundaryPathEdge implements IBoundaryPathEdge
{
    center : IPoint = {x: NaN, y :NaN} as IPoint;
    majorAxis : IPoint = {x: NaN, y :NaN} as IPoint;
    minorAxisRatio : number;
    startAngle : number;
    endAngle : number;
    isCounterClockwise: boolean;

    getType(): BoundaryPathEdgeType{
        return BoundaryPathEdgeType.Ellipse;
    }

    processCodeValue(curr : IGroup) : boolean
    {
        switch (curr.code)
        {
            case 10:
                this.center.x = curr.value as number;
                break;
            case 20:
                this.center.y = curr.value as number;
                break;
            case 11:
                this.majorAxis.x = curr.value as number;
                break;
            case 21:
                this.majorAxis.y = curr.value as number;
                break;
            case 40:
                this.minorAxisRatio = curr.value as number;
                break;
            case 50:
                this.startAngle = curr.value as number;
                break;
            case 51:
                this.endAngle = curr.value as number;
                break;
            case 73:
                this.isCounterClockwise = curr.value as boolean;
                break;
            default:
                return false;
        }

        return true;
    }
}

class SplineBoundaryPathEdge implements IBoundaryPathEdge
{
    degree : number;
    isRational : boolean;
    isPeriodic : boolean;
    knots : number[];
    knotCount : number;
    controlPoints : IPoint[] = [];
    controlPointsCount : number;
    weights : number[] = [];
    fitPointsCount : number
    fitPoints : IPoint[] = []
    startTangent : IPoint;
    endTangent : IPoint;
    currentWeight : number = 0;

    getType(): BoundaryPathEdgeType{
        return BoundaryPathEdgeType.Spline;
    }

    processCodeValue(curr : IGroup) : boolean
    {
        switch (curr.code)
        {
            case 94:
                this.degree = curr.value as number;
                break;
            case 73:
                this.isRational = curr.value as boolean;
                break;
            case 74:
                this.isPeriodic = curr.value as boolean;
                break;
            case 95:
                this.knotCount = curr.value as number;
                break;
            case 96:
                this.controlPointsCount = curr.value as number;
                break;
            case 40:
                this.knots.push(curr.value as number);
                break;
            case 10:
                const pt = { x: (curr.value as number), y:  0.0, z: 0.0 } as IPoint;
                this.controlPoints.push(pt);
                break;
            case 20:
                this.controlPoints[this.controlPoints.length - 1].y = curr.value as number;
                break;
            case 42:
                this.weights.push(curr.value as number);
                break;
            case 97:
                this.fitPointsCount = curr.value as number;
                break;
            case 11:
                this.fitPoints.push({ x: (curr.value as number), y:  0.0, z: 0.0 } as IPoint);
                break;
            case 21:
                this.fitPoints[this.fitPoints.length - 1].y = curr.value as number;
                break;
            case 12:
                this.startTangent = { x: (curr.value as number), y:  0.0, z: 0.0 } as IPoint
                break;
            case 22:
                this.startTangent.y = curr.value as number;
                break;
            case 13:
                this.endTangent = { x: (curr.value as number), y:  0.0, z: 0.0 } as IPoint
                break;
            case 23:
                this.endTangent.y =  curr.value as number;
                break;
            default:
                return false;
        }

        return true;
    }
}

export class PatternDefinitionLine
{
    angle: number = 0.0;
    basePoint : IPoint = {x: 0., y: 0.,z: 0.} as IPoint;
    offset : IPoint= {x: 0., y: 0.,z: 0.} as IPoint;
    dashLengthCount : number;
    dashLengths : number[];

    processCodeValue(curr : IGroup) : boolean
    {
        switch (curr.code)
        {
            case 53:
                this.angle = curr.value as number;
                break;
            case 43:
                this.basePoint.x = curr.value as number;
                break;
            case 44:
                this.basePoint.x = curr.value as number;
                break;
            case 45:
                this.offset.x = curr.value as number;
                break;
            case 46:
                this.offset.y = curr.value as number;
                break;
            case 79:
                this.dashLengthCount = curr.value as number;
                break;
            case 49:
                this.dashLengths.push(curr.value as number);
                break;
            default:
                return false;
        }

        return true;
    }
}


export default class Hatch implements IGeometry {
	public ForEntityName = 'HATCH' as const;

	public parseEntity(scanner: DxfArrayScanner, curr: IGroup) {
		const entity = {
            type: curr.value,
            elevationPoint: {x: 0.0, y: 0.0, z: 0.0} as IPoint,
            extrusionDirection: {x: 0.0, y: 0.0, z: 0.0} as IPoint,
            seedPoints: [],
            patternDefinitionLines: [],
            boundaryPaths: []
        } as IHatchEntity;
		
        curr = scanner.next();
		while (!scanner.isEOF()) {
			if (curr.code === 0) break;

            switch (curr.code)
            {
                case 2:
                    entity.patternName = curr.value as string;
                    break;
                case 10:
                    entity.elevationPoint.x = curr.value as number;
                    break;
                case 20:
                    entity.elevationPoint.y = curr.value as number;
                    break;
                case 30:
                    entity.elevationPoint.z = curr.value as number;
                    break;
                case 41:
                    entity.patternScale = curr.value as number;
                    break;
                case 47:
                    entity.pixelSize = curr.value as number;
                    break;
                case 52:
                    entity.patternAngle = curr.value as number;
                    break;
                case 63:
                    entity.fillColor = curr.value as number;
                    break;
                case 70:
                    entity.fillMode = curr.value as HatchPatternFillMode;
                    break;
                case 71:
                    entity.isAssociative = curr.value as boolean;
                    break;
                case 73: 
                    entity.isAnnotatedBoundary = curr.value as boolean;
                    break;
                case 75:
                    entity.hatchStyle = curr.value as HatchStyle;
                    break;
                case 76:
                    entity.patternType = curr.value as HatchPatternType;
                    break;
                case 77:
                    entity.isPatternDoubled = curr.value as boolean;
                    break;
                case 78:
                    entity.patternDefinitionLineCount = curr.value as number;
                    this.parsePatternDefinitions(entity.patternDefinitionLineCount, scanner, entity);
                    break;
                case 91:
                    entity.boundaryPathCount = curr.value as number;
                    this.parseBoundaryPath(entity.boundaryPathCount, scanner, entity);
                    break;
                case 98:
                    entity.seedPointCount = curr.value as number;
                    this.parseSeedPoints(entity.seedPointCount, scanner, entity);
                    break;
                case 210:
                    entity.extrusionDirection.x = curr.value as number;
                    break;
                case 220:
                    entity.extrusionDirection.y = curr.value as number; 
                    break;
                case 230:
                    entity.extrusionDirection.z = curr.value as number;
                    break;
                case 450:
                    entity.isGradient =  curr.value as boolean;
                    break;
                case 451:
                    entity.zero =  curr.value as number;;
                    break;
                case 452:
                    entity.gradientColorMode =  curr.value as GradientColorMode;
                    break;
                case 453:
                    entity.numberOfColors =  curr.value as number;;
                    break;
                case 460:
                    entity.gradientRotationAngle =  curr.value as number;
                    break;
                case 461:
                    entity.gradientDefinitionShift =  curr.value as number;
                    break;
                case 462:
                    entity.colorTint =  curr.value as number;
                    break;
                case 463:
                    entity.reserved =  curr.value as number;
                    break;
                case 470:
                    entity.stringValue =  curr.value as string;
                    break;

                default:
                    helpers.checkCommonEntityProperties(entity, curr, scanner);
            }

            curr = scanner.next();
        }

        return entity;
    };

    parseSeedPoints(numberOfPoints : number, scanner: DxfArrayScanner, entity : IHatchEntity) : boolean {
        for (let i =0; i < 2 * numberOfPoints; ++i) {
            if (scanner.isEOF())
                return false; // Some data is lost. not enough points..

            let curr = scanner.next();
            switch (curr.code) {
                case 10:
                    entity.seedPoints.push({x: curr.value as number, y: 0.0, z: 0.0} as IPoint);
                    break;
                case 20:
                    entity.seedPoints[entity.seedPoints.length-1].y = curr.value as number;
                    break;
                default:
                    return false;
            }
        }
        return true;
    }

    parsePatternDefinitions(numberPatternDefinitionLines : number, scanner: DxfArrayScanner, entity : IHatchEntity) : boolean {
        let curr = scanner.next();
        while (!scanner.isEOF()) {
            if (curr.code == 53) {
                // Start of new definition
                entity.patternDefinitionLines.push(new PatternDefinitionLine());
            }

            const lastDef = entity.patternDefinitionLines[ entity.patternDefinitionLines.length -1];
            if (!lastDef.processCodeValue(curr)) {
                scanner.rewind();
                break;
            }
            
            curr = scanner.next();
        }
        
        return (entity.patternDefinitionLines.length == numberPatternDefinitionLines); 
    }

    parseBoundaryPath(numberBoundaryPaths : number, scanner: DxfArrayScanner, entity : IHatchEntity) : boolean {
        let curr = scanner.next();
        while (!scanner.isEOF()) {
            if (curr.code == 92) {
                // Start of new boundary path
                const type = curr.value as BoundaryPathType;
                const path = (type == BoundaryPathType.Polyline) ? new PolylineBoundaryPath() : new NonPolylineBoundaryPath();
                path.pathType = type;    
               
                entity.boundaryPaths.push(path);
            } else {
                const lastBoundary = entity.boundaryPaths[entity.boundaryPaths.length -1];
                if (!lastBoundary.processCodeValue(curr)) {
                    scanner.rewind();
                    break;
                }
            }

            curr = scanner.next();
        }
        return (entity.boundaryPaths.length == numberBoundaryPaths); 
    }
};
