import { useMemo } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import "./ProgressIndicator.sass";

type IProps = {
  percentage?: number;
};
const ProgressIndicator = ({ percentage }: IProps): JSX.Element => {
  const DisplayedPercentage = useMemo(() => {
    if (percentage == null || percentage < 0 || percentage > 100) {
      return () => null;
    }
    return () => <span className="percentage">{percentage}%</span>;
  }, [percentage]);
  return (
    <div className="progress-indicator">
      <LoadingOutlined />
      <DisplayedPercentage />
    </div>
  );
};

export default ProgressIndicator;
