import axios from "../../services/api/utilities/axios-wrapper";
import apiProvider from "../../services/api/utilities/Provider";
import { getAxiosRequestConfig } from "../../services/api/utilities";
import { showToastMessage } from "../../helpers/messages";
import { appModel } from "../../models/AppModel";
import CorePlanJsonTool from "../../editor/tools/CorePlanJsonTool";
import log from "loglevel";
import { TGetRenderJobsResponse, TRenderJob } from "./types";
import { revitFiles } from "../revitFiles";
import { Modal } from "antd";
const { confirm } = Modal;

const START_JOB_SUCCESS_MESSAGE = "Render 3D model has been initiated";
const START_JOB_ERROR_MESSAGE = "Error occurred when starting Render 3D model";
const GET_CORE_PLAN_JOBS_ERROR_MESSAGE = "Error occurred when getting corePlan jobs";
const DELETE_CORE_PLAN_JOB_SUCCESS_MESSAGE = "Successfully deleted corePlan job";
const DELETE_CORE_PLAN_JOB_ERROR_MESSAGE = "Error occurred when deleting corePlan job";
const REVIT_CATALOG_NOT_UPLOADED_ERROR_MESSAGE = "Revit catalog is not uploaded. Generating 3D model is not available";

class RenderJob {
  /**
   * Start Render 3D model job
   */
  static async startJob(): Promise<void> {
    try {
      const corePlanId = appModel.activeCorePlan.id;
      const jsonTool = new CorePlanJsonTool(appModel.baseManager.roomManager);
      const json = await jsonTool.getCorePlanData();

      // Endpoint expected nested field in payload
      const jsonBody = { json: JSON.stringify(json) };

      const url = RenderJob.getEndpointUrl(corePlanId);
      const axiosConfig = getAxiosRequestConfig();

      // Start render job
      const response = await axios.post(url, jsonBody, axiosConfig);

      if (response.status === 200) {
        showToastMessage("Success", START_JOB_SUCCESS_MESSAGE);
      } else {
        showToastMessage("Error", START_JOB_ERROR_MESSAGE);
      }
    } catch (err) {
      log.error(err);
      showToastMessage("Error", START_JOB_ERROR_MESSAGE);
    }
  }

  static async canStartJob(): Promise<boolean> {
    if (!revitFiles.files.catalogFile || !revitFiles.files.settingsFile || !revitFiles.files.templateFile) {
      showToastMessage("Error", REVIT_CATALOG_NOT_UPLOADED_ERROR_MESSAGE);
      return false;
    }

    const rooms = appModel.activeCorePlan?.floors.flatMap(floor => floor.rooms);
    // if (!rooms.length) {
    //   return false;
    // }

    if (rooms.some(room => room.isObsolete)) {
      return await new Promise(resolve => {
        confirm({
          title: "The current floor plan contains rooms from previous room catalog version. Do you want to continue?",
          okText: "Yes",
          okType: "primary",
          cancelText: "No",
          onOk: () => resolve(true),
          onCancel: () => resolve(false),
        });
      });
    }

    return true;
  }

  static async getCorePlanJobs(corePlanId: string): Promise<TRenderJob[]> {
    try {
      const url = RenderJob.getEndpointUrl(corePlanId);
      const axiosConfig = getAxiosRequestConfig();

      const response = await axios.get<TGetRenderJobsResponse>(url, axiosConfig);

      if (response.status !== 200) {
        showToastMessage("Error", GET_CORE_PLAN_JOBS_ERROR_MESSAGE);
        return [];
      }

      return response.data.jobs.map(j => ({
        ...j,
        updatedAt: new Date(j.updatedAt),
        createdAt: new Date(j.createdAt),
      }));
    } catch (error) {
      log.error(error);
      showToastMessage("Error", GET_CORE_PLAN_JOBS_ERROR_MESSAGE);
    }
  }

  static async deleteCorePlanJob(corePlanId: string, jobId: string): Promise<boolean> {
    try {
      const url = RenderJob.getEndpointUrl(corePlanId);
      const axiosConfig = getAxiosRequestConfig();
      const response = await axios.delete(`${url}/${jobId}`, axiosConfig);
      if (response.status === 204) {
        showToastMessage("Success", DELETE_CORE_PLAN_JOB_SUCCESS_MESSAGE);
        return true;
      } else {
        showToastMessage("Error", DELETE_CORE_PLAN_JOB_ERROR_MESSAGE);
      }
    } catch (error) {
      log.error(error);
      showToastMessage("Error", DELETE_CORE_PLAN_JOB_ERROR_MESSAGE);
    }
    return false;
  }

  static getEndpointUrl(corePlanId: string): string {
    return `${apiProvider.host}corePlan/${corePlanId}/jobs`;
  }
}

export default RenderJob;
