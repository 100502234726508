import { Tabs } from "antd";
import log from "loglevel";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { fileManager } from "../../../../entities/fileManager";
import { RenderJob } from "../../../../entities/renderJob";
import { TRenderJob } from "../../../../entities/renderJob/types";
import { FileEntity } from "../../../../models/FileEntity";
import { CorePlan } from "../../../../models/CorePlan";
import { FolderOptions } from "../../Editor/LeftBar/LeftBar";
import "./CorePlanDetails.sass";
import TabCorePlanFiles from "./TabCorePlanFiles";
import TabCorePlanOverview from "./TabCorePlanOverview";

interface CorePlanDetailsProps {
  corePlan: CorePlan;
}

let CorePlanDetails = ({ corePlan }: CorePlanDetailsProps) => {
  const [fileList, setFileList] = useState<Map<string, FileEntity[]>>(new Map<string, FileEntity[]>());
  const [jobs, setJobs] = useState<TRenderJob[]>([]);

  useEffect(() => {
    getCorePlanFilesAndJobs(corePlan);
  }, [corePlan]);

  const getCorePlanFilesAndJobs = async (corePlan: CorePlan) => {
    try {
      const files = await fileManager.getCorePlanFileList(corePlan.id);
      if (!files) {
        setFileList(new Map<string, FileEntity[]>());
        return;
      }
      const folderFilesMap = files.reduce((map, file) => {
        const splitName = file.URI.split("/");
        // unattached FilePath structure - [empty space]/files/corePlans/<corePlanId>/<folderName>[/<folderName>...]/<fileName>
        // registered in db FilePath structure - [empty space]/files/corePlans/<corePlanId>/<fileName>
        const folderName = splitName.length < 6 ? FolderOptions.CorePlanFolder : splitName[4]; // File is in corePlan root folder -> so is registered in db -< show in Documents tab
        if (map.has(folderName)) {
          map.get(folderName).push(file);
        } else {
          map.set(folderName, [file]);
        }

        return map;
      }, new Map<string, FileEntity[]>());

      setFileList(folderFilesMap);
      const jobs = await RenderJob.getCorePlanJobs(corePlan.id);
      setJobs(jobs);
    } catch (error) {
      setFileList(new Map<string, FileEntity[]>());
      log.error(error);
    }
  };

  const removeJob = (jobId: string) => {
    const jobIndex = jobs.findIndex(j => j.id === jobId);
    if (jobIndex >= 0) {
      jobs.splice(jobIndex, 1);
      setJobs([...jobs]);
    }
  };

  const removeFile = async (fileId: string, folder: string) => {
    const folderFiles = fileList.get(folder);
    const fileIndex = folderFiles.findIndex(f => f.id === fileId);
    if (fileIndex >= 0) {
      folderFiles.splice(fileIndex, 1);
      setFileList(new Map(fileList));
    }
  };

  const corePlanTabs = [
    { label: "CorePlan Overview", key: "corePlanOverview", children: <TabCorePlanOverview corePlan={corePlan} /> },
    {
      label: "Floor Plans",
      key: "floorPlans",
      children: (
        <TabCorePlanFiles
          corePlan={corePlan}
          files={fileList.get(FolderOptions.FloorPlanFolder)}
          folderName={FolderOptions.FloorPlanFolder}
          removeFile={removeFile}
        />
      ),
    },
    {
      label: "TML",
      key: "tml",
      children: (
        <TabCorePlanFiles corePlan={corePlan} files={fileList.get(FolderOptions.TmlFolder)} folderName={FolderOptions.TmlFolder} removeFile={removeFile} />
      ),
    },
    {
      label: "Renderings",
      key: "renderings",
      children: (
        <TabCorePlanFiles
          corePlan={corePlan}
          files={fileList.get(FolderOptions.RenderingsFolder)}
          folderName={FolderOptions.RenderingsFolder}
          removeFile={removeFile}
        />
      ),
    },
    {
      label: "3D Model",
      key: "3DModel",
      children: (
        <TabCorePlanFiles
          corePlan={corePlan}
          files={fileList.get(FolderOptions.ThreeDModelsFolder)}
          folderName={FolderOptions.ThreeDModelsFolder}
          removeFile={removeFile}
          jobs={jobs}
          removeJob={removeJob}
        />
      ),
    },
    {
      label: "Documents",
      key: "documents",
      children: (
        <TabCorePlanFiles
          corePlan={corePlan}
          files={fileList.get(FolderOptions.CorePlanFolder)}
          folderName={FolderOptions.CorePlanFolder}
          removeFile={removeFile}
        />
      ),
    },
  ];

  return <Tabs defaultActiveKey="corePlanOverview" className="tabs-corePlan" items={corePlanTabs} />;
};

CorePlanDetails = observer(CorePlanDetails);
export default CorePlanDetails;
