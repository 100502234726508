import { appModel } from "../../models/AppModel";
import * as THREE from "three";
import SceneUtils from "../utils/SceneUtils";
import UnitsUtils from "../utils/UnitsUtils";

export default class RoomEditToolPosition {
  static calculateRoomsBoundary(rooms): THREE.Box3 {
    const bb = new THREE.Box3();

    rooms.forEach(soRoom => {
      bb.union(SceneUtils.getRoomBoundingBoxByModelLines(soRoom));
    });

    const offset = appModel.showCladding ? UnitsUtils.getAreaCalculationExteriorOffset() : UnitsUtils.getSyntheticWallHalfSize();
    bb.expandByVector(new THREE.Vector3(offset, offset));

    return bb;
  }

  static setPosition(isShow): void {
    if (!isShow) {
      appModel.setEditToolOptions({
        show: false,
      });
      return;
    }

    const rooms = appModel.baseManager.roomManager.getCorePlanSelectedSoRooms();
    const boundingBox = this.calculateRoomsBoundary(rooms);
    const rect = appModel.baseManager.getCanvas().getBoundingClientRect();
    const camera = appModel.baseManager.roomManager.camera;

    const roomBottomPosition = new THREE.Vector3((boundingBox.min.x + boundingBox.max.x) / 2, boundingBox.min.y, (boundingBox.min.z + boundingBox.max.z) / 2);
    const vector = roomBottomPosition.project(camera);

    const x = (vector.x * 0.5 + 0.5) * appModel.baseManager.renderer.domElement.clientWidth;
    const y = (1 - (vector.y * 0.5 + 0.5)) * appModel.baseManager.renderer.domElement.clientHeight - rect.top;

    const containerWidth = rect.width;
    const containerHeight = rect.height;

    const elementWidth = 152; //EditorToolbar width
    const elementHeight = 38; //EditorToolbar height

    let adjustedX = x;
    if (adjustedX < elementWidth / 2) {
      adjustedX = elementWidth / 2 + 4; //EditorToolbar width / 2 + padding
    } else if (adjustedX + elementWidth / 2 > containerWidth) {
      adjustedX = containerWidth - elementWidth / 2 - 4; //EditorToolbar width / 2 - padding;
    }

    let adjustedY = y + 70;
    if (adjustedY < 0) {
      adjustedY = 0;
    } else if (adjustedY + elementHeight > containerHeight) {
      adjustedY = containerHeight - elementHeight;
    }

    appModel.setEditToolOptions({
      show: true,
      top: adjustedY,
      left: adjustedX,
    });
  }
}
