import { UploadFile } from "antd";
import { action, computed, makeObservable, observable } from "mobx";
import { v4 as uuid } from "uuid";
import { DTOConvertOptions, EntityDataType, PersistentObject } from "../entities/entityManager";
import { settings } from "../entities/settings";
import { createNameSuffixNumber } from "../helpers/models";
import { DTORecord, asArray, deepCopy2DTO, isEmpty } from "../helpers/pojo";
import { arrayIdGet, arrayIdSet } from "../helpers/utilities";
import { kindBackgroundRaw, kindCorePlanRaw, markPublic } from "../services/allKinds";
import { RawFile, RawCorePlan } from "../services/rawTypes";
import { UserDepot } from "../store/UserDepot";
import { appModel } from "./AppModel";
import { FileEntity } from "./FileEntity";
import { Floor } from "./Floor";
import { LotLineSide, CorePlanAttributes, convert2feet, convert2inches } from "./CorePlanAttributes";
import { Room } from "./Room";
import RoomOpening from "./RoomOpening";
import { RoomType } from "./RoomType";
import { UserIdentity } from "./UserIdentity";
import { Vector3V } from "./Vector3V";
import { Variation } from "./Variation";
import { getFloorCoverages } from "../ui/components/CorePlans/CorePlanDetailsEditor";

type LockedRoomDimensions = {
  x: boolean;
  y: boolean;
};

export type RoomsLock = {
  [key: string]: LockedRoomDimensions;
};

export class CorePlan implements PersistentObject<CorePlan, RawCorePlan> {
  id: string = uuid();
  lennar_id: number = -1;
  name: string = "";
  series: string = "";
  state: string = "";
  description: string = "";
  status: number = 0;
  buildingType: number = 0;
  layoutType: number = 0;
  notes: string[] = [];
  createdAt: string = "";
  createdBy: number = 0; // userId
  updatedAt: string = "";
  updatedBy: number = 0; // userId
  basePoint: Vector3V = new Vector3V();
  floors: Floor[] = [];
  variations: Variation[] = [];
  cost: number = 0;
  costPerSqft: number = 0;
  co2Emission: number = 0;
  isCostOutdated: boolean = false;
  lockedBy: UserIdentity = null;
  width: number = 0;
  state_id: number = 1688;

  files: FileEntity[] = [];
  ext_info?: DTORecord;
  variety: string[] = [];

  lockedRoomDimensions: RoomsLock = null;

  isEstimatingCorePlanCost: boolean = false;
  protected _attributes: CorePlanAttributes;

  constructor(name: string = "") {
    this.name = name;

    makeObservable(this, {
      name: observable,
      lennar_id: observable,
      description: observable,
      status: observable,
      buildingType: observable,
      layoutType: observable,
      notes: observable,
      createdAt: observable,
      createdBy: observable,
      basePoint: observable,
      floors: observable,
      variations: observable,
      files: observable,
      cost: observable,
      costPerSqft: observable,
      co2Emission: observable,
      isCostOutdated: observable,
      isEstimatingCorePlanCost: observable,
      lockedBy: observable,
      lockedRoomDimensions: observable,
      series: observable,
      width: observable,
      state: observable,
      state_id: observable,

      homeSize: computed,
      attributes: computed,
      floorToFloorHeight: computed,

      changeFloorCount: action,
      fromDTO: action,
      addFloor: action,
      setIsCostOutdated: action,
      setIsEstimatingCost: action,
      setIncludeCladdingThickness: action,
      setLockedBy: action,
    });
  }

  clearUploadFiles(): void {
    this._uploadFiles.splice(0, this._uploadFiles.length);
  }

  addFloor(floor: Floor): void {
    floor.corePlanId = this.id;
    arrayIdSet(this.floors, floor);
  }
  addVariation(variation: Variation): void {
    variation.corePlanId = this.id;
    arrayIdSet(this.variations, variation);
  }

  deleteVariation(variation: Variation): void {
    const index = this.variations.findIndex(v => v.id === variation.id);
    if (index !== -1) {
      this.variations.splice(index, 1);
      console.log(`Variation with ID ${variation.id} has been removed.`);
    } else {
      console.log(`No variation found with ID ${variation.id}.`);
    }
  }

  getRooms(ids?: string[]): Room[] {
    let rooms = this.floors.map(floor => floor.rooms).flat();

    if (ids) {
      rooms = rooms.filter(room => ids.includes(room.id));
    }

    return rooms || [];
  }

  getRoom(id: string): Room {
    return this.getRooms([id])[0] || null;
  }

  /**
   * Returns list of all RoomTypes that used in the CorePlan
   */
  getCorePlanRoomTypes(): RoomType[] {
    const rooms = this.getRooms();
    const roomTypeIds = rooms.map(r => r.roomTypeId);
    const uniqueRoomTypeIds = [...new Set(roomTypeIds)];
    const roomTypes = uniqueRoomTypeIds.map(id => appModel.getRoomType(id)).filter(Boolean);
    return roomTypes;
  }

  getBackgroundFiles(): FileEntity[] {
    return this.files.filter((fileEntity: FileEntity) => fileEntity.relType === kindBackgroundRaw.id);
  }

  getRoomOpening(roomId: string, openingId: string): RoomOpening | null {
    const room = this.getRoom(roomId);
    return room?.openings?.find(opening => opening.id === openingId) ?? null;
  }

  checkUsedFile(file: FileEntity): { message: string; owner: any }[] {
    const result = [];

    for (const floor of this.floors || []) {
      const used = floor.checkUsedFile(file, this);
      if (used.length) result.push(...used);
    }

    return result;
  }

  changeFloorCount(newFloorCount: number): boolean {
    if (this.floors.length > newFloorCount) {
      const filtered = this.floors.filter(f => f.index < newFloorCount);
      this.floors.splice(0, this.floors.length, ...filtered);
      return true;
    } else if (this.floors.length < newFloorCount) {
      for (let i = this.floors.length + 1; i <= newFloorCount; ++i) {
        this.addFloor(new Floor(i - 1, `Story ${i}`));
      }
      return true;
    }
    return false;
  }

  setIsCostOutdated(isOutdated: boolean): void {
    this.isCostOutdated = isOutdated;
  }
  setIsEstimatingCost(isEstimating: boolean): void {
    this.isEstimatingCorePlanCost = isEstimating;
  }
  setIncludeCladdingThickness(value: boolean): void {
    this.attributes.includeCladdingThickness = value;
  }

  setLockedBy(user: UserIdentity): void {
    this.lockedBy = user;
  }

  setLockedRoomDimensions(newLockedRoomDimensions: RoomsLock): void {
    this.lockedRoomDimensions = { ...newLockedRoomDimensions };
  }

  clone(useSystemSettingsCeilingHeight = false): CorePlan {
    const result = new CorePlan();
    result.lennar_id = this.lennar_id;
    result.name = createNameSuffixNumber(this.name, " - clone");
    result.description = this.description;
    // result.status = this.status; // ???
    result.buildingType = this.buildingType;
    result.layoutType = this.layoutType;
    result.series = this.series;
    result.width = this.width;
    result.state = this.state;
    result.state_id = this.state_id;
    //result.notes = this.notes.slice();
    result.variety = this.variety.slice();
    result.basePoint = this.basePoint.clone();
    result.cost = this.cost;
    result.costPerSqft = this.costPerSqft;
    result.co2Emission = this.co2Emission;
    result.lockedRoomDimensions = this.lockedRoomDimensions;
    result.isCostOutdated = this.isCostOutdated;
    result.ext_info = Object.assign({}, this.ext_info, {
      clonedFrom: this.id,
    });

    result.attributes = {
      ...this.attributes,
      lotLineSideAssociation: {
        left: this.attributes?.lotLineSideAssociation?.left || LotLineSide.Side,
        right: this.attributes?.lotLineSideAssociation?.right || LotLineSide.Side,
        top: this.attributes?.lotLineSideAssociation?.top || LotLineSide.Rear,
      },
      lotLineSetback: {
        rear: this.attributes?.lotLineSetback?.rear || 0,
        side: this.attributes?.lotLineSetback?.side || 0,
        front: this.attributes?.lotLineSetback?.front || 0,
        front2: this.attributes?.lotLineSetback?.front2 || 0,
      },
      ceilingHeight: useSystemSettingsCeilingHeight ? settings.values.corePlanDefaults.netCeilingToFloor : this.attributes.ceilingHeight,
    };

    result.floors.push(
      ...this.floors.map(floor => {
        const newFloor = floor.clone();
        newFloor.corePlanId = result.id;
        return newFloor;
      })
    );

    return result;
  }

  isLocked(): boolean {
    return !!this.lockedBy;
  }

  isLockedByCurrentUser(): boolean {
    return this.isLocked() && this.lockedBy.id === UserDepot.current.userData.id;
  }

  get homeSize(): number {
    let result = 0;

    for (const floor of this.floors) {
      for (const room of floor.rooms) {
        if (appModel.getRoomType(room.roomTypeId).attributes.indoor) {
          result += room.area;
        }
      }
    }

    return result;
  }

  get floorToFloorHeight(): number {
    return (this.attributes.floorHeight || 0.0) + (this.attributes.ceilingHeight || 0.0);
  }

  get attributes(): CorePlanAttributes {
    if (!this._attributes) {
      this._attributes = observable({});
    }

    return this._attributes;
  }

  get publicFiles(): FileEntity[] {
    return this.files?.filter(f => f.mark === markPublic.id) || [];
  }

  // eslint-disable-next-line @typescript-eslint/adjacent-overload-signatures
  set attributes(attr: CorePlanAttributes) {
    Object.entries(attr || {}).forEach(([prop, val]) => {
      this.attributes[prop] = val;
    });
  }

  _uploadFiles: UploadFile[] = []; // for UI only

  // * Persistence part *

  mark: number = markPublic.id;
  static readonly kind = kindCorePlanRaw;

  toDTO(format?: EntityDataType, options?: DTOConvertOptions): RawCorePlan {
    if (format === "FormData") {
      return {
        id: this.id,
        lennar_id: this.lennar_id,
        name: this.name,
        series: this.series,
        state: this.state,
        state_id: this.state_id,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        _uploadFiles: this._uploadFiles,
        notes: this.notes,
        attributes: convert2feet({
          ...this.attributes,
          lotLineSideAssociation: {
            left: this.attributes?.lotLineSideAssociation?.left || LotLineSide.Side,
            top: this.attributes?.lotLineSideAssociation?.top || LotLineSide.Rear,
            right: this.attributes?.lotLineSideAssociation?.right || LotLineSide.Side,
          },
          floorCoverages: this.attributes?.floorCoverages ? [...this.attributes.floorCoverages] : getFloorCoverages(this.attributes.floors),
        }),
        mark: this.mark,
        existedFloors: this.floors.length || 0,
        cost: this.cost,
        costPerSqft: this.costPerSqft,
        co2Emission: this.co2Emission,
        lockedRoomDimensions: this.lockedRoomDimensions,
        isCostOutdated: this.isCostOutdated,
      };
    }

    const result = deepCopy2DTO(
      {
        kind: CorePlan.kind.id,
        id: this.id,
        lennar_id: this.lennar_id,
        name: this.name,
        series: this.series,
        state: this.state,
        basePoint: this.basePoint,

        status: this.status,
        mark: this.mark,

        buildingType: this.buildingType,
        layoutType: this.layoutType,

        attributes: this.attributes,

        state_id: this.state_id,
        width: this.width,

        description: this.description,
        //notes: asArray(this.notes),
        variety: this.variety,
        ext_info: this.ext_info,
        cost: this.cost,
        costPerSqft: this.costPerSqft,
        co2Emission: this.co2Emission,
        lockedRoomDimensions: this.lockedRoomDimensions,
        isCostOutdated: this.isCostOutdated,

        attached: options?.scope === "full" ? this.floors : undefined,
      },
      { scope: options?.scope, only: options?.onlyProps }
    ) as unknown as RawCorePlan;

    if (!isEmpty(this._uploadFiles)) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      result._uploadFiles = this._uploadFiles;
    }

    return result;
  }
  fromDTO(arg: any, format: EntityDataType = "APIdata"): CorePlan {
    if (format === "FormData") {
      this.fromFormData(arg as CorePlanFormData);
    } else if (format.startsWith("APIdata")) {
      this.fromAPI(arg as RawCorePlan, format);
    } else throw new Error(`Unknown format ${format}`);

    return this;
  }

  private fromFormData(formData: CorePlanFormData) {
    this.lennar_id = formData.lennar_id;
    this.name = formData.name;
    this.series = formData.series;
    this.state = formData.state;
    this.notes = formData.notes;
    this.lockedRoomDimensions = formData.lockedRoomDimensions;
    this._uploadFiles = formData._uploadFiles;
    this.attributes = convert2inches(formData.attributes);
    if (formData.mark) this.mark = formData.mark;
  }
  private fromAPI(dto: RawCorePlan, format?: EntityDataType) {
    if (CorePlan.kind.id != dto?.kind) throw "dto: RawCorePlan: incorrect kind";

    const copiedProperties = [
      "id",
      "lennar_id",
      "name",
      "series",
      "state",
      "status",
      "mark",
      "createdAt",
      "createdBy",
      "updatedAt",
      "updatedBy",
      "ext_info",
      "cost",
      "costPerSqft",
      "co2Emission",
      "lockedRoomDimensions",
      "isCostOutdated",
    ];

    if (format === "APIdata") {
      if (dto.basePoint) this.basePoint = new Vector3V().fromDTO(dto.basePoint);
      if (dto.lockedBy) {
        this.lockedBy = UserIdentity.fromJs(dto.lockedBy);
      }

      copiedProperties.push(...["buildingType", "layoutType", "attributes", "stateId", "state", "series", "width", "description", "notes", "variety"]);
    }

    for (const nameProperty of copiedProperties) {
      if (dto[nameProperty] === undefined) continue;

      this[nameProperty] = dto[nameProperty];
    }

    for (const attachedRaw of dto.attached || []) {
      if (attachedRaw.kind == Floor.kind.id) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.addFloor((arrayIdGet(this.floors, attachedRaw.id) || new Floor()).fromDTO(attachedRaw, format));
      } else if (attachedRaw.kind == Variation.kind.id) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.addVariation((arrayIdGet(this.variations, attachedRaw.id) || new Variation()).fromDTO(attachedRaw, format));
      } else if (attachedRaw.kind == FileEntity.kind.id) {
        if (!this.files) this.files = [];
        const idx = this.files.findIndex(f => f.name === attachedRaw.name);
        if (idx !== -1) {
          this.files.splice(idx, 1);
        }
        this.files.push(new FileEntity().fromDTO(attachedRaw as unknown as RawFile));
      }
    }
  }
}

export type CorePlanFormData = {
  _isNewCorePlan?: boolean;
  _copyAllFiles?: boolean;
  _uploadFiles?: UploadFile[];
  id: string;
  lennar_id: number;
  name: string;
  series: string;
  state: string;
  //width: number;
  notes: string[];
  attributes: CorePlanAttributes;
  mark?: number;
  existedFloors?: number;
  lockedRoomDimensions?: RoomsLock;
};
