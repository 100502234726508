import listCategory from "./categories";
import buiSeqID from "./builSequence";
import { markPublic } from "./../services/allKinds";
const random = () => crypto.getRandomValues(new Uint32Array(1))[0] / 2 ** 32;

const listRoomType = [];

for (const category of listCategory) {
  for (let ii = 1; ii < 8; ++ii) {
    const rT = {
      id: buiSeqID(),
      name: "Conf. " + ii + "-" + category.id,
      category_id: category.id,
      thumbnail_url: "/assets/foo_room_type.jpg",
      kind: 1200,
      variety: random() > 0.5 ? ["mod"] : undefined,
      isModification: random() < 0.5,
      attributes: {},
      mark: markPublic.id,
    };

    listRoomType.push(rT);
  }
}

export default listRoomType;
