import { action, computed, makeObservable, observable } from "mobx";
import { v4 as uuid } from "uuid";

export interface IRoofEdge {
  startNode: { x: number; y: number };
  endNode: { x: number; y: number };
  slope: number;
  gableDepth: number;
}

export interface IRoof {
  id: string;
  name: string;
  roofEdges: IRoofEdge[];
  addEdge(edge: IRoofEdge): void;
  removeEdge(index: number): void;
  perimeter: number;
}

export class RoofEdge implements IRoofEdge {
  startNode: { x: number; y: number };
  endNode: { x: number; y: number };
  slope: number;
  gableDepth: number;

  constructor(startNode: { x: number; y: number }, endNode: { x: number; y: number }, slope: number, gableDepth: number) {
    this.startNode = startNode;
    this.endNode = endNode;
    this.slope = slope;
    this.gableDepth = gableDepth;
  }
}

export class Roof implements IRoof {
  id: string = uuid();
  name: string = "";
  roofEdges: RoofEdge[] = [];

  constructor(name: string = "") {
    this.name = name;
    makeObservable(this, {
      name: observable,
      roofEdges: observable,
      addEdge: action,
      removeEdge: action,
      perimeter: computed,
    });
  }

  get perimeter(): number {
    return this.roofEdges.reduce(
      (acc, edge) => acc + Math.sqrt(Math.pow(edge.endNode.x - edge.startNode.x, 2) + Math.pow(edge.endNode.y - edge.startNode.y, 2)),
      0
    );
  }

  addEdge(edge: IRoofEdge): void {
    this.roofEdges.push(edge);
  }

  removeEdge(index: number): void {
    if (index >= 0 && index < this.roofEdges.length) {
      this.roofEdges.splice(index, 1);
    }
  }
}
