import * as THREE from "three";
import GeometryUtils from "../utils/GeometryUtils";
import SceneUtils from "../utils/SceneUtils";
import RoomManager from "../managers/RoomManager/RoomManager";

export default class WindowSelectionHelper {
  private selectionRectangle: HTMLElement;
  private startPoint: THREE.Vector3;
  private initiallySelectedIds: string[];
  private isEnclosedOnlyMode: boolean;
  private bottomLeftPoint: THREE.Vector3;
  private topRightPoint: THREE.Vector3;

  constructor(private roomManager: RoomManager) {}

  public start(event: MouseEvent): void {
    if (!this.selectionRectangle) {
      this.selectionRectangle = document.createElement("div");
      this.selectionRectangle.style.pointerEvents = "none";

      this.startPoint = new THREE.Vector3(event.clientX, event.clientY);
    }
  }
  public setInitiallySelected(initiallySelectedIds: string[]): void {
    this.initiallySelectedIds = initiallySelectedIds;
  }
  public update(event: MouseEvent, soRooms: THREE.Object3D[]): void {
    const parent = this.roomManager.baseManager.getParentContainer()!;
    if (!parent.contains(this.selectionRectangle)) {
      parent.appendChild(this.selectionRectangle);
    }

    this.updateSelectionRectangle(event);
    this.select(soRooms);
  }
  public end(): void {
    this.selectionRectangle?.parentElement?.removeChild(this.selectionRectangle);

    this.selectionRectangle = null;
  }

  private updateSelectionRectangle(event: MouseEvent): void {
    this.isEnclosedOnlyMode = this.startPoint.x < event.clientX;

    if (this.isEnclosedOnlyMode) {
      this.selectionRectangle.classList.remove("enclosed-and-intersected-select-box");
      this.selectionRectangle.classList.add("enclosed-select-box");
    } else {
      this.selectionRectangle.classList.remove("enclosed-select-box");
      this.selectionRectangle.classList.add("enclosed-and-intersected-select-box");
    }

    this.bottomLeftPoint = new THREE.Vector3(Math.min(this.startPoint.x, event.clientX), Math.min(this.startPoint.y, event.clientY));
    this.topRightPoint = new THREE.Vector3(Math.max(this.startPoint.x, event.clientX), Math.max(this.startPoint.y, event.clientY));

    this.selectionRectangle.style.left = `${this.bottomLeftPoint.x}px`;
    this.selectionRectangle.style.top = `${this.bottomLeftPoint.y}px`;
    this.selectionRectangle.style.width = `${this.topRightPoint.x - this.bottomLeftPoint.x}px`;
    this.selectionRectangle.style.height = `${this.topRightPoint.y - this.bottomLeftPoint.y}px`;
  }
  private select(soRooms: THREE.Object3D[]) {
    const selectPredicate = this.isEnclosedOnlyMode ? this.isEnclosed.bind(this) : this.isEnclosedOrIntersected.bind(this);

    soRooms.forEach(soRoom => {
      if (this.initiallySelectedIds.includes(soRoom.userData.id)) {
        return;
      }

      const shouldSelect = selectPredicate(SceneUtils.getRoomBoundingBox(soRoom));
      this.roomManager.selectRoom(soRoom, shouldSelect);
    });
  }
  private isEnclosed(box: THREE.Box3) {
    return this.getScreenSpacePoints(box).every(p => this.isPointInSelectionWindow(p));
  }
  private isEnclosedOrIntersected(box: THREE.Box3) {
    const screenSpacePoints = this.getScreenSpacePoints(box);

    // No intersection, object inside window or selection window is inside object.
    if (this.isPointInSelectionWindow(screenSpacePoints[0]) || GeometryUtils.isPointInsidePolygon(screenSpacePoints, this.startPoint)) {
      return true;
    }

    const rectanglePoints = [
      this.topRightPoint,
      new THREE.Vector3(this.topRightPoint.x, this.bottomLeftPoint.y),
      this.bottomLeftPoint,
      new THREE.Vector3(this.bottomLeftPoint.x, this.topRightPoint.y),
    ];

    // Check intersections between selection hull and rectangle.
    for (let i = 0; i < screenSpacePoints.length; i += 1) {
      const line1A = screenSpacePoints[i];
      const line1B = i < screenSpacePoints.length - 1 ? screenSpacePoints[i + 1] : screenSpacePoints[0];

      for (let j = 0; j < rectanglePoints.length; j += 1) {
        const line2A = rectanglePoints[j];
        const line2B = j < rectanglePoints.length - 1 ? rectanglePoints[j + 1] : rectanglePoints[0];

        if (GeometryUtils.doLinesIntersect(line1A, line1B, line2A, line2B)) {
          return true;
        }
      }
    }

    return false;
  }
  private getScreenSpacePoints(box: THREE.Box3): THREE.Vector3[] {
    return [box.min, new THREE.Vector3(box.min.x, box.max.y), box.max, new THREE.Vector3(box.max.x, box.min.y)].map(
      p => GeometryUtils.positionToScreenPosition(p, this.roomManager.camera, this.roomManager.baseManager.getParentContainerRectangle()) as THREE.Vector3
    );
  }
  private isPointInSelectionWindow(point: THREE.Vector3): boolean {
    return point.x > this.bottomLeftPoint.x && point.x < this.topRightPoint.x && point.y > this.bottomLeftPoint.y && point.y < this.topRightPoint.y;
  }
}
