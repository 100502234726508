export class Vector3V {
  constructor(
    public x: number = 0.0,
    public y: number = 0.0,
    public z: number = 0.0
  ) {}

  clone(): Vector3V {
    return new Vector3V(this.x, this.y, this.z);
  }

  fromDTO(dto: any): this {
    this.x = dto.x || this.x;
    this.y = dto.y || this.y;
    this.z = dto.z || this.z;
    return this;
  }
}
