/**
 * Get value of a cookie
 * (have no effect for HttpOnly cookies)
 */
const getCookie = (cookieName: string): string => {
  const cookieString = RegExp("" + cookieName + "[^;]+").exec(document.cookie);
  let stringToParse = "";

  if (cookieString) {
    stringToParse = cookieString.toString().replace(/^[^=]+./, "");
  }

  return decodeURIComponent(stringToParse);
};

/**
 * Set a cookie with given name and value
 * (have no effect for HttpOnly cookies)
 */
const setCookie = (cookieName: string, value: string, daysToLive: number): void => {
  let cookie = `${cookieName}=${encodeURIComponent(value)}`;
  cookie += `; max-age=${daysToLive * 60 * 60 * 24}`;
  document.cookie = cookie;
};

/**
 * Delete a cookie
 * (have no effect for HttpOnly cookies)
 */
const deleteCookie = (cookieName: string): void => {
  setCookie(cookieName, "", 0);
};

export { getCookie, setCookie, deleteCookie };
