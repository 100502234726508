import RoomManager from "../../managers/RoomManager/RoomManager";
import SceneUtils from "../../utils/SceneUtils";
import { CommandTypes } from "./CommandTypes";
import { RoomCommand } from "./RoomCommand";

export class RotateRoomCommand extends RoomCommand {
  constructor(
    entityId: string,
    private angle: number,
    private position?: THREE.Vector3
  ) {
    super(entityId);

    this.type = CommandTypes.RotateRoomCommand;
  }

  apply(roomManager: RoomManager): void {
    const soRoom = roomManager.getActiveFloorSoRoom(this.entityId);
    SceneUtils.rotateRoom(soRoom, this.angle, this.position);

    roomManager.updateRoomsProperties([this.entityId]);
    roomManager.checkRoomsSharedObjects([this.entityId]);
  }

  undo(roomManager: RoomManager): void {
    const soRoom = roomManager.getActiveFloorSoRoom(this.entityId);
    SceneUtils.rotateRoom(soRoom, -this.angle, this.position);

    roomManager.updateRoomsProperties([this.entityId]);
    roomManager.checkRoomsSharedObjects([this.entityId]);
  }
}
