import log from "loglevel";
import { listUser, voUser, vtUser } from "../entities/user";
import { UserIdentity } from "../models/UserIdentity";
import { User } from "./auth-context";

type TokenFunc = () => Promise<string>;

let _depot: UserDepot | null;

export class UserDepot {
  private _user?: voUser;

  constructor(
    public userData: User,
    public getToken: TokenFunc
  ) {
    if (_depot) throw "UserDepot is exists";
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    _depot = this;

    listUser({ byId: 10 }).then(res => {
      this._user = res[0];
      setTimeout(runUserActions, 32, "login");
    });
  }

  get user(): vtUser {
    return this._user;
  }

  private destroy() {}

  static close() {
    if (!_depot) return null;
    runUserActions("logout");
    _depot.destroy();
    _depot = null;
    return null;
  }

  static get current() {
    return _depot;
  }

  // TODO use only user
  static get userIdentity() {
    return new UserIdentity(_depot.userData.id, _depot.userData.username);
  }
}

type ActionUser = "login" | "logout";
type HandlerActionUserFunc = (user: vtUser, userDepot?: UserDepot, act?: ActionUser) => void;

const _allActions: { [key in ActionUser]: HandlerActionUserFunc[] } = {
  login: [],
  logout: [],
};

export function runUserActions(act: ActionUser) {
  for (const handler of _allActions[act]) {
    try {
      const currentDepot = UserDepot.current;
      handler(currentDepot?.user, currentDepot, act);
    } catch (error) {
      log.error(error);
    }
  }
}

export function registerUserAction(act: ActionUser, handler: HandlerActionUserFunc) {
  _allActions[act].push(handler);
}

registerUserAction("logout", (user: vtUser) => log.info("logoutHandler", user));
