import { UploadFile } from "antd";
import { TRevitFile, TRevitFileType, TRevitFiles, TServerResponse } from "./types";
import { kindFileRaw } from "../../services/allKinds";

export const FILE_EXTENSION = ".rvt";
export const SETTINGS_SUFFIX = ".settings.rvt";
export const TEMPLATE_SUFFIX = ".template.rvt";

export enum UploadingRevitFileListState {
  Empty = "Empty",
  Valid = "Valid",
  Invalid = "Invalid",
}

/**
 * Check if server response is valid one
 * We always expect one of 3 cases:
 * - either we not have Revit files,
 * - or we have 3 files, with catalog, settings and template
 */
export const isServerResponseValid = (response: any): response is TServerResponse => {
  if (response && response?.data && response?.data?.data && Array.isArray(response.data.data)) {
    const data = response.data.data;

    // Check case with no files
    if (data.length === 0) {
      return true;
    }

    // Check case with 2 fiiles
    const fileTypesMap = data.reduce((acc, f) => {
      acc[f.filePath] = true;
      return acc;
    }, {});

    if (Object.keys(fileTypesMap).length === 3 && fileTypesMap["catalog"] && fileTypesMap["settings"] && fileTypesMap["template"]) {
      return true;
    }
  }

  return false;
};

/**
 * Checks if Revit files [description] exists in server response
 * Expect correct response shape to be used as input
 * Returns true if files exists, otherwise false
 */
export const isFilesPresentInResponse = (response: TServerResponse): boolean => {
  if (response.data.data.length > 0) {
    return true;
  }

  return false;
};

/**
 * Extract Revit files [descriptions] from server response
 * Expect correct response shape to be used as input
 */
export const extractRevitFilesFromResponse = (response: TServerResponse): TRevitFiles => {
  if (!isFilesPresentInResponse(response)) {
    return {
      catalogFile: null,
      settingsFile: null,
      templateFile: null,
    };
  }

  const data = response.data.data;
  const rawCatalogFile = data.find(f => f.filePath === "catalog");
  const rawSettingsFile = data.find(f => f.filePath === "settings");
  const rawTemplateFile = data.find(f => f.filePath === "template");

  if (!rawCatalogFile || !rawSettingsFile || !rawTemplateFile) {
    throw new Error("Lack correct type of Revit file in server response");
  }

  const catalogFile: TRevitFile = {
    type: "catalog",
    id: rawCatalogFile.id,
    name: rawCatalogFile.name,
    lastModified: rawCatalogFile.lastModified,
    fileSize: rawCatalogFile.fileSize,
    uri: rawCatalogFile.URI,
    createdAt: new Date(rawCatalogFile.createdAt),
  };

  const settingsFile: TRevitFile = {
    type: "settings",
    id: rawSettingsFile.id,
    name: rawSettingsFile.name,
    lastModified: rawSettingsFile.lastModified,
    fileSize: rawSettingsFile.fileSize,
    uri: rawSettingsFile.URI,
    createdAt: new Date(rawSettingsFile.createdAt),
  };

  const templateFile: TRevitFile = {
    type: "template",
    id: rawTemplateFile.id,
    name: rawTemplateFile.name,
    lastModified: rawTemplateFile.lastModified,
    fileSize: rawTemplateFile.fileSize,
    uri: rawTemplateFile.URI,
    createdAt: new Date(rawTemplateFile.createdAt),
  };

  return {
    catalogFile,
    settingsFile,
    templateFile,
  };
};

/**
 * Predicate, returns true if given file name is a Revit file name
 */
export const isRevitFileName = (fileName: string): boolean => {
  return fileName.toLowerCase().endsWith(FILE_EXTENSION);
};

/**
 * Predicate, returns true if given file name is a valid name for file store.
 */
export const isRevitFileNameValid = (fileName: string): boolean => {
  const regex = /[^a-zA-Z0-9-_!.\*\'\(\)]/;
  return !regex.test(fileName);
};

/**
 * Predicate, returns true if file list of Revit files for uploading is empty
 */
export const isEmptyUploadFilesList = (fileList: UploadFile[]): boolean => {
  return fileList.length === 0;
};

/**
 * Predicate, returns true if file list of Revit files for uploading contains correct values
 * At the moment values considered as correct if:
 * - we have exact 3 files
 * - one file - aka 'catalog' - have name that match schema 'DEcatalog-<version>.rvt'
 * - second file - aka 'settings' - have name that match schema 'DEcatalog-<version>.Settings.rvt'
 * - third file - aka 'template' - have name that match schema 'DEcatalog-<version>.Template.rvt'
 */
export const isUploadFilesListContainsValidValues = (fileList: UploadFile[]): boolean => {
  const fileNames = fileList.map(f => f.name.toLowerCase());

  const res =
    fileNames.length === 3 &&
    fileNames.every(isRevitFileName) &&
    fileNames.every(isRevitFileNameValid) &&
    fileNames.filter(n => n.endsWith(SETTINGS_SUFFIX)).length === 1 &&
    fileNames.filter(n => n.endsWith(TEMPLATE_SUFFIX)).length === 1;

  return res;
};

/**
 * Get the 'type' of Revit file
 * Type determined based on the file name
 * At the moment we have 3 types:
 * - catalog
 * - settings
 * - template
 */
export const getRevitFileType = (file: UploadFile): TRevitFileType => {
  if (file.name.toLocaleLowerCase().endsWith(SETTINGS_SUFFIX)) {
    return "settings";
  }

  if (file.name.toLocaleLowerCase().endsWith(TEMPLATE_SUFFIX)) {
    return "template";
  }

  return "catalog";
};

/**
 * Prepare payload that will be used in server request for upload Revit files to server
 */
export const prepareUploadFilesPayload = (fileList: UploadFile[]): FormData => {
  const bodyFormData = new FormData();
  const files = [];
  const blobs = [];
  let fileId = -1;

  fileList.forEach((f: UploadFile) => {
    const obj = {
      id: fileId,
      kind: kindFileRaw.id,
      lastModified: f.lastModified,
      name: f.name,
      fileSize: f.size,
      mimeType: "application/octet-stream",
      filePath: getRevitFileType(f),
    };

    files.push(obj);
    blobs.push([`${fileId}`, f.originFileObj]);
    fileId -= 1;
  });

  const envelope = {
    meta: { v: 1 },
    data: files,
  };

  // 'envelope' should be placed before blobs in multipart/form-data
  // to allow server extract envelope data before handlings binary parts
  bodyFormData.append("envelope", JSON.stringify(envelope));
  blobs.forEach(([id, blob]) => {
    bodyFormData.append(id, blob);
  });

  return bodyFormData;
};
