export interface vtUser {
  id: number;
  name: string;
}

export class voUser implements vtUser {
  id: number;
  name: string;
}

type WhereUser = {
  byId: number;
};
export const listUser = async (where: WhereUser): Promise<vtUser[]> => {
  const _user = new voUser();
  _user.id = 10;
  _user.name = "Default";

  return [_user];
};
