import RoomManager from "../../managers/RoomManager/RoomManager";
import SceneUtils from "../../utils/SceneUtils";
import { ReferenceLine } from "../ReferenceLine";
import { CommandTypes } from "./CommandTypes";
import { RoomCommand } from "./RoomCommand";

export class StretchRoomCommand extends RoomCommand {
  constructor(
    entityId: string,
    private referenceLine: ReferenceLine,
    private delta: number
  ) {
    super(entityId);

    this.type = CommandTypes.StretchRoomCommand;
  }

  apply(roomManager: RoomManager): void {
    const soRoom = roomManager.getActiveFloorSoRoom(this.entityId);
    SceneUtils.stretchRoomByReferenceLine(soRoom, this.referenceLine, this.delta);

    roomManager.updateRoomsProperties([this.entityId]);
    roomManager.checkRoomsSharedObjects([this.entityId]);
  }

  undo(roomManager: RoomManager): void {
    const soRoom = roomManager.getActiveFloorSoRoom(this.entityId);
    SceneUtils.stretchRoomByReferenceLine(soRoom, this.referenceLine, -this.delta);

    roomManager.updateRoomsProperties([this.entityId]);
    roomManager.checkRoomsSharedObjects([this.entityId]);
  }
}
