import "./ModalSystemSettings.sass";
import { observer } from "mobx-react-lite";
import { Modal, Tabs } from "antd";
import { useEffect, useState } from "react";
import RoomManagementTab from "./RoomManagementTab";
import SettingsTab, { SubCategorySettings } from "./SettingsTab";
import { useForm } from "antd/lib/form/Form";
import { settings } from "../../../../entities/settings";
import { getDisplayValue } from "../../../../helpers/measures";
import { defaultSettings, defaultUnits } from "../../../../entities/settings/defaults";
import { TSettingsValues } from "../../../../entities/settings/types";
import { CorePlansStatisticsTab } from "./CorePlansStatisticsTab";

const defaultActiveTabKey = "roomManagement";
export const requestDataSettings: SubCategorySettings[] = [
  {
    subCategoryDisplayName: "Ranges",
    subCategoryName: "corePlanRangesSettings",
    settings: [
      "storiesMax",
      "netFloorToCeilingMin",
      "netFloorToCeilingMax",
      "netFloorToCeilingIncrement",
      null,
      "bedroomMin",
      "bedroomMax",
      "bathroomMin",
      "bathroomMax",
      "carsMax",
    ],
  },
  {
    subCategoryDisplayName: "Defaults",
    subCategoryName: "corePlanDefaults",
    settings: ["numOfStories", "netFloorToCeiling", "netCeilingToFloor", "numOfBedrooms", "numOfBathrooms", "numOfCars"],
  },
  {
    subCategoryName: "corePlanDefaults",
    settings: [
      "minBuildingNetArea",
      "maxBuildingGrossArea",
      "lotArea",
      "lotWidth",
      "lotLength",
      "lotFrontSetback",
      "lotBackSetback",
      "lotSideSetback",
      "lotFront2Setback",
    ],
  },
];

export const validationSettings: SubCategorySettings[] = [
  {
    subCategoryDisplayName: "Structural",
    subCategoryName: "validationSettings",
    settings: [
      "gravityMaxFloorSpan",
      "gravityMaxBeamSpan",
      "gravityMaxCantileverSpan",
      "shearF1cap",
      "shearF2cap",
      "shearSeismicFactorCd",
      "shearComboDesignFactor",
      "shearPreliminaryFactor",
      null,
      "exteriorWallWeight",
      "interiorWallWeight",
      "beamWeight",
      "floorWeight",
      "roofWeight",
    ],
  },
  {
    subCategoryDisplayName: "Common",
    subCategoryName: "validationSettings",
    settings: [
      "openingAlignmentMinGap",
      "areaCalculationExteriorOffset",
      "gridCellSizeForNewCorePlans",
      "stretchAllowance",
      "frameSize",
      "minimalWallSegmentLength",
      "roofDefaultSlope",
      "roofDefaultOverhang",
      "minimumGableSeparation",
      "windowEdgeOffset",
      "doorEdgeOffset",
    ],
  },
  {
    subCategoryDisplayName: "MEP",
    subCategoryName: "validationSettings",
    className: "mep-category-settings",
    settings: ["openingStructuralTolerance", "edgeOfExteriorWallTolerance", "firstFloorPlumbingPointMaxOffset", "pipeFallTolerance"],
  },
];

export const displaySettings: SubCategorySettings[] = [
  {
    subCategoryDisplayName: "Canvas",
    subCategoryName: "webAppUISettings",
    settings: [
      "selectionColor",
      "highlightColor",
      "wallsColor",
      "floorsColor",
      "openingsColor",
      "gridPrimaryColor",
      "gridSecondaryColor",
      "lotLinesColor",
      "lotSetbackColor",
      "obsoleteRoomsColor",
      "glassColor",
      "doorColor",
      "roofSurface",
      "roofEdge",
    ],
  },
  {
    subCategoryDisplayName: "Validations",
    subCategoryName: "webAppUISettings",
    settings: [
      "gravityExteriorLBColor",
      "gravityStackedLBColor",
      "gravityNonLBColor",
      "gravityBeamColor",
      "shearWalls",
      "shearObstructionOnCurrentLevel",
      "shearObstructionOnLevelBelow",
    ],
  },

  {
    subCategoryDisplayName: "Areas Take Off",
    subCategoryName: "webAppUISettings",
    settings: ["livingSpaces", "garage", "porchesAndPatio", "roof"],
  },
];

let ModalSystemSettings = ({ isOpen, handleModalClose, handleBulkUploadModalOpen }) => {
  const [activeTabKey, setActiveTabKey] = useState<string>(defaultActiveTabKey);
  const [form] = useForm();

  useEffect(() => {
    if (!isOpen) {
      return;
    }
    setFormValues(settings.values);
  }, [settings.values, isOpen, form]);

  if (!isOpen) return <></>;

  const handleTabClick = key => {
    setActiveTabKey(key);
  };
  const onCloseClick = () => {
    setActiveTabKey(defaultActiveTabKey);
    handleModalClose();
  };
  const setFormValues = (settingsValues: TSettingsValues) => {
    const formSettings = {};

    for (const settingCategoryKey in settingsValues) {
      if (typeof settingsValues[settingCategoryKey] !== "object") {
        continue;
      }

      formSettings[settingCategoryKey] = {};

      for (const settingKey in defaultSettings[settingCategoryKey]) {
        const setting = settingsValues[settingCategoryKey][settingKey] ?? defaultSettings[settingCategoryKey][settingKey];
        formSettings[settingCategoryKey][settingKey] = getDisplayValue(setting, defaultUnits[settingCategoryKey][settingKey]);
      }
    }
    form.setFieldsValue(formSettings);
  };

  const corePlanTabs = [
    {
      label: "Room Management",
      key: defaultActiveTabKey,
      children: <RoomManagementTab handleModalClose={onCloseClick} handleBulkUploadModalOpen={handleBulkUploadModalOpen} />,
    },
    {
      label: "Request data",
      key: "requestData",
      children: <SettingsTab handleModalClose={onCloseClick} tabSettings={requestDataSettings} form={form} resetToDefaults={setFormValues} />,
    },
    {
      label: "Validations",
      key: "validations",
      children: <SettingsTab handleModalClose={onCloseClick} tabSettings={validationSettings} form={form} resetToDefaults={setFormValues} />,
    },
    {
      label: "Display",
      key: "display",
      children: <SettingsTab handleModalClose={onCloseClick} tabSettings={displaySettings} form={form} resetToDefaults={setFormValues} />,
    },
    {
      label: "Core Plans Statistics",
      key: "corePlansStatistics",
      children: <CorePlansStatisticsTab />,
    },
  ];

  return (
    <Modal className="system-settings-modal" open={isOpen} onCancel={onCloseClick} title="System settings" footer={[]} destroyOnClose>
      <Tabs className="tabs-settings" items={corePlanTabs} activeKey={activeTabKey} onTabClick={handleTabClick} />
    </Modal>
  );
};

ModalSystemSettings = observer(ModalSystemSettings);
export default ModalSystemSettings;
