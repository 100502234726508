import { showToastMessage } from "../../helpers/messages";
import { Wall, WallType } from "./types";
import { parseRevitWalls } from "./utils";

export class CatalogSettings {
  public walls: Record<WallType, Wall>;

  public async loadSettings() {
    try {
      const catalogSettingsJson = await fetch("/settings/catalogSettings.json").then(data => data.json());
      this.walls = parseRevitWalls(catalogSettingsJson.walls);
    } catch (error) {
      showToastMessage("Error", "Error occurred on settings loading.");
    }
  }
}

const catalogSettings = new CatalogSettings();
(window as any).catalogSettings = catalogSettings; // TODO remove. Temporary used for testing.

export default catalogSettings;
