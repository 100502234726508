import { EPSILON, ROUND_PRECISION } from "../consts";

export default class MathUtils {
  static areNumberArraysEqual(left: Array<number>, right: Array<number>, epsilon = EPSILON): boolean {
    if (left.length !== right.length) return false;

    let areArraysEqual = true;

    for (let idx = 0; idx < left.length; idx += 1) {
      areArraysEqual &&= MathUtils.areNumbersEqual(left[idx], right[idx], epsilon);
      if (!areArraysEqual) return false;
    }
    return true;
  }
  static areNumbersEqual(left: number, right: number, epsilon = EPSILON): boolean {
    return Math.abs(left - right) < epsilon;
  }
  static isNumberLessOrEqual(left: number, right: number, epsilon = EPSILON): boolean {
    return left < right + epsilon;
  }
  static isNumberGreaterOrEqual(left: number, right: number, epsilon = EPSILON): boolean {
    return left > right - epsilon;
  }
  static isNumberInRange(num: number, rangeMin: number, rangeMax: number, epsilon = EPSILON): boolean {
    if (rangeMin > rangeMax) {
      const tmp = rangeMin;
      rangeMin = rangeMax;
      rangeMax = tmp;
    }

    return rangeMin - epsilon < num && num < rangeMax + epsilon;
  }

  static round(num: number, e: number = ROUND_PRECISION): number {
    return Math.round(num * e) / e;
  }

  /**
   * Thin wrapper over GeometryUtils.round, removes inaccuracy of floating point numbers' binary representation
   */
  static preciseRound(num: number, precision: number): number {
    const rounded = MathUtils.round(num, precision);

    // Removing an inaccuracy of representation
    const digits = Math.trunc(precision).toString().length;
    const res = Number(rounded.toFixed(digits));
    return res;
  }

  static ceil(num: number, e: number = ROUND_PRECISION): number {
    return Math.ceil(num * e) / e;
  }

  static floor(num: number, e: number = ROUND_PRECISION): number {
    return Math.floor(num * e) / e;
  }

  static clamp(value: number, min: number, max: number): number {
    return Math.max(min, Math.min(max, value));
  }
}
