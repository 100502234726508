import log from "loglevel";
import { IReactionDisposer, reaction } from "mobx";
import * as THREE from "three";
import { appModel } from "../../../models/AppModel";
import { Background } from "../../../models/Background";
import { CursorStyle } from "../../../models/CursorStyle";
import { Floor } from "../../../models/Floor";
import { CorePlan } from "../../../models/CorePlan";
import { Vector3V } from "../../../models/Vector3V";
import { addAuthParam } from "../../../services/api/utilities/AuthHeader";
import { prompt } from "../../../ui/components/common/Prompt";
import { DragMode } from "../../models/DragMode";
import { Keys } from "../../models/Keys";
import { SceneEditorMode } from "../../models/SceneEditorMode";
import { SceneEntityType } from "../../models/SceneEntityType";
import { BackgroundCommand } from "../../models/commands/BackgroundCommand";
import { ChangeBackgroundCommand } from "../../models/commands/ChangeBackgroundCommand";
import { RotateBackgroundCommand } from "../../models/commands/RotateBackgroundCommand";
import { ScaleBackgroundCommand } from "../../models/commands/ScaleBackgroundCommand";
import { TranslateBackgroundCommand } from "../../models/commands/TranslateBackgroundCommand";
import GeometryUtils from "../../utils/GeometryUtils";
import MathUtils from "../../utils/MathUtils";
import SceneUtils from "../../utils/SceneUtils";
import UnitsUtils from "../../utils/UnitsUtils";
import { BackgroundCommandManager } from "../CommandManager/BackgroundCommandManager";
import { CommandManagerEvents, Scope } from "../CommandManager/CommandManager";
import RoomManager from "./RoomManager";
import { feetInchesFractionToInches } from "../../../helpers/measures";

export default class BackgroundManager {
  private reactions: IReactionDisposer[] = [];
  private activeFloorReactions: IReactionDisposer[] = [];
  private corePlan: CorePlan = null;
  private commandManager: BackgroundCommandManager = new BackgroundCommandManager(this);

  private soRoot = new THREE.Group();
  private dragMode: DragMode = DragMode.none;
  private areSubscriptionsDisabled: boolean = false;
  private checkpoint: { backgrounds: Background[]; soBackgrounds: THREE.Object3D[] } = null;

  constructor(public roomManager: RoomManager) {
    this.soRoot.name = "Editor Background Manager Root";
    this.roomManager.getSoRoot().add(this.soRoot); // ???

    this.commandManager.addEventListener(CommandManagerEvents.ScopesChanged, e => {
      if (appModel.sceneEditorMode !== SceneEditorMode.Background) {
        return;
      }

      if (e.scopes.some((scope: [string, Scope<BackgroundCommand>]) => scope[1].undos.length)) {
        appModel.setPendingChanges(true);
      } else {
        appModel.setPendingChanges(false);
      }
    });

    reaction(() => appModel.activeCorePlan, this.onActiveCorePlanChanged.bind(this));
    reaction(() => appModel.sceneEditorMode, this.onEditModeChanged.bind(this));
    this.onActiveCorePlanChanged(appModel.activeCorePlan);
  }

  public onMouseDown(e: MouseEvent) {
    if (this.dragMode === DragMode.scalingFloorBackground) {
      const scalingLine = this.getScalingLine();
      if (!scalingLine) {
        this.soRoot.add(SceneUtils.createFloorBackgroundScalingLine(this.roomManager.intersectionPoint.clone(), this.roomManager.intersectionPoint.clone()));
      }
    }
  }

  public onMouseMove(e: MouseEvent) {
    if (this.roomManager.baseManager.isMouseHandlersEnabled) {
      if (this.dragMode === DragMode.none) {
        if (this.roomManager.baseManager.isMouseDown && e.buttons === 1) {
          const intersectedBackground = this.getIntersectedBackground();
          if (intersectedBackground) {
            this.dragMode = DragMode.movingFloorBackground;
            intersectedBackground.userData.startPosition = intersectedBackground.position.clone();
            const mousePosition = this.roomManager.intersectionPoint.clone();
            intersectedBackground.userData.moveOffset = mousePosition.clone().sub(intersectedBackground.position);
          }
        }
      } else if (this.dragMode === DragMode.movingFloorBackground) {
        const soBackground = this.findSoBackground(appModel.activeFloor.background?.imageFileId);
        if (soBackground) {
          this.updateDragPosition(soBackground);
          this.updateActiveBackgroundProperties();
        }

        (e.target as HTMLCanvasElement).style.cursor = CursorStyle.Pointer;
      } else if (this.dragMode === DragMode.scalingFloorBackground) {
        this.updateScalingLine(e.shiftKey);
      }
    }

    if (this.dragMode === DragMode.scalingFloorBackground) {
      (e.target as HTMLCanvasElement).style.cursor = CursorStyle.Crosshair;
    } else if (this.dragMode === DragMode.none) {
      (e.target as HTMLCanvasElement).style.cursor = CursorStyle.Default;
    }
  }

  public onMouseLeave(e: MouseEvent): void {
    if (!this.roomManager.baseManager.isMouseHandlersEnabled) {
      return;
    }

    this.handleDragFinish(false);
  }

  public onMouseUp(e: MouseEvent) {
    if (!this.roomManager.baseManager.isMouseHandlersEnabled) {
      return;
    }

    this.handleDragFinish();
  }

  public onKeyUp(e: KeyboardEvent) {
    if (appModel.isViewOnlyMode) {
      return;
    }
    if (!e.ctrlKey && !e.metaKey) {
      return;
    }

    switch (e.code) {
      case Keys.Y: {
        this.redo();
        break;
      }
      case Keys.Z: {
        this.undo();
        break;
      }
    }
  }

  public findSoBackground(imageFileId: string): THREE.Object3D | undefined {
    return this.soRoot.children.find(child => child.userData.imageFileId === imageFileId);
  }

  public setAreSubscriptionsDisabled(value: boolean): void {
    this.areSubscriptionsDisabled = value;
  }

  public updateActiveBackgroundProperties(): void {
    const soBackground = this.findSoBackground(appModel.activeFloor.background.imageFileId);
    this.populateBackgroundProperties(appModel.activeFloor.background, soBackground);
    this.updateSimilarBackgrounds(appModel.activeFloor.background);
  }

  public changeFloor(floor?: Floor): void {
    this.soRoot.children.forEach(child => (child.visible = false));
    this.unsubscribe(this.activeFloorReactions);

    if (floor) {
      this.activeFloorReactions.push(reaction(() => appModel.activeFloor.background, this.onActiveFloorBackgroundChanged.bind(this)));
      if (floor.background) {
        const soBackground = this.findSoBackground(floor.background.imageFileId);
        if (soBackground) {
          soBackground.visible = true;
        }
      }
    }
  }
  public async changeBackground(background: Background, oldBackground: Background): Promise<void> {
    if (oldBackground) {
      const soOldBackground = this.findSoBackground(oldBackground.imageFileId);

      if (!this.corePlan.floors.some(floor => floor.background?.imageFileId === oldBackground.imageFileId)) {
        if (soOldBackground) {
          this.soRoot.remove(soOldBackground);
        }
      } else {
        soOldBackground.visible = false;
      }
    }
    try {
      const soBackground = await this.updateSoBackground(background);
      if (soBackground) {
        soBackground.visible = true;
      }
    } catch (error: any) {
      //log.error(error)
    }
  }

  public toggleScaleMode(): void {
    if (!appModel.activeFloor.background) {
      return;
    }

    if (this.dragMode === DragMode.none) {
      appModel.setIsBackgroundScalingEnabled(true);
      this.dragMode = DragMode.scalingFloorBackground;
    } else if (this.dragMode === DragMode.scalingFloorBackground) {
      appModel.setIsBackgroundScalingEnabled(false);
      this.dragMode = DragMode.none;
    }
  }

  public rotateBackground(isClockwise: boolean): void {
    const angle = isClockwise ? -Math.PI / 2 : Math.PI / 2;

    this.commandManager.apply(new RotateBackgroundCommand(appModel.activeFloor.background.imageFileId, angle));
  }

  public undo(): void {
    if (this.dragMode === DragMode.none) {
      this.commandManager.undo();
    }
  }
  public redo(): void {
    if (this.dragMode === DragMode.none) {
      this.commandManager.redo();
    }
  }

  public setCheckpoint(): void {
    this.commandManager.clearScopes();
    this.commandManager.setScope(appModel.activeFloor.id);
    this.checkpoint?.soBackgrounds?.forEach(it => GeometryUtils.disposeObject(it));

    this.checkpoint = {
      backgrounds: this.corePlan.floors.map(floor => floor.background?.clone() || null),
      soBackgrounds: this.soRoot.children.filter(it => it.userData.type === SceneEntityType.FloorBackground).map(so => GeometryUtils.deepClone(so)),
    };
  }
  public rollbackChanges(): void {
    this.unsubscribe(this.activeFloorReactions);

    GeometryUtils.disposeObject(this.soRoot);
    this.corePlan.floors.forEach((floor, idx) => floor.setBackground(this.checkpoint ? this.checkpoint.backgrounds[idx] : null));
    this.checkpoint?.soBackgrounds?.forEach(so => this.soRoot.add(so));
    this.checkpoint = null;

    this.changeFloor(appModel.activeFloor);
  }

  /**
   * Perform necessary operations based on the current drag mode and reset the drag mode to none.
   * @param {boolean} isMouseUpEvent: Indicates if the action was performed releasing the mouse button.
   */
  private handleDragFinish(isMouseUpEvent: boolean = true): void {
    if (this.dragMode === DragMode.none) {
      return;
    }

    if (this.dragMode === DragMode.scalingFloorBackground) {
      if (isMouseUpEvent) {
        this.finishBackgroundScaling();
      } else {
        this.disposeScalingLine();
        return;
      }
    } else if (this.dragMode === DragMode.movingFloorBackground) {
      const soBackground = this.findSoBackground(appModel.activeFloor.background.imageFileId);
      const delta = soBackground.position.clone().sub(soBackground.userData.startPosition);

      soBackground.position.copy(soBackground.userData.startPosition.add(delta));
      delete soBackground.userData.startPosition;
      soBackground.updateMatrixWorld();

      this.commandManager.add(new TranslateBackgroundCommand(soBackground.userData.imageFileId, delta));
    }

    this.dragMode = DragMode.none;
    this.roomManager.controls.noPan = false;
    this.roomManager.baseManager.setCursorStyle(CursorStyle.Default);
    appModel.setIsBackgroundScalingEnabled(false);
  }

  private onActiveCorePlanChanged(corePlan?: CorePlan): void {
    this.corePlan = corePlan;

    this.commandManager.clearScopes();
    this.unsubscribe(this.activeFloorReactions);
    this.unsubscribe(this.reactions);

    GeometryUtils.disposeObject(this.soRoot);

    if (this.corePlan) {
      Promise.all(this.corePlan.floors.map(floor => this.updateSoBackground(floor.background))).then(() => {
        this.reactions.push(reaction(() => appModel.activeFloor, this.onActiveFloorChanged.bind(this), { fireImmediately: true }));
        this.reactions.push(reaction(() => appModel.showBackground, this.onShowBackgroundChanged.bind(this), { fireImmediately: true }));
      });
    }
  }
  private onActiveFloorChanged(floor?: Floor, oldFloor?: Floor): void {
    if (this.areSubscriptionsDisabled) {
      return;
    }

    this.changeFloor(floor);
    this.commandManager.setScope(floor?.id);
  }
  private onActiveFloorBackgroundChanged(background: Background, oldBackground: Background): void {
    if (this.areSubscriptionsDisabled) {
      return;
    }

    this.commandManager.apply(new ChangeBackgroundCommand(background, oldBackground));

    if (!background) {
      this.dragMode = DragMode.none;
      appModel.setIsBackgroundScalingEnabled(false);
    }
  }
  private onEditModeChanged(mode: SceneEditorMode, oldMode: SceneEditorMode): void {
    if (mode === SceneEditorMode.Background) {
      this.setCheckpoint();
      this.updateBackgroundVisibility(true);
    }

    if (oldMode === SceneEditorMode.Background) {
      this.dragMode = DragMode.none;
      appModel.setIsBackgroundScalingEnabled(false);
      this.updateBackgroundVisibility(appModel.showBackground);
    }
  }
  private onShowBackgroundChanged(showBackground: boolean): void {
    if (appModel.sceneEditorMode === SceneEditorMode.Background) {
      return;
    }

    this.updateBackgroundVisibility(showBackground);
  }

  private unsubscribe(reactions: IReactionDisposer[]): void {
    reactions.forEach(r => r());
    reactions.length = 0;
  }

  private populateBackgroundProperties(background: Background, soBackground: THREE.Object3D): void {
    const size = GeometryUtils.getGeometryBoundingBox3D(soBackground).getSize(new THREE.Vector3());

    background.setPosition(new Vector3V(soBackground.position.x, background.position.y));
    background.setPosition(new Vector3V(background.position.x, soBackground.position.y));
    background.setWidth(size.y);
    background.setLength(size.x);

    let angle = MathUtils.round(THREE.MathUtils.radToDeg(soBackground.rotation.z), 1);
    if (angle % 180 === 0) {
      angle = Math.abs(angle);
    }
    background.setRotation(angle);
    background.setScale(new Vector3V(soBackground.scale.x, soBackground.scale.y, soBackground.scale.z));
  }

  private updateSimilarBackgrounds(background: Background): void {
    appModel.activeCorePlan.floors.forEach(floor => {
      if (floor.background && floor.background.imageFileId === background.imageFileId && floor.background.floorId !== background.floorId) {
        floor.background.setPosition(background.position.clone());
        floor.background.setRotation(background.rotation);
        floor.background.setScale(background.scale.clone());
      }
    });
  }

  private async updateSoBackground(background: Background): Promise<THREE.Object3D> {
    if (!background) {
      return;
    }

    let soBackground = this.findSoBackground(background.imageFileId);

    if (!soBackground) {
      const backgroundFile = appModel.activeCorePlan.files.find(file => file.id === background.imageFileId);
      if (!backgroundFile) {
        // throw BackgroundError.alert('Background file not exist')
        log.error(
          `Background file not exist: imageFileId ${background.imageFileId} in`,
          appModel.activeCorePlan.files.map(file => file.id)
        );
      } else {
        soBackground = await SceneUtils.createFloorBackground(addAuthParam(backgroundFile.URL));
        soBackground.userData.imageFileId = background.imageFileId;

        soBackground.position.set(background.position.x, background.position.y, 0.0);
        soBackground.rotation.z = THREE.MathUtils.degToRad(background.rotation);
        if (!MathUtils.areNumbersEqual(background.scale.x, 0.0) && !MathUtils.areNumbersEqual(background.scale.y, 0.0)) {
          soBackground.scale.set(background.scale.x, background.scale.y, 1.0);
        }
        soBackground.updateMatrixWorld();

        this.populateBackgroundProperties(background, soBackground);
        this.soRoot.add(soBackground);
      }
    }

    return soBackground;
  }

  private updateBackgroundVisibility(visible: boolean): void {
    this.soRoot.visible = visible;
  }

  private getIntersectedBackground(): THREE.Object3D {
    const soBackground = this.findSoBackground(appModel.activeFloor.background?.imageFileId);
    if (soBackground) {
      const bb = GeometryUtils.getGeometryBoundingBox2D(soBackground);
      const isIntersected = this.roomManager.raycaster.ray.intersectsBox(bb);
      if (isIntersected) {
        return soBackground;
      }
    }

    return null;
  }
  private getScalingLine(): THREE.Line {
    return this.soRoot.children.find(child => child.userData.type === SceneEntityType.FloorBackgroundScalingLine) as THREE.Line;
  }
  private disposeScalingLine(): void {
    const scalingLine = this.getScalingLine();
    if (scalingLine) {
      this.soRoot.remove(scalingLine);
      GeometryUtils.disposeObject(scalingLine);
    }
  }

  private updateDragPosition(soBackground: THREE.Object3D): void {
    const intersectionPoint = this.roomManager.intersectionPoint.clone();

    const newPosition = intersectionPoint.clone().sub(soBackground.userData.moveOffset || new THREE.Vector3());
    soBackground.position.setX(newPosition.x);
    soBackground.position.setY(newPosition.y);
    soBackground.updateMatrixWorld();
  }

  private updateScalingLine(isShiftPressed: boolean): void {
    const scalingLine = this.getScalingLine();
    if (scalingLine) {
      const geometry = scalingLine.geometry as THREE.BufferGeometry;
      const points = GeometryUtils.getPointsPositions(geometry);
      const startPoint = points[0];
      const endPoint = this.roomManager.intersectionPoint.clone();

      // If the Shift key is pressed, constrain the movement to an orthogonal direction
      if (isShiftPressed) {
        const deltaX = Math.abs(endPoint.x - startPoint.x);
        const deltaY = Math.abs(endPoint.y - startPoint.y);

        if (deltaX > deltaY) {
          endPoint.setY(startPoint.y);
        } else {
          endPoint.setX(startPoint.x);
        }
      }

      points.splice(1, 1);
      points.push(endPoint);
      geometry.setFromPoints(points);
      geometry.computeBoundingSphere();
      scalingLine.computeLineDistances();
    }
  }

  private async finishBackgroundScaling(): Promise<void> {
    const scalingLine = this.getScalingLine();
    if (!scalingLine) {
      return;
    }

    const soBackground = this.findSoBackground(appModel.activeFloor.background?.imageFileId);
    const points = GeometryUtils.getPointsPositions(scalingLine.geometry as THREE.BufferGeometry);
    const distance = points[0].distanceTo(points[1]);

    this.disposeScalingLine();

    if (!soBackground || MathUtils.areNumbersEqual(distance, 0)) {
      return;
    }

    const userDistance = await prompt({
      title: "Enter expected length for the selected interval:",
      placeholder: "A’-B C/D”",
    }).then(text => {
      const dist = feetInchesFractionToInches(text);
      return dist ?? 0;
    });

    if (!userDistance) {
      return;
    }

    const bb = GeometryUtils.getGeometryBoundingBox2D(soBackground);
    bb.applyMatrix4(new THREE.Matrix4().extractRotation(soBackground.matrixWorld.clone().invert()));

    const size = bb.getSize(new THREE.Vector3());
    const scaleFactor = userDistance / distance;
    const scale = (scaleFactor * size.y) / UnitsUtils.getFloorBackgroundInitialSize();
    const aspectRatio = size.x / size.y;

    this.commandManager.apply(
      new ScaleBackgroundCommand(soBackground.userData.imageFileId, new THREE.Vector3(aspectRatio * scale, scale, 1.0), soBackground.scale.clone())
    );
  }
}
