import { Modal } from "antd";
import { observer } from "mobx-react-lite";
import { makeAutoObservable } from "mobx";
import { Floor } from "../../../models/Floor";
import { appModel } from "../../../models/AppModel";
import { ErrorReason } from "../../../services";
import { markHidden } from "../../../services/allKinds";
import { RoomType, updateRoomTypeMark } from "../../../models/RoomType";
import { CorePlan } from "../../../models/CorePlan";

class ModalErrorFloorState {
  isModalErrorFloorSave: boolean = false;
  error: ErrorReason;
  text: string;
  floor?: Floor;
  corePlan?: CorePlan;
  roomTypeId?: string;

  constructor() {
    makeAutoObservable(this);
    this.text =
      "The floors uses some room types which have been removed from the catalog. These rooms need to be removed from floor plan before saving. Do you want to remove unavailable room type instances?";
  }

  openModalErrorFloorSave(floor: Floor | CorePlan, error: ErrorReason) {
    if (error.kind !== RoomType.kind.id) return;

    const isFloor = floor instanceof Floor;

    this.corePlan = isFloor ? undefined : floor;
    this.floor = isFloor ? floor : undefined;
    this.error = error;
    this.roomTypeId = error.value;
    this.isModalErrorFloorSave = true;
    updateRoomTypeMark([{ id: this.roomTypeId, mark: markHidden.id, kind: error.kind }]);
  }

  okModalErrorFloorSave(): void {
    this.isModalErrorFloorSave = false;

    const removeFromFloor = (floor: Floor) => {
      floor.getRoomsByType(this.roomTypeId).forEach(room => appModel.baseManager.roomManager.deleteRoomByRoomId(room.id));
    };

    if (this.floor) removeFromFloor(this.floor);
    else if (this.corePlan) this.corePlan.floors.forEach(removeFromFloor);

    this.floor = undefined;
    this.corePlan = undefined;
    this.error = undefined;
  }

  closeModalErrorFloorSave(): void {
    this.isModalErrorFloorSave = false;

    this.floor = undefined;
    this.corePlan = undefined;
    this.error = undefined;
  }
}

const modalErrorFloorState = new ModalErrorFloorState();

export const openErrorFloorSave = (floor: Floor | CorePlan, error: ErrorReason) => {
  modalErrorFloorState.openModalErrorFloorSave(floor, error);
};

function closeModal() {
  modalErrorFloorState.closeModalErrorFloorSave();
}

function okModal() {
  modalErrorFloorState.okModalErrorFloorSave();
}

let ModalErrorFloorSave = () => {
  return (
    <Modal
      data-testid="modal-error-floor-save"
      className="modal-error-floor-save"
      title="Error floor saving"
      open={modalErrorFloorState.isModalErrorFloorSave}
      onCancel={closeModal}
      onOk={okModal}
      centered
      destroyOnClose
    >
      <div>{modalErrorFloorState.text}</div>
    </Modal>
  );
};

ModalErrorFloorSave = observer(ModalErrorFloorSave);

export default ModalErrorFloorSave;
