/**
 * Revit files (aka DEngine catalog files) model
 */

import { UploadFile } from "antd/lib/upload";
import log from "loglevel";
import { IObservableValue, computed, makeObservable, observable, runInAction } from "mobx";
import MathUtils from "../../editor/utils/MathUtils";
import { showToastMessage } from "../../helpers/messages";
import { getAxiosRequestConfig } from "../../services/api/utilities";
import apiProvider from "../../services/api/utilities/Provider";
import axios from "../../services/api/utilities/axios-wrapper";
import { TFilesUploadPercentage, TRevitFiles } from "./types";
import { extractRevitFilesFromResponse, isServerResponseValid, prepareUploadFilesPayload } from "./utils";

const LOADING_ERROR_MESSAGE = "Error occurred on DEngine catalog files loading";
const UPLOADING_ERROR_MESSAGE = "Error occurred on DEngine catalog files uploading";
const DELETING_ERROR_MESSAGE = "Error occurred on DEngine catalog files deleting";

export class RevitFiles {
  private _files: TRevitFiles = observable({
    catalogFile: null,
    settingsFile: null,
    templateFile: null,
  });

  private _uploadPercentage: IObservableValue<TFilesUploadPercentage> = observable.box(null);

  constructor() {
    makeObservable(this, {
      isFilesExists: computed,
      catalogVersion: computed,
      files: computed,
      uploadPercentage: computed,
    });
  }

  /**
   * Predicate, returns true if Revit files exists
   */
  public get isFilesExists(): boolean {
    return !!this._files.catalogFile && !!this._files.settingsFile && !!this._files.templateFile;
  }

  public get catalogVersion(): string {
    const name = this._files.catalogFile?.name;
    const match = /\w+-(?<version>[\d.]+)(?=\.\w)/.exec(name);
    return match?.groups.version ?? null;
  }

  /**
   * Returns current Revit files [descriptions]
   */
  public get files(): TRevitFiles {
    return this._files;
  }

  /**
   * Returns current progress of Revit files uploading progress
   */
  public get uploadPercentage(): TFilesUploadPercentage {
    return this._uploadPercentage.get();
  }

  /**
   * Load Revit files [descriptions] from server
   */
  public async loadFiles(): Promise<boolean> {
    try {
      const url = RevitFiles.getEndpointUrl();
      const axiosConfig = getAxiosRequestConfig();
      const response = await axios.get(url, axiosConfig);

      if (response.status !== 200 || !isServerResponseValid(response)) {
        showToastMessage("Error", LOADING_ERROR_MESSAGE);
        return false;
      }

      const revitFiles: TRevitFiles = extractRevitFilesFromResponse(response);
      runInAction(() => {
        this._files.catalogFile = revitFiles.catalogFile;
        this._files.settingsFile = revitFiles.settingsFile;
        this._files.templateFile = revitFiles.templateFile;
      });

      return true;
    } catch (err) {
      log.error(err);
      showToastMessage("Error", LOADING_ERROR_MESSAGE);
      return false;
    }
  }

  /**
   * Upload Revit files to server
   */
  public async uploadFiles(fileList: UploadFile[]): Promise<boolean> {
    try {
      if (!fileList?.length) {
        return false;
      }

      // Prepare payload
      const bodyFormData = prepareUploadFilesPayload(fileList);

      // Prepare request
      const url = RevitFiles.getEndpointUrl();
      const axiosConfig = getAxiosRequestConfig();
      axiosConfig["Content-Type"] = "multipart/form-data";

      axiosConfig.onUploadProgress = progressEvent => {
        const percentCompleted = (progressEvent.loaded * 100) / progressEvent.total;
        const rounderPercent = MathUtils.round(percentCompleted, 10);
        runInAction(() => {
          this._uploadPercentage.set(rounderPercent);
        });
      };

      // Upload Revit files
      const response = await axios.post(url, bodyFormData, axiosConfig);

      if (response.status !== 200 || !isServerResponseValid(response)) {
        showToastMessage("Error", UPLOADING_ERROR_MESSAGE);
        return false;
      }

      const revitFiles: TRevitFiles = extractRevitFilesFromResponse(response);
      runInAction(() => {
        this._files.catalogFile = revitFiles.catalogFile;
        this._files.settingsFile = revitFiles.settingsFile;
        this._files.templateFile = revitFiles.templateFile;
      });

      return true;
    } catch (err) {
      log.error(err);
      showToastMessage("Error", UPLOADING_ERROR_MESSAGE);
      return false;
    } finally {
      runInAction(() => {
        this._uploadPercentage.set(null);
      });
    }
  }

  /**
   * Delete Revit files from server
   */
  public async deleteFiles(): Promise<boolean> {
    try {
      if (!this.isFilesExists) {
        return false;
      }

      const query = `?ids=${this.files.catalogFile.id},${this.files.settingsFile.id},${this.files.templateFile.id}`;
      const url = `${RevitFiles.getEndpointUrl()}${query}`;

      const axiosConfig = getAxiosRequestConfig();
      const response = await axios.delete(url, axiosConfig);

      if (response.status !== 200) {
        showToastMessage("Error", DELETING_ERROR_MESSAGE);
        return false;
      }

      runInAction(() => {
        this._files.catalogFile = null;
        this._files.settingsFile = null;
        this._files.templateFile = null;
      });

      return true;
    } catch (err) {
      log.error(err);
      showToastMessage("Error", DELETING_ERROR_MESSAGE);
      return false;
    }
  }

  public static getEndpointUrl(): string {
    return `${apiProvider.host}files/revit/`;
  }
}

const revitFiles = new RevitFiles();

export default revitFiles;
