import * as THREE from "three";
import GeometryUtils from "../../utils/GeometryUtils";
import { appModel } from "../../../models/AppModel";
// import { IReactionDisposer, reaction } from "mobx";
import UnitsUtils from "../../utils/UnitsUtils";
import PreviewManager from "./PreviewManager";
import {
  GLOBAL_GRID_RENDER_ORDER,
  GRID_MAJOR_CELL_RATIO,
  //  GRID_MIN_CELL_SIZE_CUTOFF_3D,
  GRID_MINOR_CELL_RATIO,
} from "../../consts";
import { settings } from "../../../entities/settings";
import MathUtils from "../../utils/MathUtils";

export default class GridManager {
  // private reactions: IReactionDisposer[] = [];
  private soRoot: THREE.Group = new THREE.Group();

  private gridMinorLineMaterial = new THREE.LineDashedMaterial({
    dashSize: UnitsUtils.getGridMinorLineDashSize(),
    gapSize: UnitsUtils.getGridMinorLineDashSize(),
    color: settings.getColorNumber("gridSecondaryColor"),
    transparent: true,
    opacity: 1.0,
  }); // 0.5 unit
  private gridMediumLineMaterial = new THREE.LineBasicMaterial({
    color: settings.getColorNumber("gridSecondaryColor"),
    transparent: true,
    opacity: 1.0,
  }); // 1 unit
  private gridMajorLineMaterial = new THREE.LineBasicMaterial({
    color: settings.getColorNumber("gridPrimaryColor"),
    transparent: true,
    opacity: 1.0,
  }); // 5 units

  // helper parameters
  private sbb: THREE.Box3;
  private viewportSize: THREE.Vector3;
  private viewportCenter: THREE.Vector3;
  private cellSize: number;
  private minorCellSize: number;
  private majorCellSize: number;
  private minorCellSizePixels: number;
  private cellSizePixels: number;
  private minorCellsInMajorCell: number;
  private minorCellsCountX: number;
  private minorCellsCountY: number;
  private majorOffsetFactor: THREE.Vector3;
  //private textScale: number;

  constructor(public previewManager: PreviewManager) {
    this.soRoot.name = "Grid Manager Root";
    this.previewManager.soGridRoot.add(this.soRoot);

    // this.reactions.push(reaction(() => appModel.gridUnitSizeInches, this.onGridUnitSizeChanged.bind(this)));
    // this.reactions.push(reaction(() => appModel.showGrid, this.onShowGridChanged.bind(this)));
  }

  // private onGridUnitSizeChanged(): void {
  //   this.update();
  // }
  // private onShowGridChanged(): void {
  //   this.update();
  // }

  public reset(): void {
    GeometryUtils.disposeObject(this.soRoot);
  }
  public update(): void {
    this.reset();

    this.updateParameters();
    this.updateSoGrid();
  }

  private updateParameters(): void {
    this.sbb = GeometryUtils.getGeometryBoundingBox2D(this.previewManager.soRoomsRoot);
    this.viewportSize = this.sbb.getSize(new THREE.Vector3());
    this.viewportCenter = this.sbb.getCenter(new THREE.Vector3());

    // in scene units
    this.cellSize = UnitsUtils.getGridCellSize();
    this.minorCellSize = this.cellSize / GRID_MINOR_CELL_RATIO;
    this.majorCellSize = this.cellSize * GRID_MAJOR_CELL_RATIO;

    this.minorCellSizePixels = this.minorCellSize * GeometryUtils.getSceneUnitPixels(this.previewManager.camera, this.previewManager.baseManager.renderer);
    this.cellSizePixels = this.minorCellSizePixels * GRID_MINOR_CELL_RATIO;

    const extraMajorCellsCount = 5; // add extra major cell beyond the viewport
    this.minorCellsInMajorCell = GRID_MINOR_CELL_RATIO * GRID_MAJOR_CELL_RATIO;
    const extraMinorCellsCount = extraMajorCellsCount * this.minorCellsInMajorCell;

    // minor steps in both semi-axes
    this.minorCellsCountX = MathUtils.ceil(this.viewportSize.x / this.minorCellSize / 2.0, 1) + extraMinorCellsCount;
    this.minorCellsCountY = MathUtils.ceil(this.viewportSize.y / this.minorCellSize / 2.0, 1) + extraMinorCellsCount;

    this.majorOffsetFactor = new THREE.Vector3(
      MathUtils.round(this.viewportCenter.x / this.majorCellSize, 1),
      MathUtils.round(this.viewportCenter.y / this.majorCellSize, 1),
      0.0
    );

    //this.textScale = this.roomManager.getDistanceFactor();
  }
  private updateSoGrid(): void {
    //this.soGrid.visible = appModel.showGrid;

    // const texture = await new THREE.TextureLoader().loadAsync("/assets/grass.jpg");
    // const soPlane = new THREE.Mesh(
    //   new THREE.PlaneBufferGeometry(this.viewportSize.x, this.viewportSize.y),
    //   //new THREE.MeshBasicMaterial({ color: 0xff0000, transparent: true, opacity: 1.0 })
    //   new THREE.MeshBasicMaterial({ map: texture })
    // );
    // soPlane.name = "Global Background Plane";
    // //soPlane.renderOrder = GLOBAL_BACKGROUND_RENDER_ORDER;
    // soPlane.geometry.computeBoundingBox();
    // this.soGrid.add(soPlane);

    // vertical lines
    for (let idx = -this.minorCellsCountX; idx <= this.minorCellsCountX; idx++) {
      this.addGridLine(idx, true);
    }
    // horizontal lines
    for (let idx = -this.minorCellsCountY; idx <= this.minorCellsCountY; idx++) {
      this.addGridLine(idx, false);
    }

    // grid is calculated relative to (0; 0);
    // need to shift it regarding the viewport origin offset, proportionally to integer major cells count
    this.soRoot.position.copy(this.majorOffsetFactor.clone().multiplyScalar(this.majorCellSize));
  }
  private addGridLine(idx: number, isVerticalLine: boolean): void {
    const isMajorLine = idx % (GRID_MAJOR_CELL_RATIO * 2) == 0;
    const isMediumLine = !isMajorLine && idx % 2 == 0;
    const isMinorLine = !isMajorLine && !isMediumLine;

    const isMinorLineVisible = false; //this.minorCellSizePixels >= GRID_MIN_CELL_SIZE_CUTOFF_3D;
    const isMediumLineVisible = true; //this.cellSizePixels >= GRID_MIN_CELL_SIZE_CUTOFF_3D;

    if (isMinorLine && !isMinorLineVisible) {
      return;
    }
    if (isMediumLine && !isMediumLineVisible) {
      return;
    }

    let vertices: THREE.Vector3[];
    if (isVerticalLine) {
      const x = this.minorCellSize * idx;
      const y = this.minorCellSize * this.minorCellsCountY;
      vertices = [new THREE.Vector3(x, -y, 0.0), new THREE.Vector3(x, y, 0.0)];
    } else {
      const y = this.minorCellSize * idx;
      const x = this.minorCellSize * this.minorCellsCountX;
      vertices = [new THREE.Vector3(-x, y, 0.0), new THREE.Vector3(x, y, 0.0)];
    }

    const material = isMajorLine ? this.gridMajorLineMaterial : isMediumLine ? this.gridMediumLineMaterial : this.gridMinorLineMaterial;
    material.transparent = appModel.showGridOverPlan;

    const line = new THREE.Line(new THREE.BufferGeometry().setFromPoints(vertices), material);
    if (isMinorLine) {
      line.computeLineDistances();
    }
    line.renderOrder = /*appModel.showGridOverPlan ? GLOBAL_RULERS_RENDER_ORDER :*/ GLOBAL_GRID_RENDER_ORDER;
    this.soRoot.add(line);
  }
}
