import { action, makeObservable, observable } from "mobx";
import { DTOConvertOptions, EntityDataType, PersistentObject } from "../entities/entityManager";
import { RawVariation } from "../services/rawTypes";
import { kindVariationRaw } from "../services/allKinds";
import { deepCopy2DTO } from "../helpers/pojo";
import { SnapshotPosition } from "../editor/managers/PreviewManager/PreviewManager";

export class Variation implements PersistentObject<Variation, RawVariation> {
  id: string = "";
  name: string = "";
  corePlanId: string = "";
  createdAt: Date = new Date();
  createdBy: number = 0;
  data: Record<string, any> = {};
  guid: string = "";

  constructor(name: string = "") {
    this.name = name;

    makeObservable(this, {
      name: observable,
      createdAt: observable,
      createdBy: observable,
      data: observable,
      fromDTO: action,
      //data: observable,
    });
  }
  static readonly kind = kindVariationRaw;
  toDTO(format?: EntityDataType, options?: DTOConvertOptions): RawVariation {
    return deepCopy2DTO(
      {
        kind: Variation.kind.id,
        corePlanId: this.corePlanId,
        id: this.id,
        name: this.name,
        createdAt: this.createdAt.toISOString(), // Convert Date to ISO string for serialization
        createdBy: this.createdBy,
        data: this.data,
        guid: this.guid,
        //commands: ,
      },
      { scope: options?.scope, only: options?.onlyProps }
    ) as unknown as RawVariation;
  }
  fromDTO(dto: RawVariation, format?: EntityDataType): Variation {
    if (Variation.kind.id != dto?.kind) throw "dto:RawVariation: incorrect kind";
    this.id = dto.id;
    this.corePlanId = dto.corePlanId;
    this.name = dto.name;
    this.data = dto.data;
    this.createdAt = new Date(dto.createdAt); // Convert string to Date when deserializing
    this.createdBy = dto.createdBy;
    this.guid = dto.guid;
    if (format === "APIdata" || format === "APIdata.saved") {
      if (dto.corePlanId) {
        this.corePlanId = dto.corePlanId;
      }
    }
    return this;
  }

  public async generateSnapshotThumbnail(screenshotDataUrl: string, position: SnapshotPosition): Promise<File> {
    // Convert the data URL to a Blob
    const response = await fetch(screenshotDataUrl);
    const blob = await response.blob();

    // Create a File object from the Blob
    const jpegFileName = `Preview_Snapshot_${position}.jpeg`;
    return new File([blob], jpegFileName, { type: "image/jpeg" });
  }

  public async generateMaterialThumbnail(imageContent: string): Promise<File> {
    // Create a Blob from the base64 image content
    const byteCharacters = atob(imageContent);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: "image/jpeg" });

    // Create a File object from the Blob
    const jpegFileName = `Material_Thumbnail.jpeg`;
    return new File([blob], jpegFileName, { type: "image/jpeg" });
  }
}
