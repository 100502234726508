import { useState } from "react";
import { entityManager } from "../../../../entities/entityManager";
import { StatisticsTools } from "../../../../editor/tools/StatisticsTools";
import { Button, InputNumber, Row } from "antd";
import { showToastMessage } from "../../../../helpers/messages";
import { GENERATE_EXCEL_ERROR_MESSAGE } from "../../../../editor/consts";

import "./CorePlansStatisticsTab.sass";

export const CorePlansStatisticsTab = () => {
  const [isGeneratingStatistics, setIsGeneratingStatistics] = useState<boolean>(false);
  const [months, setMonthts] = useState<number>(1);
  const generateStatistics = async () => {
    setIsGeneratingStatistics(true);
    try {
      const statistics = await entityManager.getCorePlansStatistics(months);
      if (!statistics) {
        return;
      }

      const tools = new StatisticsTools();
      const blob = await tools.generateExcelReport(statistics);

      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "CorePlans-Statistics.xlsx";
      link.click();
    } catch (error) {
      showToastMessage("Error", GENERATE_EXCEL_ERROR_MESSAGE);
    } finally {
      setIsGeneratingStatistics(false);
    }
  };
  const onMonthChange = (value: number) => {
    const monthsValue = `${value}`.replace(/[^\d]+/g, "");
    setMonthts(+monthsValue);
  };

  return (
    <>
      <div className="coreplan-statistics-tab">
        <Row>Enter number of months to generate report:</Row>
        <Row>
          <InputNumber min={1} defaultValue={months} value={months} step={1} precision={0} controls={true} autoFocus={true} onChange={onMonthChange} />
        </Row>
      </div>

      <Row className="buttons-row">
        <div className="right-buttons">
          <Button loading={isGeneratingStatistics} type="primary" onClick={generateStatistics}>
            Generate Report
          </Button>
        </div>
      </Row>
    </>
  );
};
