export type TRenderJob = {
  id: string;
  status: RenderJobStatus;
  errors?: string[];
  createdAt: Date;
  updatedAt: Date;
};

export enum RenderJobStatus {
  Pending = "PENDING",
  Completed = "COMPLETED",
  Failed = "FAILED",
  TimedOut = "TIMED_OUT",
}

export type TGetRenderJobsResponse = {
  jobs: TRenderJob[];
  additionalResultsExist: boolean;
};
